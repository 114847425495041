import { memo } from 'react';
import { useSelector } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTracking } from 'react-tracking'

import { Text } from 'react-internationalization'
import { Button } from '@countr/ui'
import { TRACKER_EVENTS } from '../../utils/trackerEvents'
import Util from '../../utils/Util'

const ConfirmationModal = memo(props => {
  const { trackEvent } = useTracking()
  const user = useSelector(state => state.user.user)
  const theme = useSelector(state => state.app.theme)
  const device = useSelector(state => state.devices.device)
  const style = theme &&
    theme.colors && {
      color: theme.colors.text,
      backgroundColor: theme.colors.body
    }
  const handleClose = () => {
    handleTrack(TRACKER_EVENTS.CLOSE_CONFIRMATION_MODAL)
    if(props.handleCloseConfirmation){
      props.handleCloseConfirmation()
    }
  }

  const handleCancel = () => {
    handleTrack(TRACKER_EVENTS.CANCEL_CONFIRMATION_MODAL)
    if(props.handleCancelBtn){
      props.handleCancelBtn()
    }
    if(props.handleCloseConfirmation){
      props.handleCloseConfirmation()
    }
  }

  const handleConfirm = () => {
    handleTrack(TRACKER_EVENTS.CONFIRM_CONFIRMATION_MODAL)
    props.confirmBtn()
    if(props.handleCloseConfirmation){
      props.handleCloseConfirmation()
    }
  }

  const handleTrack = actionType => {
    trackEvent({
      event: actionType,
      data: { confirmationType: props.type || 'unknown' },
      merchant: user._id
    })
  }

  return (
    <Dialog
      open={props.openConfirmation}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, handleClose)
      }}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown={props.noCloseBtn}>
      <DialogTitle id="form-dialog-title" style={style}>
        {props.confirmationTitle}
      </DialogTitle>
      <DialogContent style={style}>{props.confirmationText}</DialogContent>
      <DialogActions style={style}>
        {!props.noCloseBtn && (
          <Button
            label={<Text id="cancel" />}
            onClick={handleCancel}
            className="secondary"
            testid="confirmation-cancel"
          />
        )}

        {!props.noOkButton && (
          <Button
            label={<Text id="ok" />}
            onClick={handleConfirm}
            className="primary"
            testid="confirmation-ok"
          />
        )}
      </DialogActions>
    </Dialog>
  )
})

export default ConfirmationModal
