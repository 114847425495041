import { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { osName } from 'react-device-detect'
import { Text } from 'react-internationalization'

import { AppInstances } from './../../utils/countrSdkInstance'
import { returnUpdatableDevice } from '../../utils/DeviceUtils'
import { setSettings } from './../../store/actions/settings'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

const mapStateToProps = state => {
  return {
    settings: state.settings,
    devices: state.devices
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSettings: settings => dispatch(setSettings(settings))
  }
}

const MAC_PORTS = ['/dev/cu.usbserial']
const WINDOWS_PORTS = [
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'COM10'
]

export const CDSSettings = memo(props => {
  const [port, setPort] = useState('')

  useEffect(() => {
    if (props.settings.customerScreenPort === '') {
      setPort(osName.indexOf('Mac') >= 0 ? MAC_PORTS[0] : WINDOWS_PORTS[0])
    } else {
      setPort(props.settings.customerScreenPort)
    }
  }, [])

  const handleChange = e => {
    setPort(e.target.value)
  }

  const save = async () => {
    const copy = JSON.parse(JSON.stringify(props.settings))
    copy.customerScreenPort = port
    props.setSettings(copy)

    let deviceSettings = {}
    if (props.devices.device.settings) {
      deviceSettings = Object.assign({}, props.devices.device.settings)
    }

    deviceSettings.web_settings = copy

    const dev = Object.assign({}, props.devices.device)
    dev.settings = deviceSettings

    const countr = await AppInstances.getCountrSdk()
    countr.devices.update(dev._id, returnUpdatableDevice(dev))
    props.handleClose()
  }

  return (
    <Dialog open={props.openCDSSettings} onClose={props.handleClose}>
      <DialogTitle>
        <Text id="cds_settings" />
      </DialogTitle>
      <DialogContent style={{ minWidth: 350 }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Typography component="h2">
              <Text id="cds_port" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField label={<Text id="cds_port" />} value={port} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.handleClose}>
          <Text id="close" />
        </Button>
        <Button variant="contained" color="primary" className="button-primary-color" onClick={save}>
          <Text id="save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(CDSSettings)
