import { memo, useState, useEffect } from 'react';
import { cartUtils } from './../../utils/cartUtils'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CartItemsList from './../Lists/CartItemsList'

import './RecoveryCarts.css'

const RecoveryCartCard = memo(({ cart, handleSelectCart, getDeviceName, setCart }) => {
  const [showCartDetails, setShowCartDetails] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(setCart)
  }, [setCart])

  const getCartAvatar = cart => {
    const name = cartUtils.getCartName({ ...cart }).trim()
    return <Avatar style={{ backgroundColor: '#f44336' }}>{name[0]}</Avatar>
  }

  const handleSelect = event => {
    handleSelectCart(event.target.checked, cart._id)
    setIsChecked(event.target.checked)
  }

  const handleShowDetails = () => {
    const show = !showCartDetails
    setShowCartDetails(show)
  }

  return (
    <Card>
      <CardHeader
        avatar={getCartAvatar(cart)}
        action={
          <Checkbox
            edge="end"
            style={{ color: '#318ed5' }}
            onChange={handleSelect}
            checked={isChecked}
          />
        }
        title={`[${cartUtils.getCartSource(cart)}] ${cartUtils
          .getCartName(cart)
          .trim()} - ${getDeviceName(cart.device)}`}
        subheader={`Last updated: ${new Date(cart.updated_at).toLocaleDateString()}`}
      />
      <CardContent style={{ padding: 0 }}>
        <div style={{ textAlign: 'center' }}>
          <IconButton onClick={handleShowDetails} style={{ fontSize: 12 }}>
            <span
              className="icon-left-arrow"
              style={{ transform: showCartDetails ? 'rotate(90deg)' : 'rotate(-90deg)' }}
            />
          </IconButton>
        </div>
        {showCartDetails && (
          <div style={{ padding: 8 }}>
            <CartItemsList cart={cart} hasDivider={false} />
          </div>
        )}
      </CardContent>
    </Card>
  )
})

export default RecoveryCartCard
