import { Component } from 'react';
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { Text, translate } from 'react-internationalization'
import { AppInstances } from '../../../utils/countrSdkInstance'
import { selectCart } from '../../../store/actions/carts'

import Util from '../../../utils/Util'
import DesktopUtils from '../../../utils/DesktopUtils'

import LoadBar from '../../generic/LoadBar'
import './PayplazaModal'

const mapStateToProps = state => {
  return {
    app: state.app,
    carts: state.carts,
    user: state.user,
    device: state.devices.device
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart))
  }
}

class CCVModal extends Component {
  intervalDesktopConnection = null
  retryCont = 0

  state = {
    transactionUpdateMsgTitle: '',
    transactionUpdateMsg: '',
    ccvStatusMsg: '',
    isTransactionComplete: true
  }

  cleanDesktopConnectionInterval = () => {
    if (this.intervalDesktopConnection) {
      clearInterval(this.intervalDesktopConnection)
      this.intervalDesktopConnection = null
      this.retryCont = 0
    }
  }

  processCCV = () => {
    const ips = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))

    if (ips && ips.local_ip) {
      const web = AppInstances.getDesktopConnection(
        `${process.env.REACT_APP_WS_TYPE}://${
          ips.local_ip
        }:${DesktopUtils.getDesktopListenerPort()}`
      )

      web.onerror = this.ccvErrorListener

      web.onclose = evt => {
        this.cleanDesktopConnectionInterval()
        this.receiveCCVReturn({
          name: 'FAILED'
        })
      }

      web.onmessage = this.ccvMessageListener

      const payment = {
        currency: this.props.currency,
        pay: parseFloat(this.props.total).toFixed(2),
        method: this.props.method || 'CardPayment',
        ccvLocalIp: ips,
        ccv_lang: this.props.language.toUpperCase()
      }

      this.setState({
        transactionUpdateMsgTitle: 'Starting CCV payment...',
        isTransactionComplete: false
      })

      const sendEvent = {
        type: 'pay-ccv',
        method: this.props.method,
        payload: payment
      }

      if (web && web.readyState === 1) {
        web.send(JSON.stringify(sendEvent))
      } else {
        this.intervalDesktopConnection = setInterval(() => {
          if (web && web.readyState === 1) {
            this.cleanCCVListeners(web)
            this.cleanDesktopConnectionInterval()
            this.processCCV()
          }
        }, 500)
      }

      ips.paymentProcessorSettings.map(payment => {
        return payment.ccv.ipAddress
      })
    }
  }

  cleanCCVListeners = socket => {
    switch (socket) {
      case socket.onmessage:
        socket.removeListener('message', this.ccvMessageListener)
        break

      case socket.onerror:
        socket.removeListener('error', this.ccvErrorListener)
        break

      default:
        break
    }
  }

  ccvErrorListener = error => {
    this.cleanDesktopConnectionInterval()

    AppInstances.logError({
      message: `Error when processing CCV payment with Desktop ws connection`,
      user: this.props.user.user._id,
      store: this.props.device.store._id,
      device: this.props.device._id,
      stack: JSON.stringify(error.stack),
      date: new Date().toISOString()
    })
  }

  ccvMessageListener = message => {
    if (message.data === 'pong') return
    message = JSON.parse(message.data)
    if (message && message.name && message.name.toLowerCase() === 'printer') {
      if (this.props.method && this.props.method === 'refund') {
        localStorage.setItem('ccv-refund-payload', JSON.stringify(message.payload))
      } else {
        const mergedCcvPaymentInfo = Object.assign({}, this.props.carts.selectedCart)
        mergedCcvPaymentInfo.ccvInfo = message.payload
        this.props.selectCart(mergedCcvPaymentInfo)
      }
    } else {
      this.receiveCCVReturn(message)
      this.setState({ ccvStatusMsg: message.name })
    }
  }

  receiveCCVReturn = message => {
    switch (message.name) {
      case 'AUTHORISED':
        this.setState({
          transactionUpdateMsgTitle: translate('authorization_ok'),
          isTransactionComplete: true
        })

        this.props.handleCloseCCVModal('AUTHORISED')

        break
      case 'CANCELLED':
        this.setState({
          transactionUpdateMsgTitle: translate('transaction_aborted'),
          isTransactionComplete: true
        })
        break
      case 'DECLINED':
        this.setState({
          transactionUpdateMsgTitle: translate('authorization_error'),
          transactionUpdateMsg: message.payload,
          isTransactionComplete: true
        })
        break
      case 'FAILED':
        this.setState({
          transactionUpdateMsgTitle: translate('authorization_error'),
          isTransactionComplete: true
        })
        break
      case 'ERROR':
        this.setState({
          transactionUpdateMsgTitle: translate('authorization_error'),
          isTransactionComplete: true
        })
        break
      case 'CashierDisplay':
        this.setState({
          transactionUpdateMsgTitle: 'Payment Cashier Display',
          transactionUpdateMsg: message.payload.replace(/-/g, ''),
          isTransactionComplete: this.checkCashierDisplayExceptions(message.status)
        })
        break
      default:
        this.setState({
          transactionUpdateMsgTitle: translate('authorization_error'),
          isTransactionComplete: true
        })
        break
    }
  }

  checkCashierDisplayExceptions = status => {
    if (!status) {
      return false
    }

    switch (status.trim()) {
      case 'CARD UNUSABLE':
      case 'ONBRUIKBARE KAART':
      case 'KAART TERUGNEMEN':
        return true
      default:
        return false
    }
  }

  handleCloseCCVModal() {
    this.props.handleCloseCCVModal(false)
  }

  componentDidMount = () => {
    this.processCCV()
  }

  render() {
    return (
      <Dialog
        open={this.props.openCCVModal}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(reason, this.handleCloseCCVModal)
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={true}>
        {!this.state.isTransactionComplete && <LoadBar />}
        <DialogTitle id="form-dialog-title">
          <div>CCV - {this.state.transactionUpdateMsgTitle}</div>
        </DialogTitle>
        <DialogContent className="payplaza-content">
          <Grid container alignItems="center" justifyContent="center" className="center">
            <Grid item xs={12} className="payplaza-result">
              {this.state.transactionUpdateMsg}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!this.state.isTransactionComplete ? (
            <div />
          ) : (
            <Button
              className="payment-button"
              onClick={() => this.props.handleCloseCCVModal(this.state.ccvStatusMsg)}>
              <Text id="ok" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const CCVModalComponentConnected = connect(mapStateToProps, mapDispatchToProps)(CCVModal)

export default CCVModalComponentConnected
