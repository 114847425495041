import { memo } from 'react';
import ListItem from '@material-ui/core/ListItem'
import { Text } from 'react-internationalization'
import './Menu.scss'

const MenuItem = memo(
  ({ icon, title, subtitle, action, disabled, hasFont, divider = true }) => {
    return (
      <ListItem
        button
        divider={divider}
        onClick={action}
        disabled={disabled}
        className="menu-item-btn">
        <span className={icon} />
        <div className="text">
          <span>
            {hasFont ? (
              <font className="main-menu-header-items">{title}</font>
            ) : (
              <Text id={title} />
            )}
            {subtitle && (
              <span>
                <Text id="trial_expired" />
              </span>
            )}
          </span>
        </div>
      </ListItem>
    )
  }
)

export default MenuItem
