import { useState } from 'react';
import { useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import { Button, Divider, Row, Input } from '@countr/ui'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Util from '../../utils/Util'
import PaymentSelectionModal from '../Payments/PaymentSelectionModal'

const TipModal = ({ open, handleClose, currency, transaction, handleUpdateTransaction }) => {
  const [tipAmount, setTipAmount] = useState('')
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }

  const handleChangeTipAmount = v => {
    if (isNaN(v)) return
    setTipAmount(v)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(reason, handleClose)
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown>
        <DialogTitle id="form-dialog-title" style={style}>
          <Row justifyContent="space-between">
            <Text id="add_tip" />
          </Row>
        </DialogTitle>
        <DialogContent style={style}>
          <Row>
            <Text id="gratuity_amount" />
          </Row>
          <Divider />
          <Row>
            {currency}
            <Input value={tipAmount} onChange={handleChangeTipAmount} type="number" />
          </Row>

          <Row>
            <PaymentSelectionModal
              handleClosePaymentSelectionModal={handleClose}
              onlyButtons
              total={0}
              change={tipAmount}
              paidProducts={transaction?.items || []}
              openTransaction={transaction}
              handleUpdateTransaction={handleUpdateTransaction}
              closeTipsModal={handleClose}
              disablePaymentButtons={!tipAmount?.length}
            />
          </Row>
        </DialogContent>

        <DialogActions style={style}>
          <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TipModal
