import { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { Text, setLanguage, translate } from 'react-internationalization'
import { Helmet } from 'react-helmet'
import Intercom from 'react-intercom'

import OpenExternalUrlIframe from './components/generic/OpenExternalUrlIframe'

import { cartUtils } from './utils/cartUtils'
import { AppInstances } from './utils/countrSdkInstance'
import { RequestQueue } from './utils/RequestQueue'
import LogUtils from './utils/LogUtils'
import { returnUpdatableDevice } from './utils/DeviceUtils'
import ProductUtils from './utils/ProductUtils'
import EmployeeShift from './components/Employees/Shift/EmployeeShift'

import CountrLoader from './utils/CountrLoader'
import Login from './components/Login/Login'
import Registration from './components/Login/Registration'
import Main from './components/Main'
import Settings from './components/Settings/Settings'
import ConfirmationModal from './components/generic/ConfirmationModal'
import ChangePropertyModal from './components/generic/ChangePropertyModal'
import AggregatedInvoicesModal from './components/Payments/AggregatedInvoicesModal'
import ToastMessage from './components/generic/ToastMessage'
import { ConviousDialog, ConviousApiManager } from 'convious-api-manager'

import { setSettings, disableKioskMode } from './store/actions/settings'
import {
  showCart,
  showMainMenu,
  showSettings,
  setToastMessage,
  setDisconnectedTime,
  showDeliveryNotes,
  showExternalOpenUrl
} from './store/actions/app'
import { addDevice } from './store/actions/devices'
import { showEmployeesModal } from './store/actions/employees'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import LogoImg from './assets/countr_white.png'

import './App.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    loading: state.loading,
    user: state.user,
    settings: state.settings,
    carts: state.carts,
    devices: state.devices,
    employees: state.employees,
    queue: state.queue
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showCart: () => dispatch(showCart()),
    setSettings: settings => dispatch(setSettings(settings)),
    addDevice: dev => dispatch(addDevice(dev)),
    showSettings: () => dispatch(showSettings()),
    showMainMenu: () => dispatch(showMainMenu()),
    disableKioskMode: () => dispatch(disableKioskMode()),
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    showEmployeesModal: () => dispatch(showEmployeesModal()),
    setDisconnectedTime: (time, reason) => dispatch(setDisconnectedTime(time, reason)),
    showExternalOpenUrl: obj => dispatch(showExternalOpenUrl(obj)),
    showDeliveryNotes: () => dispatch(showDeliveryNotes())
  }
}

const CHECK_STATUS_INTERVAL = 5000

class App extends Component {
  state = {
    openSettingsDialog: false,
    openDeliveryNotes: false,
    connectionStatus: true,
    openConnectionStatusAlert: false,
    kioskModeCounter: 0,
    openAdminPin: false
  }

  intervalConnection = null

  kioskMode = () => {
    const newValue = this.state.kioskModeCounter + 1
    this.setState({ kioskModeCounter: newValue })

    if (newValue === 3) {
      this.setState({ openAdminPin: true })
    }
  }

  handleCloseChangePropertyModal = () => {
    this.setState({ openAdminPin: false, kioskModeCounter: 0 })
  }

  handleConfirmChangePropertyModal = async value => {
    this.setState({ openAdminPin: false })

    if (this.props.devices.device.settings !== undefined) {
      const pin = this.props.devices.device.settings.admin_pin

      if (value === pin) {
        this.props.disableKioskMode()
        this.setState({ kioskModeCounter: 0 })
        const settings = JSON.parse(JSON.stringify(this.props.settings))
        settings.kioskMode = false
        this.props.setSettings(settings)
        this.props.setToastMessage('kiosk_mode_disabled')

        const device = { ...this.props.devices.device }
        device.settings = {
          ...device.settings,
          web_settings: settings
        }

        this.props.addDevice(device)
        const countr = await AppInstances.getCountrSdk()
        countr.devices.update(device._id, returnUpdatableDevice(device))
      } else {
        this.props.setToastMessage('admin_pin_incorrect')
        this.setState({ kioskModeCounter: 0 })
      }
    }
  }

  componentDidMount() {
    const lang = localStorage.getItem('CountrWeb:Language') || 'en'
    setLanguage(lang)
    document.documentElement.lang = lang
    this.checkStatus()
    LogUtils.initSDKSocketListeners()
  }

  componentWillUnmount() {
    clearInterval(this.intervalConnection)
  }

  handleSettings = () => {
    this.props.showSettings()
    this.setState({ openSettingsDialog: this.props.app.showSettingsDialog })
  }

  handleDeliveryNotes = () => {
    this.props.showDeliveryNotes()
    this.setState({ showDeliveryNotes: this.props.app.showDeliveryNotes })
  }

  handleCloseConnectionStatusAlert = () => {
    const { store } = this.props.devices

    if (!!store && !!store.options && !!store.options.disallowOffline && !navigator.onLine) {
      this.props.setToastMessage('disallow_offline')
      return
    }

    this.setState({ openConnectionStatusAlert: false })
  }

  getEmployeeName = employee => {
    return employee.first_name + ' ' + employee.last_name
  }

  checkStatus = () => {
    this.intervalConnection = setInterval(async () => {
      if (this.state.connectionStatus && !navigator.onLine) {
        this.setState({ openConnectionStatusAlert: true })
      }

      if (this.state.connectionStatus !== navigator.onLine) {
        this.setState({ connectionStatus: navigator.onLine })
        if (navigator.onLine) {
          this.checkProductsDelta()
        }
      }

      const queueList = this.props.queue.queueList
      if (navigator.onLine && queueList.length) {
        const countr = await AppInstances.getCountrSdk()
        if (countr.ws.socket.connected) {
          RequestQueue.dequeueActions(queueList)
        }
      }
    }, CHECK_STATUS_INTERVAL)
  }

  getConnectionStatus = () => {
    return this.state.connectionStatus
      ? { background: '#36e458', borderColor: '#9de09d' }
      : { background: '#f73f3f', borderColor: '#e09d9d' }
  }

  getConnectionFailMsg = () => {
    const { store } = this.props.devices
    return !!store && !!store.options && !!store.options.disallowOffline
      ? 'disallow_offline_fiscal'
      : 'no_connection_sync'
  }

  getHeaderTitle = () => {
    const { user } = this.props.user
    const DEFAULT = 'Countr - Web POS'
    return user && user.email ? `${user.email.split('@')[0]} - ${DEFAULT}` : DEFAULT
  }

  checkProductsDelta = () => {
    const { device, store } = this.props.devices
    ProductUtils.checkDelta(store._id, device._id)
  }

  handleCloseConviousDialog = () => {
    this.props.showExternalOpenUrl({
      showNative: false
    })
  }

  handleConviousCartCreated = async (cartId, timeslot, product, cartProducts) => {
    const response = await ConviousApiManager.getCart(
      cartId,
      this.props.user.user.extras.external_account_name
    )

    response.items.forEach(responseItem => {
      cartProducts.forEach(cartProduct => {
        if (cartProduct.id === responseItem.productId) {
          cartProduct.price = parseFloat(responseItem.price)
        }
      })
    })

    product.variants[0].price = parseFloat(0)
    cartUtils.addProductToCart(
      this.props.carts.selectedCart,
      product,
      product.variants[0],
      cartProducts,
      1,
      this.props.settings,
      cartId
    )
  }

  render() {
    let user = {}
    if (this.props.user.user._id) {
      user = {
        user_id: this.props.user.user._id,
        email: this.props.user.user.username,
        name: this.props.user.user.contact ? this.props.user.user.contact.first_name : '',
        device_type: `Countr Web - v${process.env.REACT_APP_VERSION}`,
        product_count: this.props.user.user.product_count
      }
    }

    return (
      <div id="countr-app" className="Countr">
        <Helmet>
          <title>{this.getHeaderTitle()}</title>
        </Helmet>
        {this.props.loading.loading && <CountrLoader />}
        <header id="countr-header" className="Countr-header app-header">
          <div className="status" style={this.getConnectionStatus()} />
          {window.location.pathname === '/main' && !this.props.settings.kioskMode && (
            <IconButton
              className="menuButton"
              color="inherit"
              aria-label="Menu"
              onClick={this.props.showMainMenu}>
              <span className="icon-hamburger" />
            </IconButton>
          )}
          <img
            src={LogoImg}
            className="Countr-header-logo"
            alt="Countr-logo"
            onClick={this.kioskMode}
          />

          {window.location.pathname === '/main' && (
            <div>
              {this.props.settings.openCloseShift && (
                <EmployeeShift employeeId={this.props.employees.selectedEmployee._id} />
              )}

              {Object.keys(this.props.employees.selectedEmployee).length > 0 && (
                <Button className="employee-icon" onClick={this.props.showEmployeesModal}>
                  <span className="icon-features-pinemployee" />
                  <span className="employee-icon-span">
                    {this.getEmployeeName(this.props.employees.selectedEmployee)}
                  </span>
                </Button>
              )}

              {/* Cart Button */}
              <IconButton
                id="header-cart-btn"
                className="mobile-btn"
                aria-label="Mobile"
                disabled={this.props.settings.cartAlwaysOpen}
                onClick={() => this.props.showCart()}>
                <Badge
                  overlap="rectangular"
                  id="header-shopping-cart-badge"
                  className="cart-items-count"
                  badgeContent={cartUtils.calculateCartItemsNum(this.props.carts.selectedCart)}
                  color="error">
                  <span className="icon-cart" />
                </Badge>
              </IconButton>
            </div>
          )}
        </header>
        <div className="Root">
          <Switch>
            <Route
              exact
              style={{ position: 'relative', zIndex: '2' }}
              path="/"
              render={({ history, location }) => <Login history={history} location={location} />}
            />
            <Route
              exact
              path="/registration"
              render={({ history }) => <Registration history={history} />}
            />
            <Route exact path="/main" render={({ history }) => <Main history={history} />} />
            <Route
              path="*"
              render={({ history, location }) => <Login history={history} location={location} />}
            />
          </Switch>
        </div>
        {this.props.app.showSettingsDialog && (
          <Settings
            openSettingsDialog={this.props.app.showSettingsDialog}
            handleCloseSettings={this.handleSettings}
          />
        )}
        {this.props.app.showDeliveryNotes && (
          <AggregatedInvoicesModal
            openConfirmation={this.props.app.showDeliveryNotes}
            handleClose={this.handleDeliveryNotes}
            handleConfirm={() => {}}
            dialogTitle="Delivery notes"
          />
        )}
        <ConfirmationModal
          openConfirmation={this.state.openConnectionStatusAlert}
          handleCloseConfirmation={this.handleCloseConnectionStatusAlert}
          confirmBtn={this.handleCloseConnectionStatusAlert}
          noCloseBtn={true}
          confirmationTitle={<Text id="offline" />}
          confirmationText={<Text id={this.getConnectionFailMsg()} />}
          type="offline_alert"
        />
        {this.state.openAdminPin && (
          <ChangePropertyModal
            openChangeProperty={this.state.openAdminPin}
            handleClose={this.handleCloseChangePropertyModal}
            handleConfirm={this.handleConfirmChangePropertyModal}
            changePropertyTitle="admin_pin"
            changePropertyText="device_locked_enter_pin"
            changePropertyHelpText="admin_pin_should_be"
            errorCounterMin={4}
            errorCounterMax={6}
          />
        )}
        {this.props.app.isToastDisplayed && <ToastMessage />}
        {process.env.REACT_APP_PROD === 'true' && user.user_id && (
          <Intercom appID={process.env.REACT_APP_INTERCOM_ID} {...user} />
        )}

        {/* External url modal */}
        {this.props.app.openExternalUrlIframe?.show && <OpenExternalUrlIframe />}
        {/*  */}

        {this.props.app.openExternalUrlIframe?.showNative && (
          <ConviousDialog
            categoryId={this.props.app.openExternalUrlIframe.url}
            product={this.props.app.openExternalUrlIframe.product}
            closeDialogCallBack={this.handleCloseConviousDialog}
            language={this.props.settings.language}
            cartCreatedCallback={this.handleConviousCartCreated}
            cartId={this.props.carts.selectedCart.id}
            account_slung={this.props.user.user.extras.external_account_name}
            translations={{
              from: translate('from'),
              price: translate('price'),
              confirm: translate('confirm'),
              cancel: translate('cancel'),
              continue: translate('next'),
              no_timeslots_available: translate('no_timeslots_available'),
              back: translate('back')
            }}></ConviousDialog>
        )}
      </div>
    )
  }
}

const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App)
export default withRouter(AppConnected)
