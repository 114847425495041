import { memo } from 'react';
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { setActiveCategory } from '../../../store/actions/categories'
import { setProductSearchInput, runProductSearch } from '../../../store/actions/products'
import { Text } from 'react-internationalization'

const mapStateToProps = state => {
  return {
    categories: state.categories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveCategory: id => dispatch(setActiveCategory(id)),
    setProductSearchInput: str => dispatch(setProductSearchInput(str)),
    runProductSearch: shouldRun => dispatch(runProductSearch(shouldRun))
  }
}

const CategoriesModal = memo(props => {
  const handleSelectCategory = event => {
    resetProductList()
    const category =
      event.target.value === 'all_categories'
        ? 'all_categories'
        : JSON.parse(event.target.value)._id
    props.setActiveCategory(category)
    props.handleCloseCategories()
  }

  const resetProductList = () => {
    props.setProductSearchInput('')
    props.runProductSearch(false)
  }

  return (
    <Dialog
      open={props.categoriesDialog}
      onClose={props.handleCloseCategories}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Text id="categories" />
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="ringtone"
          name="ringtone"
          onChange={$event => handleSelectCategory($event)}>
          <FormControlLabel
            value="all_categories"
            control={<Radio className="product-list-categories-radio-btn" />}
            label={<Text id="all_categories" />}
          />
          {props.categories.categories.map(
            category =>
              category.visible && (
                <FormControlLabel
                  value={JSON.stringify(category)}
                  key={category._id}
                  control={<Radio className="product-list-categories-radio-btn" />}
                  label={category.name}
                />
              )
          )}
        </RadioGroup>
      </DialogContent>
    </Dialog>
  )
})

const CategoriesModalConnected = connect(mapStateToProps, mapDispatchToProps)(CategoriesModal)
export default CategoriesModalConnected
