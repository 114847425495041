import { memo, Fragment, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Text, translate } from 'react-internationalization'
import { AppInstances } from './../../../utils/countrSdkInstance'
import Util from '../../../utils/Util'
import DesktopUtils from '../../../utils/DesktopUtils'
import LoadBar from '../../generic/LoadBar'
import './PayplazaModal.css'

const EVENTS = {
  CUSTOM_PAYMENT: 'custom-payment',
  CUSTOM_PAYMENT_START: 'custom-payment-start',
  CUSTOM_PAYMENT_CANCEL: 'custom-payment-cancel'
}
const METHODS = {
  PAYMENT_STARTED: 'paymentStarted',
  MESSAGE_RECEIVED: 'messageReceived',
  CONTINUE_WITH_PAYMENT: 'continueWithPayment',
  HANDLE_RESULT: 'handleResult',
  HANDLE_ERROR: 'handleError'
}
const PAYMENT_METHODS = {
  CIKAM: 'cikam'
}

const CustomPayment = memo(
  ({
    method,
    amount,
    currency,
    reference,
    open,
    handleClose,
    handleResultPayment,
    transaction,
    handleClearExtra
  }) => {
    const [pendingPayment, setPendingPayment] = useState(false)
    const [title, setTitle] = useState('')
    const [messageDisplay, setMessageDisplay] = useState('')
    const [totalPaid, setTotalPaid] = useState(0)
    const [remainingToPay, setRemainingToPay] = useState(0)
    const [showContinueButton, setShowContinueButton] = useState(false)
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [showCloseButton, setShowCloseButton] = useState(false)
    const [extraInfo, setExtraInfo] = useState({})
    const [qrCode, setQrCode] = useState(null)
    const appState = useSelector(state => state.app)
    const deviceState = useSelector(state => state.devices.device)
    const currentCart = useSelector(state => state.carts.selectedCart)

    const ws = useRef()
    const wsPort = useRef()

    useEffect(() => {
      wsPort.current = DesktopUtils.getDesktopListenerPort()
      ws.current = AppInstances.getDesktopConnection(
        `${process.env.REACT_APP_WS_TYPE}://${appState.desktopIP}:${wsPort.current}`
      )
      setTitle(method.method)
      initializePayment()

      return () => {
        ws.current.removeEventListener('message', onMessage)
      }
    }, [])

    const initializePayment = () => {
      if (!appState.isDesktop) {
        setPendingPayment(false)
        setMessageDisplay('Desktop only method!')
        setShowCloseButton(true)
      } else if (!!appState.desktopIP) {
        const sendEvent = {
          ip: appState.desktopIP,
          type: EVENTS.CUSTOM_PAYMENT,
          payload: {
            method: method.method,
            provider: method.provider,
            extras: method.extras,
            amount: Math.round(amount * 100), // Amount should be in cents
            reference: reference,
            currency: currency.code,
            device_id: deviceState.device_id,
            device: deviceState._id,
            checkoutId: currentCart._id,
            info: method.extraInfo
          }
        }

        // Refund need paymentId from transaction.paymets
        // props.transaction only present in Refunds
        if (!!transaction) {
          sendEvent.payload.checkoutId = transaction._id
          const payment =
            transaction.payments.find(
              p =>
                !!p.info &&
                (!!p.info.paymentId ||
                  !!p.info.approvalID ||
                  !!p.info.approvalCode ||
                  !!p.info.info?.paymentId)
            ) || {}
          sendEvent.payload.paymentId =
            (payment.info || {}).paymentId ||
            (payment.info || {}).approvalID ||
            (payment.info || {}).approvalCode ||
            ((payment.info || {}).info || {}).paymentId

          sendEvent.payload.paymentTimeStamp =
            ((payment.info || {}).iso8601TxDate || '').substr(0, 19) + 'Z'
        }

        ws.current.onerror = () => {
          handleError({
            message: translate('error_try_again')
          })
        }

        ws.current.addEventListener('message', onMessage)

        if (ws.current.readyState === 1) {
          ws.current.send(JSON.stringify(sendEvent))
          setRemainingToPay(amount)
        }
      } else {
        handleError({
          message: translate('error_try_again')
        })
      }
    }

    const onMessage = e => {
      if (e.data) {
        const event = JSON.parse(e.data)

        if (event.event) {
          switch (event.event) {
            case METHODS.PAYMENT_STARTED:
              paymentStarted(event.payload)
              break
            case METHODS.MESSAGE_RECEIVED:
              messageReceived(event.payload)
              break
            case METHODS.CONTINUE_WITH_PAYMENT:
              continueWithPayment(event.payload)
              break
            case METHODS.HANDLE_RESULT:
              handleResult(event.payload)
              break
            case METHODS.HANDLE_ERROR:
              handleError(event.payload)
              break
            default:
              break
          }
        }
      }
    }

    const paymentStarted = payload => {
      if (!!payload && !pendingPayment) {
        setPendingPayment(true)
        setShowContinueButton(false)
        setShowCloseButton(false)
        setShowCancelButton(true)
      }

      // Payment Started event send the Payconiq QR code to be paid
      if (!!payload.extra) {
        setExtraInfo(payload.extra)

        if (!!payload.extra.paymentQR) {
          setQrCode(payload.extra.paymentQR)
        }
      }
    }

    const messageReceived = payload => {
      setTitle(
        translate('total_to_pay', {
          currency: currency.symbol,
          amount: parseFloat(amount).toFixed(2)
        })
      )
      if (!!payload.message) {
        setMessageDisplay(payload.message)
      } else if (payload.total_paid_value && payload.remaining_value) {
        setTotalPaid(payload.total_paid_value)
        setRemainingToPay(payload.remaining_value)
      }
    }

    const continueWithPayment = payload => {
      setShowContinueButton(true)
      setShowCloseButton(true)
      setTitle(translate('sign_out_header'))
      setMessageDisplay(payload.message.replace(/(?:\r\n|\r|\n)/g, '\n'))
    }

    const handleContinue = () => {
      setPendingPayment(true)

      if (!!appState.desktopIP) {
        const sendEvent = {
          ip: !!appState.desktopIP,
          type: EVENTS.CUSTOM_PAYMENT_START
        }

        if (ws.current.readyState === 1) {
          ws.current.send(JSON.stringify(sendEvent))
          setShowContinueButton(false)
          setShowCancelButton(true)
        }
      }
    }

    const handleCancel = () => {
      if (!pendingPayment) {
        handleClose()
        return
      }

      if (!!appState.desktopIP) {
        const sendEvent = {
          ip: appState.desktopIP,
          type: EVENTS.CUSTOM_PAYMENT_CANCEL
        }

        if (ws.current.readyState === 1) {
          ws.current.send(JSON.stringify(sendEvent))
        }
      }
    }

    const handleResult = result => {
      setShowCancelButton(false)

      if (!!result && !!result.success) {
        handleResultPayment(result)
      } else {
        handleError(result)
      }
    }

    const handleError = async error => {
      const { message } = error
      setMessageDisplay(message && message.length ? message : JSON.stringify(error))
      setPendingPayment(false)
      setShowContinueButton(false)
      setShowCloseButton(true)
      setShowCancelButton(false)

      if (method.extraInfo && handleClearExtra) {
        handleClearExtra()
      }
    }

    return (
      <Dialog
        open={open}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(reason, handleClose)
        }}
        disableEscapeKeyDown={true}>
        {!showCloseButton && <LoadBar />}
        <DialogTitle className="payplaza-title">{title}</DialogTitle>
        <DialogContent className="payplaza-content">
          <Grid container alignItems="center" justifyContent="center" className="center">
            <Grid item xs={12} className="payment-title">
              <strong>{messageDisplay}</strong>
            </Grid>
            {method.method === PAYMENT_METHODS.CIKAM && !showContinueButton && !showCloseButton && (
              <Fragment>
                <Grid item xs={12} className="payment-info">
                  <Text
                    id="total_amount_paid"
                    values={{
                      currency: currency.symbol,
                      amount: parseFloat(totalPaid).toFixed(2)
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="payment-info">
                  <Text
                    id="total_remaining"
                    values={{
                      currency: currency.symbol,
                      amount: parseFloat(remainingToPay).toFixed(2)
                    }}
                  />
                </Grid>
              </Fragment>
            )}
            {!showCloseButton && extraInfo && qrCode && (
              <img
                className="payment-img"
                alt="payment qr code"
                draggable="false"
                width="240"
                height="240"
                src={qrCode}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {showCloseButton && (
            <Button color="secondary" variant="contained" onClick={handleClose}>
              <Text id="close" />
            </Button>
          )}
          {showCancelButton && (
            <Button color="secondary" onClick={handleCancel}>
              <Text id="cancel" />
            </Button>
          )}
          {showContinueButton && (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#318ed5',
                color: '#fff'
              }}
              onClick={handleContinue}>
              <Text id="continue" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
)

export default CustomPayment
