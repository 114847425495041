import { Component } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from '@countr/ui'

import { Text } from 'react-internationalization'

import { AppInstances } from '../../utils/countrSdkInstance'
import { generateFingerprintUuid } from '../../utils/DeviceUtils'
import Util from '../../utils/Util'
import { returnUpdatableDevice } from './../../utils/DeviceUtils'

import { addUser } from './../../store/actions/user'
import { setToastMessage } from '../../store/actions/app'
import { addDevice } from './../../store/actions/devices'
import {
  loadingTrue,
  loadingFalse,
  setLoadingMsg,
  setLoadingValue
} from '../../store/actions/loading'
import { addTransactionHead } from '../../store/actions/transactions'
import { setSettings } from '../../store/actions/settings'
import { loadPaymentsMethods } from '../../store/actions/payments'

import ResourceLoader from '../../ResourceLoader'

import './Registration.css'

const mapStateToProps = state => {
  return {
    user: state.user,
    settings: state.settings,
    products: state.product,
    payments: state.payments,
    transactions: state.transactions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse()),
    setLoadingMsg: msg => dispatch(setLoadingMsg(msg)),
    setLoadingValue: value => dispatch(setLoadingValue(value)),
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    addUser: user => dispatch(addUser(user)),
    addDevice: device => dispatch(addDevice(device)),
    setSettings: settings => dispatch(setSettings(settings)),
    loadPaymentsMethods: payments => dispatch(loadPaymentsMethods(payments)),
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction))
  }
}

class Registration extends Component {
  state = {
    deviceName: '',
    storesList: [],
    storeSelected: '',
    devicesList: [],
    deviceSelected: '',
    noStores: false,
    noDeviceError: false,
    noStoreError: false,
    loadingStores: true
  }

  // Resouce loader
  resource = null

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      noDeviceError: false
    })
  }

  handlerStoreSelect = name => event => {
    this.setState({
      [name]: event.target.value,
      noStoreError: false
    })
  }

  checkDevices = async () => {
    const countr = await AppInstances.getCountrSdk()
    return countr.devices.read({ limit: 100, sort: '-updated_at' }).then(
      devices => {
        if (devices && devices.length) {
          const last = devices.find(d => {
            if (d.store === this.state.storeSelected && d.settings && d.settings.web_payments) {
              const payplaza = d.settings.web_payments.find(p => p.method === 'payplaza')

              if (payplaza.extra && payplaza.extra.length) {
                return true
              }
            }
            return false
          })

          if (last) {
            // set the payments and settings of last updated device
            this.props.loadPaymentsMethods(last.settings.web_payments)
            this.props.setSettings(last.settings.web_settings)
            return last.settings
          } else {
            // TODO: Get another device with settings
            const scondTry = devices.find(d =>
              d.store === this.state.storeSelected && d.settings && d.settings.web_payments
                ? true
                : false
            )
            if (scondTry) {
              // set the payments and settings of last updated device
              this.props.loadPaymentsMethods(scondTry.settings.web_payments)
              this.props.setSettings(scondTry.settings.web_settings)
              return scondTry.settings
            }
          }

          return 'no_settings'
        }
      },
      error => {
        console.log('### [Registration] New device and it doesnt have old devices to recovery.')
        return 'no_settings'
      }
    )
  }

  register = async () => {
    if (!this.state.deviceName.length) {
      this.props.setToastMessage('deviceid_mandatory')
      this.setState({ noDeviceError: true })
      return
    }

    if (this.state.storeSelected) {
      this.props.loadingTrue()
      const devicefingerprintid = await generateFingerprintUuid()

      const isThirdPartUUIDWorkaround = localStorage.getItem('isThirdPartUUIDWorkaround')

      const result = Util.getDeviceUuid(devicefingerprintid, isThirdPartUUIDWorkaround)

      localStorage.setItem('deviceUuidUpdated', true)

      const param = {
        store: this.state.storeSelected,
        store_id: this.state.storeSelected,
        name: this.state.deviceName,
        uuid: result,
        merchant: this.props.user.user._id,
        info: {
          platform: navigator.platform,
          useragent: navigator.userAgent
        },
        settings: {
          language: this.props.settings.language
        }
      }

      const countr = await AppInstances.getCountrSdk()

      countr.ws.devices.create(JSON.stringify(param)).then(
        device => {
          countr.ws.devices.readOne.detailed(device._id).then(async dev => {
            for (var key in localStorage) {
              if (key.indexOf('CountrLite:Cart-') >= 0) {
                localStorage.removeItem(key)
              }
              localStorage.removeItem('CountrLite:CurrentCart')
            }

            this.resource = await new ResourceLoader(this.props.user.user, dev)

            this.resource.loadAllResources(dev).then(
              async success => {
                const settings = await this.checkDevices()

                if (settings && settings !== 'no_settings') {
                  dev.settings = settings
                  countr.devices.update(dev._id, returnUpdatableDevice(dev)).then(
                    dev_updated => {
                      console.log('Adding payment methods to device')
                    },
                    error => {
                      console.log(error)
                      console.log('Not able to save new settings to this device')
                      this.logError(dev, error)
                    }
                  )
                }
                localStorage.setItem('CountrLite:Device', JSON.stringify(dev))
                this.props.addDevice(dev)

                this.props.setLoadingMsg('')

                this.checkLastTransaction()

                // Setting the last transaction number as 0 for new device
                localStorage.setItem('CountrLite:LastTransaction', '0')

                this.props.loadingFalse()
                this.props.history.push({
                  pathname: '/main'
                })
              },
              error => {
                console.log('Registration -> register -> error', error)
                this.props.loadingFalse()
                this.props.history.push({
                  pathname: '/'
                })
              }
            )
          })
        },
        error => {
          console.log('Registration -> register -> error', error)
          this.props.loadingFalse()
          this.props.history.push({
            pathname: '/'
          })
        }
      )
    } else {
      this.props.setToastMessage('you_must_select_a_store')
      this.setState({ noStoreError: true })
      return
    }
  }

  logError = (device, error) => {
    const errorMessage = JSON.stringify(error)
    this.props.setToastMessage(errorMessage)
    const user = JSON.parse(localStorage.getItem('user'))
    const errorObj = {
      source: Util.returnPOSType(),
      message: errorMessage,
      user: user ? user._id : '',
      store: device.store._id,
      device: device._id,
      stack: `[${Util.returnPOSType()}] Registration - ResourceLoader result: ${errorMessage}`,
      date: new Date()
    }
    AppInstances.logError(errorObj)
  }

  checkLastTransaction = async () => {
    const countr = await AppInstances.getCountrSdk()
    const recoveryTransaction = []

    for (var key in localStorage) {
      if (key.indexOf('CountrWeb:LastTransactionInProgress-') >= 0) {
        recoveryTransaction.push(key)
      }
    }

    recoveryTransaction.forEach(transactionKey => {
      const lastTransaction = JSON.parse(localStorage.getItem(transactionKey))

      if (lastTransaction && lastTransaction !== null) {
        const index = this.props.transactions.transactions.findIndex(
          t => t.receipt_id === lastTransaction.receipt_id
        )

        if (index >= 0) {
          localStorage.removeItem(transactionKey)
        } else {
          countr.transactions.create(lastTransaction).then(transaction => {
            this.props.addTransactionHead(transaction)
            localStorage.removeItem(transactionKey)
          })
        }
      }
    })
  }

  componentDidMount = () => {
    if (!Object.keys(this.props.user.user).length) {
      // If the user is not defined, go back to login
      this.props.history.push({
        pathname: '/'
      })
    } else {
      AppInstances.getCountrSdk().then(socket => {
        socket.stores.read().then(stores => {
          this.setState({ storesList: stores }, () => {
            if (stores.length === 0) {
              this.setState({ noStores: true }, () => {
                this.props.setToastMessage('need_store_register_device')
                setTimeout(() => {
                  localStorage.removeItem('CountrLite:RememberLogin')
                  this.props.history.push({
                    pathname: '/'
                  })
                }, 5000)
              })
            } else {
              this.setState({ loadingStores: false })
            }
          })
        })
      })
    }
  }

  render() {
    return (
      <div className="Registration">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="deviceName"
              label={<Text id="addid_device" />}
              className="registration-input-field registration-input-field-label"
              value={this.state.deviceName}
              onChange={this.handleChange('deviceName')}
              margin="normal"
              error={this.state.noDeviceError}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="registration-select-label">
              <Text id="storeid" />
            </InputLabel>
          </Grid>
          <Grid item xs={12}>
            {this.state.loadingStores ? (
              <CircularProgress color="primary" />
            ) : (
              <Select
                value={this.state.storeSelected}
                onChange={this.handlerStoreSelect('storeSelected')}
                className="registration-select registration-input-field-label"
                error={this.state.noStoreError}>
                {this.state.storesList.length &&
                  this.state.storesList.map(store => {
                    return (
                      <MenuItem key={store._id} value={store._id}>
                        {store.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            )}
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Button
              label={<Text id="register_terminal" />}
              onClick={this.register}
              disabled={this.state.noStores}
              styles={{ width: 300 }}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const RegistrationConnected = connect(mapStateToProps, mapDispatchToProps)(Registration)
export default RegistrationConnected
