import CountrClient from '@countr/sdk'
import countrLog from '@countr/slack'

import store from '../index'

/**
 * Creating a instance for Countr SDK and
 * if Desktop create a socket connection
 */
export const AppInstances = (function (window) {
  let instance,
    desktopInstance,
    desktopUrl,
    desktopConnectionRetryCount = 0

  async function createCountrSdk() {
    const {
      REACT_APP_PROD,
      REACT_APP_STAGING,
      REACT_APP_CLIENT_ID,
      REACT_APP_CLIENT_SECRET,
      REACT_APP_API_DOMAIN
    } = process.env

    const opts = {
      staging: REACT_APP_PROD !== 'true' && REACT_APP_STAGING === 'true',
      local: REACT_APP_PROD !== 'true' && REACT_APP_STAGING !== 'true',
      enableSocket: true
    }

    if (REACT_APP_API_DOMAIN) {
      opts.customDomain = REACT_APP_API_DOMAIN
    }

    const countrClient = new CountrClient({
      opts
    })

    countrClient.setClient({
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET
    })

    countrClient.on('refreshed', async token => {
      localStorage.setItem('access_token', token.access_token)
      localStorage.setItem('refresh_token', token.refresh_token)
    })

    return countrClient
  }

  const retryConnection = () => {
    const clean = () => {
      if (interval) {
        clearInterval(interval)
        interval = null
      }
    }

    let interval = setInterval(async () => {
      if (
        desktopConnectionRetryCount === Number(process.env.REACT_APP_DESKTOP_SOCKET_RETRY || 15)
      ) {
        clean()

        if (process.env.REACT_APP_PROD === 'true') {
          const errorObj = {
            message: `Max retry to connect Desktop socket exceed`,
            user: store.getState().user.user._id,
            store: store.getState().devices.store._id,
            device: store.getState().devices.device._id,
            date: new Date().toISOString()
          }

          await AppInstances.getCountrSdk()
          AppInstances.logError(errorObj)
        }

        return
      }

      let desktopSocket = await AppInstances.getDesktopConnection(desktopUrl)
      if (desktopSocket && desktopSocket.readyState === 3) {
        desktopSocket = await AppInstances.getDesktopConnection(desktopUrl)
      } else if (desktopSocket && desktopSocket.readyState === 1) {
        if (interval) {
          clean()
        }
      }

      ++desktopConnectionRetryCount
    }, 1000)
  }

  const closeSocketListener = () => {
    desktopInstance.removeEventListener('close', closeSocketListener)
    desktopInstance.removeEventListener('error', closeSocketListener)
    desktopInstance = null

    retryConnection()
  }

  return {
    getCountrSdk: function () {
      if (!instance) {
        instance = createCountrSdk()
      }

      // Checking if is not connected, than force it to connect again
      instance.then(countr => {
        if (!countr.ws.socket.connected) {
          countr.ws.socket.connect()
        }
      })

      return instance
    },

    /**
     * Log error with Countr
     */
    logError: async (errorObj, slack = true) => {
      const countr = await instance

      errorObj.date = new Date().toISOString()
      errorObj.source = process.env.REACT_APP_ERROR_SOURCE

      if (errorObj.user instanceof Object && !!errorObj.user._id) {
        errorObj.user = errorObj.user._id
      }

      try {
        countr.e.create(errorObj)
        if (errorObj.source === 'true' && slack) {
          countrLog.logSlack(
            errorObj.source,
            `${process.env.NODE_ENV} - ${errorObj.source} - ${JSON.stringify(errorObj)}`
          )
        }
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * Slack Channel log error with Countr
     * If we catch an error we try to use Countr Error Collection
     * @params {errorObj} - Object with the information to stringify
     *
     * @return void
     */
    logSlackError: async errorObj => {
      try {
        countrLog.logSlack(
          process.env.REACT_APP_ERROR_SLACK_WEBHOOK,
          `${process.env.NODE_ENV} - ${process.env.REACT_APP_ERROR_SOURCE} - ${JSON.stringify(
            errorObj
          )}`
        )
      } catch (error) {
        await AppInstances.getCountrSdk()
        const errorObj = {
          source: process.env.REACT_APP_ERROR_SOURCE,
          message: `Failed to log on Slack channel - ${process.env.NODE_ENV} - ${process.env.REACT_APP_ERROR_SOURCE}`,
          date: new Date().toISOString()
        }
        AppInstances.logError(errorObj)
      }
    },

    getDesktopConnection: url => {
      desktopUrl = url
      if (!desktopInstance) {
        desktopInstance = new WebSocket(desktopUrl)
        desktopInstance.addEventListener('close', closeSocketListener)
        desktopInstance.addEventListener('error', closeSocketListener)
      }

      return desktopInstance
    }
  }
})(window)
