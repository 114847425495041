import { useEffect, useState } from 'react';
import { Text } from 'react-internationalization'
import { useSelector } from 'react-redux'
import CountrButton from '../../Shared/CountrButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Util from '../../../utils/Util'
import './Giftcard.scss'

const GiftcardDetails = ({ giftcard, redeem, hasItems, total }) => {
  const store = useSelector(state => state.devices.store)
  const [disablePayment, setDisablePayment] = useState(false)

  const getUsedPercentage = () => {
    const { value_remaining, total_value } = giftcard
    return (value_remaining / total_value) * 100
  }

  const getStoreCurrency = () => {
    return !!store.currency ? store.currency.symbol : '$'
  }
  const isEven = number => {
    return number % 2 === 0
  }

  useEffect(() => {
    if (hasItems && giftcard.value_remaining < total) {
      setDisablePayment(true)
    }
  }, [])

  return (
    <div className="giftcard-details">
      <div className="title">
        <span>
          <Text id="giftcard" />: {giftcard.code}
        </span>
      </div>
      <div className="current-balance">
        <div className="value">
          <span className="current">
            {getStoreCurrency()} {giftcard.value_remaining.toFixed(2)} /{' '}
          </span>
          <span className="total">
            {getStoreCurrency()} {giftcard.total_value.toFixed(2)}
          </span>
        </div>
        <LinearProgress className="progress" variant="determinate" value={getUsedPercentage()} />
        <span className="label">
          <Text id="current_balance" />
        </span>
      </div>
      <div className="add-balance">
        {disablePayment && (
          <span className="low-giftcard">
            <Text id="giftcard_low_value" />
          </span>
        )}
        <CountrButton
          handleClick={redeem}
          text="redeem"
          fullWidth={true}
          disabled={!giftcard.is_active || disablePayment}
        />
      </div>
      <div className="activity">
        <span className="title">
          <Text id="card_activity" />
        </span>
      </div>
      <Grid container className="list-container">
        {giftcard.activity.map((activity, i) => (
          <Grid item xs={12} key={i} style={isEven(i) ? { backgroundColor: '#dddddd' } : {}}>
            <Grid container>
              <Grid item xs={4}>
                <span className="date">{Util.formattedDate(new Date(activity.date))}</span>
              </Grid>
              <Grid item xs={4}>
                <span className="store">{activity.current}</span>
              </Grid>
              <Grid item xs={4}>
                <span className="value">
                  {getStoreCurrency()} {activity.journeys.toFixed(2)}
                </span>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default GiftcardDetails
