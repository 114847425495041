import { memo } from 'react';
import { ProductUtils } from '@countr/utils'
import { Text, translate } from 'react-internationalization'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

import './ProductDetails.scss'

const Inventory = memo(({ product, inventory, style, language }) => {
  const getVariantName = (storeObj, itemKey) => {
    return ProductUtils.getVariantName(storeObj.units[itemKey], language)
  }

  const getVariantAmount = (storeObj, itemKey) => {
    return ProductUtils.getVariantInventory(product, itemKey, storeObj._id)
  }

  return (
    <>
      <div className="title-div">
        <Text id="inventory" />
      </div>
      {!!inventory.length ? (
        inventory.map(storeObj => (
          <li key={`section-${storeObj.name}`} className="list-section">
            <ul className="list-items">
              <ListSubheader style={style} className="sub-header">
                {storeObj.name}
              </ListSubheader>
              {Object.keys(storeObj.units).map(itemKey => (
                <ListItem key={itemKey} divider={true}>
                  <ListItemText
                    primary={`${getVariantName(storeObj, itemKey)}: ${getVariantAmount(
                      storeObj,
                      itemKey
                    )} ${translate('units')}`}
                  />
                </ListItem>
              ))}
            </ul>
          </li>
        ))
      ) : (
        <ListItem>
          <ListItemText primary={<Text id="inventory_not_found" />} />
        </ListItem>
      )}
    </>
  )
})

export default Inventory
