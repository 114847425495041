import { memo } from 'react';
import IconButton from '@material-ui/core/IconButton'
import { Text } from 'react-internationalization'
import { useSelector } from 'react-redux'
import './CartToolbar.scss'

const CartToolbarButton = memo(({ text, icon, action, iconSelected }) => {
  const { colors = { toolbarText: '#000', primaryText: '#318ed5' } } = useSelector(
    state => state.app.theme
  )

  return (
    <IconButton onClick={action}>
      <div
        className="action-btn-container"
        style={{
          color: iconSelected ? colors.primaryText : colors.toolbarText
        }}>
        <span className={`${icon} icon`} />
        <span className="text">
          <Text id={text} />
        </span>
      </div>
    </IconButton>
  )
})

export default CartToolbarButton
