import { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux'

import { AppInstances } from '../../utils/countrSdkInstance'
import { setListeningStores } from '../../store/actions/settings'

import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Settings.css'

const mapStateToProps = state => {
  return {
    devices: state.devices
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setListeningStores: settings => dispatch(setListeningStores(settings))
  }
}

export const StoresToListen = memo(props => {
  const [allStores, setAllStores] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const handleAllStores = async () => {
    try {
      const countr = await AppInstances.getCountrSdk()
      const device = await countr.devices.readOne.detailed(props.devices?.device?._id)

      if (device?.settings?.onlineOrderStores) {
        props.setListeningStores(device.settings.onlineOrderStores)
      }
      
      const stores = await countr.stores.read()
      const filtered = stores ? stores.filter(s => s._id !== props.devices.store._id) : []
      setAllStores(filtered)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErrorMessage(error?.message || 'Error while loading stores')
    }
  }

  const handleIsChecked = id => {
    const inList = props.onlineOrderStores?.find(s => s === id)
    return inList ? true : false
  }

  const handleChange = id => {
    const listToUpdate = handleIsChecked(id)
      ? props.onlineOrderStores.filter(s => s !== id)
      : [...props.onlineOrderStores, id]

    props.handleUpdateOnlineOrderStores(listToUpdate)
    props.setListeningStores(listToUpdate)
  }

  useEffect(() => {
    handleAllStores()
  }, [])

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress style={{ color: '#318ed5' }} />
        </div>
      ) : (
        <List>
          <ListItem>
            <ListItemIcon>
              <Checkbox checked style={{ color: '#318ed5' }} />
            </ListItemIcon>

            <ListItemText primary={props.devices?.store?.name || ''} />
          </ListItem>
          {!!errorMessage && (
            <ListItem>
              <ListItemText style={{ color: '#bf1e2d' }}>* {errorMessage}</ListItemText>
            </ListItem>
          )}
          {allStores.map(store => (
            <ListItem key={store._id} button onClick={() => handleChange(store._id)}>
              <ListItemIcon>
                <Checkbox
                  checked={handleIsChecked(store._id)}
                  onChange={() => handleChange(store._id)}
                  style={
                    handleIsChecked(store._id)
                      ? {
                          color: '#318ed5'
                        }
                      : {}
                  }
                />
              </ListItemIcon>

              <ListItemText primary={store.name} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(StoresToListen)
