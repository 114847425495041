import { memo, useState, useEffect } from 'react'
import { Text } from 'react-internationalization'
import { useSelector } from 'react-redux'
import { AddonUtils } from '@countr/utils'
import { Button, Addons, AddonGroups } from '@countr/ui'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import './Modal.scss'

const AddonsModal = memo(function AddonsModal(props) {
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }
  const [currentAddons, setCurrentAddons] = useState([])
  const [outputAddons, setOutputAddons] = useState([])

  useEffect(() => {
    let addons

    if (AddonUtils.isAddonGroups(props.product)) {
      addons = props.product.addonGroups
        .map(group => group._id.addons.filter(addon => addon.amount))
        .flat()
    } else if (currentAddons) {
      addons = currentAddons.filter(addon => addon.amount)
    } else {
      addons = []
    }

    setOutputAddons(addons)
  }, [currentAddons])

  const validateAllGroups = () => {
    if (!currentAddons) {
      return false
    }

    const isValid = []
    currentAddons.forEach(group => {
      if (!group._id.visible) {
        return
      }

      const list = [...group._id.addons]
      isValid.push(AddonUtils.validateGroup(list, group))
    })

    return isValid.indexOf(false) < 0
  }

  const close = () => {
    props.handleClose()
  }

  return (
    <Dialog open={props.openAddons} onClose={close}>
      <DialogTitle style={style}>
        <Text id="addons" />
      </DialogTitle>
      <DialogContent testid="addons-modal" style={style} className="modal-content">
        {!AddonUtils.isAddonGroups(props.product) ? (
          <Addons
            product={props.product}
            handleChange={setCurrentAddons}
            store={props.store}
            language={props.language}
          />
        ) : (
          <>
            {AddonUtils.allGroupsValid(props.product) ? (
              <AddonGroups
                product={props.product}
                handleChange={setCurrentAddons}
                store={props.store}
                language={props.language}
              />
            ) : (
              <div>
                <span>
                  <Text id="invalid_addon_groups" />
                </span>
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={close} className="secondary" />
        <Button
          testid="add-addons-product-cart"
          className="primary"
          label={<Text id="add_to_cart" />}
          onClick={() => props.handleClose(outputAddons)}
          disabled={AddonUtils.isAddonGroups(props.product) && !validateAllGroups()}
        />
      </DialogActions>
    </Dialog>
  )
})

export default AddonsModal
