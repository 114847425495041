import { memo, useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Text } from 'react-internationalization'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { cartUtils } from '../../../utils/cartUtils'
import { layoutUtils } from '../../../utils/LayoutUtils'
import { SELECT_CART, ADD_CART, TABLE_SEAT } from '../../../store/actions/actionTypes'

import './TableLayout.scss'

const Table = memo(function Table({
  table,
  selectedCart,
  handleCloseFloorplan,
  handleUnlinkedCart,
  handleError,
  coverSettings,
  seatSettings,
  mergeTables,
  handleTableToMerge
}) {
  TimeAgo.addLocale(en)
  const cartList = useSelector(state => state.carts.carts)
  const dispatch = useDispatch()
  const setCurrentCart = useCallback(c => dispatch({ type: SELECT_CART, payload: c }), [dispatch])
  const addCartRedux = useCallback(c => dispatch({ type: ADD_CART, payload: c }), [dispatch])
  const changeSeat = useCallback(seat => dispatch({ type: TABLE_SEAT, payload: seat }), [dispatch])
  const timeAgo = useMemo(() => {
    return new TimeAgo('nl')
  }, [])

  const [cart, setCart] = useState(null)
  const [currency, setCurrency] = useState('')
  const [guests, setGuests] = useState(0)
  const [tablesInfo, setTablesInfo] = useState({})

  useEffect(() => {
    if (table.linked_cart) {
      const linkedCart = cartList.find(c => c._id === table.linked_cart)
      setCart(linkedCart)
      setTablesInfo(cartUtils.tablesInfo(table, cartList))
      handleGestNumber(linkedCart && linkedCart.extras.covers ? linkedCart.extras.covers : 0)

      if (!!linkedCart) {
        setCurrency(linkedCart.currency.symbol)
      }
    }
  }, [cartList])

  const tableIsCurrentCart = () => {
    if (table.linked_cart) {
      return selectedCart._id === table.linked_cart
    }

    return false
  }

  const handleGestNumber = guest => {
    if (guest > 0 && coverSettings) {
      setGuests(guest)
    } else {
      setGuests(table.subtables ? table.subtables.length : 0)
    }
  }

  const selectTable = () => {
    if (mergeTables) {
      handleTableToMerge(table)
      return
    }
    if (!!cart) {
      const selectCart = { ...cart, extras: { ...cart.extras, deviceCartName: table.name } }
      setCurrentCart(selectCart)
      handleCloseFloorplan()
      handleSeatChange(cart)
    } else {
      // Cart not on the redux, check if table has linked and retrieve it from the server
      if (!!table.linked_cart) {
        getLinkedCart(table)
      } else {
        handleUnlinkedCart(table)
      }
    }
  }

  const handleSeatChange = cart => {
    if (seatSettings && cart.extras?.covers > 0) {
      changeSeat(1)
    } else {
      changeSeat(0)
    }
  }

  const getLinkedCart = async selectTable => {
    const countr = await AppInstances.getCountrSdk()

    try {
      const serverCart = await countr.carts.readOne(selectTable.linked_cart)

      if (!!serverCart) {
        serverCart.extras = {
          ...serverCart.extras,
          deviceCartName: selectTable.name
        }
        handleGestNumber(serverCart.extras.covers ? serverCart.extras.covers : 0)
        setCart(serverCart)
        setCurrentCart(serverCart)
        addCartRedux(serverCart)
        localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(serverCart))
        localStorage.setItem('CountrLite:Cart-' + serverCart._id, JSON.stringify(serverCart))
        cartUtils.updateCartServer(serverCart)
        handleCloseFloorplan()
      }
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <div
      className="table"
      style={layoutUtils.returnTablePosition(cart, table, tableIsCurrentCart, tablesInfo)}
      onClick={selectTable}>
      {table && (table.layout.shape === 'bar-l' || table.layout.shape === 'bar-u') && (
        <div className="in-bar" style={layoutUtils.setBarPosition(table)}>
          <div
            className="out-bar"
            style={layoutUtils.setBarPositionIn(table, cart, tablesInfo)}></div>
        </div>
      )}
      <div
        style={layoutUtils.handleBarUText(table, true)}
        className={
          table && (table.layout.shape === 'bar-l' || table.layout.shape === 'bar-u')
            ? 'bar-name'
            : ''
        }>
        <div className="icon-div">
          <span className="icon-profile" />
          <span className="icon-text">{guests}</span>
        </div>
        {table.name}
      </div>
      {!!cart && !!tablesInfo.items > 0 ? (
        <div
          className={
            table && (table.layout.shape === 'bar-l' || table.layout.shape === 'bar-u')
              ? 'details-bar'
              : 'details'
          }
          style={layoutUtils.handleBarUText(table)}>
          <span>#Items: {tablesInfo.items}</span>
          <span>
            <Text id="total" />: <strong>{`${currency} ${tablesInfo.total.toFixed(2)}`}</strong>
          </span>
          {layoutUtils.handleTableInfo(table) && (
            <span className={layoutUtils.handleSelectedClass(table)}>
              Updated {timeAgo.format(new Date(cart.updated_at))}
            </span>
          )}
        </div>
      ) : (
        <div
          className={
            table && (table.layout.shape === 'bar-l' || table.layout.shape === 'bar-u')
              ? 'details-bar-info'
              : 'details'
          }>
          {/* <span>
            <Text id="load_linked_cart" />
          </span> */}
        </div>
      )}
    </div>
  )
})

export default Table
