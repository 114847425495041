import { memo } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import { Text } from 'react-internationalization'
import { PaymentUtils } from '../../utils/PaymentUtils'
import Util from '../../utils/Util'

import './NewPaymentModal.scss'

const ContinuePartial = memo(function ContinuePartial({ cart, openContinue, handleClose }) {
  const paid = PaymentUtils.getLastCartPaymentValue(cart).toFixed(2)
  const paidSoFar = (PaymentUtils.calculatePaidValue(cart) - paid).toFixed(2)
  const remaining = parseFloat(cart.total - paidSoFar - paid).toFixed(2)

  return (
    <Dialog
      open={openContinue}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, handleClose)
      }}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown={true}>
      <DialogTitle id="form-dialog-title">
        <Text id="partial_payment_paid_successfully" />
      </DialogTitle>
      <DialogContent className="partial-payment-content">
        <div className="spaced">
          <div className="info">
            <strong>
              <Text id="sales_info" />
            </strong>
          </div>
        </div>
        <div className="spaced">
          <div className="transaction-subitem-left">
            <span className="heighlight">
              <Text id="paying_now" />
            </span>
          </div>
          <div className="transaction-subitem-right">
            <span className="heighlight">
              {cart.currency.symbol} {paid}
            </span>
          </div>
        </div>
        <div className="spaced">
          <div className="transaction-subitem-left">
            <span className="heighlight">
              <Text id="paid_so_far" />
            </span>
          </div>
          <div className="transaction-subitem-right">
            <span className="heighlight">
              {cart.currency.symbol} {paidSoFar}
            </span>
          </div>
        </div>
        <div className="spaced">
          <div className="transaction-subitem-left">
            <span className="heighlight">
              <Text id="remaining_payment" />
            </span>
          </div>
          <div className="transaction-subitem-right">
            <span className="heighlight">
              {cart.currency.symbol} {remaining}
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="payment-types-button" onClick={handleClose}>
          <Text id="continue_payment" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ContinuePartial
