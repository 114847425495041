import { memo } from 'react'
import { connect } from 'react-redux'

import InnerHTML from 'dangerously-set-html-content'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import { Text } from 'react-internationalization'

import { showExternalOpenUrl } from '../../store/actions/app'

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showExternalOpenUrl: obj => dispatch(showExternalOpenUrl(obj))
  }
}

const OpenExternalUrlIframe = memo(props => {
  function createMarkup() {
    const html = `<!DOCTYPE html>
    <html>
    <body>
      <div id='convious-inline-checkout'>
        <script id='convious_loader' src="${props.app.openExternalUrlIframe.url}"></script>
      </div>
    </body>
    </html>`

    return html
  }

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') {
      return
    }

    props.showExternalOpenUrl({
      show: false,
      url: ''
    })
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={props.app.openExternalUrlIframe.show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"></DialogTitle>
      <DialogContent style={{ minWidth: 600 }}>
        <InnerHTML html={createMarkup()} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          <Text id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(OpenExternalUrlIframe)
