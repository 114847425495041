import { memo } from 'react';
import { Text } from 'react-internationalization'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@countr/ui'
import './CardInfoRequestModal.scss'

const CardInfoRequestModal = memo(function CardInfoRequestModal(props) {

    const handleClose = () => {
        props.handleClose()
    }

    const handleWithoutCardInfo = () =>{
        props.handleWithoutCardInfo()
    }

    const handleWithCardInfo = () =>{
        props.handleWithCardInfo() 
    }

    return (
        <Dialog
            open={props.openCardInfoRequestModal}
            onClose={handleClose}>
            <DialogContent id="dialog-content">
                <Button
                    className="primary"
                    label={<Text id="with_card_info"/>}
                    onClick={handleWithCardInfo}
                />
                <Button
                    className="primary"
                    label={<Text id="without_card_info"/>}
                    onClick={handleWithoutCardInfo}
                />
            </DialogContent>

            <DialogActions>
                <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
            </DialogActions>

        </Dialog>
    )
})

export default CardInfoRequestModal;