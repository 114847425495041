import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'

import { PrinterUtils } from '../../utils/PrinterUtils'

import './PrintButton.scss'

const A4PrintButton = ({ color }) => {
  return (
    <button className="print-btn" title="A4 printer">
      <span className="icon-printer" style={{ color: color }} />
    </button>
  )
}

const PrintButton = ({
  transaction,
  externalPrinterAvailable,
  isDesktop,
  a4Print,
  a4ContentRef,
  printReceipt,
  handleUpdateTransaction
}) => {
  const theme = useSelector(state => state.app.theme)

  const printExternal = () => {
    PrinterUtils.externalOSPrint(transaction)
  }

  return (
    <>
      {a4Print ? (
        <ReactToPrint
          trigger={() => A4PrintButton({ color: theme.colors.text })}
          content={() => a4ContentRef}
          onAfterPrint={handleUpdateTransaction}
        />
      ) : (
        <>
          {isDesktop && (
            <button className="print-btn" onClick={() => printReceipt()} title="Thermal printer">
              <span className="icon-printer" style={{ color: theme.colors.text }} />
            </button>
          )}

          {externalPrinterAvailable && (
            <button className="print-btn" onClick={() => printExternal()} title="External printer">
              <span className=" icon-features-print-receipt" style={{ color: theme.colors.text }} />
            </button>
          )}
        </>
      )}
    </>
  )
}

export default PrintButton
