import { PureComponent } from 'react';
import { connect } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'

import { loadingFalse, loadingTrue } from '../../store/actions/loading'
import { setToastMessage } from '../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { AppInstances } from './../../utils/countrSdkInstance'
import { PrinterUtils } from './../../utils/PrinterUtils'

import { Text } from 'react-internationalization'

import ReportSection from './ReportSection'
import ReportPeriodSelection from './ReportPeriodSelection'
import CashInOut from '../../components/CashInOut'
import ReportRevenue from './ReportRevenue'
import ReportTaxes from './ReportTaxes'
import PrintButton from '../generic/PrintButton'

import './Reports.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    devices: state.devices,
    settings: state.settings,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    setToastMessage: (msg, opt, action) => dispatch(setToastMessage(msg, opt, action))
  }
}

class Reports extends PureComponent {
  state = {
    tab: 0,
    haveReport: false,
    report: {},
    printReport: {},
    reportSelected: '',
    today: {
      date: new Date(),
      devices: [],
      employees: [],
      stores: [],
      utc_offset: new Date().getTimezoneOffset()
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  getReport = (report, type) => {
    if (Object.keys(report.content).length) {
      const sales = report.content[this.props.devices.store.currency.code]
      this.setState({ report: sales, haveReport: true, reportSelected: type, printReport: report })
    } else {
      this.setState({ report: {}, haveReport: false, reportSelected: type, printReport: {} })
    }
  }

  threatReportError = type => {
    this.setState({ haveReport: false, reportSelected: type })
  }

  getReportBy = async period => {
    this.props.loadingTrue()
    const countr = await AppInstances.getCountrSdk()
    countr.reports.generate[period](this.state.today).then(
      report => {
        this.getReport(report, period)
        this.props.loadingFalse()
      },
      error => {
        console.log(error)
        this.threatReportError(period)
        this.props.loadingFalse()
      }
    )
  }

  componentDidMount = () => {
    const today = Object.assign({}, this.state.today)
    today.utc_offset = new Date(today.date).getTimezoneOffset()
    today.stores.push(this.props.devices.store._id)

    if (!this.props.settings.reportsStoreLevel) {
      today.devices.push(this.props.devices.device._id)
    }

    this.setState({ today }, () => {
      this.getReportBy('day')
    })
  }

  handleChangeTab = (event, value) => {
    this.setState({ tab: value })
  }

  printReport = () => {
    PrinterUtils.printReport(this.state.printReport, this.props.devices.store.currency.code, {
      setToastMessage: this.props.setToastMessage
    }, this.props.devices.store.name)
  }

  render() {
    return (
      <Dialog
        open={this.props.openReports}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={!isMobileOnly}
        fullScreen={isMobileOnly}>
        <DialogTitle id="form-dialog-title">
          <div className="reports-header">
            {/* Title */}
            <Text id="reports" />

            {this.state.tab === 0 && this.state.haveReport && (
              <PrintButton
                isDesktop={this.props.app.isDesktop}
                a4Print={this.props.settings.a4Print}
                a4ContentRef={this.printComponent}
                printReceipt={this.printReport}
                handleUpdateTransaction={() => {}}
              />
            )}
          </div>
        </DialogTitle>

        {/* CONTENT */}
        <DialogContent justifyContent="center">
          {/* TABS */}
          <Tabs
            className="reports-tabs"
            value={this.state.tab}
            onChange={this.handleChangeTab}
            variant="fullWidth"
            indicatorColor="primary">
            <Tab label={<Text id="reports" />} />
            <Tab label={<Text id="operations" />} />
          </Tabs>

          {/* Cash in / Cash Out functionality */}
          {this.state.tab === 1 && (
            <CashInOut employeeId={this.props.employees.selectedEmployee._id} />
          )}

          {/* Select kind of Report */}
          {this.state.tab === 0 && (
            <ReportPeriodSelection
              reportSelected={this.state.reportSelected}
              getReportBy={this.getReportBy}
              outstandingInvoices={this.props.app.outstandingInvoices}
            />
          )}
          {this.state.tab === 0 && this.state.haveReport ? (
            <div ref={el => (this.printComponent = el)} className="reports-print-content">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Text id="reports" />
                </Grid>
              </Grid>
              {/* Revenue */}
              <ReportRevenue
                currency={this.props.devices.store.currency}
                report={this.state.report}
              />
              {/* Transactions by method */}
              {this.state.report.tables.transaction_by_method !== undefined &&
                Object.keys(this.state.report.tables.transaction_by_method.details).length > 0 && (
                  <ReportSection
                    type="transactions"
                    reportSection={this.state.report.tables.transaction_by_method}
                    currency={this.props.devices.store.currency.symbol}
                  />
                )}
              {/* Payment by method */}
              {this.state.report.tables.payment_by_method !== undefined &&
                Object.keys(this.state.report.tables.payment_by_method.details).length > 0 && (
                  <ReportSection
                    type="payment"
                    reportSection={this.state.report.tables.payment_by_method}
                    currency={this.props.devices.store.currency.symbol}
                  />
                )}
              {/* Refunds by method */}
              {this.state.report.tables.refund_by_method !== undefined &&
                Object.keys(this.state.report.tables.refund_by_method.details).length > 0 && (
                  <ReportSection
                    type="refunds"
                    reportSection={this.state.report.tables.refund_by_method}
                    currency={this.props.devices.store.currency.symbol}
                  />
                )}
              {/* Taxes */}
              <ReportTaxes
                report={this.state.report}
                currency={this.props.devices.store.currency}
              />
            </div>
          ) : (
            this.state.tab === 0 && (
              <Grid container className="reports-type-container-not-found">
                <Grid item xs={12}>
                  <InputLabel>
                    <Text id="report_not_found" />
                  </InputLabel>
                </Grid>
              </Grid>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            <Text id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const ReportsConnected = connect(mapStateToProps, mapDispatchToProps)(Reports)
export default ReportsConnected
