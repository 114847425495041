import store from './../index'
import { setIsDesktop as setIsDesktopAction } from './../store/actions/app'

export default class DesktopUtils {
  static async setIsDesktop() {
    const desktop = JSON.parse(localStorage.getItem('isDesktop'))
    const version = localStorage.getItem('DesktopVersion')
    const localDesktop = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
    !!desktop &&
      store.dispatch(
        setIsDesktopAction({
          version: !!version ? version : '',
          ip: !!localDesktop && !!localDesktop.local_ip ? localDesktop.local_ip : ''
        })
      )
  }

  static isExternalPrintersAvailable() {
    return DesktopUtils.isDesktop() && store.getState().devices.store.options?.externalPrinters
  }

  static getDesktopListenerPort() {
    const localstorageDesktopPortSaved = localStorage.getItem('DL_PORT')
    const port =
      localstorageDesktopPortSaved ??
      (store.getState().devices.device?.settings?.desktopListenerPort ||
        process.env.REACT_APP_DESKTOP_PORT ||
        2222)

    console.log('🚀 DL_PORT', port)

    return port
  }

  static isDesktop() {
    return store.getState().app.isDesktop
  }

  static getDesktopVersion() {
    return store.getState().app.desktopVersion
  }

  static getDesktopIP() {
    return store.getState().app.desktopIP
  }

  static isVersionValid(currentVersion, majorVersion) {
    const version = !!currentVersion ? currentVersion.split('.') : 0
    return !!version.length ? +version[0] > majorVersion : false
  }
}
