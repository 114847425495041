import { memo, useEffect, useState } from 'react'
import { useSelector, connect } from 'react-redux'
import Receipt from './../Transaction/Receipt'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { loadingFalse, loadingTrue } from '../../store/actions/loading'

import './AggregatedInvoicesModal.css'

const mapStateToProps = state => {
  return {
    transactions: state.transactions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue())
  }
}

const AggregatedInvoicesModal = memo(props => {
  const theme = useSelector(state => state.app.theme)
  const language = useSelector(state => state.settings.language)
  const [isTransactionSelected, setIsTransactionSelected] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState({})

  const style = theme &&
    theme.colors && {
      color: theme.colors.text,
      backgroundColor: theme.colors.body
    }
  const handleClose = () => {
    if (isTransactionSelected) {
      setIsTransactionSelected(false)
      return
    }
    if (props.handleClose) {
      props.handleClose()
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleIframeEventListener)
    props.loadingTrue()
    const iframe = document.getElementById('payments-service-iframe')
    iframe.onload = () => {
      props.loadingFalse()
    }
    return () => {
      window.removeEventListener('message', handleIframeEventListener)
    }
  }, [])

  const handleIframeEventListener = event => {
    switch (event.data.type) {
      case 'COUNTR_PAYMENTS_CHECK_TOKEN':
        sendToken()
        break
      case 'COUNTR_PAYMENTS_CLOSE_DIALOG':
        handleClose()
        break
      case 'COUNTR_PAYMENTS_OPEN_RECEIPT':
        const transaction = props.transactions.transactions.filter(transaction => {
          return transaction._id === event.data.data
        })[0]
        if (transaction === undefined) {
          return
        }
        setSelectedTransaction(
          props.transactions.transactions.filter(transaction => {
            return transaction._id === event.data.data
          })[0]
        )
        setIsTransactionSelected(true)
        break
      case 'COUNTR_PAYMENTS_START_LOADING':
        props.loadingTrue()
        break
      case 'COUNTR_PAYMENTS_STOP_LOADING':
        props.loadingFalse()
        break
    }
  }

  const sendToken = () => {
    const iframe = document.getElementById('payments-service-iframe')
    iframe.contentWindow.postMessage(
      {
        type: 'COUNTR_PAYMENTS_RECEIVE_TOKEN',
        access_token: window.localStorage.getItem('access_token')
      },
      '*'
    )
  }

  return (
    <div>
      {props.openConfirmation && (
        <div className="iframe-container" onClick={handleClose}>
          <iframe
            id="payments-service-iframe"
            className="iframe"
            src={`${process.env.REACT_APP_PAYMENTS_SERVICE_URL}${language}/${
              props.target
            }?merchantId=${props.merchantId}&authorization=${window.localStorage.getItem(
              'access_token'
            )}&layout=1&parentLoading=true`}></iframe>

          {isTransactionSelected && (
            <Dialog open={isTransactionSelected}>
              <Grid>
                <DialogContent>
                  <Receipt transaction={selectedTransaction} transactionComplete={false} />
                </DialogContent>
              </Grid>
            </Dialog>
          )}
        </div>
      )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(AggregatedInvoicesModal)
