import { memo } from 'react';
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { Text } from 'react-internationalization'

const ReportRevenue = memo(({ report, currency }) => {
  const returnOverall = type => {
    return report.tables.revenue_summary.details.overall[type] || 0
  }

  const returnOverallTaxes = () => {
    return Object.keys(report.tables.product_by_taxes.details).reduce((acc, key) => {
      return acc + report.tables.product_by_taxes.details[key].taxes
    }, 0)
  }

  const returnOverallProfit = () => {
    return returnOverall('amount') - returnOverall('cost') - returnOverallTaxes()
  }

  return (
    <Grid container className="reports-type-container">
      <Grid item xs={12} className="reports-type-title">
        <InputLabel>
          <Text id="revenue" />
        </InputLabel>
      </Grid>
      <Grid item xs={12} className="reports-type-table">
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-header">
            <InputLabel>
              <Text id="revenue" />
            </InputLabel>
          </Grid>
          <Grid item xs={6} className="reports-type-table-header">
            <InputLabel>
              <Text id="amount" />
            </InputLabel>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-content">
            <Text id="deposit" />
          </Grid>
          <Grid item xs={6} className="reports-type-table-content">
            {currency.symbol} {returnOverall('deposit').toFixed(2)}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-content">
            <Text id="gross_revenue" />
          </Grid>
          <Grid item xs={6} className="reports-type-table-content">
            {currency.symbol} {returnOverall('amount').toFixed(2)}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-content">
            <Text id="cost" />
          </Grid>
          <Grid item xs={6} className="reports-type-table-content">
            {currency.symbol} {returnOverall('cost').toFixed(2)}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-content">
            <Text id="taxes" />
          </Grid>
          <Grid item xs={6} className="reports-type-table-content">
            {currency.symbol} {returnOverallTaxes().toFixed(2)}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-header">
            <b>
              <Text id="profit" />
            </b>
          </Grid>
          <Grid item xs={6} className="reports-type-table-header">
            <b>
              {currency.symbol} {returnOverallProfit().toFixed(2)}
            </b>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

export default ReportRevenue
