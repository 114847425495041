import { memo } from 'react';
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { Text } from 'react-internationalization'

const mapStateToProps = state => {
  return {
    payments: state.payments,
    settings: state.settings
  }
}

const RefundButtonsDialog = memo(props => {
  const shouldPaymentBeAvailable = method => {
    if (props.settings.refundWithSameMethod) {
      const paymentsMethods = props.transaction.payments.map(payment => payment.method)
      const paymentsProviders = props.transaction.payments.map(payment => payment.provider)

      return (
        method.enabled &&
        (paymentsMethods.indexOf(method.method) >= 0 ||
          paymentsProviders.indexOf(method.method) >= 0)
      )
    } else {
      return method.enabled
    }
  }

  return (
    <Dialog open={props.openRefundsButtonsDialog} onClose={props.handleCloseRefundsButtonsDialog}>
      <DialogTitle id="form-dialog-title">
        <Text id="refund" />
      </DialogTitle>
      <DialogContent style={{ maxWidth: 300, textAlign: 'center' }}>
        <Grid container justifyContent="center" alignItems="center">
          {props.payments.paymentMethods.map(
            method =>
              shouldPaymentBeAvailable(method) && (
                <Grid item xs={12} key={method.method} style={{ margin: 5 }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#318ed5', color: '#fff' }}
                    onClick={() => props.handleRefund(method)}>
                    <span className="payment-btn-fit">
                      <Text id={method.method} />
                    </span>
                  </Button>
                </Grid>
              )
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleCloseRefundsButtonsDialog}>
          <Text id="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default connect(mapStateToProps, null)(RefundButtonsDialog)
