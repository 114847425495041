import { ADD_QUEUE, REMOVE_QUEUE, REMOVE_ACTION_QUEUE, REMOVE_DUPLICATE } from './actionTypes'

export const addQueue = action => ({
  type: ADD_QUEUE,
  payload: action
})

export const removeQueue = () => ({
  type: REMOVE_QUEUE
})

export const removeActionQueue = id => ({
  type: REMOVE_ACTION_QUEUE,
  payload: id
})

export const removeDuplicate = id => ({
  type: REMOVE_DUPLICATE,
  payload: id
})
