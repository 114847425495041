import { memo } from 'react';

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Typography from '@material-ui/core/Typography'

import './Employees.css'

const EmployeeTile = memo(props => {
  const returnEmployeeInitials = employee => {
    return (
      employee.first_name.slice(0, 1).toUpperCase() + employee.last_name.slice(0, 1).toUpperCase()
    )
  }

  const initials = returnEmployeeInitials(props.employee)

  return (
    <div className="employee-tile">
      <Card className="employee-card" onClick={() => props.handleSelectEmployee(props.employee)}>
        <div className="employee-card-no-img">
          <font className="employee-card-initial">{initials}</font>
        </div>
        <CardContent className="employee-card-content-name">
          <Typography variant="button" className="employee-card-content-name-typo">
            {props.employee.first_name} {props.employee.last_name}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
})

export default EmployeeTile
