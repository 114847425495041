import { memo, useState } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'
import { setToastMessage } from '../../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import KeyBoardComponent from '../../generic/KeyboardComponent'
import { Button } from '@countr/ui'

// import './CustomProductModal.scss'

const mapStateToProps = state => {
  return {
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const AskBuzzerInfoModal = memo(props => {
  const [quantity, setQuantity] = useState(0)
  const style = {
    color: props.theme.colors.text,
    backgroundColor: props.theme.colors.body
  }

  const handleClose = () => {
    props.handleCloseBuzzerInfoRequest()
  }

  const handleChangeQuantity = value => {
    if (value === 'C') {
      setQuantity(0)
    } else {
      const newValue = quantity + '' + value
      setQuantity(parseInt(newValue, 10))
    }
  }

  const handleConfirm = () => {
    props.handleConfirmBuzzerInfo(quantity)
  }

  return (
    <Dialog
      open={props.openBuzzerInfoRequest}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      testid="buzzer-info-modal">
      <DialogTitle id="form-dialog-title" style={style}>
        <Text id="buzzer" />
      </DialogTitle>
      <DialogContent style={style}>
        <div className="custom-product">
          <span>
            {<Text id="buzzer" />} {quantity}
          </span>
        </div>
        <KeyBoardComponent handleChange={handleChangeQuantity} />
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        <Button
          testid="buzzer-info-add"
          className="primary"
          label={<Text id="confirm" />}
          onClick={handleConfirm}
        />
      </DialogActions>
    </Dialog>
  )
})

const AskBuzzerInfoModalConnected = connect(mapStateToProps, mapDispatchToProps)(AskBuzzerInfoModal)
export default AskBuzzerInfoModalConnected
