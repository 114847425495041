import { memo, useEffect } from 'react';
import { connect } from 'react-redux'

import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import { hideToastMessage, showKds } from '../../store/actions/app'
import { Text } from 'react-internationalization'

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideToastMessage: () => dispatch(hideToastMessage()),
    showKds: show => dispatch(showKds(show))
  }
}

const ToastMessage = memo(props => {
  useEffect(() => {
    setTimeout(() => {
      props.hideToastMessage()
    }, 3000)
  }, [props.app.isToastDisplayed])

  const action = () => {
    switch (props.app.toastAction) {
      case 'showKDS':
        props.showKds(!props.app.showKds)
        break
      case 'go_dashboard':
        var win = window.open(process.env.REACT_APP_DASHBOARD_URL, '_blank')
        win.focus()
        break
      default:
        break
    }
    props.hideToastMessage()
  }

  const returnActionText = () => {
    switch (props.app.toastAction) {
      case 'showKDS':
        return 'open_kds'
      case 'go_dashboard':
        return 'go_dashboard'
      default:
        return 'close'
    }
  }

  const styleAction = () => {
    return !props.app.toastAction ? 'primary' : 'secondary'
  }

  return (
    <Snackbar
      testid="toast-msg"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.app.isToastDisplayed}
      message={
        <span id="message-id">
          <Text id={props.app.toastMsg} values={props.app.toastOpt} />
        </span>
      }
      action={
        <Button color={styleAction()} size="small" onClick={action}>
          <Text id={returnActionText()} />
        </Button>
      }
    />
  )
})

const ToastMessageConnected = connect(mapStateToProps, mapDispatchToProps)(ToastMessage)
export default ToastMessageConnected
