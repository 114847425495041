import { memo, useState } from 'react';
import { useSelector } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import CartItemNoteComponent from '../Assortment/CartItemDetails/CartItemNoteComponent'
import { Text } from 'react-internationalization'
import { Button } from '@countr/ui'
import Util from '../../utils/Util'

const PayOnAccountModal = memo(props => {
  const theme = useSelector(state => state.app.theme)
  const [note, setNote] = useState('')
  const style = theme &&
    theme.colors && {
      color: theme.colors.text,
      backgroundColor: theme.colors.body
    }

  const handleClose = () => {
    if(props.handleCloseConfirmation){
      props.handleCloseConfirmation()
    }
  }

  const handleCancel = () => {
    if(props.handleCancelBtn){
      props.handleCancelBtn()
    }
    if(props.handleCloseConfirmation){
      props.handleCloseConfirmation()
    }
  }

  const handleConfirm = () => {
    props.confirmBtn(note)
    if(props.handleCloseConfirmation){
      props.handleCloseConfirmation()
    }
  }
  const handleChangeNote = value => {
    setNote(value)
  }

  return (
    <Dialog
      open={props.openConfirmation}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, handleClose)
      }}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown={props.noCloseBtn}>
      <DialogTitle id="form-dialog-title" style={style}>
        {props.confirmationTitle}
      </DialogTitle>
      <DialogContent>
        <CartItemNoteComponent note={note} handleChangeNote={handleChangeNote} />
      </DialogContent>
      <DialogActions style={style}>
        {!props.noCloseBtn && (
          <Button
            label={<Text id="cancel" />}
            onClick={handleCancel}
            className="secondary"
            testid="confirmation-cancel"
          />
        )}

        {!props.noOkButton && (
          <Button
            label={<Text id="ok" />}
            onClick={handleConfirm}
            className="primary"
            testid="confirmation-ok"
          />
        )}
      </DialogActions>
    </Dialog>
  )
})

export default PayOnAccountModal
