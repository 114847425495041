import { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import { Button, Input } from '@countr/ui'
import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

const ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right'
}
const TRANSFORM = {
  vertical: 'top',
  horizontal: 'right'
}

const TransactionListSendEmail = memo(props => {
  const [email, setEmail] = useState('')
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }

  useEffect(() => {
    if (props.initCustomer) {
      setEmail(props.initCustomer)
    }
  }, [props.anchorEl])

  const handleChange = value => {
    setEmail(value)
  }

  const handleKeyPress = () => {
    handleSend()
  }

  const handleClose = () => {
    setEmail('')
    props.handleCloseSendEmail()
  }

  const handleSend = () => {
    props.sendReceiptEmail(email)
    setEmail('')
  }

  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={handleClose}
      anchorOrigin={ORIGIN}
      transformOrigin={TRANSFORM}>
      <Grid container className="transaction-email-popover" style={style}>
        <Grid item xs={12} className="transaction-email-popover-title">
          <Typography variant="h6">
            <Text id="send_receipt" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Input
            label={<Text id="email_address" />}
            value={email}
            onChange={handleChange}
            handleEnter={handleKeyPress}
            type="email"
          />
        </Grid>
        <Grid item xs={3}>
          <Button label={<Text id="send" />} disabled={!email.length} onClick={handleSend} />
        </Grid>
      </Grid>
    </Popover>
  )
})

export default TransactionListSendEmail
