import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import store from './../index'
import { setToastMessage } from '../store/actions/app'
import { loadingFalse } from '../store/actions/loading'

const returnUpdatableDevice = dev => {
  const device = { ...dev }
  device.store = device.store._id ?? device.store

  if (device.employees?.length) {
    device.employees = device.employees.map(employee =>
      typeof employee === 'string' ? employee : employee._id
    )
  }

  return { ...device }
}

const generateFingerprintUuid = async () => {
  const hasAdBlock = await detectAdBlock()
  const deviceUuidUpdated = localStorage.getItem('deviceUuidUpdated')

  if (!hasAdBlock) {
    try {
      const storedDeviceUuid = localStorage.getItem('StoredDeviceUuid')

      //TODO Temporary workaround to update UUIDs to the latest FingerprintJS one
      if (
        storedDeviceUuid &&
        storedDeviceUuid !== '' &&
        storedDeviceUuid !== 'undefined' &&
        deviceUuidUpdated
      )
        return storedDeviceUuid

      const fpPromise = FingerprintJS.load({
        apiKey: 'qWrdj7r6z8RlqD9KlAwy',
        endpoint: [
          'https://fp.countrhq.com',
          FingerprintJS.defaultEndpoint // The default endpoint as fallback
        ]
      })
      // const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get({ extendedResult: true })
      const devicefingerprintid = result.visitorId

      return devicefingerprintid
    } catch (e) {
      throw Error(e)
    }
  } else {
    if (deviceUuidUpdated) {
      return deviceUuidUpdated
    }
    store.dispatch(loadingFalse())
    store.dispatch(setToastMessage('disable_adblocker'))
  }
}

const detectAdBlock = async () => {
  try {
    const url = 'https://www3.doubleclick.net'
    const response = await fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store'
    })
    if (response) return false
  } catch (error) {
    return true
  }
}

export { returnUpdatableDevice, generateFingerprintUuid, detectAdBlock }
