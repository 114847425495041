import { memo } from 'react'

import { CartUtils } from '@countr/utils'
import { translate } from 'react-internationalization'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Grid from '@material-ui/core/Grid'

import { cartUtils as cartUtilities } from '../../utils/cartUtils'

import defaultImg from './../../assets/icon.svg'
import './CartItemsList.css'

const CartItem = memo(props => {
  const cartDiscount = !!props.isModal && !!props.cart?.discount ? 1 - props.cart.discount : 1
  const itemPrice = CartUtils.calculateCartEntryPrice(props.item)
  const itemsAmount = !!props.isModal ? props?.item?.amount : 1
  const itemsCost = ((itemPrice * cartDiscount) / itemsAmount).toFixed(2)

  const returnItemDeposit = item => {
    const deposit = item.product.options.deposit
    if (deposit && deposit.price) {
      const line = `+ ${translate('deposit')} - ${
        props.cart.currency.symbol
      }${deposit.price.toFixed(2)}`
      return (
        <Grid item xs={12}>
          <ListItemText secondary={line} />
        </Grid>
      )
    }
  }

  return (
    <ListItem
      id={`cart-item-${cartUtilities.getCartEntryId(props.item)}`}
      disableGutters={true}
      // props.hasDivider = true, last item has a divider
      divider={
        props.isModal
          ? false
          : !props.hasDivider && props.index === props.cart.items.length - 1
          ? false
          : true
      }>
      {props.item.product.image ? (
        <img
          src={props.item.product.image}
          className="cart-item-avatar"
          onError={e => (e.target.src = defaultImg)}
          alt=""
        />
      ) : (
        <div className="cart-item-avatar-default">
          <font className="cart-item-avatar-no-img-initial">
            {props.item.product.name.slice(0, 3).toUpperCase()}
          </font>
        </div>
      )}
      <Grid container>
        <Grid item xs={12}>
          <ListItemText
            primary={props.item.product.name}
            secondary={
              !props.item.product.current_variant.default
                ? props.item.product.current_variant.name
                : ''
            }
          />
        </Grid>
        {returnItemDeposit(props.item)}
        <Grid item xs={12}>
          <ListItemText secondary={props.item.note} />
        </Grid>
        {!!props.item.product.current_addons ? (
          props.item.product.current_addons.map(addon => (
            <Grid item xs={12} key={addon._id} className="shrink-row-height">
              <ListItemText
                secondary={`${addon.amount} x ${addon.name} ${
                  !!addon.price > 0 ? `(${props.cart.currency.symbol} ${addon.price.toFixed(2)})` : ''
                }`}
              />
            </Grid>
          ))
        ) : (
          <div />
        )}
      </Grid>
      <div className="cart-item-values">
        <Grid container>
          <Grid item xs={12}>
            <ListItemText
              className="cart-item-price"
              primary={`${props.cart.currency.symbol} ${itemsCost}`}
              secondary={`${!props.item.product.options.sold_by_weight ? 'x' : ''} ${
                props.quantity ? props.quantity : props.item.amount
              } ${props.item.product.options.sold_by_weight ? ' g' : ''}`}
            />
          </Grid>
          <Grid item xs={12}>
            <ListItemText
              secondary={
                props.item.product.discount > 0 &&
                (props.item.product.discount * 100).toFixed(2) + '%'
              }
            />
          </Grid>
        </Grid>
      </div>
    </ListItem>
  )
})

export default CartItem
