import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Text, translate } from 'react-internationalization'
import onScan from 'onscan.js'
import { BarcodeUtils } from '../../utils/BarcodeUtils'
import { cartUtils } from '../../utils/cartUtils'
import ProductUtils from '../../utils/ProductUtils'
import Util from '../../utils/Util'
import CustomerUtils from '../../utils/CustomerUtils'
import VoucherUtils from '../../utils/VoucherUtils'
import { setToastMessage } from '../../store/actions/app'
import { setCalculator } from '../../store/actions/products'
import { selectCart } from '../../store/actions/carts'
import { setGiftcardCode } from '../../store/actions/payments'
import ConfirmationModal from '../generic/ConfirmationModal'
import { AppInstances } from '../../utils/countrSdkInstance'

const mapStateToProps = state => {
  return {
    currentCart: state.carts.selectedCart,
    settings: state.settings,
    customers: state.customers.customers,
    calculator: state.products.calculator
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    setCalculator: num => dispatch(setCalculator(num)),
    selectCart: cart => dispatch(selectCart(cart)),
    setGiftcardCode: code => dispatch(setGiftcardCode(code))
  }
}

class BarcodeReader extends PureComponent {
  state = {
    barcodeNotFound: false,
    notFoundMessage: '',
    barcodeMsg: ''
  }

  componentDidMount = () => {
    // Start barcode listener
    onScan.attachTo(document, {
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      ignoreIfFocusOn: 'input',
      // keyCodeMapper: (e) => (([12,16,46].indexOf(e.keyCode) >= 0) ? onScan.decodeKeyEvent(e) : e.key || String.fromCharCode(e.keyCode)),
      // Handle dash in scan
      keyCodeMapper: function (oEvent) {
        // Look for special keycodes or other event properties specific to
        // the scanner
        if (oEvent.which == '189') {
          return '-' //189 is dash
        }
        // Fall back to the default decoder in all other cases
        return onScan.decodeKeyEvent(oEvent)
      },
      onScan: code => {
        // If external modal is open dont serach for product just pass the code
        if (this.props.openExternal) {
          this.props.handleProductsExternalService(code)
          return
        }
        // If giftcard modal is open, don't try to find a product with the barcode scanned
        const giftcardModal = document.getElementById('giftcard-modal')
        if (!giftcardModal) {
          this.searchScannedCode(code)
        } else {
          return this.props.setGiftcardCode(code)
        }
      }
    })
  }

  componentWillUnmount = () => {
    if (!!document.scannerDetectionData) {
      onScan.detachFrom(document)
    }
  }

  handlePlaySoundAndCalculator = () => {
    const { playSoundSuccessScan } = this.props.settings
    Util.playSound(playSoundSuccessScan)
    this.props.setCalculator(0)
  }

  searchScannedCode = async ean => {
    const {
      playSoundSuccessScan,
      barcodeCustomer,
      allowVoucherScan,
      searchProductBarcodeServer,
      allowTicketScan
    } = this.props.settings
    const { product, variant } = await BarcodeUtils.searchProductBarcode(
      ean,
      searchProductBarcodeServer
    )

    // First try full match product barcode
    if (!!product) {
      const dynamicPrice = ProductUtils.isDynamicPriceProduct(product)

      if (!!dynamicPrice) {
        this.props.handleDynamicPriceProduct(product)
        this.handlePlaySoundAndCalculator()
        return
      }

      const selectedVariant = !!variant ? variant : product.variants[0]
      this.props.handleProductScanned(product, selectedVariant)
      Util.playSound(playSoundSuccessScan)
      this.props.setCalculator(0)
      return
    } else {
      // Not found any full match barcode, trying dynamic barcode
      const dynamic = await BarcodeUtils.searchDynamicBarcode(ean)

      if (!!dynamic) {
        this.props.handleProductScanned(dynamic, dynamic.variants[0])
        Util.playSound(playSoundSuccessScan)
        this.props.setCalculator(0)
        return
      }
    }

    // check if cart's product has this qr_tag number
    if (allowTicketScan) {
      const countr = await AppInstances.getCountrSdk()

      const ticket = await countr.carts.searchTickets({ text: ean })

      if (!!ticket?.length) {
        this.props.selectCart(ticket[0])
        return
      }
    }

    // Checking if has any customer linked with this barcode
    if (barcodeCustomer) {
      const customer = await CustomerUtils.searchCustomerByIdentifier(this.props.customers, ean)

      if (!!customer) {
        const cart = { ...this.props.currentCart }
        cart.customer = customer
        cartUtils.updateCartLocally(cart)
        cartUtils.updateCartServer(cart)
        Util.playSound(playSoundSuccessScan)
        this.props.setToastMessage('customer_add_cart')
        return
      }
    }

    // Checking for a voucher with scanned code
    if (allowVoucherScan) {
      const voucher = await VoucherUtils.searchVoucherCode(ean).catch(error => {
        console.log('#### Voucher not found', error)
        this.handleOpenBarcodeNotFound(ean)
        return
      })

      if (!!voucher) {
        if (!this.props.currentCart.items.length) {
          return this.props.setToastMessage('empty_cart_voucher_warning')
        }
        const cart = { ...this.props.currentCart }
        const result = await VoucherUtils.checkVoucherValid(voucher, cart, this.props.selectCart)

        if (!!result) {
          this.props.setToastMessage(result.message)
          return
        }
      }
    }

    // Just run this function if it wasnt running in voucher check
    // Avoid duplicate register-operations entry for this scanned code
    !allowVoucherScan && this.handleOpenBarcodeNotFound(ean)
  }

  handleOpenBarcodeNotFound = ean => {
    BarcodeUtils.registerBarcodeNotFound(ean, this.props.currentCart)
    const modalText = translate('scan_code_not_exist')
    const formatedText = modalText.replace('{{barcode}}', `${ean}`)
    this.setState({ barcodeNotFound: true, barcodeMsg: ean, notFoundMessage: formatedText })
  }

  handleCloseBarcodeNotFound = () => {
    this.setState({ barcodeNotFound: false, barcodeMsg: '', notFoundMessage: '' })
  }

  render() {
    return (
      <div id="barcode-reader">
        {this.state.barcodeNotFound && (
          <ConfirmationModal
            openConfirmation={this.state.barcodeNotFound}
            handleCloseConfirmation={this.handleCloseBarcodeNotFound}
            confirmBtn={this.handleCloseBarcodeNotFound}
            noCloseBtn={true}
            confirmationTitle={<Text id="barcode_scanner" />}
            confirmationText={this.state.notFoundMessage}
            type="barcode_not_found"
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeReader)
