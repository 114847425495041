import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import VariantsModal from '../Modals/VariantsModal'
import NonRevenueModal from '../Modals/NonRevenueModal'
import ProductSoldByWeightModal from '../Modals/ProductSoldByWeightModal'
import CustomPrice from '../Modals/CustomPrice'
import AddonsModal from '../Modals/AddonsModal'
import ProductUtils from '../../../utils/ProductUtils'

const mapStateToProps = state => {
  return {
    settings: state.settings,
    store: state.devices.store
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const SpecialProductSelector = memo(function SpecialProductSelector({
  actionOrder,
  handleSpecialSelector,
  settings,
  store
}) {
  const [openVariants, setOpenVariants] = useState(false)
  const [openNonRevenue, setOpenNonRevenue] = useState(false)
  const [openSoldByWeight, setOpenSoldByWeight] = useState(false)
  const [openDynamic, setOpenDynamic] = useState(false)
  const [openAddons, setOpenAddons] = useState(false)

  useEffect(() => {
    if (actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.VARIANTS) {
      setOpenVariants(true)
    } else if (
      actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.NON_REVENUE &&
      settings.askExtraNonRevenueInfo
    ) {
      setOpenNonRevenue(true)
    } else if (
      actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.NON_REVENUE &&
      !settings.askExtraNonRevenueInfo
    ) {
      handleClose('-')
    } else if (actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.SOLD_BY_WEIGHT) {
      setOpenSoldByWeight(true)
    } else if (actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.DYNAMIC) {
      setOpenDynamic(true)
    } else if (actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.ADDONS) {
      setOpenAddons(true)
    } else if (actionOrder.order[0] === ProductUtils.PRODUCT_ACTION_ORDER.BARCODE) {
      handleClose('-')
    }
  }, [actionOrder])

  const handleClose = value => {
    setOpenVariants(false)
    setOpenNonRevenue(false)
    setOpenSoldByWeight(false)
    setOpenDynamic(false)
    setOpenAddons(false)

    // Variants
    if (value) {
      handleSpecialSelector(actionOrder.order[0], value)
    }
  }

  return (
    <div className="special-product-selector">
      {openVariants && (
        <VariantsModal
          openVariants={openVariants}
          handleClose={handleClose}
          product={actionOrder.product}
          settings={settings}
          store={store}
        />
      )}
      {openNonRevenue && (
        <NonRevenueModal
          openNonRevenue={openNonRevenue}
          handleClose={handleClose}
          handleConfirm={handleClose}
        />
      )}
      {openSoldByWeight && (
        <ProductSoldByWeightModal
          openSoldByWeight={openSoldByWeight}
          handleCloseSoldByWeight={handleClose}
          handleConfirmCustomProduct={handleClose}
        />
      )}
      {openDynamic && (
        <CustomPrice
          openCustomPrice={openDynamic}
          handleCloseCustomPrice={handleClose}
          handleConfirmCustomPrice={handleClose}
          store={store}
        />
      )}
      {openAddons &&
        (actionOrder.product.addons?.some(addon => addon.visible) ||
          actionOrder.product.addonGroups?.some(group => group._id?.visible)) && (
          <AddonsModal
            openAddons={openAddons}
            handleClose={handleClose}
            product={actionOrder.product}
            store={store}
            language={settings.language}
          />
        )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(SpecialProductSelector)
