import { memo } from 'react';
import { useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import SearchTransaction from './SearchTransaction'
import IconButton from '@material-ui/core/IconButton'

const TransactionListHeader = memo(props => {
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text
  }

  const returnHeaderText = () => {
    if (!props.isTransactionSelected) {
      return 'transactions'
    } else {
      return props.selectedTransaction.__t
        ? props.selectedTransaction.__t.toLowerCase()
        : 'transactions'
    }
  }

  return (
    <div>
      {props.isTransactionSelected && (
        <IconButton className="back-to-list-btn" onClick={props.backToTransactionsList}>
          <span className="icon-left-arrow" style={style} />
        </IconButton>
      )}
      <Text id={returnHeaderText()} />
      <div
        className="transaction-btn-action"
        style={{ opacity: props.isTransactionSelected ? 0 : 1 }}>
        <SearchTransaction searchTransaction={props.searchTransaction} />
      </div>
    </div>
  )
})

export default TransactionListHeader
