import { memo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import { Text } from 'react-internationalization'

const releaseNotes = require('./../../release_notes/release_note.json')
const lastFiveVersions = releaseNotes.releases.splice(0, 5)

const detailsStyle = { padding: 8 }

const ReleaseNote = memo(({ open, handleClose }) => {
  //
  //
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        <Text id="release_note" />
      </DialogTitle>
      <DialogContent>
        {lastFiveVersions.map(release => (
          <Accordion key={release.version}>
            <AccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
              <Typography>
                <strong>
                  <Text id="release_note" /> - {release.version}
                </strong>
              </Typography>
            </AccordionSummary>
            {!!release?.improvements?.length &&
              release.improvements.map((improvement, index) => (
                <AccordionDetails key={index} style={detailsStyle}>
                  <Typography>
                    [<Text id="improvement" />] - {improvement}
                  </Typography>
                </AccordionDetails>
              ))}
            {!!release?.fixes?.length &&
              release.fixes.map((fix, index) => (
                <AccordionDetails key={index} style={detailsStyle}>
                  <Typography>
                    [<Text id="fixed" />] - {fix}
                  </Typography>
                </AccordionDetails>
              ))}
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Text id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ReleaseNote
