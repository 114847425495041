import { memo } from 'react';
import { Text, translate } from 'react-internationalization'
import { useSelector } from 'react-redux'
import ProductUtils from '../../../utils/ProductUtils'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Button } from '@countr/ui'

import './../Catalog.scss'

const NOT_TRACKED = 'not_tracked'

const VariantsModal = memo(function VariantsModal(props) {
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }
  const showVariantNamePrice = variant => {
    const name = `${ProductUtils.getVariantName(variant, props.settings.language)} (${
      props.store.currency.symbol
    } ${ProductUtils.getProductPrice(props.store._id, props.product, variant._id).toFixed(2)})`
    let stock = ProductUtils.getVariantStock(props.store._id, props.product, variant._id)
    stock = stock === NOT_TRACKED ? translate(NOT_TRACKED) : `${stock} ${translate('units')}`

    return props.settings.showStock ? `${name}: ${stock}` : name
  }

  const close = () => {
    props.handleClose()
  }

  return (
    <Dialog open={props.openVariants} onClose={close}>
      <DialogTitle style={style}>{`${props.product.name} - ${translate(
        'select_variant'
      )}`}</DialogTitle>
      <DialogContent style={style}>
        <List component="nav" className="giveaway-list">
          {props.product.variants.map((variant, index) => (
            <ListItem
              key={variant._id}
              style={style}
              button={true}
              divider={true}
              onClick={() => props.handleClose(variant)}
              testid={`variant-${index}`}>
              <ListItemText primary={showVariantNamePrice(variant)} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={close} className="secondary" />
      </DialogActions>
    </Dialog>
  )
})

export default VariantsModal
