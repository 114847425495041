import {
  ADD_EMPLOYEE,
  ADD_ALL_EMPLOYEES,
  SELECT_EMPLOYEE,
  SHOW_EMPLOYEES_MODAL
} from './actionTypes'

export const addEmployee = employee => ({
  type: ADD_EMPLOYEE,
  payload: employee
})

export const addAllEmployees = employees => ({
  type: ADD_ALL_EMPLOYEES,
  payload: employees
})

export const selectEmployee = employee => ({
  type: SELECT_EMPLOYEE,
  payload: employee
})

export const showEmployeesModal = () => ({
  type: SHOW_EMPLOYEES_MODAL
})
