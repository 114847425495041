import { memo, Fragment, useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import { useTracking } from 'react-tracking'
import { AppInstances } from '../../../utils/countrSdkInstance'
import ProductUtils from '../../../utils/ProductUtils'
import ErrorUtils from '../../../utils/ErrorUtils'
import Util from '../../../utils/Util'
import TaxesUtils from '../../../utils/TaxesUtils'
import { cartUtils } from '../../../utils/cartUtils'
import { CountrListeners } from '../../../utils/CountrListeners'
import { setCalculator } from '../../../store/actions/products'
import { loadingTrue, loadingFalse } from '../../../store/actions/loading'
import { setToastMessage } from '../../../store/actions/app'
import ProductTile from './ProductTile'
import ExternalServiceProductModal from './ExternalServiceProductModal'
import ProductDetails from '../Modals/ProductDetails/ProductDetails'
import SpecialProductSelector from './SpecialProductSelector'
import BarcodeReader from '../BarcodeReader'
import usePrevious from '../../../utils/usePrevious'
import { TRACKER_EVENTS } from '../../../utils/trackerEvents'
import { setLocalSearchInput, getLocalSearchInput } from '../../../utils/ProductRefreshTmpFunctions'

import { setActiveCategory } from '../../../store/actions/categories'

import './ProductsList.scss'

const mapStateToProps = state => {
  return {
    settings: state.settings,
    user: state.user.user,
    store: state.devices.store,
    device: state.devices.device,
    activeCategory: state.categories.activeCategory,
    currentCart: state.carts.selectedCart,
    products: state.products,
    selectedEmployee: state.employees.selectedEmployee
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveCategory: id => dispatch(setActiveCategory(id)),
    setCalculator: num => dispatch(setCalculator(num)),
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse()),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const INITIAL_ORDER_PRODUCT = {
  product: {},
  order: [],
  variant: {},
  amount: 1,
  addons: [],
  extras: {}
}
const NAME = 'name'
const POSITION = 'position'
const LIMIT_PAGE = 200
const LIMIT_COUNT_LOADER = 1000

const ProductsList = memo(function ProductsList({
  settings,
  activeCategory,
  user,
  store,
  device,
  currentCart,
  products,
  setCalculator,
  loadingTrue,
  loadingFalse,
  setToastMessage,
  selectedEmployee
}) {
  const [openExternal, setOpenExternal] = useState(false)
  const [externalProduct, setExternalProduct] = useState({})
  const [productsList, setProductsList] = useState([])
  const [showInventory, setShowInventory] = useState(null)
  const [productActionOrder, setProductActionOrder] = useState(INITIAL_ORDER_PRODUCT)
  const prevSearch = usePrevious({
    isProductSearchRunning: products.isProductSearchRunning,
    searchInput: products.searchInput
  })
  const { trackEvent } = useTracking()

  const activeCategoryChanged = useSelector(state => state.categories.activeCategory)
  const employeeChanged = useSelector(state => state.employees.selectedEmployee)

  useEffect(() => {
    loadProducts()
  }, [employeeChanged, activeCategoryChanged])

  useEffect(() => {
    loadProducts()
  }, [activeCategory, settings.productsAlphabeticOrder, products.showDepositProducts])

  useEffect(() => {
    //TODO: replace tmp fix
    setLocalSearchInput(products.searchInput)
    if (!!prevSearch) {
      if (isSearchProductValid()) {
        loadProducts(products.searchInput)
      } else if (!products.isProductSearchRunning && prevSearch.isProductSearchRunning) {
        loadProducts()
      }
    }
  }, [products.isProductSearchRunning, products.searchInput])

  useEffect(() => {
    initProductsListeners()
  }, [])

  const loadProducts = async value => {
    if (value) {
      showProductListLoader() && loadingTrue()
    }

    const { showDepositProducts } = products
    const sortBy = settings.productsAlphabeticOrder ? NAME : POSITION

    try {
      let loadedProducts = []

      if (!!value) {
        trackEvent({
          event: TRACKER_EVENTS.SEARCH_PRODUCT,
          data: { search: products.searchInput },
          merchant: user._id
        })

        loadedProducts = await ProductUtils.searchProducts(
          value,
          sortBy,
          settings.language,
          showDepositProducts
        )

        if (!loadedProducts.length && settings.searchProductBarcodeServer) {
          const countr = await AppInstances.getCountrSdk()
          loadedProducts = await countr.products.search({ text: value })
        }
      } else if (showDepositProducts) {
        loadedProducts = await ProductUtils.getDepositProductList(sortBy, activeCategory)
      } else {
        loadedProducts = await ProductUtils.getProductList(sortBy, activeCategory)
      }

      if (!!loadedProducts) {
        let productsList =
          sortBy === NAME
            ? Util.sortAlphabeticalByField(loadedProducts, NAME, settings.language)
            : loadedProducts.sort(Util.sortByPositionName)

        //Remove items that are restricted to admins only, if not admin here
        if (selectedEmployee?.rights !== 'admin') {
          productsList = productsList.filter(product => !product.options?.admin_only)
        }

        if (!!value) {
          // show searched products (invisible items should appear in search)
          setProductsList(productsList.splice(0, LIMIT_PAGE))
        } else {
          // show all visible products
          setProductsList(productsList.filter(product => !!product.visible).splice(0, LIMIT_PAGE))
        }
      }
    } catch (error) {
      ErrorUtils.logError({
        msg: `Error while retrieving products list from IndexedDB
          (ProductUtils.getProductList(${sortBy}, ${activeCategory}))`,
        stack: JSON.stringify(error)
      })
    }

    showProductListLoader() && loadingFalse()
  }

  const showProductListLoader = () => {
    return products.count > LIMIT_COUNT_LOADER
  }

  const isSearchProductValid = () => {
    return (
      !!products.isProductSearchRunning && !!products.searchInput && products.searchInput.length > 2
    )
  }

  const initProductsListeners = () => {
    CountrListeners.initProdListeners(
      store._id,
      productListenersCallback,
      TaxesUtils.taxesListenerCallback
    )
  }

  const productListenersCallback = async (result, event) => {
    //TODO: replace tmp fix
    const searchInput = await getLocalSearchInput()
    if (searchInput !== '') {
      loadProducts(searchInput)
    } else {
      loadProducts()
    }
  }

  const selectProductAction = async (product, scanned = false) => {
    const order = ProductUtils.getProductActionOrder(product, products.showDepositProducts)
    const quantity = !!products.calculator ? products.calculator : 1

    if (!!order.length && order[0] === ProductUtils.PRODUCT_ACTION_ORDER.DEFAULT) {
      cartUtils.addProductToCart(currentCart, product, product.variants[0], [], quantity, settings)
      setCalculator(0)
    } else if (!!order.length && order[0] === ProductUtils.PRODUCT_ACTION_ORDER.DEPOSIT) {
      const deposit = ProductUtils.createDepositProduct(product, currentCart)
      cartUtils.addProductToCart(currentCart, deposit, deposit.variants[0], [], quantity, settings)
      setCalculator(0)
    } else if (
      !!order.length &&
      order[0] === ProductUtils.PRODUCT_ACTION_ORDER.DYNAMIC &&
      scanned
    ) {
      // Dynamic barcode product
      cartUtils.addProductToCart(currentCart, product, product.variants[0], [], quantity, settings)
      setCalculator(0)
    } //If giftcard and not non-revenue, generate a QR code / tag for the item
    else if (!!order.length && order[0] === ProductUtils.PRODUCT_ACTION_ORDER.GIFTCARD) {
      let option = {}
      if (!settings.askExtraNonRevenueInfo) {
        // add both QR tag and non_revenue_info as same value
        product = Object.assign({}, product) //Stop existing cart entry updating
        const countr = await AppInstances.getCountrSdk()
        const barcode = countr.generateItemBarcode(product)
        product.extras = product.extras || {}
        product.extras.qr_tag = barcode + ''
        product.options.voucher.identifier.code_value = barcode + ''
        product.extras.non_revenue_info = barcode + ''
        option = {
          ...productActionOrder,
          product,
          order
        }
        setProductActionOrder(option)
        dequeueAction(option)
      } else {
        product = Object.assign({}, product)
        product.extras = product.extras || {}
        product.options = product.options || {
          voucher: {
            identifier: {
              code_type: 'qr'
            }
          }
        }
        option = {
          ...productActionOrder,
          product,
          order
        }
        setProductActionOrder(option)
        dequeueAction(option)
      }
    } else {
      let option = {
        ...productActionOrder,
        product,
        order,
        amount: quantity
      }
      setProductActionOrder(option)
    }
  }

  const handleSpecialSelector = async (type, value) => {
    const event = {
      event: TRACKER_EVENTS.SPECIAL_PRODUCT_CLICK,
      data: { type },
      merchant: user._id
    }

    if (!!value) {
      event.data._id = value._id || ''
      event.data.name = value.name || ''
    } else {
      event.event = TRACKER_EVENTS.CLOSE_SPECIAL_PRODUCT_MODAL
    }

    trackEvent(event)

    if (!value) {
      dequeueAction()
      return
    }

    let option = {}

    switch (type) {
      case ProductUtils.PRODUCT_ACTION_ORDER.VARIANTS:
        option = {
          ...productActionOrder,
          variant: value
        }
        setProductActionOrder(option)
        break
      case ProductUtils.PRODUCT_ACTION_ORDER.NON_REVENUE:
        option = {
          ...productActionOrder,
          product: {
            ...productActionOrder.product,
            extras: {
              ...productActionOrder.product.extras,
              non_revenue_info: value
            }
          }
        }
        if (value !== '-') {
          option.product.extras.qr_tag = value
          option.product.options = {
            ...productActionOrder.product.options,
            voucher: {
              ...productActionOrder.product.options.voucher,
              code_value: value
            }
          }
        }
        setProductActionOrder(option)
        break
      case ProductUtils.PRODUCT_ACTION_ORDER.SOLD_BY_WEIGHT:
        option = {
          ...productActionOrder,
          amount: parseInt(value, 10)
        }
        setProductActionOrder(option)
        break
      case ProductUtils.PRODUCT_ACTION_ORDER.DYNAMIC:
        option = {
          ...productActionOrder,
          product: {
            ...productActionOrder.product,
            variants: [
              {
                ...productActionOrder.product.variants[0],
                price: value
              }
            ]
          }
        }
        setProductActionOrder(option)
        break
      case ProductUtils.PRODUCT_ACTION_ORDER.ADDONS:
        option = {
          ...productActionOrder,
          addons: value
        }
        setProductActionOrder(option)
        break
      case ProductUtils.PRODUCT_ACTION_ORDER.BARCODE:
        //If needs_barcode, generate a QR code / tag for the item
        const countr = await AppInstances.getCountrSdk()
        const barcode = countr.generateItemBarcode(productActionOrder.product)
        option = {
          ...productActionOrder,
          product: {
            ...productActionOrder.product,
            extras: {
              ...productActionOrder.product.extras,
              qr_tag: barcode + ''
            }
          }
        }
        setProductActionOrder(option)
        break
      default:
        break
    }

    dequeueAction(option)
  }

  const dequeueAction = option => {
    const order = { ...option }
    order.order.shift()

    if (!!order.order.length) {
      setProductActionOrder(order)
    } else {
      const { product, variant, addons, amount } = order
      const usedVariant = !!variant && !!variant._id ? variant : product.variants[0]
      cartUtils.addProductToCart(currentCart, product, usedVariant, addons, amount, settings)
      setProductActionOrder(INITIAL_ORDER_PRODUCT)
      setCalculator(0)
    }
  }

  const handleShowInventory = product => {
    setShowInventory(!!product && !!product._id ? product : null)
    trackEvent({
      event: TRACKER_EVENTS.SHOW_PRODUCT_DETAILS,
      data: { name: product.name, _id: product._id },
      merchant: user._id
    })
  }

  const handleProductScanned = (product, variant) => {
    const productCopy = { ...product }
    productCopy.variants = [{ ...variant }]
    selectProductAction(productCopy, true)
    trackEvent({
      event: TRACKER_EVENTS.PRODUCT_BARCODE_SCANNED,
      data: { name: product.name, _id: product._id, barcode: variant.ean },
      merchant: user._id
    })
  }

  const handleProductsExternalService = async code => {
    if (!code) {
      return
    }

    setOpenExternal(false)
    try {
      const processArgs = {
        provider: externalProduct.options.external_service.provider,
        merchantId: user._id,
        storeId: store._id,
        cartId: currentCart._id,
        productId: externalProduct._id,
        variantId: externalProduct.variants[0]._id,
        barcode: code,
        test: !JSON.parse(process.env.REACT_APP_PROD)
      }
      const countr = await AppInstances.getCountrSdk()
      const result = await countr.processProductExternal(processArgs)
      console.log('### Response from processing product externally', result)
    } catch (error) {
      const errorMessage =
        error?.message?.error?.message || 'Error processing product via external service'
      setToastMessage(errorMessage)
    }
  }

  const handleShowExternalModal = () => {
    setOpenExternal(!openExternal)
  }

  return (
    <Fragment>
      <div
        id="products-list"
        className={`product-list${settings.productTileSize ? ' increase-product-size' : ''}`}>
        {productsList.map(product => (
          <ProductTile
            key={product._id}
            product={product}
            selectAction={selectProductAction}
            store={store}
            isDepositSelected={products.showDepositProducts}
            showInventory={!!showInventory}
            handleShowInventory={handleShowInventory}
            handleShowExternalModal={handleShowExternalModal}
            setExternalProduct={setExternalProduct}
          />
        ))}
        {!productsList.length && (
          <div className="products-not-found">
            <span>
              <Text id="products_not_found" />
            </span>
          </div>
        )}
      </div>
      <SpecialProductSelector
        actionOrder={productActionOrder}
        dequeueAction={dequeueAction}
        handleSpecialSelector={handleSpecialSelector}
      />
      {!!showInventory && (
        <ProductDetails
          showInventory={!!showInventory}
          handleClose={handleShowInventory}
          product={showInventory}
          language={settings.language}
        />
      )}
      <BarcodeReader
        handleProductScanned={handleProductScanned}
        open={openExternal}
        handleProductsExternalService={handleProductsExternalService}
        handleDynamicPriceProduct={selectProductAction}
      />
      <ExternalServiceProductModal
        open={openExternal}
        close={handleShowExternalModal}
        handleProductsExternalService={handleProductsExternalService}
      />
    </Fragment>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList)
