import { memo } from 'react'
import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'
import { cartUtils } from './../../utils/cartUtils'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import defaultImg from './../../assets/icon.svg'

const RefundSelectItems = memo(props => {
  return (
    <div>
      <Grid container justifyContent="center" className="refund-content-dialog">
        <Grid item xs={12} className="refund-title">
          <InputLabel>
            <Text id="refund_items" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} className="refund-total">
          <Typography variant="h5" component="h2">
            {props.currency.symbol} {props.refundValue.toFixed(2)}
          </Typography>
          <InputLabel>
            <Text id="out_of" /> {props.transaction.total.toFixed(2)}
          </InputLabel>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className="refund-content-dialog refund-items">
        <Grid item xs={4} className="refund-list-item">
          <InputLabel>
            <Text id="purchased_items" />
          </InputLabel>
        </Grid>
        <Grid item xs={4} className="refund-list-item">
          <InputLabel>
            <Text id="refundable" />
          </InputLabel>
        </Grid>
        <Grid item xs={4} className="refund-list-item">
          <InputLabel>
            <Text id="to_be_refunded" />
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <List component="nav" disablePadding={true}>
            {props.refundItems
              .filter(item => item.amount > 0)
              .map((item, index) => (
                <ListItem
                  key={`${cartUtils.getCartEntryId(item)}-${item.product.discount}`}
                  disableGutters={true}
                  divider={true}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className="refund-list-item">
                    <Grid item xs={4} className="refund-list-item-avatar">
                      {item.product.image ? (
                        <img
                          src={item.product.image}
                          className="cart-item-avatar"
                          onError={e => (e.target.src = defaultImg)}
                          alt=""
                        />
                      ) : (
                        <div className="cart-item-avatar-default">
                          <font className="cart-item-avatar-no-img-initial">
                            {item.product.name.slice(0, 3).toUpperCase()}
                          </font>
                        </div>
                      )}
                      <ListItemText
                        className="refund-list-item-product-name"
                        primary={item.product.name}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {props.getRefundableAmount(item)}
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className="refund-list-item">
                        <Grid item xs={3}>
                          <Button
                            className="discount-select-mode-btn"
                            size="small"
                            onClick={() => props.changeRefundItemButton(index, 'remove')}>
                            <span className="icon-minus" />
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={isMobileOnly ? 3 : 6}
                          className={isMobileOnly ? 'push-right' : ''}>
                          <TextField
                            fullWidth={true}
                            type="number"
                            className="refund-amount"
                            value={item.refund}
                            onChange={props.changeRefundItem(index)}
                            error={props.openSelectItemsToRefund}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            className="discount-select-mode-btn"
                            size="small"
                            onClick={() => props.changeRefundItemButton(index, 'add')}>
                            <span className="icon-plus" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item xs={12} className="refund-all">
          <Button onClick={props.refundAllItems} variant="contained">
            <Text id="refund_all" />
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className="refund-note">
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            value={props.note}
            label={<Text id="note" />}
            onChange={props.handleChange('note')}
          />
        </Grid>
      </Grid>
    </div>
  )
})

export default RefundSelectItems
