import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import { Text } from 'react-internationalization'

import './NewPaymentModal.scss'

const PayPerItemButtons = props => {
  return (
    <>
      {props.cart.payments.length > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="payment-grid payment-grid-type">
          {props.cart.payments.map((payment, index) => (
            <div key={payment.date} className="partial-payment-item">
              <Grid item xs={9} className="partial-payment-item-time">
                {new Date(payment.date).toLocaleString()}
                {' - '}
                {!!payment.method ? <Text id={payment.method} /> : ''}
                {!!payment.provider ? <Text id={payment.provider} /> : ''}
                {/* Show paid products (if any) */}
                {payment?.info?.products?.map(product => (
                  <div key={product.id} className="partial-payment-items">
                    {product.amount} x {props.handleProductsName(product, props.cart)}
                  </div>
                ))}
              </Grid>
              <Grid item xs={2} className="partial-payment-item-value">
                <b>
                  {props.cart.currency.symbol} {parseFloat(payment.paid).toFixed(2)}
                </b>
              </Grid>
              <Grid item xs={1} className="partial-payment-item-value">
                <IconButton
                  onClick={() => props.handleDeletePayment(index, props.cart)}
                  className="partial-payment-item-delete">
                  <span className="icon-close" />
                </IconButton>
              </Grid>
            </div>
          ))}
        </Grid>
      )}
    </>
  )
}

export default PayPerItemButtons
