/* eslint-disable no-case-declarations */
import {
  ADD_CART,
  EDIT_CART,
  SELECT_CART,
  SELECT_CART_ITEM,
  CLEAN_CART,
  SET_TRANSACTION,
  ADD_CUSTOMER_CART,
  DELETE_CART,
  TABLE_SEAT
} from './../actions/actionTypes'

const initialState = {
  carts: [],
  selectedCart: {},
  selectedCartItem: {},
  transaction: {},
  customers: [],
  seatNo: 0
}

const cartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART:
      const index = state.carts.findIndex(cart => cart._id === action.payload._id)
      return {
        ...state,
        carts: index < 0 ? state.carts.concat(action.payload) : state.carts
      }
    case EDIT_CART:
      const editedCarts = state.carts.map(cart => {
        return cart._id === action.id ? action.payload : cart
      })
      return {
        ...state,
        carts: editedCarts
      }
    case SELECT_CART:
      return {
        ...state,
        selectedCart: action.payload || {}
      }
    case SELECT_CART_ITEM:
      return {
        ...state,
        selectedCartItem: action.payload
      }
    case CLEAN_CART:
      const cleanCart = state.selectedCart
      cleanCart.items = []
      cleanCart.total = 0
      cleanCart.sub_total = 0
      cleanCart.discount = 0
      cleanCart.reduction = {
        numeric: 0,
        percentage: 0
      }
      cleanCart.scanned_codes = []
      cleanCart.paid = 0
      cleanCart.payments = []
      cleanCart.customer = null
      cleanCart.extras.note = ''
      cleanCart.date = new Date()
      cleanCart.updated_at = new Date()
      cleanCart.extras.cartStartedAt = new Date()
      cleanCart.extras.covers = 0
      cleanCart.extras.buzzer = null
      cleanCart.external_id = null
      delete cleanCart.extras.tickets
      delete cleanCart.extras.booking_id
      delete cleanCart.extras.drop_ship
      return {
        ...state,
        selectedCart: cleanCart
      }
    case SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload
      }
    case ADD_CUSTOMER_CART:
      return {
        ...state,
        customers: action.payload
      }
    case DELETE_CART:
      if (state.carts?.length === 1) {
        return {
          ...state
        }
      }
      const notDeletedCarts = state.carts.filter(cart => cart._id !== action.payload)
      const newSelectedCart = { ...notDeletedCarts[0] }
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(newSelectedCart))
      return {
        ...state,
        carts: notDeletedCarts,
        selectedCart: newSelectedCart
      }
    case TABLE_SEAT:
      return {
        ...state,
        seatNo: action.payload
      }
    default:
      return state
  }
}

export default cartsReducer
