import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Button, Input, Divider } from '@countr/ui'
import { Text, translate } from 'react-internationalization'
import './CartItemDetails.scss'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    theme: state.app.theme,
    settings: state.settings
  }
}

const defaultDiscounts = [0.05, 0.1, 0.15, 0.2]
const discountSet = ['discount_new_price', 'discount_percent', 'discount_value']

const CartItemDiscount = memo(props => {
  const [discountType, setDiscountType] = useState('discount_percent')
  const [inputValue, setInputValue] = useState(0)
  const style = props.theme &&
    props.theme.colors && {
      color: props.theme.colors.text,
      backgroundColor: props.theme.colors.body
    }

  useEffect(() => {
    if (props.reduction?.numeric > 0) {
      setDiscountType('discount_value')
      setInputValue(props.reduction.numeric)
    } else if (props.discount > 0) {
      setDiscountType('discount_percent')
      setInputValue(props.discount)
    }
  }, [])

  const returnDiscountButtons = () => {
    const { options } = props.devices.store
    const discounts =
      !!options && !!options.presetDiscounts?.length ? options.presetDiscounts : defaultDiscounts

    return (
      <Row>
        {discounts.map(discount => (
          <Button
            key={discount}
            className="neutro"
            onClick={() => applySelectPercentDiscount(discount)}
            label={returnDiscountValueString(discount)}
            styles={{ width: '100%', margin: 2 }}
          />
        ))}
      </Row>
    )
  }

  const returnDiscountValueString = discount => {
    return `${parseFloat(discount * 100)}%`
  }

  const applySelectPercentDiscount = discount => {
    setDiscountType('discount_percent')
    setInputValue(discount * 100)
    props.handleChangeDiscount('discount_percent', discount * 100)
  }

  const returnTypeTranslated = type => {
    const currency = props.devices.store.currency
    if (type === 'discount_new_price') {
      return <Text id="new_price" values={{ currency: currency.symbol }} />
    } else if (type === 'discount_percent') {
      return `${translate('discount')} (%)`
    } else {
      return <Text id="discount_currency" values={{ currency: currency.symbol }} />
    }
  }

  const handleChange = value => {
    if (discountType === 'discount_percent') {
      props.handleChangeDiscount('discount_percent', parseFloat(value))
    } else {
      props.handleChangeDiscount(discountType, parseFloat(value))
    }

    setInputValue(value)
  }

  return (
    <div className="cart-item-discount center">
      {props.settings.disableCustomDiscounts && (
        <Row>
          <span style={style}>
            <Text id="apply_discount" />
          </span>
        </Row>
      )}
      {returnDiscountButtons()}
      <br />
      {!props.settings.disableCustomDiscounts && (
        <>
          <span style={style}>
            <Text id="or_customize_discount" />
          </span>
          <Divider />
          <Row>
            {discountSet.map(type => (
              <Button
                key={type}
                className="primary"
                onClick={() => setDiscountType(type)}
                label={returnTypeTranslated(type)}
                styles={{
                  margin: 2,
                  border:
                    type === discountType ? `2px solid ${props.theme.colors.secondaryText}` : ''
                }}
              />
            ))}
          </Row>
          <Row>
            <Input
              type="number"
              label={<Text id="discount" />}
              value={inputValue}
              onChange={handleChange}
              style={{ left: 0 }}
            />
          </Row>
        </>
      )}
    </div>
  )
})

export default connect(mapStateToProps, null)(CartItemDiscount)
