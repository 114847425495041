import { useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import { Button, Divider, Row } from '@countr/ui'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Util from '../../utils/Util'
import { PaymentUtils } from '../../utils/PaymentUtils'

import './PaymentModal.scss'

const ExcessAmountModal = ({ open, handleClose, cart, amount, handleConfirmTip }) => {
  const payingAmount = parseFloat(amount) || 0
  const currency = cart?.currency?.symbol || '€'
  const paid = PaymentUtils.getLastCartPaymentValue(cart).toFixed(2)
  const paidSoFar = (PaymentUtils.calculatePaidValue(cart) - paid).toFixed(2)
  const remaining = parseFloat(cart.total - paidSoFar - paid).toFixed(2)
  const excessAmount = parseFloat(payingAmount - remaining).toFixed(2)

  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }

  return (
    <>
      <Dialog
        className="excess-modal"
        open={open}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(reason, handleClose)
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown>
        <DialogTitle id="form-dialog-title" style={style}>
          <Row justifyContent="space-between">
            <Text id="tip" />
          </Row>
        </DialogTitle>
        <DialogContent style={style}>
          <Row className="gratuity-header">
            <Text id="payment_info" />
          </Row>
          <Divider />

          <div className="container-style">
            <div className="left-column">
              <Text id="total_due" />
            </div>

            <div className="right-column">
              {currency} {remaining}
            </div>
          </div>
          <Divider />

          <div className="container-style">
            <div className="left-column">
              <Text id="paying_now" />
            </div>

            <div className="right-column">
              {currency} {payingAmount.toFixed(2)}
            </div>
          </div>
          <Divider />

          <div className="container-style">
            <div className="left-column">
              <Text id="excess_amount" />
            </div>

            <div className="right-column">
              {currency} {excessAmount}
            </div>
          </div>
          <Divider />
        </DialogContent>

        <DialogActions className="gratuity-actions" style={style}>
          <Button
            label={<Text id="confirm_with_tip" />}
            onClick={() => handleConfirmTip(true)}
            className="primary"
          />
          <Button
            label={<Text id="confirm_with_change" />}
            onClick={() => handleConfirmTip(false)}
            className="primary"
          />
        </DialogActions>

        <DialogActions style={style}>
          <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExcessAmountModal
