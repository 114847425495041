import { memo } from 'react';
import { Text } from 'react-internationalization'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const styleButton = {
  backgroundColor: '#318ed5',
  color: '#fff'
}

const RecoveryCartsAlertMsg = memo(props => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        <Text id="recover_carts" />
      </DialogTitle>
      <DialogContent>
        <span style={{ color: '#f44336' }}>
          <Text id="recover_carts_alert" />
        </span>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.handleClose}>
          <Text id="cancel" />
        </Button>
        <Button variant="contained" style={styleButton} onClick={props.handleConfirm}>
          <Text id="confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default RecoveryCartsAlertMsg
