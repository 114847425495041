import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import StoreUtils from '../../../utils/StoreUtils'
import { AppInstances } from '../../../utils/countrSdkInstance'
import { cartUtils } from '../../../utils/cartUtils'
import { PaymentUtils } from '../../../utils/PaymentUtils'
import { selectCart, editCart } from '../../../store/actions/carts'
import { addTransactionHead } from '../../../store/actions/transactions'
import { setToastMessage } from '../../../store/actions/app'
import { loadingTrue, loadingFalse } from '../../../store/actions/loading'
import Util from '../../../utils/Util'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import { Button } from '@countr/ui'

import './CartToolbar.scss'

const GIVEAWAY = [
  { key: 'loss', icon: 'icon-minus-border' },
  { key: 'stolen', icon: 'icon-close-toast' },
  { key: 'own_use', icon: 'icon-home' },
  { key: 'giveaway', icon: 'icon-gift-thin' },
  { key: 'breakage', icon: 'icon-breakage' }
]

const mapStateToProps = state => {
  return {
    carts: state.carts,
    devices: state.devices,
    employees: state.employees,
    settings: state.settings,
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction)),
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse())
  }
}

const RepsWasteModal = memo(props => {
  const [repsAndWasteOptions, setRepsAndWasteOptions] = useState([])
  const style = {
    color: props.theme.colors.text,
    backgroundColor: props.theme.colors.body
  }

  const handleGiveaway = async type => {
    props.loadingTrue()
    const countr = await AppInstances.getCountrSdk()
    const hasTables = StoreUtils.hasFloorplans(props.devices.store)
    const device = Object.assign({}, props.devices.device)
    const cart = Object.assign({}, props.carts.selectedCart)
    cart.discount = 1
    cart.reduction = {
      numeric: 0,
      percentage: 1
    }
    const { totalAmount, totalAmountPreTax } = countr.calculateTotal(cart)
    // Recalculating total/subtotal and update
    cart.total = totalAmount
    cart.sub_total = totalAmountPreTax
    cart.date = new Date()
    cart.employee = PaymentUtils.getEmployee(props.employees.selectedEmployee)
    const callbacks = {
      editCart: props.editCart,
      selectCart: props.selectCart,
      addTransactionHead: props.addTransactionHead
    }
    cart.extras = {
      ...cart.extras,
      isGiveaway: true,
      giveaway_reason: type
    }

    const { kioskMode, showCartsListAlphabeticOrder } = props.settings
    const cartIndex = showCartsListAlphabeticOrder
      ? cartUtils.sortCartListAlphabetical(props.carts.carts).findIndex(c => c._id === cart._id)
      : props.carts.carts.findIndex(c => c._id === cart._id)
    const body = PaymentUtils.createTransactionBody(
      'cash',
      null,
      cart,
      device,
      cart.total,
      0,
      kioskMode
    )

    const { store } = props.devices

    PaymentUtils.payWithMethod(body, cart, callbacks, cartIndex, hasTables).then(
      result => {
        console.log(`### Transaction #${body.receipt_id} created!`)
      },
      error => {
        console.log(error)
        props.loadingFalse()
      }
    )

      props.loadingFalse()
      props.handleCloseRepsWasteModal(body)
  }

  const handleCustomName = item => {
    if (item.customer && !props.carts.selectedCart.customer) {
      return <Text id="add_customer_to_cart" />
    }
  }

  const handleItemIcon = icon => {
    if (icon.length > 1 && icon.includes('icon')) {
      return <span className={icon} />
    }

    return <Avatar alt="Option Image" src={!!icon ? icon : require('../../../assets/icon.svg')} />
  }

  useEffect(() => {
    const { options } = props.devices.store
    if (options && options.presetReps && options.presetReps.length) {
      setRepsAndWasteOptions(
        options.presetReps.map(custom => {
          return {
            key: custom.title,
            icon: custom.image,
            customer: custom.customer
          }
        })
      )
    } else {
      setRepsAndWasteOptions(GIVEAWAY)
    }
  }, [props.devices.store])

  return (
    <Dialog
      open={props.openRepsWasteModal}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, props.handleCloseRepsWasteModal)
      }}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={style}>
        <Text id="reps_waste" />
      </DialogTitle>
      <DialogContent style={style}>
        <List component="nav" className="giveaway-list">
          {repsAndWasteOptions.map(item => (
            <ListItem
              disabled={item.customer && !props.carts.selectedCart.customer}
              key={item.key}
              button={true}
              divider={true}
              onClick={() => handleGiveaway(item.key)}>
              <ListItemIcon>{handleItemIcon(item.icon)}</ListItemIcon>
              <ListItemText primary={<Text id={item.key} />} secondary={handleCustomName(item)} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions style={style}>
        <Button
          label={<Text id="cancel" />}
          onClick={props.handleCloseRepsWasteModal}
          className="secondary"
        />
      </DialogActions>
    </Dialog>
  )
})

const RepsWasteModalConnected = connect(mapStateToProps, mapDispatchToProps)(RepsWasteModal)
export default RepsWasteModalConnected
