import {
  SET_PRODUCT_COUNT,
  SET_CALCULATOR,
  BARCODE_SCANNER,
  SHOW_DEPOSIT_PRODUCTS,
  SET_PRODUCT_SEARCH_INPUT,
  RUN_PRODUCT_SEARCH
} from './../actions/actionTypes'

const initialState = {
  showDepositProducts: false,
  selectedProduct: {},
  count: 0,
  calculator: 0,
  barcodeScanner: true,
  searchInput: '',
  isProductSearchRunning: false
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_COUNT:
      return {
        ...state,
        count: action.payload
      }
    case SET_CALCULATOR:
      return {
        ...state,
        calculator: action.payload
      }
    case BARCODE_SCANNER:
      return {
        ...state,
        barcodeScanner: !state.barcodeScanner
      }
    case SHOW_DEPOSIT_PRODUCTS:
      return {
        ...state,
        showDepositProducts: !state.showDepositProducts
      }
    case SET_PRODUCT_SEARCH_INPUT:
      return {
        ...state,
        searchInput: action.payload
      }
    case RUN_PRODUCT_SEARCH:
      return {
        ...state,
        isProductSearchRunning: action.payload
      }
    default:
      return state
  }
}

export default productsReducer
