export const TRACKER_EVENTS = {
  PRODUCT_CLICK: 'PRODUCT_CLICK',
  SPECIAL_PRODUCT_CLICK: 'SPECIAL_PRODUCT_CLICK',
  CLOSE_SPECIAL_PRODUCT_MODAL: 'CLOSE_SPECIAL_PRODUCT_MODAL',
  SHOW_PRODUCT_DETAILS: 'SHOW_PRODUCT_DETAILS',
  PRODUCT_BARCODE_SCANNED: 'PRODUCT_BARCODE_SCANNED',
  SEARCH_PRODUCT: 'SEARCH_PRODUCT',
  CATEGORY_CLICK: 'CATEGORY_CLICK',
  CART_ITEM_REMOVE: 'CART_ITEM_REMOVE',
  CART_ITEM_EDIT_OPEN: 'CART_ITEM_EDIT_OPEN',
  CONFIRM_CONFIRMATION_MODAL: 'CONFIRM_CONFIRMATION_MODAL',
  CANCEL_CONFIRMATION_MODAL: 'CANCEL_CONFIRMATION_MODAL',
  CLOSE_CONFIRMATION_MODAL: 'CLOSE_CONFIRMATION_MODAL',
  PAY_CLICK: 'PAY_CLICK',
  ADD_CUSTOMER: 'ADD_CUSTOMER'
}
