import { memo } from 'react';
import { Text } from 'react-internationalization'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// string[Optional] Refund reason('items_returned', 'wrong_billing', 'order_cancelled', 'other')... default is 'other'
const refundReasons = ['items_returned', 'wrong_billing', 'order_cancelled', 'other']

const RefundTypeSelect = memo(({ reason, handleChange }) => {
  return (
    <Select value={reason} onChange={handleChange('reason')} fullWidth>
      {refundReasons.map(reasonItem => {
        return (
          <MenuItem key={reasonItem} value={reasonItem}>
            <Text id={reasonItem} />
          </MenuItem>
        )
      })}
    </Select>
  )
})

export default RefundTypeSelect
