import { memo, Fragment, useState } from 'react';
import { connect } from 'react-redux'
import Fab from '@material-ui/core/Fab'
import { Text } from 'react-internationalization'
import MerchantUtils from '../../utils/MerchantUtils'
import { cartUtils } from '../../utils/cartUtils'
import { getStartDay, evaluateCurrentShiftState } from './../../utils/EmployeeUtils'
import { setToastMessage } from '../../store/actions/app'
import PaymentModal from '../Payments/PaymentModal'

const mapStateToProps = state => {
  return {
    user: state.user.user,
    currentCart: state.carts.selectedCart,
    settings: state.settings,
    devices: state.devices,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt))
  }
}

const style = {
  position: 'fixed',
  zIndex: 1000,
  bottom: 15,
  right: 15,
  width: 100,
  height: 100,
  fontSize: 20,
  backgroundColor: '#318ed5',
  color: '#fff'
}
const smallSize = {
  fontSize: 9
}

const FabPayButton = memo(
  ({ user, currentCart, settings, devices, employees, setToastMessage }) => {
    const [openPayment, setOpenPayment] = useState(false)

    const handleOpenPayment = () => {
      const total = parseFloat(currentCart.total)
      if (!settings.allowNegativePayments && total < 0) {
        setToastMessage('negative_payments_warning')
      } else if (total > 0) {
        setOpenPayment(true)
      }
    }

    const handleClosePayment = transaction => {
      setOpenPayment(false)

      if (!!transaction && !!transaction.receipt_id) {
        setToastMessage('recovery_transaction_complete', { ref: `#${transaction.receipt_id}` })
      }
    }

    const checkShiftState = () => {
      if (!settings.openCloseShift) {
        return false
      }
      const { haveStartDay } = evaluateCurrentShiftState(
        getStartDay(employees.selectedEmployee._id || devices.device._id)
      )

      return !haveStartDay
    }

    const returnPaymentValue = () => {
      if (MerchantUtils.trialHasExpired(user)) {
        return (
          <font style={smallSize}>
            <Text id="trial_expired" />
          </font>
        )
      } else if (checkShiftState()) {
        return (
          <font style={smallSize}>
            <Text id="pay_before_open_error" />
          </font>
        )
      } else {
        return (
          <font>{`${cartUtils.getCartCurrency(currentCart)} ${cartUtils.getCartTotal(
            currentCart
          )}`}</font>
        )
      }
    }

    return (
      <Fragment>
        <Fab
          size="medium"
          onClick={handleOpenPayment}
          style={style}
          disabled={MerchantUtils.trialHasExpired(user) || checkShiftState()}>
          {returnPaymentValue()}
        </Fab>
        {openPayment && <PaymentModal open={openPayment} handleClose={handleClosePayment} settings={settings}/>}
      </Fragment>
    );
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(FabPayButton)
