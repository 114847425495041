import { PureComponent } from 'react';
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'

import DesktopUtils from '../../utils/DesktopUtils'
import { AppInstances } from './../../utils/countrSdkInstance'
import { returnUpdatableDevice } from './../../utils/DeviceUtils'

import {
  setPayments,
  setMethod,
  setMethodCashDrawer,
  setMethodPrintReceipt,
  setMethodExtra
} from './../../store/actions/payments'
import { loadingFalse, loadingTrue } from '../../store/actions/loading'

import './PaymentMethods.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    payments: state.payments,
    devices: state.devices,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPayments: payments => dispatch(setPayments(payments)),
    setMethod: method => dispatch(setMethod(method)),
    setMethodCashDrawer: method => dispatch(setMethodCashDrawer(method)),
    setMethodPrintReceipt: method => dispatch(setMethodPrintReceipt(method)),
    setMethodExtra: method => dispatch(setMethodExtra(method)),
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse())
  }
}

const CCV = 'ccv'
// const PAYPLAZA = 'payplaza'

class PaymentMethods extends PureComponent {
  constructor(props) {
    super(props)
    this.timeout = null
  }

  handleChangeEnable = event => {
    this.props.setMethod(event.target.value)
    this.updateDevicePayments()
  }

  handleChangeCashDrawer = event => {
    this.props.setMethodCashDrawer(event.target.value)
    this.updateDevicePayments()
  }

  handleChangePrint = event => {
    this.props.setMethodPrintReceipt(event.target.value)
    this.updateDevicePayments()
  }

  handleChangeExtra = method => event => {
    this.props.setMethodExtra({ method: method.method, extra: event.target.value })
    this.updateDevicePayments()
  }

  updateDevicePayments = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      let devicePayments = {}

      if (this.props.devices.device.settings) {
        devicePayments = Object.assign({}, this.props.devices.device.settings)
      }

      devicePayments.web_payments = Object.assign([], this.props.payments.paymentMethods)
      devicePayments.web_payments = devicePayments.web_payments.filter(method => !method.isCustom)
      const dev = Object.assign({}, this.props.devices.device)

      dev.settings = devicePayments
      AppInstances.getCountrSdk().then(countr => {
        countr.devices.update(dev._id, returnUpdatableDevice(dev))
      })
    }, 1000)
  }

  returnCheckboxColor = method => {
    if (method.isCustom) {
      return '#616161'
    }

    if (method.enabled) {
      return '#318ed5'
    }

    return '#616161'
  }

  reprintCCV = () => {
    this.props.loadingTrue()
    const ips = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))

    if (ips && ips.local_ip) {
      const web = AppInstances.getDesktopConnection(
        `${process.env.REACT_APP_WS_TYPE}://${
          ips.local_ip
        }:${DesktopUtils.getDesktopListenerPort()}`
      )

      if (web && web.readyState === 1) {
        web.send(
          JSON.stringify({
            type: 'reprint-ccv',
            payload: {
              languageCode: this.props.settings.language
            }
          })
        )
      }

      web.onmessage = message => {
        console.log(message)
        this.props.loadingFalse()
      }
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.openPaymentMethodsDialog}
        onClose={this.props.handleClosePaymentMethods}
        aria-labelledby="form-dialog-title"
        fullScreen={isMobileOnly}
        fullWidth={!isMobileOnly}
        maxWidth="md">
        <DialogTitle id="form-dialog-title">
          <Text id="payment_method" />
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text id="payment_method" />
                </TableCell>
                <TableCell>
                  <Text id="enable_method" />
                </TableCell>
                {this.props.app.isDesktop && (
                  <TableCell>
                    <Text id="open_cash_drawer" />
                  </TableCell>
                )}
                {this.props.app.isDesktop && (
                  <TableCell>
                    <Text id="print_receipt" />
                  </TableCell>
                )}
                <TableCell>
                  <Text id="ecrId" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Render payment methods that is common to web/desktop */}
              {this.props.payments.paymentMethods.map(method => {
                return (
                  !method.isDesktopOnly &&
                  !method.isCustom && (
                    <TableRow key={method.method}>
                      <TableCell className="method-name">
                        <span className={method.icon} /> <Text id={method.method} />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={method.enabled}
                          value={method.method}
                          onChange={this.handleChangeEnable}
                          color="default"
                          style={{ color: this.returnCheckboxColor(method) }}
                          disabled={method.isCustom}
                        />
                      </TableCell>
                      {this.props.app.isDesktop && (
                        <TableCell>
                          <Checkbox
                            checked={method.cashDrawer}
                            value={method.method}
                            onChange={this.handleChangeCashDrawer}
                            color="default"
                            style={{
                              color: method.cashDrawer ? '#318ed5' : '#616161'
                            }}
                          />
                        </TableCell>
                      )}
                      {this.props.app.isDesktop && (
                        <TableCell>
                          <Checkbox
                            checked={method.printReceipt}
                            value={method.method}
                            onChange={this.handleChangePrint}
                            color="default"
                            style={{
                              color: method.printReceipt ? '#318ed5' : '#616161'
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell className="method-name">
                        {method.hasExtra && (
                          <TextField
                            label={method.extraName}
                            value={method.extra}
                            onChange={this.handleChangeExtra(method)}
                            margin="normal"
                            disabled={!method.hasExtra}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )
              })}
              {/* Render payment methods that is only for desktop */}
              {this.props.payments.paymentMethods.map(method => {
                return (
                  method.isDesktopOnly &&
                  this.props.app.isDesktop && (
                    <TableRow key={method.method}>
                      <TableCell className="method-name">
                        <span className={method.icon} /> <Text id={method.method} />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={method.enabled}
                          value={method.method}
                          onChange={this.handleChangeEnable}
                          color="default"
                          style={{
                            color: method.enabled ? '#318ed5' : '#616161'
                          }}
                        />
                      </TableCell>
                      {this.props.app.isDesktop && (
                        <TableCell>
                          <Checkbox
                            checked={method.cashDrawer}
                            value={method.method}
                            onChange={this.handleChangeCashDrawer}
                            color="default"
                            style={{
                              color: method.cashDrawer ? '#318ed5' : '#616161'
                            }}
                          />
                        </TableCell>
                      )}
                      {this.props.app.isDesktop && (
                        <TableCell>
                          <Checkbox
                            checked={method.printReceipt}
                            value={method.method}
                            onChange={this.handleChangePrint}
                            color="default"
                            style={{
                              color: method.printReceipt ? '#318ed5' : '#616161'
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell className="method-name">
                        {method.hasExtra && (
                          <TextField
                            label={method.extraName}
                            value={method.extra}
                            onChange={this.handleChangeExtra(method)}
                            margin="normal"
                            disabled={!method.hasExtra}
                          />
                        )}
                      </TableCell>
                      {this.props.app.isDesktop && method.method === CCV && (
                        <TableCell className="method-name">
                          <Button
                            style={{ backgroundColor: '#318ed5', color: '#fff' }}
                            onClick={this.reprintCCV}>
                            REPRINT
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.props.handleClosePaymentMethods}>
            <Text id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const PaymentMethodsConnected = connect(mapStateToProps, mapDispatchToProps)(PaymentMethods)
export default PaymentMethodsConnected
