import { Button } from '@countr/ui'
import { useTracking } from 'react-tracking'
import { TRACKER_EVENTS } from '../../utils/trackerEvents'

const styles = {
  width: '100%',
  margin: 0,
  borderRadius: 0,
  padding: 30,
  fontSize: 25
}

const CartPayBtn = ({ cart, label, handleClick, isDisabled }) => {
  const { trackEvent } = useTracking()

  const handlePayClick = () => {
    trackEvent({
      event: TRACKER_EVENTS.PAY_CLICK,
      data: { cartTotal: cart.total },
      merchant: cart.merchant
    })
    handleClick()
  }

  return (
    <Button
      testid="pay-btn"
      disabled={isDisabled}
      label={label}
      onClick={handlePayClick}
      styles={styles}
    />
  )
}

export default CartPayBtn
