import { ADD_TAX, ADD_ALL_TAXES, UPDATE_TAX, DELETE_TAX } from './../actions/actionTypes'

const initialState = {
  taxes: []
}

const taxesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAX:
      return {
        ...state,
        taxes: state.taxes.concat(action.payload)
      }
    case ADD_ALL_TAXES:
      return {
        ...state,
        taxes: action.payload
      }
    case UPDATE_TAX:
      const updatedTaxes = state.taxes.map(tax => {
        return tax._id === action.payload._id ? action.payload : tax
      })
      return {
        ...state,
        taxes: updatedTaxes
      }
    case DELETE_TAX:
      const notDeleteTaxes = state.taxes.filter(tax => {
        return tax._id !== action.payload
      })
      return {
        ...state,
        taxes: notDeleteTaxes
      }
    default:
      return state
  }
}

export default taxesReducer
