import {
  ADD_DEVICE,
  UPDATE_DEVICE,
  ADD_STORE,
  SET_LAST_TRANSACTION,
  ADD_CURRENCY
} from './actionTypes'

export const addDevice = device => {
  try {
    delete device.store.devices
    delete device.merchant
    delete device.store.merchant
  } catch (error) {
    console.log(error)
  }

  return {
    type: ADD_DEVICE,
    payload: device
  }
}

export const updateDevice = device => ({
  type: UPDATE_DEVICE,
  payload: device
})

export const addStore = store => {
  delete store.devices

  return {
    type: ADD_STORE,
    payload: store
  }
}

export const addCurrency = currency => {
  return {
    type: ADD_CURRENCY,
    payload: currency
  }
}

export const setLastTransaction = lastTransaction => ({
  type: SET_LAST_TRANSACTION,
  payload: lastTransaction
})
