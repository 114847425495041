import { AppInstances } from './countrSdkInstance'
import store from './../index'
import { addQueue, removeActionQueue, removeDuplicate } from './../store/actions/queue'
import { addTransactionHead, addRefundHead, editTransaction } from '../store/actions/transactions'

const UPDATE = 'update'
const CREATE = 'create'
const TRANSACTIONS = 'transactions'
const REFUNDS = 'refunds'

export const RequestQueue = (function () {
  return {
    // selectState: function(state) {
    //   return state.queue.queueList
    // },

    updateActionDate: function (action) {
      if (action.payload && action.payload._id) {
        action.payload.date = new Date().toISOString()
        // Removing old cart request from the queue
        // Just need to sync the last carts update
        store.dispatch(removeDuplicate(action.payload._id))
      }

      action.id = action.payload._id ? action.payload._id : action.payload.receipt_id

      return action
    },

    enqueueAction: function (action) {
      // action = this.updateActionDate(action)
      store.dispatch(addQueue(action))
    },

    dequeueActions: async function (queueList) {
      for (let i = 0; i < queueList.length; i++) {
        setTimeout(async () => {
          const action = queueList[i]
          const result = await this.performAction(action)

          if (result) {
            this.updateRedux(action, result)
          }
        }, 1000)
      }
    },

    performAction: async function (action) {
      const countr = await AppInstances.getCountrSdk()

      if (action.type === 'transactions' && action?.payload?.receipt_id) {
        localStorage.removeItem('CountrWeb:LastTransactionInProgress-' + action.payload.receipt_id)
      }

      if (action.action === CREATE) {
        return countr[action.type][action.action](action.payload)
      } else {
        return countr[action.type][action.action](action.payload._id, action.payload)
      }
    },

    updateRedux: async function (action, result) {
      store.dispatch(removeActionQueue(action.id))

      switch (action.type) {
        case TRANSACTIONS:
          store.dispatch(addTransactionHead(result))
          break
        case REFUNDS:
          store.dispatch(addRefundHead(result))

          if (action.action === CREATE && action.extra) {
            const transaction = action.extra
            transaction.refunds.push(result._id)

            const updatedTransaction = await this.performAction({
              type: TRANSACTIONS,
              action: UPDATE,
              payload: transaction
            })
            if (updatedTransaction) {
              store.dispatch(editTransaction(updatedTransaction))
            }
          }
          break
        default:
          break
      }
    }
  }
})()
