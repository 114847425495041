import { memo } from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Text } from 'react-internationalization'
import { employeeHasRights } from '../../utils/EmployeeUtils'
import './../Menu.scss'

const StorageComponent = memo(({ quota, employee }) => {
  const _fixComponentPosition = () => {
    if (!!employee && !!Object.keys(employee).length) {
      return employeeHasRights(employee, 'sign_out') ? 31 : 0
    }

    return 31
  }

  return (
    <div className="main-menu-quota" style={{ bottom: _fixComponentPosition() }}>
      <Divider />
      <List component="nav" style={{ padding: 0 }}>
        <ListItem>
          <span className="icon-categories-sidebar" />
          <div className="text">
            <span>{<Text id="storage" />}</span>
            <span>{<Text id="storage_data" values={{ quota: quota }} />}</span>
          </div>
        </ListItem>
        <LinearProgress color="primary" variant="determinate" value={parseInt(quota, 10)} />
      </List>
    </div>
  )
})

export default StorageComponent
