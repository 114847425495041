export default class MerchantUtils {
  static trialHasExpired = merchant => {
    if (!!merchant.trial_expires_at) {
      const expireDate = new Date(merchant.trial_expires_at)

      if (expireDate < new Date()) {
        return true
      }
    }

    return false
  }
}
