import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { CartUtils } from '@countr/utils'
import { cartUtils as CartUtilities } from '../../utils/cartUtils'

import { Button } from '@countr/ui'

import Grid from '@material-ui/core/Grid'

import CartItem from '../Lists/CartItem'
import { Text, translate } from 'react-internationalization'

import PayPerItemButtons from './PayPerItemButtons'

import './NewPaymentModal.scss'

const PartialPaymentSeat = props => {
  const [activeSeat, setActiveSeat] = useState(0)
  const [toPay, setToPay] = useState(0)
  const [currentArray, setCurrentArray] = useState([])
  const [seatItems, setSeatItems] = useState([])
  const WIDTH = window.innerWidth > 500 ? 500 : window.innerWidth
  const seatNumber = useSelector(state => state.carts.seatNo)
  const cart = props.activeCart

  useEffect(() => {
    setActiveSeat(seatNumber)
    setSeatItems(handleGetSeatItems(seatNumber))
  }, [])

  useEffect(() => {
    const current = []
    cart.items.forEach(item => {
      current.push({
        id: CartUtilities.getCartEntryId(item),
        current: 0
      })
    })
    setCurrentArray(current)
    setToPay(0)
    props.handleChangeValue('expectedAmount', 0)
  }, [cart.payments])

  const handleUpdateCurrent = (item, type, quantity) => {
    const maxAmount = quantity ? quantity : item.amount
    const arr = [...currentArray]
    const currentIndex = arr.findIndex(el => el.id === CartUtilities.getCartEntryId(item))

    if (type === 'add' && arr[currentIndex].current < maxAmount - getPaidAmount(item)) {
      arr[currentIndex].current++
    } else if (type === 'remove' && arr[currentIndex].current > 0) {
      arr[currentIndex].current--
    } else {
      return
    }

    setCurrentArray(arr)
    updateToPayValue(arr)
  }

  const updateToPayValue = toPayListArray => {
    let sum = 0
    toPayListArray.forEach(current => {
      if (!!current.current) {
        const item = cart.items.find(i => CartUtilities.getCartEntryId(i) === current.id)
        const price = CartUtils.calculateCartEntryPrice({ ...item, amount: current.current })
        sum += price * (1 - cart.discount)
      }
    })

    setToPay(sum)
    props.handleChangeValue('expectedAmount', sum)

    const paidProducts = []
    toPayListArray.forEach(el => {
      if (el.current > 0) {
        const index = cart.items.findIndex(i => CartUtilities.getCartEntryId(i) === el.id)

        paidProducts.push({
          id: CartUtilities.getCartEntryId(cart.items[index]),
          amount: el.current,
          position: activeSeat
        })
      }
    })
    props.handlePaidProducts(paidProducts)
  }

  const getPaidAmount = item => {
    return CartUtilities.getCartEntryPaidAmount(cart, item, activeSeat)
  }

  const getCurrentValue = item => {
    const current = currentArray.find(el => el.id === CartUtilities.getCartEntryId(item))
    return !!current && !!current.current && current.current <= item.amount ? current.current : 0
  }

  const changeSeat = seat => {
    setActiveSeat(seat)
    setSeatItems(handleGetSeatItems(seat))
  }

  const handleGetSeatItems = seat => {
    if (!seat && seat != 0) return

    return cart.items.filter(i => i.product.seat === seat) || []
  }

  return (
    <div style={{ width: WIDTH }}>
      <Grid container spacing={2}>
        {!!cart?.extras?.covers &&
          [...Array(cart.extras.covers + 1).keys()].map(seat => (
            <Grid item key={seat}>
              <Button
                label={
                  seat === 0 ? (
                    cart.extras.deviceCartName
                  ) : (
                    <span>
                      <Text id="seat" /> {seat}
                    </span>
                  )
                }
                onClick={() => changeSeat(seat)}
                className={activeSeat === seat ? 'active-seat' : 'primary'}
              />
            </Grid>
          ))}
      </Grid>
      {seatItems.map((item, index) => (
        // <div key={item.cartEntryId}>{item.cartEntryId}</div>
        <Grid
          key={item.product._id + index}
          container
          justifyContent="center"
          alignItems="center"
          className="payment-grid-type partial-payment-per-item-container">
          <Grid item xs={6}>
            <CartItem
              isModal
              cart={cart}
              item={item}
              index={index}
              quantity={item.quantity}
              hasDivider={false}
            />
          </Grid>
          <Grid item xs={4}>
            <PayPerItemButtons
              cart={cart}
              item={item}
              quantity={item.quantity}
              index={index}
              handleUpdateCurrent={handleUpdateCurrent}
              getPaidAmount={getPaidAmount}
              getCurrentValue={getCurrentValue}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container style={{ minHeight: 74 }} justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                {translate('paid') + ': '}
                <b>{getPaidAmount(item)}</b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="payment-grid-type">
        <Grid item xs={6} className="payment-grid-left">
          <b>
            <Text id="total" />
          </b>
        </Grid>
        <Grid item xs={6} className="payment-grid-right">
          <b>
            {cart.currency.symbol} {parseFloat(toPay).toFixed(2)}
          </b>
        </Grid>
      </Grid>
    </div>
  )
}

export default PartialPaymentSeat
