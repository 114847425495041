import { AppInstances } from './countrSdkInstance'
import WorkerResourceLoader from './../WorkerResourceLoader'
import ProductUtils from './ProductUtils'
import RegisterOperationsUtils from './RegisterOperationsUtils'

export const BarcodeUtils = (function () {
  return {
    registerBarcodeNotFound: function (code, isQr = false) {
      const operationArgs = {
        action: 'scan_fail',
        note: `${isQr ? 'QrCode' : 'Barcode'} ${code} fail to scan`
      }
  
      RegisterOperationsUtils.applyOperation(operationArgs)
    },
    searchProductBarcode: async function (ean, searchServer = false) {
      const products = await WorkerResourceLoader.searchDbByFields(ean, ['ean'], 'products')
      let product = null
      let variant = null

      if (!!products && products.length) {
        product = products.find(prod => {
          variant = this.searchVariantByBarcode(prod, ean)
          return !!variant
        })
      }

      if (!product && !variant && searchServer) {
        const countr = await AppInstances.getCountrSdk()
        const productsServer = await countr.products.search({ text: ean })

        if (!!productsServer && productsServer.length) {
          product = productsServer.find(prod => {
            variant = this.searchVariantByBarcode(prod, ean)
            return !!variant
          })
        }
      }

      return {
        product,
        variant
      }
    },
    searchVariantByBarcode: function (product, ean) {
      const variant = product.variants.find(variant => {
        // Removing spaces from variant.ean because in indexedDB we already save it without spaces
        // and onScanJS also scan the barcode without spaces
        if (!!variant.ean && variant.ean.replace(/\s/g, '') === ean) {
          return true
        } else if (!!variant.ean) {
          const splitedComma = variant.ean.split(',')
          const splitedDash = variant.ean.split('-')

          return splitedComma.indexOf(ean) >= 0 || splitedDash.indexOf(ean) >= 0
        } else {
          return false
        }
      })

      return variant
    },
    searchDynamicBarcode: async function (ean) {
      const products = await WorkerResourceLoader.getAllProducts()
      const search = products.filter(product => {
        return (
          product.variants.filter(variant => {
            return (
              variant.ean &&
              variant.ean.length >= 5 &&
              ean.toLowerCase().indexOf((variant.ean || '').toLowerCase()) >= 0
            )
          }).length > 0
        )
      })

      if (!!search && search.length) {
        return ProductUtils.checkDynamicBarcode(search[0], ean)
      } else {
        return null
      }
    }
  }
})()
