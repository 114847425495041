import { memo, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { Text } from 'react-internationalization'
import CountButtonComponent from '../../generic/CountButtonComponent'
import './CoversTakeawayModal.scss'

const CoversTakeawayModal = memo(
  ({ open, handleClose, cart, table, setGuests, store }) => {
    const [covers, setCovers] = useState(0)

    useEffect(() => {
      if (!!cart.extras.covers) {
        setCovers(cart.extras.covers)
      } else if (store) {
        store.floorplans.forEach(f => {
          const found = f.tables.find(t => t.linked_cart === cart._id)
          if (found && found.subtables) {
            setCovers(found.subtables.length)
          }
        })
      } 
    }, [])

    const handleCovers = num => {
      if (num > 0) {
        setCovers(num)
      }
    }

    const handleSave = takeaway => {
      const cartCopy = { ...cart }
      cartCopy.extras.takeaway = takeaway
      cartCopy.extras.covers = covers
      cartCopy.extras.modified = true
      if (setGuests) {
        setGuests(covers)
      }
      handleClose()
    }

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Text id="table_configuration" />
        </DialogTitle>
        <DialogContent className="covers-takeaway-content">
          <div className="covers-takeaway-item">
            <Button className="takeaway-btn" fullWidth onClick={() => handleSave(true)}>
              <Text id="takeaway" />
            </Button>
          </div>
          <div className="covers-takeaway-item">
            <span>
              <strong>
                <Text id="num_covers" />
              </strong>
            </span>
            <CountButtonComponent value={covers} handleChange={handleCovers} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            <Text id="cancel" />
          </Button>
          <Button color="primary" onClick={() => handleSave(false)}>
            <Text id="save" />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default CoversTakeawayModal
