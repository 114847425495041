import {
  SET_SETTINGS,
  SET_DESKTOP_SETTINGS,
  DISABLE_KIOSK,
  ENABLE_A4_PRINT,
  LISTENING_STORES,
  LISTENING_CATEGORIES
} from './../actions/actionTypes'

const initialState = {
  cartsTable: false,
  cartAlwaysOpen: true,
  showStock: false,
  showProductImage: false,
  showProductImageList: true,
  productTileSize: false,
  hideCategoriesTile: false,
  categoriesAlphabeticOrder: false,
  productsAlphabeticOrder: false,
  cartLevelDiscount: true,
  productLevelDiscount: true,
  disableCustomDiscounts: false,
  enableCartItemRemove: true,
  showCartsListAlphabeticOrder: false,
  skipTransactionModal: false,
  showCartNameInTransaction: false,
  allowPartialPayment: true,
  allowNegativePayments: false,
  allowProductExchanges: false,
  allowProductRefunds: true,
  showOrderBill: true,
  showEANSKUReceipt: false,
  showBrandReceipt: false,
  showCalculator: true,
  showRepsWaste: false,
  dropShip: false,
  enableDepositButton: true,
  kioskMode: false,
  adminOnlyCustomItem: false,
  kitchenPrint: false,
  kitchenDeliveryPrint: false,
  disablePrintRemovedItems: false,
  adminPin: '',
  employeePin: false,
  sharedShift: false,
  adminOnlyRefunds: false,
  employeeLock: false,
  openCloseShift: false,
  a4Print: false,
  barcodeCustomer: false,
  allowVoucherScan: false,
  allowTicketScan: false,
  splitOrderItems: false,
  splitOrderAmount: false,
  allowPartialPaymentPrint: false,
  reportsStoreLevel: false,
  showFullReceiptAfterPayment: false,
  playSoundSuccessScan: false,
  enableCustomerScreen: false,
  customerScreenPort: '',
  refundWithSameMethod: false,
  searchProductBarcodeServer: false,
  floorplanOnNewSale: false,
  enableGiftcardScan: false,
  roundCashPayment: false,
  askTips: false,
  askCoversTakeaway: false,
  showItemStatus: true,
  listStatusSquares: false,
  printQR: false,
  printDescription: true,
  showSuggestions: true,
  theme: '',
  language: 'en',
  askExtraNonRevenueInfo: true,
  printOrdersForMultipleStores: false,
  listeningStores: [],
  buzzerCategories: [],
  askBuzzerInfo: false,
  printTickets: false,
  printTicketsOnReceiptPrinter: false,
  qrCodeForLineItems: false,
  crashDrawerPartialPayment: false,
  printCardInfo: true,
  shareCartAllStores: false,
  keepSingleCart: false
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...action.payload
      }

    case SET_DESKTOP_SETTINGS:
      return {
        ...state,
        desktopSettings: state.desktopSettings
      }

    case DISABLE_KIOSK:
      return {
        ...state,
        kioskMode: false
      }

    case ENABLE_A4_PRINT:
      return {
        ...state,
        a4Print: !state.a4Print
      }

    case LISTENING_STORES:
      return {
        ...state,
        listeningStores: action.payload
      }
    case LISTENING_CATEGORIES:
      return {
        ...state,
        buzzerCategories: action.payload
      }
    default:
      return state
  }
}

export default settingsReducer
