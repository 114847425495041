import { memo } from 'react';
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { Text, translate } from 'react-internationalization'

import ListItem from '@material-ui/core/ListItem'
import Chip from '@material-ui/core/Chip'

import './Transaction.css'

const TRANSACTION = 'Transaction'
const REFUND = 'Refund'

const TransactionListItem = memo(props => {
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text
  }

  const isTransaction = transaction => {
    return transaction.__t === TRANSACTION
  }

  const isRefund = transaction => {
    return transaction.__t === REFUND
  }

  const hasRefund = transaction => {
    return transaction.refunds && transaction.refunds.length > 0
  }

  const hasGiveawayReason = transaction => {
    return !!transaction.extras && !!transaction.extras.giveaway_reason
  }

  const findRefundValueOfTransaction = transaction => {
    let totalRefunded = 0

    if (transaction.refunds && transaction.refunds.length > 0) {
      transaction.refunds.forEach(refund => {
        const ref = props.refunds.find(r => r._id === refund)
        if (ref) {
          totalRefunded += parseFloat(ref.paid)
        }
      })
    }

    return totalRefunded.toFixed(2)
  }

  const pendingPayment = transaction => {
    return transaction.payments.findIndex(payment => payment.postponed) >= 0
  }

  const getTransactionDate = transaction => {
    return new Date(transaction.date).toDateString()
  }

  const returnTransactionLine = transaction => {
    const total = hasGiveawayReason(transaction)
      ? translate(transaction.extras.giveaway_reason) || transaction.extras.giveaway_reason
      : `${props.symbol} ${parseFloat(transaction.total).toFixed(2)}`

    return (
      <div className="refund-content">
        <h3 className="refund-content-title">{`${getTransactionDate(transaction)} - ${total}`}</h3>
        <p className="refund-content-description">{`#${transaction.receipt_id}`}</p>
      </div>
    )
  }

  const returnRefundLine = transaction => {
    return (
      <div className="refund-content">
        <h3 className="refund-content-title refund-item">
          {`${getTransactionDate(transaction)} - ${props.symbol} ${(
            transaction.total || Number(0)
          ).toFixed(2)}`}
        </h3>
        <p className="refund-content-description refund-item">{`#${transaction.receipt_id}`}</p>
      </div>
    )
  }

  return (
    <ListItem
      button
      divider
      className={isRefund(props.transaction) ? 'refund-item' : ''}
      onClick={() => props.selectTransaction(props.transaction)}>
      {hasGiveawayReason(props.transaction) ? (
        <span style={style} className="icon-sidebar-order" />
      ) : (
        <span
          style={style}
          className={
            isTransaction(props.transaction) ? 'icon-cash' : 'icon-cash-refund refund-item'
          }
        />
      )}

      {isTransaction(props.transaction) && hasRefund(props.transaction) && (
        <div className="transaction-has-refunds">
          {`${props.symbol} ${findRefundValueOfTransaction(props.transaction)}`}
        </div>
      )}

      {isTransaction(props.transaction)
        ? returnTransactionLine(props.transaction)
        : returnRefundLine(props.transaction)}

      {pendingPayment(props.transaction) && <span className="icon-pay-later m-r-15" />}
      <span className="icon-right-arrow" />
      {isRefund(props.transaction) && !isMobileOnly && (
        <Chip label={<Text id="refund" />} className="refund-chip" />
      )}
    </ListItem>
  )
})

export default TransactionListItem
