import { memo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import KeyboardComponent from './../generic/KeyboardComponent'
import { Text, translate } from 'react-internationalization'
import Util from '../../utils/Util'

const VerificationModal = memo(({ user, open, handleClose, setToastMessage }) => {
  const [code, setCode] = useState('')

  const handleChangePinKeyboard = value => {
    if (value === 'C') {
      setCode('')
    } else {
      setCode(code + value)
    }
  }

  const checkCode = () => {
    if (code === user.verification.code) {
      handleClose(true)
    } else {
      setCode('')
      setToastMessage(translate('inserted_code_not_valid'))
    }
  }

  return (
    <Dialog
      testid="verification-modal"
      open={open}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, handleClose)
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Text id="validate_user" values={{ user_address: user.verification.validationAddress }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Text id="enter_validation_code" />
        </DialogContentText>
        <br />
        <div style={{ textAlign: 'center' }}>{'*'.repeat(code.length)}</div>
        <KeyboardComponent
          handleChange={handleChangePinKeyboard}
          enterAction={checkCode}
          enterValue={<Text id="confirm" />}
        />
      </DialogContent>
    </Dialog>
  )
})

export default VerificationModal
