import { memo } from 'react';
import './TableLayout.scss'
import plantImage from '../../../assets/table_layout/plant.png'
import stairsImage from '../../../assets/table_layout/stairs.png'

const Static = memo(function Static({ staticObj }) {
  const returnStaticPosition = () => {
    if (staticObj && staticObj.name !== 'Plant' && staticObj.name !== 'Stairs') {
      return {
        left: staticObj.layout.x + '%',
        top: staticObj.layout.y + '%',
        width: staticObj.layout.width + '%',
        height: staticObj.layout.length + '%',
        borderRadius: staticObj.layout.shape === 'round' ? '50%' : 9,
        backgroundColor: '#86939e',
        border: '3px solid black'
      }
    } else {
      return {
        left: staticObj.layout.x + '%',
        top: staticObj.layout.y + '%',
        width: 4 + '%',
        height: 12 + '%',
        borderRadius: staticObj.layout.shape === 'round' ? '50%' : 5,
        backgroundImage: staticObj.name === 'Plant' ? `url(${plantImage})` : `url(${stairsImage})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }
    }
  }

  return (
    <div className={staticObj.name === '' ? 'static' : 'item'} style={returnStaticPosition()} />
  )
})

export default Static
