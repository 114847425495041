import { PureComponent } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import { CategoryUtils } from '@countr/utils'
import Util from '../../../utils/Util'
import IntercomUtils from '../../../utils/IntercomUtils'
import { CountrListeners } from '../../../utils/CountrListeners'
import {
  addCategory,
  updateCategory,
  deleteCategory,
  setActiveCategory
} from '../../../store/actions/categories'
import { runProductSearch, setProductSearchInput } from '../../../store/actions/products'
import CategoryButton from './CategoryButton'

import './CategoriesList.scss'

const mapStateToProps = state => {
  return {
    categories: state.categories,
    devices: state.devices,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCategory: category => dispatch(addCategory(category)),
    updateCategory: category => dispatch(updateCategory(category)),
    deleteCategory: id => dispatch(deleteCategory(id)),
    setActiveCategory: id => dispatch(setActiveCategory(id)),
    setProductSearchInput: str => dispatch(setProductSearchInput(str)),
    runProductSearch: shouldRun => dispatch(runProductSearch(shouldRun))
  }
}

const ALL_CATEGORIES = 'all_categories'
const ALL_CATEGORIES_OBJ = {
  _id: ALL_CATEGORIES,
  name: <Text id={ALL_CATEGORIES} />
}

class CategoriesList extends PureComponent {
  state = {
    categoriesList: []
  }

  childAlreadyInList = children => {
    return !!children[0]
      ? this.state.categoriesList.findIndex(cat => cat._id === children[0]._id)
      : 0
  }

  getCategory = id => {
    return this.props.categories.categories.find(c => c._id === id)
  }

  selectCategory = (category, index) => {
    const {
      setActiveCategory,
      settings: { categoriesAlphabeticOrder },
      categories: { categories }
    } = this.props

    IntercomUtils.trackEvent('main:select_category')

    // Cleaning catalog header search bar
    this.resetProductList()
    setActiveCategory(category._id === ALL_CATEGORIES ? ALL_CATEGORIES : category._id)

    // Removing other children categories from the list
    if (category.parent === undefined || category.parent === null) {
      this.createCategoriesList()
    }

    if (category !== ALL_CATEGORIES && CategoryUtils.hasChildren(category)) {
      const list = Object.assign([], this.state.categoriesList)
      const children = CategoryUtils.getCategoryChildren(categories, category)

      if (this.childAlreadyInList(children) < 0) {
        let orderedChilds = []
        if (categoriesAlphabeticOrder) {
          orderedChilds = Util.sortAlphabeticalByField(children, 'name')
        } else {
          category.children.forEach(child => {
            const foundChild = children.find(c => c._id === child)

            if (!!foundChild) {
              orderedChilds.push(foundChild)
            }
          })
        }

        orderedChilds.forEach((child, i) => {
          list.splice(index + i + 1, 0, child)
        })

        this.setState({ categoriesList: list })
      }
    }
  }

  createCategoriesList = () => {
    const { categoriesAlphabeticOrder } = this.props.settings
    const categories = Object.assign([], this.props.categories.categories)
    const allCategories = Object.assign(
      [],
      categoriesAlphabeticOrder ? Util.sortAlphabeticalByField(categories, 'name') : categories
    )

    const list = []

    // Just add parent categories (chield categories will appear in the list in runtime)
    allCategories.forEach(category => {
      if (category.parent === undefined || category.parent === null) {
        list.push(category)
      }
    })

    if (!categoriesAlphabeticOrder) {
      list.sort(Util.sortByPositionName)
    }

    this.setState({ categoriesList: list })
  }

  resetProductList = () => {
    this.props.setProductSearchInput('')
    this.props.runProductSearch(false)
  }

  componentDidMount = () => {
    this.createCategoriesList()
    CountrListeners.initCategoriesListener(
      this.props.devices.store._id,
      this.categoryListenersCallback
    )
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.settings.categoriesAlphabeticOrder !== this.props.settings.categoriesAlphabeticOrder
    ) {
      this.createCategoriesList()
    }
  }

  categoryListenersCallback = (result, event) => {
    switch (event) {
      case CountrListeners.getConstants().CATEGORY_CREATED:
        this.props.addCategory(result)

        if (result.parent) {
          const parent = this.getCategory(result.parent)
          parent.children.push(result._id)
          this.props.updateCategory(parent)
        }
        break
      case CountrListeners.getConstants().CATEGORY_UPDATED:
        this.props.updateCategory(result)
        break
      case CountrListeners.getConstants().CATEGORY_DELETED:
        this.props.deleteCategory(result)
        break
      default:
        break
    }

    this.createCategoriesList()
  }

  render() {
    return (
      <div id="categories-list">
        <CategoryButton
          category={ALL_CATEGORIES_OBJ}
          getCategory={this.getCategory}
          activeCategory={this.props.categories.activeCategory}
          selectCategory={this.selectCategory}
        />
        {this.state.categoriesList.map(
          (category, index) =>
            category.visible && (
              <CategoryButton
                key={category._id}
                category={category}
                index={index}
                getCategory={this.getCategory}
                activeCategory={this.props.categories.activeCategory}
                selectCategory={this.selectCategory}
                language={this.props.settings.language}
              />
            )
        )}
      </div>
    )
  }
}

const CategoriesListConnected = connect(mapStateToProps, mapDispatchToProps)(CategoriesList)
export default CategoriesListConnected
