export var CONST = {
  errors: {
    NO_CART_ID: 'no_cart_id'
  },
  transactionType: {
    PURCHASE: 'purchase',
    REFUND: 'refund'
  },
  transactionStatus: {
    ABORTED: 'transaction_aborted',
    AUTHORIZATION_ERROR: 'authorization_error',
    AUTHORIZATION_OK: 'authorization_ok',
    CANCELED: 'transaction_cancelled',
    CLEARANCE_ERROR: 'clearance_error',
    CLEARANCE_OK: 'clearance_ok',
    DECLINED_BY_CARD: 'transaction_declined',
    FINISHED: 'transaction_complete',
    INITIAL: 'initiating',
    PAYMENT_APPLICATION_SELECTED: 'application_selected',
    SCHEDULED_FOR_CLEARANCE: 'scheduled_for_clearance',
    TERMINAL_ERROR: 'terminal_error',
    UNKNOWN: 'unknown_status',
    WAITING_FOR_CLEARANCE: 'waiting_for_clearance',
    payment_started: 'payment_started',
    PAYMENT_STARTED: 'payment_started'
  },
  resultCode: {
    AMOUNT_EXCEEDED: 'amount_exceeded',
    AUTHORIZATION_FAILURE: 'authorization_error',
    AUTHORIZATION_TIMEOUT: 'authorization_timeout',
    BATCH_PROCESSING_FAILED: 'batch_processing_failed',
    CANCELED: 'cancelled',
    CARD_BLOCKED: 'card_blocked',
    CARD_INVALID: 'card_invalid',
    DECLINED_BY_CARD: 'card_declined',
    FAILED: 'transaction_failed',
    INSUFFICIENT_FUNDS: 'insufficient_funds',
    NETWORK_ERROR: 'check_network',
    NOT_PAIRED: 'terminal_not_paired',
    PIN_INCORRECT_LAST_ATTEMP: 'pin_incorrect_last_retry',
    PIN_INCORRECT_RETRY: 'pin_incorrect_retry',
    PRINTER_BUSY: 'printer_busy',
    PRINTER_ERROR: 'printer_error',
    PRINTER_NOT_PRESENT: 'printer_not_present',
    PRINTER_OUT_OF_PAPER: 'printer_out_of_paper',
    SUCCESS: 'success',
    SYSTEM_ERROR: 'system_error',
    TRANSACTION_NOT_FOUND: 'transaction_not_found',
    UNKNOWN_DEVICE: 'unknown_device'
  }
}
