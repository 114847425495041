import { cartUtils } from './cartUtils'

export const layoutUtils = (function () {
  return {
    getBgColor: function (cart, table, tablesInfo) {
      if (table && table.subtables && table.subtables.length > 0) {
        const bgColor = tablesInfo.multiEntries ? '#ef6b13' : '#eb5656'
        return tablesInfo.items && tablesInfo.items > 0 ? bgColor : '#ffffff'
      }
      return !!cart ? cartUtils.getTableColor(cart) : 'white'
    },

    returnTablePosition: function (cart, table, tableIsCurrentCart, tablesInfo) {
      if (table && !table.layout.size) {
        return {
          left: table.layout.x + '%',
          top: table.layout.y + '%',
          width: table.layout.width + '%',
          height: table.layout.length + '%',
          borderRadius: table.layout.shape === 'round' ? '50%' : 5,
          backgroundColor: this.getBgColor(cart, table, tablesInfo),
          border: tableIsCurrentCart() ? '5px solid' : '3px solid black'
        }
      } else if (table && (table.layout.shape === 'square' || table.layout.shape === 'round')) {
        return {
          left: table.layout.x + '%',
          top: table.layout.y + '%',
          width: table.layout.width + 'vw',
          height: table.layout.length + 'vw',
          borderRadius: table.layout.shape === 'round' ? '50%' : 9,
          backgroundColor: this.getBgColor(cart, table, tablesInfo),
          border: tableIsCurrentCart() ? '5px solid' : '3px solid black',
          boxShadow: 'rgb(204, 204, 204) 1px 1px 1px 1px'
        }
      } else if (table && table.layout.shape === 'rectangle') {
        return {
          left: table.layout.x + '%',
          top: table.layout.y + '%',
          width: table.layout.width - 2 + '%',
          height: table.layout.length - 2 + '%',
          borderRadius: table.layout.shape === 'round' ? '50%' : 9,
          backgroundColor: this.getBgColor(cart, table, tablesInfo),
          border: tableIsCurrentCart() ? '5px solid' : '3px solid black',
          boxShadow: 'rgb(204, 204, 204) 1px 1px 1px 1px'
        }
      } else if (table && (table.layout.shape === 'bar-l' || table.layout.shape === 'bar-u')) {
        const style = this.getStyle(table.layout)
        return style
      } else {
        return {
          left: table.layout.x + '%',
          top: table.layout.y + '%',
          width: table.layout.shape === 'bar-l' ? table.layout.width + '%' : 200 + 'px',
          height: table.layout.shape === 'bar-l' ? table.layout.length + '%' : 200 + 'px',
          borderRadius: table.layout.shape === 'round' ? '50%' : 5,
          backgroundColor: this.getBgColor(cart, table, tablesInfo),
          border: tableIsCurrentCart() ? '5px solid' : ''
        }
      }
    },

    getStyle: function (layout) {
      const styl = {
        top: layout.y + '%',
        left: layout.x + '%',
        height: 200 + 'px',
        width: 200 + 'px',
        backgroundColor: '#443022',
        borderRadius: '15px'
      }
      if (layout.position === 'top' || layout.position === 'bottom') {
        if (layout.size === 'sm') {
          return styl
        } else if (layout.size === 'md') {
          styl.width = 280 + 'px'
          styl.height = 190 + 'px'
          return styl
        } else if (layout.size === 'lg') {
          styl.width = 360 + 'px'
          styl.height = 190 + 'px'
          return styl
        } else if (layout.size === 'xl') {
          styl.width = 440 + 'px'
          styl.height = 190 + 'px'
          return styl
        }
      } else {
        if (layout.size === 'sm') {
          return styl
        } else if (layout.size === 'md') {
          styl.height = 280 + 'px'
          return styl
        } else if (layout.size === 'lg') {
          styl.height = 360 + 'px'
          return styl
        } else if (layout.size === 'xl') {
          styl.height = 440 + 'px'
          return styl
        }
      }
    },

    setBarPosition: function (table) {
      const styl = {
        height: 200 + 'px',
        width: 200 + 'px',
        // position: 'relative',
        backgroundColor: '#443022',
        borderRadius: 15 + 'px'
      }
      if (
        table.layout.shape === 'bar-l' ||
        (table.layout.shape === 'bar-u' &&
          (table.layout.position === 'right' || table.layout.position === 'left'))
      ) {
        if (table.layout.size === 'sm') {
          return styl
        } else if (table.layout.size === 'md') {
          styl.height = 280 + 'px'
          return styl
        } else if (table.layout.size === 'lg') {
          styl.height = 360 + 'px'
          return styl
        } else if (table.layout.size === 'xl') {
          styl.height = 440 + 'px'
          return styl
        }
      } else if (
        table.layout.shape === 'bar-u' &&
        (table.layout.position === 'top' || table.layout.position === 'bottom')
      ) {
        if (table.layout.size === 'sm') {
          return styl
        } else if (table.layout.size === 'md') {
          styl.width = 280 + 'px'
          return styl
        } else if (table.layout.size === 'lg') {
          styl.width = 360 + 'px'
          return styl
        } else if (table.layout.size === 'xl') {
          styl.width = 440 + 'px'
          return styl
        }
      }
    },

    setBarPositionIn: function (table, cart, tablesInfo) {
      let styl = {}
      if (table.layout.shape === 'bar-l' || table.layout.shape === 'bar-u') {
        styl = this.getBarPosition(
          table.layout.position,
          table.layout.size,
          table.layout.shape,
          cart,
          table,
          tablesInfo
        )
        return styl
      }
    },

    handleTableInfo: function (table) {
      const size = table.layout.size ? table.layout.size : null
      const shape = table.layout.shape
      let show = true
      if (!size) {
        return show
      } else if (size === 'sm') {
        show = false
      } else if (shape === 'round' && size === 'md') {
        show = false
      }
      return show
    },

    handleSelectedClass: function (table) {
      const shape = table.layout.shape
      const position = table.layout.position ? table.layout.position : null
      let selectedClass = 'small'

      if (shape === 'bar-l' && (position === 'top-right' || position === 'bottom-right')) {
        selectedClass = 'small-right'
      } else if (shape === 'bar-l' && (position === 'top-left' || position === 'bottom-left')) {
        selectedClass = 'small-left'
      } else if (shape === 'bar-u' && position === 'right') {
        selectedClass = 'small-right'
      } else if (shape === 'bar-u' && position === 'left') {
        selectedClass = 'small-left'
      }
      return selectedClass
    },

    handleBarUText: function (table, top) {
      const shape = table.layout.shape
      const position = table.layout.position ? table.layout.position : null

      if (!top && shape === 'bar-u' && position === 'bottom') {
        return { marginTop: 30 }
      } else if (!top && shape === 'bar-u' && position === 'top') {
        return { marginTop: 70 }
      } else if (top && shape === 'bar-u' && position === 'top') {
        return { marginTop: 20 }
      } else return null
    },

    getBarPosition: function (position, size, shape, cart, table, tablesInfo) {
      const styl = {
        height: shape === 'bar-l' ? 150 + 'px' : 120 + 'px',
        width: 150 + 'px',
        position: 'absolute',
        backgroundColor: this.getBgColor(cart, table, tablesInfo),
        left: 0 + 'px',
        right: 0 + 'px',
        bottom: 0 + 'px',
        top: 0 + 'px',
        margin: ''
      }

      if (shape === 'bar-l') {
        if (position === 'top-right') {
          delete styl.right
          delete styl.top
        } else if (position === 'top-left') {
          delete styl.top
          delete styl.left
        } else if (position === 'bottom-left') {
          delete styl.top
          delete styl.left
          delete styl.bottom
        } else if (position === 'bottom-right') {
          delete styl.top
          delete styl.right
          delete styl.bottom
        }
        styl.width = 150 + 'px'
        if (size === 'sm') {
          return styl
        } else if (size === 'md') {
          styl.height = 230 + 'px'
          return styl
        } else if (size === 'lg') {
          styl.height = 310 + 'px'
          return styl
        } else if (size === 'xl') {
          styl.height = 390 + 'px'
          return styl
        }
      } else {
        if (position === 'right') {
          if (size === 'md') {
            styl.height = 200 + 'px'
          } else if (size === 'lg') {
            styl.height = 280 + 'px'
          } else if (size === 'xl') {
            styl.height = 360 + 'px'
          }
          delete styl.right
          delete styl.top
          styl.width = 150 + 'px'
          styl.bottom = 40 + 'px'
          return styl
        } else if (position === 'left') {
          if (size === 'md') {
            styl.width = 150 + 'px'
            styl.height = 200 + 'px'
          } else if (size === 'lg') {
            styl.width = 150 + 'px'
            styl.height = 280 + 'px'
          } else if (size === 'xl') {
            styl.width = 150 + 'px'
            styl.height = 360 + 'px'
          }
          delete styl.top
          delete styl.left
          styl.bottom = 40 + 'px'
          return styl
        } else if (position === 'top') {
          delete styl.top
          styl.height = 150 + 'px'
          styl.width = 130 + 'px'
          if (size === 'md') {
            styl.width = 200 + 'px'
            styl.height = 140 + 'px'
          } else if (size === 'lg') {
            styl.width = 280 + 'px'
            styl.height = 140 + 'px'
          } else if (size === 'xl') {
            styl.width = 360 + 'px'
            styl.height = 140 + 'px'
          }
          styl.margin = 'auto'
          return styl
        } else if (position === 'bottom') {
          delete styl.bottom
          styl.height = 150 + 'px'
          styl.width = 130 + 'px'
          if (size === 'md') {
            styl.width = 200 + 'px'
            styl.height = 140 + 'px'
          } else if (size === 'lg') {
            styl.width = 280 + 'px'
            styl.height = 140 + 'px'
          } else if (size === 'xl') {
            styl.width = 360 + 'px'
            styl.height = 140 + 'px'
          }
          styl.margin = 'auto'
          return styl
        }
      }
    }
  }
})()
