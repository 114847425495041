import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AppInstances } from './../../utils/countrSdkInstance'
import { Text } from 'react-internationalization'
import { addCart } from '../../store/actions/carts'
import { setToastMessage } from '../../store/actions/app'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import RecoveryCartCard from './RecoveryCartCard'
import RecoveryCartsAlertMsg from './RecoveryCartsAlertMsg'
import Util from '../../utils/Util'

import './RecoveryCarts.css'

const mapStateToProps = state => {
  return {
    carts: state.carts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCart: cart => dispatch(addCart(cart)),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const MAX_CARTS = 100
const SORT = '-updated_at'

const RecoveryCarts = memo(props => {
  const [isLoading, setIsLoading] = useState(false)
  const [carts, setCarts] = useState([])
  const [devices, setDevices] = useState([])
  const [selectedCarts, setSelectedCarts] = useState([])
  const [showAlertMsg, setAlertMsg] = useState(false)
  const [shouldSelectAll, setShouldSelectAll] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    loadOldCarts()
  }, [])

  const loadOldCarts = async () => {
    const countr = await AppInstances.getCountrSdk()
    const devices = await countr.devices.read()
    setDevices(devices)
    countr.carts.read({ limit: MAX_CARTS, sort: SORT }).then(carts => {
      const oldCarts = carts.filter(
        cart =>
          cart.store === props.device.store._id &&
          cart.device !== props.device._id &&
          cart.items.length > 0
      )
      setCarts(oldCarts)
      setIsLoading(false)
    })
  }

  const getDeviceName = id => {
    const device = devices.find(d => d._id === id)
    return device && device.name ? device.name : ''
  }

  const handleSelectCart = (shouldAddCart, id) => {
    if (shouldAddCart) {
      const cart = carts.find(c => c._id === id)
      const selected = [...selectedCarts, cart]
      setSelectedCarts(selected)
    } else {
      const index = selectedCarts.findIndex(c => c._id === id)

      if (index >= 0) {
        const selected = Object.assign([], selectedCarts)
        selected.splice(index, 1)
        setSelectedCarts(selected)
      }
    }
  }

  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelectedCarts(carts)
    } else {
      setSelectedCarts([])
    }

    setShouldSelectAll(event.target.checked)
  }

  const handleOpenAlert = () => {
    setAlertMsg(true)
  }

  const handleCloseAlert = () => {
    setAlertMsg(false)
  }

  const recovery = async () => {
    handleCloseAlert()
    setIsLoading(true)

    const promises = []
    const countr = await AppInstances.getCountrSdk()

    selectedCarts.forEach(cart => {
      // Updating device id
      const newCart = createCartBaseInAnotherCart(cart)
      promises.push(
        countr.carts.create(newCart).then(c => {
          props.addCart(c)
          localStorage.setItem('CountrLite:Cart-' + c._id, JSON.stringify(c))
        })
      )
    })

    Promise.all(promises).then(result => {
      setIsLoading(false)
      props.setToastMessage('recover_carts_complete')
      props.handleCloseRecoveryCarts()
    })
  }

  const createCartBaseInAnotherCart = cart => {
    const newCart = {}

    const lastTransaction = JSON.parse(localStorage.getItem('CountrLite:LastTransaction'))
    newCart.merchant = cart.merchant
    newCart.store = cart.store
    newCart.device = props.device._id
    newCart.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    newCart.date = new Date()
    newCart.currency = props.device.store.currency
    newCart.order_source = 'web_pos'
    newCart.receipt_id =
      props.device.store.store_id + '-' + props.device.device_id + '-' + new Date().getTime()
    newCart.receipt_number = lastTransaction
    newCart.items = cart.items
    newCart.total = cart.total
    newCart.sub_total = cart.sub_total
    newCart.discount = cart.discount
    newCart.reduction = cart.reduction
    newCart.extras = cart.extras

    return newCart
  }

  return (
    <Dialog
      open={props.openRecoveryCarts}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, props.handleCloseRecoveryCarts)
      }}
      disableEscapeKeyDown={true}>
      <DialogTitle>
        <div className="recover-title">
          <div className="recover-title-text">
            <Text id="recover_carts" />
          </div>
          <div>
            <Text id="select_all_carts" />
            <Checkbox edge="end" style={{ color: '#318ed5' }} onChange={handleSelectAll} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {!isLoading ? (
          <Grid container justifyContent="center" alignItems="center">
            {carts.length > 0 ? (
              carts.map(cart => (
                <Grid item xs={12} key={cart._id} className="m-10">
                  <RecoveryCartCard
                    cart={cart}
                    handleSelectCart={handleSelectCart}
                    getDeviceName={getDeviceName}
                    setCart={shouldSelectAll}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                No carts found.
              </Grid>
            )}
          </Grid>
        ) : (
          <div className="center">
            <CircularProgress color="primary" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.handleCloseRecoveryCarts}>
          <Text id="close" />
        </Button>
        <Button
          variant="contained"
          onClick={handleOpenAlert}
          style={{
            backgroundColor: selectedCarts.length === 0 ? '#b0c6d7' : '#318ed5',
            color: '#fff'
          }}
          disabled={selectedCarts.length === 0}>
          <Text id="recover_carts" />
        </Button>
      </DialogActions>
      {showAlertMsg && (
        <RecoveryCartsAlertMsg
          open={showAlertMsg}
          handleClose={handleCloseAlert}
          handleConfirm={recovery}
        />
      )}
    </Dialog>
  )
})

const RecoveryCartsConnected = connect(mapStateToProps, mapDispatchToProps)(RecoveryCarts)
export default RecoveryCartsConnected
