import { Component } from 'react';
import { connect } from 'react-redux'

import { AppInstances } from './../../utils/countrSdkInstance'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'

import { Text } from 'react-internationalization'

const mapStateToProps = state => {
  return {
    devices: state.devices
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

class ChangeInfo extends Component {
  state = {
    newValue: '',
    stores: []
  }

  handleClose = () => {
    this.props.handleClose()
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleConfirm()
    }
  }

  handleConfirm = () => {
    if (this.props.infoType === 'stores') {
      const newStore = this.state.stores.find(s => s._id === this.state.newValue)
      this.props.handleConfirm(newStore)
    } else if (this.props.infoType === 'device') {
      this.props.handleConfirm(this.state.newValue)
    }
  }

  componentDidMount = () => {
    if (this.props.infoType === 'stores') {
      AppInstances.getCountrSdk().then(socket => {
        socket.stores.read().then(stores => {
          this.setState({
            stores: stores,
            newValue: stores.find(s => s._id === this.props.devices.store._id)._id
          })
        })
      })
    } else if (this.props.infoType === 'device') {
      this.setState({ newValue: this.props.devices.device.name })
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.openChangeInfoModal}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Text id={this.props.infoType === 'device' ? 'change_device_name' : 'change_store'} />
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" justifyContent="center">
            {this.props.infoType === 'device' ? (
              <Grid item xs={12}>
                <TextField
                  label={<Text id="new_device_name" />}
                  fullWidth={true}
                  value={this.state.newValue}
                  onChange={this.handleChange('newValue')}
                  onKeyPress={this.handleKeyPress}
                  error={this.state.newValue.length === 0}
                />
              </Grid>
            ) : (
              <Grid item xs={12} style={{ minWidth: 250 }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>
                    <Text id="storeid" />
                  </InputLabel>
                  <Select
                    value={this.state.newValue}
                    onChange={this.handleChange('newValue')}
                    fullWidth={true}>
                    {this.state.stores.map(store => (
                      <MenuItem key={store._id} value={store._id}>
                        {store.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Store assigned: <b>{this.props.devices.store.name}</b>
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            <Text id="close" />
          </Button>
          <Button color="primary" onClick={this.handleConfirm}>
            <Text id="ok" />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const ChangeInfoConnected = connect(mapStateToProps, mapDispatchToProps)(ChangeInfo)
export default ChangeInfoConnected
