import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from './../../utils/countrSdkInstance'
import { RequestQueue } from './../../utils/RequestQueue'
import { PaymentUtils } from '../../utils/PaymentUtils'
import { cartUtils } from '../../utils/cartUtils'
import { PrinterUtils } from '../../utils/PrinterUtils'

import { addRefundHead, editTransaction, setRefundExtras } from '../../store/actions/transactions'
import { loadingFalse, loadingTrue } from '../../store/actions/loading'
import { setToastMessage } from '../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'

import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'

import ConfirmationModal from '../generic/ConfirmationModal'
import CCVModal from './../Payments/Providers/CCVModal'
import PayplazaModal from './../Payments/Providers/PayplazaModal'
import RefundButtonsDialog from './RefundButtonsDialog'
import RefundTypeSelect from './RefundTypeSelect'
import RefundSelectItems from './RefundSelectItems'

import './Refund.css'
import CustomPayment from '../Payments/Providers/CustomPayment'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user,
    devices: state.devices,
    carts: state.carts,
    transactions: state.transactions,
    settings: state.settings,
    payments: state.payments,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addRefundHead: refund => dispatch(addRefundHead(refund)),
    editTransaction: transaction => dispatch(editTransaction(transaction)),
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    setRefundExtras: extras => dispatch(setRefundExtras(extras))
  }
}

class Refund extends PureComponent {
  state = {
    reason: '',
    refundValue: 0,
    refundSubTotal: 0,
    refundItems: [],
    note: '',
    cardPaymentConfirmation: false,
    openSelectItemsToRefund: false,
    notRefundableItems: [],
    openPayplazaModal: false,
    openCCVModal: false,
    openRefundsButtonsDialog: false,
    modalRefundType: '',
    modalTitle: '',
    modalText: '',
    openCustomPaymentModal: false,
    customMethod: {},
    reference: ''
  }

  handleCloseNoSelectedItemsSnackbar = () => {
    this.setState({ openSelectItemsToRefund: false })
  }

  handleClose = refundComplete => {
    this.props.handleClose(refundComplete)
    //TODO Fiscalise receipt and print that
  }

  handleChange = name => event => {
    const value = event.target.value

    this.setState({
      [name]: value
    })
  }

  changeRefundItem = index => event => {
    const value = parseInt(event.target.value, 10)

    if (value > this.getRefundableAmount(this.state.refundItems[index])) {
      return
    }

    if (value >= 0 && value <= this.state.refundItems[index].amount) {
      const copy = JSON.parse(JSON.stringify(this.state.refundItems))
      copy[index].refund = value
      this.setState({ refundItems: copy }, () => {
        this.updateRefundValue()
      })
    }
  }

  changeRefundItemButton = (index, action) => {
    const copy = JSON.parse(JSON.stringify(this.state.refundItems))

    if (action === 'add') {
      copy[index].refund =
        copy[index].refund + 1 <= copy[index].amount ? copy[index].refund + 1 : copy[index].refund

      if (copy[index].refund > this.getRefundableAmount(copy[index])) {
        return
      }
    } else {
      copy[index].refund = copy[index].refund - 1 >= 0 ? copy[index].refund - 1 : 0
    }

    this.setState({ refundItems: copy }, () => {
      this.updateRefundValue()
    })
  }

  refundAllItems = () => {
    const copy = JSON.parse(JSON.stringify(this.state.refundItems))
    copy.forEach(item => {
      item.refund = this.getRefundableAmount(item)
    })
    this.setState({ refundItems: copy }, () => {
      this.updateRefundValue()
    })
  }

  updateRefundValue = () => {
    let total = 0
    this.state.refundItems.forEach(item => {
      total += item.product.current_variant.price * item.refund * (1 - item.product.discount)

      if (
        item.product.current_addons !== undefined &&
        item.product.current_addons.length > 0 &&
        item.refund > 0
      ) {
        const addonsValue = item.product.current_addons.reduce((oldValue, newValue) => {
          return oldValue + newValue.price * newValue.amount
        }, 0)

        total += addonsValue
      }
    })

    let toRefund = total

    if (this.props.transaction.discount && this.props.transaction.discount > 0) {
      toRefund = toRefund * (1 - this.props.transaction.discount)
    } else if (this.props.transaction.reduction && this.props.transaction.reduction.numeric) {
      toRefund = toRefund - this.props.transaction.reduction.numeric
    }

    this.setState({ refundValue: toRefund })
  }

  handleRefund = methodSelected => {
    const method = { ...methodSelected }

    this.handleCloseRefundsButtonsDialog()

    const listItems = JSON.parse(JSON.stringify(this.state.refundItems))
    if (this.checkRefundItems(listItems) === 0) {
      this.setState({ openSelectItemsToRefund: true })
      this.props.setToastMessage('provide_refund_item')
      setTimeout(() => {
        this.handleCloseNoSelectedItemsSnackbar()
      }, 5000)

      return
    }

    if (method.method === 'payplaza') {
      this.handleOpenPayplazaModal()
    } else if (method.method === 'manual_card_payment' || method.method === 'cheque') {
      this.handleOpenCardPaymentConfirmation(method.method)
    } else if (method.method === 'ccv') {
      this.handleOpenCCVModal()
    } else if (method.isCustom) {
      const custom = this.props.devices.store.options.extraPaymentMethods.find(
        m => m._id === method.id
      )

      if (custom && custom.extras) {
        method.extras = custom.extras
        method.name = method.method
        method.method = custom.method

        this.handleCustomPaymentWithExtras(method)
      } else if (['cash', 'other'].indexOf(method.provider) >= 0) {
        this.refund(method.method)
      } else {
        this.props.setToastMessage('error_loading_configuration')
        this.logError({
          msg: `Refund.handleRefund could not load payment config`,
          stack: JSON.stringify(method)
        })
        return
      }
    } else {
      this.refund(method.method)
    }
    if (method.cashDrawer) { //Trigger drawer if drawer open method
      PrinterUtils.openCashDrawer()
    }
  }

  logError = async obj => {
    const errorObj = {
      message: `${obj.msg}, users: ${this.props.user.user.username}, 
        _ID: ${this.props.user.user._id}, device id: ${this.props.devices.device._id}`,
      user: this.props.user.user._id,
      store: this.props.devices.store._id,
      device: this.props.devices.device._id,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  handleOpenCardPaymentConfirmation = type => {
    const refundType = type === 'cheque' ? 'cheque' : 'manual_card_payment'
    const modalTitle = type === 'cheque' ? 'manual_cheque_refund' : 'manual_card_refund'
    const modalText = type === 'cheque' ? 'did_cheque_refund_succeed' : 'did_card_refund_succeed'

    this.setState({
      cardPaymentConfirmation: true,
      modalRefundType: refundType,
      modalTitle: modalTitle,
      modalText: modalText
    })
  }

  handleCancelCardPaymentConfirmation = () => {
    this.setState({ cardPaymentConfirmation: false })
  }

  handleConfirmCardPaymentConfirmation = () => {
    this.handleCancelCardPaymentConfirmation()
    this.refund(this.state.modalRefundType)
    this.setState({ modalRefundType: '', modalTitle: '', modalText: '' })
  }

  checkRefundItems = listItems => {
    const refunds = listItems.reduce((acc, currentItem) => {
      return acc + currentItem.refund
    }, 0)

    return refunds
  }

  refund = async type => {
    const countr = await AppInstances.getCountrSdk()
    const transaction = this.props.transaction
    const user = this.props.user.user
    const device = this.props.devices.device
    const listItems = JSON.parse(JSON.stringify(this.state.refundItems))
    const newNumber = parseInt(localStorage.getItem('CountrLite:LastTransaction'), 10) + 1
    const employee = PaymentUtils.getEmployee(this.props.employees.selectedEmployee)
    let refundValue = this.state.refundValue
    let roundCheck = false
    this.props.loadingTrue()

    // Round payment check
    if (this.props.settings.roundCashPayment && type === 'cash') {
      refundValue = PaymentUtils.roundCashPayment(refundValue)
      roundCheck = true
    }

    const refundBody = PaymentUtils.createRefundBody(
      countr,
      user,
      device,
      transaction,
      refundValue,
      listItems,
      type,
      this.state.reason,
      this.state.note,
      this.props.transactions.refundExtras,
      newNumber,
      employee,
      roundCheck
    )

    countr.refunds.create(refundBody).then(
      refundComplete => {
        if (roundCheck) {
          refundComplete.total = -refundValue
        }
        this.props.addRefundHead(refundComplete)

        // updating transaction with refund
        const trans = JSON.parse(JSON.stringify(transaction))
        trans.refunds.push(refundComplete._id)
        trans.total_refunded = trans.total_refunded || 0
        trans.total_refunded += refundComplete.total
        // updating new last transaction number
        localStorage.setItem('CountrLite:LastTransaction', newNumber)

        countr.transactions.update(trans._id, trans).then(updatedTransaction => {
          this.props.editTransaction(trans)
          this.props.setRefundExtras({})
          this.props.loadingFalse()

          localStorage.removeItem('CountrWeb:RefundInProgress')
          localStorage.removeItem('CountrWeb:RefundExtraRecovery')
          localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
          this.handleClose(refundComplete)
        })
      },
      error => {
        console.log(error)
        RequestQueue.enqueueAction({
          type: 'refunds',
          action: 'create',
          payload: refundBody,
          extra: transaction
        })
        localStorage.setItem('CountrLite:LastTransaction', newNumber)
        localStorage.removeItem('CountrWeb:RefundInProgress')
        localStorage.removeItem('CountrWeb:RefundExtraRecovery')
        localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
        this.props.loadingFalse()
        this.handleClose(refundBody)
      }
    )
  }

  handleOpenCCVModal = () => {
    this.setState({ openCCVModal: true })
  }

  handleCloseCCVModal = resultCode => {
    this.setState({ openCCVModal: false })

    if (resultCode.toLowerCase() === 'authorised') {
      this.refund('ccv')
    }
  }

  handleOpenPayplazaModal = async () => {
    const countr = await AppInstances.getCountrSdk()
    this.setState({ openPayplazaModal: true })
    const transaction = this.props.transaction
    const user = this.props.user.user
    const device = this.props.devices.device
    const listItems = JSON.parse(JSON.stringify(this.state.refundItems))
    const newNumber = parseInt(localStorage.getItem('CountrLite:LastTransaction'), 10) + 1

    const refundBody = PaymentUtils.createRefundBody(
      countr,
      user,
      device,
      transaction,
      this.state.refundValue,
      listItems,
      'payplaza',
      this.state.reason,
      this.state.note,
      this.props.transactions.refundExtras,
      newNumber
    )

    localStorage.setItem('CountrWeb:RefundInProgress', JSON.stringify(true))
    localStorage.setItem('CountrWeb:RefundExtraRecovery', JSON.stringify(refundBody))
    localStorage.setItem('CountrWeb:RefundExtraRecovery-Transaction', JSON.stringify(transaction))
  }

  completePayplazaTransaction = resultCode => {
    console.log('Refund -> handleClosePayplazaModal -> resultCode', resultCode)

    if (resultCode === 'SUCCESS') {
      this.refund('payplaza')
    } else {
      localStorage.removeItem('CountrWeb:RefundInProgress')
      localStorage.removeItem('CountrWeb:RefundExtraRecovery')
      localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
    }
  }

  handleClosePayplazaModal = resultCode => {
    this.setState({ openPayplazaModal: false })
    localStorage.removeItem('CountrWeb:RefundInProgress')
    localStorage.removeItem('CountrWeb:RefundExtraRecovery')
    localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
  }

  handleCustomPaymentWithExtras = method => {
    const reference =
      ((this.props.devices.store || {}).store_id || '0') +
      '-' +
      (this.props.devices.device.device_id || '0') +
      '-' +
      Math.round(new Date().getTime(), null)
    this.setState({
      openCustomPaymentModal: true,
      customMethod: method,
      reference
    })
  }

  handleCloseCustomPaymentWithExtras = () => {
    this.setState({ openCustomPaymentModal: false, customMethod: {} })
  }

  handleResultCustomPayment = result => {
    if (!!result && !!result.success) {      
      this.props.setRefundExtras(result)
      const custom = { ...this.state.customMethod }
      this.handleCloseCustomPaymentWithExtras()
      this.refund(custom.name)
    }
  }

  getRefundableAmount = item => {
    let amount = item.amount

    if (this.state.notRefundableItems.length > 0) {
      const not = this.state.notRefundableItems.find(ref => ref.id === cartUtils.getCartEntryId(item))

      if (not !== undefined) {
        amount = item.amount - not.amount
      }
    }

    return amount
  }

  handleOpenRefundButtonsDialog = () => {
    this.setState({ openRefundsButtonsDialog: true })
  }

  handleCloseRefundsButtonsDialog = () => {
    this.setState({ openRefundsButtonsDialog: false })
  }

  selectRefundsFromTransaction = async () => {
    const list = []
    const countr = await AppInstances.getCountrSdk()

    const promise = new Promise((resolve, reject) => {
      this.props.transaction.refunds.forEach(async (r, index) => {
        let ref = this.props.transactions.refunds.find(ref => ref._id === r)

        if (ref) {
          list.push(ref)
        } else {
          // Trying to recover this refund from the server
          ref = await countr.refunds.readOne(r).catch(error => {
            this.props.setToastMessage('refund_not_found')
          })
          if (ref) {
            list.push(ref)
          }
        }

        if (index === this.props.transaction.refunds.length - 1) {
          resolve(list)
        }
      })
    })

    return promise
  }

  loadTransactionDetails = async () => {
    const list = []
    this.props.transaction.items.forEach(item => {
      item.refund = 0
      list.push(item)
    })

    const notRefundableItems = []

    if (this.props.transaction.__t === 'Transaction' && this.props.transaction.refunds.length > 0) {
      const refList = await this.selectRefundsFromTransaction()

      refList.forEach(ref => {
        ref.items.forEach(item => {
          notRefundableItems.push({
            id: cartUtils.getCartEntryId(item),
            amount: item.amount
          })
        })
      })
    }

    this.setState({
      refundItems: list,
      notRefundableItems: notRefundableItems.length > 0 ? notRefundableItems : []
    })
  }

  componentDidMount = () => {
    this.loadTransactionDetails()
  }

  render() {
    return (
      <Dialog
        open={this.props.openRefundDialog}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={isMobileOnly}>
        <DialogTitle id="form-dialog-title">
          <Text id="refund" />
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel>
                <Text id="provide_refund_reason" />
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <RefundTypeSelect reason={this.state.reason} handleChange={this.handleChange} />
            </Grid>
          </Grid>
          {this.state.reason.length > 0 && (
            <RefundSelectItems
              currency={this.props.devices.store.currency}
              transaction={this.props.transaction}
              refundValue={this.state.refundValue}
              refundItems={this.state.refundItems}
              changeRefundItem={this.changeRefundItem}
              getRefundableAmount={this.getRefundableAmount}
              changeRefundItemButton={this.changeRefundItemButton}
              openSelectItemsToRefund={this.state.openSelectItemsToRefund}
              refundAllItems={this.refundAllItems}
              handleChange={this.handleChange}
              note={this.state.note}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            <Text id="cancel" />
          </Button>
          {this.state.reason.length > 0 && (
            <Button
              variant="contained"
              style={{
                backgroundColor:
                  this.checkRefundItems(this.state.refundItems) === 0 ? '#b0c6d7' : '#318ed5',
                color: '#fff'
              }}
              onClick={this.handleOpenRefundButtonsDialog}
              disabled={this.checkRefundItems(this.state.refundItems) === 0}>
              <Text id="refund" />
            </Button>
          )}
        </DialogActions>
        {this.state.cardPaymentConfirmation && (
          <ConfirmationModal
            openConfirmation={this.state.cardPaymentConfirmation}
            handleCloseConfirmation={this.handleCancelCardPaymentConfirmation}
            confirmBtn={this.handleConfirmCardPaymentConfirmation}
            confirmationTitle={<Text id={this.state.modalTitle} />}
            confirmationText={<Text id={this.state.modalText} />}
            type={this.state.modalTitle}
          />
        )}
        {this.props.app.isDesktop && this.state.openCCVModal && (
          // TODO: Remove when new desktop version is launch
          <CCVModal
            openCCVModal={this.state.openCCVModal}
            handleCloseCCVModal={this.handleCloseCCVModal}
            currency={this.props.transaction.currency.code}
            total={this.state.refundValue}
            method="refund"
            language={this.props.settings.language}
          />
        )}
        {this.state.openPayplazaModal && (
          <PayplazaModal
            openPayplazaModal={this.state.openPayplazaModal}
            handleClosePayplazaModal={this.handleClosePayplazaModal}
            completePayplazaTransaction={this.completePayplazaTransaction}
            type="REFUND"
            currency={this.props.carts.selectedCart.currency.code}
            totalToPay={this.state.refundValue}
          />
        )}
        {this.state.openRefundsButtonsDialog && (
          <RefundButtonsDialog
            openRefundsButtonsDialog={this.state.openRefundsButtonsDialog}
            handleCloseRefundsButtonsDialog={this.handleCloseRefundsButtonsDialog}
            handleRefund={this.handleRefund}
            transaction={this.props.transaction}
          />
        )}
        {this.state.openCustomPaymentModal && (
          <CustomPayment
            open={this.state.openCustomPaymentModal}
            handleResultPayment={this.handleResultCustomPayment}
            handleClose={this.handleCloseCustomPaymentWithExtras}
            method={this.state.customMethod}
            amount={this.state.refundValue * -1}
            currency={this.props.carts.selectedCart.currency}
            reference={this.state.reference}
            transaction={this.props.transaction}
          />
        )}
      </Dialog>
    )
  }
}

const RefundConnected = connect(mapStateToProps, mapDispatchToProps)(Refund)
export default RefundConnected
