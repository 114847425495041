import { ADD_CUSTOMER, ADD_ALL_CUSTOMERS } from './actionTypes'

export const addCustomer = customer => ({
  type: ADD_CUSTOMER,
  payload: customer
})

export const addAllCustomers = customers => ({
  type: ADD_ALL_CUSTOMERS,
  payload: customers
})
