import {
  LOADING_TRUE,
  LOADING_FALSE,
  SET_LOADING_MSG,
  SET_LOADING_VALUE
} from './../actions/actionTypes'

const initialState = {
  loading: false,
  info: '',
  value: undefined
}

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        loading: true
      }
    case LOADING_FALSE:
      return {
        ...state,
        loading: false
      }
    case SET_LOADING_MSG:
      return {
        ...state,
        info: action.payload
      }
    case SET_LOADING_VALUE:
      return {
        ...state,
        value: action.payload
      }
    default:
      return state
  }
}

export default loadingReducer
