import { AppInstances } from './countrSdkInstance'
import store from './../index'
import { setDisconnectedTime } from './../store/actions/app'
import RegisterOperationsUtils from './RegisterOperationsUtils'

// const LOG_TAG = '[LOG UTILS]'
const CONNECT = 'connect'
const DISCONNECT = 'disconnect'

export default class LogUtils {
  static async initSDKSocketListeners() {
    const countr = await AppInstances.getCountrSdk()
    countr.ws.socket.on(CONNECT, async () => {
      if (store.getState().app.disconnectedTime !== '') {
        const state = store.getState()
        const now = new Date()
        const diff = (now.getTime() - state.app.disconnectedTime.getTime()) / 1000

        const errorObj = {
          message: `Socket reconnected after ${diff} seconds`,
          user: state.user.user._id,
          store: state.devices.store._id,
          device: state.devices.device._id,
          info: {
            socketId: countr.ws.socket.id,
            offlineTime: diff,
            reason: state.app.disconnectedReason
          },
          stack: state.app.disconnectedReason,
          date: new Date().toISOString()
        }

        AppInstances.logError(errorObj, false)

        store.dispatch(setDisconnectedTime('', ''))

        // If diff bigger than 10 sec, write it down in register operations too
        if (diff > 10) {
          const operationArgs = {
            action: 'error',
            note: `Device ${
              state.devices.device.name
            } offline from ${state.app.disconnectedTime.toISOString()} until ${now.toISOString()}`
          }
      
          RegisterOperationsUtils.applyOperation(operationArgs)
        }
      }
    })
    countr.ws.socket.on(DISCONNECT, data => {
      store.dispatch(setDisconnectedTime(new Date(), data))
    })
  }
}
