import { memo, useState, useEffect } from 'react';
import { Text } from 'react-internationalization'
import AddonUtils from '../../../utils/AddonUtils'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import { AddRemoveButtons } from '@countr/ui'

import './CartItemDetails.scss'

const CartItemAddonGroups = memo(
  ({ addonsGroupList, currentAddons, setCurrentAddons, store, handleDisableSave, language }) => {
    const [selectGroup, setSelectGroup] = useState('')

    useEffect(() => {
      if (addonsGroupList.length === 1) {
        showGroup(addonsGroupList[0])
      }
    }, [addonsGroupList])

    const showGroup = group => {
      const id = group._id._id
      setSelectGroup(selectGroup !== id ? id : '')
    }

    const performeGroupAction = (group, addon, action) => {
      const aux = AddonUtils.editAddon(currentAddons, action, addon, store._id)
      setCurrentAddons(aux)
      const isValid = []

      addonsGroupList.forEach(group => {
        const sum = sumAllCurrentAddonsFromGroup(group, aux)
        const min = AddonUtils.returnMinMaxGroup(group, 'min')
        const max = AddonUtils.returnMinMaxGroup(group, 'max')
        isValid.push(sum >= min && max === 0 ? true : sum >= min && sum <= max)
      })

      handleDisableSave(isValid.indexOf(false) >= 0)
    }

    const sumAllCurrentAddonsFromGroup = (group, current) => {
      return current.reduce((acc, addon) => {
        return AddonUtils.addonBelongsGroup(addon._id, group) ? acc + addon.amount : acc
      }, 0)
    }

    return (
      <>
        {!AddonUtils.isGroupsInvalid(addonsGroupList) && (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className="cart-item-discount">
              <Grid item xs={12} className="center">
                <span>
                  <Text id="addons" /> - Group
                </span>
              </Grid>
            </Grid>
            <List component="nav" className="giveaway-list">
              {addonsGroupList.map(group => (
                <div key={group._id._id}>
                  <ListItem button={true} divider={true} onClick={() => showGroup(group)}>
                    <ListItemText primary={AddonUtils.getAddonGroupName(group, language)} />
                  </ListItem>
                  <Collapse in={selectGroup === group._id._id} timeout="auto">
                    <div className="addon-group-collapse">
                      {group._id.addons.map(addon => (
                        <Grid item xs={12} key={addon._id}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs={8} className="cart-item-addon-name">
                              {AddonUtils.getAddonNamePrice(
                                addon,
                                store._id,
                                store.currency.symbol
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <AddRemoveButtons
                                value={AddonUtils.calculateAddonAmount(currentAddons, addon)}
                                handleAdd={() => performeGroupAction(group, addon, 'add')}
                                handleRemove={() => performeGroupAction(group, addon, 'remove')}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </Collapse>
                </div>
              ))}
            </List>
          </>
        )}
      </>
    )
  }
)

export default CartItemAddonGroups
