import { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux'

import { AppInstances } from '../../utils/countrSdkInstance'
import { setListeningCategories } from '../../store/actions/settings'

import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Settings.css'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    categories: state.categories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setListeningCategories: settings => dispatch(setListeningCategories(settings))
  }
}

export const CategoriesToListen = memo(props => {
  const [allCategories, setAllCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const handleAllCategories = async () => {
    try {
      const countr = await AppInstances.getCountrSdk()
      const device = await countr.devices.readOne.detailed(props.devices?.device?._id)

      if (device?.settings?.buzzerCategories) {
        props.setListeningCategories(device.settings.buzzerCategories)
      }
      
      setAllCategories(props.categories?.categories)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErrorMessage(error?.message || 'Error while loading categories')
    }
  }

  const handleIsChecked = id => {
    const inList = props.buzzerCategories.find(s => s === id)
    return inList ? true : false
  }

  const handleChange = id => {
    const listToUpdate = handleIsChecked(id)
      ? props.buzzerCategories.filter(s => s !== id)
      : [...props.buzzerCategories, id]

    props.handleUpdateCategories(listToUpdate)
    props.setListeningCategories(listToUpdate)
  }

  useEffect(() => {
    handleAllCategories()
  }, [])

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress style={{ color: '#318ed5' }} />
        </div>
      ) : (
        <List>
          {!!errorMessage && (
            <ListItem>
              <ListItemText style={{ color: '#bf1e2d' }}>* {errorMessage}</ListItemText>
            </ListItem>
          )}
          {allCategories.map(category => (
            <ListItem key={category._id} button onClick={() => handleChange(category._id)}>
              <ListItemIcon>
                <Checkbox
                  checked={handleIsChecked(category._id)}
                  onChange={() => handleChange(category._id)}
                  style={
                    handleIsChecked(category._id)
                      ? {
                          color: '#318ed5'
                        }
                      : {}
                  }
                />
              </ListItemIcon>

              <ListItemText primary={category.name} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesToListen)
