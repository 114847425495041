import { memo, Fragment } from 'react';
import { Text } from 'react-internationalization'

import { Divider } from '@countr/ui'

import ErrorUtils from '../../utils/ErrorUtils'
import CartEntry from './CartEntry'

import './Cart.scss'

const SeatEntry = memo(
  ({
    seat,
    seatNo,
    cartName,
    setTableSeat,
    selectedCart,
    getProductId,
    settings,
    currency,
    removeItem,
    openCartItemDetailsDialog,
    devices
  }) => {
    const handleActiveSeat = n => {
      if (n === seatNo) {
        return true
      } else {
        return false
      }
    }

    const handleSeatName = n => {
      if (n === 0) {
        return cartName
      } else {
        return `Seat ${n}`
      }
    }

    const handleSeatItems = () => {
      try {
        return selectedCart.items.filter(item => item.product.seat === seat)
      } catch (error) {
        ErrorUtils.logError({
          msg: `Failed handling Seat Items`,
          stack: JSON.stringify(error)
        })
      }
    }

    return <>
      <div
        className={handleActiveSeat(seat) ? 'active-seat-header' : 'seat-header'}
        onClick={() => setTableSeat(seat)}>
        {handleSeatName(seat)}
      </div>
      {!!selectedCart?.items.find(item => item.product.seat === seat) ? (
        handleSeatItems(seat).map((item, index) => (
          <Fragment key={`${seat}-${index}`}>
            <CartEntry
              cart={selectedCart}
              item={item}
              index={index}
              settings={settings}
              currency={currency}
              openCartItemDetailsDialog={openCartItemDetailsDialog}
              removeItem={removeItem}
              seatNo={seatNo}
              position={seat}
              setTableSeat={setTableSeat}
              devices={devices}
            />
            <Divider />
          </Fragment>
        ))
      ) : (
        <h4 key={seat}>
          <Text id="no_products" />
        </h4>
      )}
    </>;
  }
)

export default SeatEntry
