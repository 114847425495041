import {
  SET_PRODUCT_COUNT,
  SET_CALCULATOR,
  BARCODE_SCANNER,
  SHOW_DEPOSIT_PRODUCTS,
  SET_PRODUCT_SEARCH_INPUT,
  RUN_PRODUCT_SEARCH
} from './actionTypes'

export const setProductCount = count => ({
  type: SET_PRODUCT_COUNT,
  payload: count
})

export const setCalculator = value => ({
  type: SET_CALCULATOR,
  payload: value
})

export const barcodeScanner = () => ({
  type: BARCODE_SCANNER
})

export const enableDepositProductsView = () => ({
  type: SHOW_DEPOSIT_PRODUCTS
})

export const setProductSearchInput = value => ({
  type: SET_PRODUCT_SEARCH_INPUT,
  payload: value
})

export const runProductSearch = value => ({
  type: RUN_PRODUCT_SEARCH,
  payload: value
})
