import track from 'react-tracking'
import AppWrapper from './AppWrapper'
import { AppInstances } from './utils/countrSdkInstance'

const logEvent = async ev => {
  const countr = await AppInstances.getCountrSdk()
  const { merchant, event, data } = ev

  countr.a.ev({
    u: merchant,
    e: `webpos:${event}`,
    p: data
  })
}

const TrackedApp = track(
  { app: `Countr ${process.env.REACT_APP_ERROR_SOURCE}` },
  {
    dispatch: data => {
      logEvent(data)
    }
  }
)(AppWrapper)

export default TrackedApp
