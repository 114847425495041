import { memo } from 'react';
import { Text } from 'react-internationalization'
import { Input } from '@countr/ui'

import './CartItemDetails.scss'

const CartItemNoteComponent = memo(({ note, handleChangeNote }) => {
  return <Input value={note} label={<Text id="note" />} onChange={handleChangeNote} />
})

export default CartItemNoteComponent
