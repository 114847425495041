import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import appReducer from './reducers/app'
import userReducer from './reducers/user'
import loadingReducer from './reducers/loading'
import productsReducer from './reducers/products'
import cateogriesReducer from './reducers/categories'
import employeesReducer from './reducers/employees'
import taxesReducer from './reducers/taxes'
import transactionsReducer from './reducers/transactions'
import devicesReducer from './reducers/devices'
import cartsReducer from './reducers/carts'
import customersReducer from './reducers/customers'
import settingsReducer from './reducers/settings'
import paymentsReducer from './reducers/payments'
import QueueReducer from './reducers/queue'
// import { logger } from './middlewares/logger'

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  loading: loadingReducer,
  products: productsReducer,
  categories: cateogriesReducer,
  employees: employeesReducer,
  taxes: taxesReducer,
  transactions: transactionsReducer,
  devices: devicesReducer,
  carts: cartsReducer,
  customers: customersReducer,
  settings: settingsReducer,
  payments: paymentsReducer,
  queue: QueueReducer
})

let enhancer = {}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SHOW_REDUX_LOGS === 'true') {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  enhancer = composeEnhancers(applyMiddleware())
}

const configureStore = () => {
  return createStore(rootReducer, enhancer)
}

export default configureStore
