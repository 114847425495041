import { AppInstances } from './countrSdkInstance'
import store from './../index'

export default class ErrorUtils {
  static logError = async obj => {
    if (!store.getState()?.devices?.device?.store?._id || !store.getState()?.user?.user?._id) {
      return
    }

    const merchantId = store.getState().user.user._id
    const deviceId = store.getState().devices.device._id
    const storeId = store.getState().devices.device.store._id

    const errorObj = {
      source: process.env.REACT_APP_ERROR_SOURCE,
      message: obj.msg,
      user: merchantId,
      store: storeId,
      device: deviceId,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }
}
