import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import Table from './Table'
import Static from './Static'
import MergingTablesModal from './MergingTablesModal'

import './TableLayout.scss'

const Floorplan = memo(function Floorplan({
  floorplan,
  handleClose,
  handleUnlinkedCart,
  handleError,
  coverSettings,
  seatSettings,
  mergeTables,
  handleSetMergeTablesState
}) {
  const selectedCart = useSelector(state => state.carts.selectedCart)
  const [tableToMerge, setTableToMerge] = useState({})

  const handleCloseMergeDialog = () => {
    setTableToMerge({})
  }

  const handleFinishMerge = () => {
    handleCloseMergeDialog()
    handleSetMergeTablesState()
    handleClose()
  }

  return (
    <div className={mergeTables ? 'floorplan merge-tables-bgr' : 'floorplan'}>
      <div>
        {floorplan.tables.map(table => (
          <Table
            key={table._id}
            table={table}
            selectedCart={selectedCart}
            handleCloseFloorplan={handleClose}
            handleUnlinkedCart={handleUnlinkedCart}
            handleError={handleError}
            coverSettings={coverSettings}
            seatSettings={seatSettings}
            mergeTables={mergeTables}
            handleTableToMerge={setTableToMerge}
          />
        ))}
      </div>
      <div>
        {floorplan.statics.map(staticObj => (
          <Static key={`${staticObj._id}`} staticObj={staticObj} />
        ))}
      </div>

      <MergingTablesModal
        openMergeDialog={!!tableToMerge?._id}
        handleCloseMergeDialog={handleCloseMergeDialog}
        tableToMerge={tableToMerge}
        handleFinishMerge={handleFinishMerge}
      />
    </div>
  )
})

export default Floorplan
