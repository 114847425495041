export const CountrRequest = (function () {
  const URL = process.env.REACT_APP_API_SERVER
  const NO_ACCESS_TOKEN = 'NO_ACCESS_TOKEN'

  return {
    login: async function (username, password) {
      if (this.getToken() !== '') {
        return Promise.resolve('TOKEN')
      }

      const request = new Request(`${URL}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        form: {
          username: username,
          password: password,
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          grant_type: 'password'
        }
      })

      try {
        return fetch(request)
          .then(response => response.json())
          .then(data => {
            this.setToken(data.access_token)
          })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    request: async function (method, api, path, body) {
      const token = this.getToken()
      if (!token) {
        return Promise.reject(NO_ACCESS_TOKEN)
      } else {
        const request = new Request(`${URL}/${api}${path !== undefined ? `/${path}` : ''}`, {
          method,
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })

        try {
          return fetch(request)
            .then(response => response.json())
            .then(resource => {
              return Promise.resolve(resource)
            })
        } catch (error) {
          return Promise.reject(error)
        }
      }
    },
    getToken: function () {
      return localStorage.getItem('access_token')
    },
    setToken: function (token) {
      localStorage.setItem('access_token', token)
    }
  }
})()
