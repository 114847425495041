import { memo } from 'react';
import { connect } from 'react-redux'
import { setCalculator } from '../../../store/actions/products'
import KeyBoardComponent from '../../generic/KeyboardComponent'
import './Calculator.scss'

const mapStateToProps = state => {
  return {
    calculator: state.products.calculator
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCalculator: value => dispatch(setCalculator(value))
  }
}

const Calculator = memo(function Calculator({ calculator, setCalculator }) {
  const changeValue = number => {
    if (number === 'C') {
      setCalculator(0)
    } else {
      const value = calculator.toString() + number
      setCalculator(parseInt(value, 10))
    }
  }

  return (
    <div className="calculator toolbar">
      <div className="calculator-result-inner">{calculator}</div>
      <KeyBoardComponent handleChange={changeValue} limitedHeight={true} />
    </div>
  )
})

const CalculatorConnected = connect(mapStateToProps, mapDispatchToProps)(Calculator)
export default CalculatorConnected
