/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const frLang = {
  student: 'étudiant',
  id_check_alert: 'Client : {{customer}}. Veuillez vérifier les identifiants suivants des clients : {{id}}.',
  id_check: 'Vérification d\'identité',
  cart: 'Panier',
  item: 'Article',
  items: 'Articles',
  subtotal: 'Sous-total',
  discount: 'Remise',
  total: 'Total',
  pay: 'Payer',
  card: 'Carte Bancaire',
  cash: 'Espèces',
  invoice: 'Facture',
  giftcard: 'Carte Cadeau',
  countr_giftcard: 'Countr Carte Cadeau',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Mode de paiement',
  cash_received: 'Montant reçu',
  or_enter_amount: 'Ou entrez le montant',
  payment_succeeded: 'Paiement réussi',
  change_due: 'Rendu',
  total_paid: 'Total payé',
  send_receipt: 'Envoyer reçu',
  send_invoice: 'Envoyer facture',
  send: 'Envoyer',
  delete: 'Effacer',
  new_sale: 'Nouvelle vente',
  custom_price: 'Prix personnalisée',
  item_description: '(Pas obligatoire) description de l\'article et nom',
  products: 'Produits',
  transactions: 'Transactions',
  reports: 'Rapports',
  report: 'Rapport',
  settings: 'Paramètres',
  sign_out: 'Se déconnecter',
  receipt: 'Reçu',
  refund: 'Rembourser',
  home: 'Home',
  clear: 'Effacer',
  add: 'Ajouter',
  sign_up: 'Inscription',
  sign_up_email: 'E-mail d\'inscription',
  sign_in: 'Connection',
  company_name: 'Nom de l\'entreprise',
  merchant_name: 'Contact',
  email_address: 'E-mail',
  phone_number: 'Numéro de téléphone',
  password: 'Mot de passe',
  confirm_password: 'Confirmez le mot de passe',
  forgot_password_question: 'Mot de passe oublié?',
  next: 'Suivant',
  demo: 'Démo',
  skip: 'Passer',
  skip_registration: 'Accès direct',
  take_a_tour: 'Faire un tour',
  items_returned: 'Les articles retournés',
  wrong_billing: 'Facturation Erronée',
  order_cancelled: 'Commande annulée',
  other: 'Autre',
  enter_valid_amount: 'Saisir un montant valide',
  invalid_merchant_name: 'Nom du marchand incorrecte',
  invalid_business_name: 'Nom d\'entreprise incorrecte',
  invalid_email_address: 'E-mail incorrecte',
  invalid_phone_number: 'Numéro de téléphone incorrecte',
  invalid_password: 'Mot de passe incorrecte, il doit être d\'au moins 8 caractères',
  password_mismatch: 'Les mots de passe ne correspondent pas',
  coming_soon: 'À venir ...',
  to_use_signup: 'Pour utiliser cette fonction, vous devez être inscript',
  login_error: 'Erreur d\'identification',
  signup_error: 'Erreur d\'inscription',
  transaction_history_empty: 'Historique des transactions vide',
  categories: 'Catégories',
  all_products: 'Tous les produits',
  search_product: 'Chercher un produit',
  reissue_receipt: 'Reçu réédition',
  print_receipt: 'Imprimer reçu',
  or_send_receipt: 'Ou envoyez reçu',
  save: 'Enregistrer',
  saved: 'Enregistré',
  search: 'Recherche',
  custom_item: 'Article personnalisée',
  submit: 'Soumettre',
  submitted: 'Soumis',
  note: 'Note',
  message: 'Message',
  amount: 'Montant',
  number: 'Nombre',
  taxes: 'Taxes',
  tax: 'Taxe',
  enter_valid_number: 'S\'il vous plaît entrer un numéro valide',
  enter_valid_text: 'S\'il vous plaît entrer le texte valide',
  enter_valid_phone: 'Entrez un numéro de téléphone valid',
  enter_valid_email: 'Entrer une adresse e-mail valide',
  feature_not_in_demo: 'Cette fonctionnalité n\'est pas disponible en mode démo',
  provide_refund_reason: 'Vous devez sélectionner une raison pour le remboursement',
  provide_valid_refund_amount: 'Vous devez entrer un montant de rendu valide',
  refund_exceed_refundable: 'Remboursement dépasse le montant maximum remboursable pour ce reçu',
  refund_saved: 'Remboursement enregistré',
  or_customize_discount: 'Ou remise personnalisée',
  enter_percentage: 'Entrez pourcentage',
  manual_card_payment: 'Paiement via TPE non-connecté',
  did_card_payment_succeed: 'Paiement CB confirmé?',
  manual_card_refund: 'Remboursement via TPE non-intégrée',
  did_card_refund_succeed: 'Remboursement via TPE confirmé?',
  giftcard_refund: 'Remboursement de la carte-cadeau',
  did_giftcard_refund_succeed: 'Nouvelle carte-cadeau fournie au client?',
  yes: 'Oui',
  no: 'Non',
  general: 'Général',
  printer: 'Imprimante',
  barcode_scanner: 'Lecteur de code barre',
  payment: 'Paiement',
  profile: 'Profil',
  currency: 'Devise',
  printing_receipt: 'Impression en cours...',
  enter_amount: 'Saisissez le montant',
  expected_change: 'Rendu',
  still_remaining: 'Reste à payer',
  issue_invoice: 'Emettre facture',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Adresse',
  address_continued: 'Adresse [option]',
  city: 'Ville',
  zip: 'Code postal',
  country: 'Pays',
  country_iso: 'Pays au format ISO 3166-2 (par exemple "nl", "de", "gb")',
  invalid_address: 'Adresse invalide ou vide',
  invalid_city: 'Ville invalide ou vide',
  invalid_country: 'Pays non valide ou vide',
  invalid_ecommerce: 'Aucune plateforme e-commerce choisi',
  signup_error_contact_support: 'Erreur d\'inscrivez, s\'il vous plaît contacter support@countrhq.com',
  open_orders: 'Commandes en cours',
  order: 'Commande',
  enter_weight_g: 'Entrez le poids (g)',
  search_scanner: 'Recherche scanner Socket CHS',
  order_settings: 'Paramètres de commande',
  order_amount: 'Nombre de commandes ouvertes disponibles',
  receipts: 'Reçus',
  print_receipts: 'Imprimer les reçus',
  open_cash_drawer: 'Ouvrir automatiquement le tiroir-caisse',
  backup: 'Sauvegarde',
  email_backup: 'Email sauvegarde',
  server_sync: 'Synchronisation du serveur',
  external_payment: 'Autre terminal de paiement par carte',
  search_printer: 'Rechercher imprimante',
  sms_successfully_sent: 'SMS envoyé avec succès',
  error_sending_sms: 'Erreur d\'envoi de SMS, réessayez plus tard',
  email_successfully_sent: 'E-mail envoyé avec succès',
  error_sending_email: 'Erreur d\'envoi d\'e-mail, réessayez plus tard',
  transaction: 'Transaction',
  logging_in: 'Connexion en cours...',
  downloading_products: 'Téléchargement produits ...',
  add_to_cart: 'Ajouter',
  invalid_price: 'Prix invalide',
  invalid_discount: 'Remise invalide',
  invalid_new_price: 'Nouveau prix invalide - doit être moins de prix d\'origine',
  invalid_amount: 'Montant invalide',
  edit_price: 'Modifier prix',
  discount_perc: 'Remise (%)',
  quantity: 'Quantité',
  remove: 'Effacer',
  sales_info: 'Info ventes',
  summary: 'Sommaire',
  refunded_information: 'Informations des remboursements',
  total_sale: 'Vente totale',
  total_amount_refunded: 'Total rendu',
  remaining: 'Restant',
  sold_by: 'Vendu par',
  source_receipt_number: 'Source numéro de reçu',
  issued_date: 'Date d\'edition',
  issued_time: 'Heure d\'edition',
  refunded_by: 'Remboursé par',
  reason: 'Raison',
  receipt_number: 'Numéro de reçu',
  refunded_date: 'Date remboursé',
  refunded_time: 'Temps remboursé',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  shop_category: 'Catégorie de la boutique',
  choose_country: 'Choisissez un pays ...',
  you_will_receive_email: 'Félicitations, votre inscription est fini',
  wait_email: 'Vous allez maintenant recevoir un email avec des instructions pour connecter votre plateforme e-commerce avec Countr POS.',
  open_dashboard: 'Vous pouvez maintenant gérer votre compte et créer des produits dans votre tableau de bord de Countr.',
  by_signing_up: 'En vous inscrivant Je accepte la',
  terms_and_conditions: 'Termes et conditions',
  and: 'et',
  privacy_policy: 'Politique de confidentialité',
  refund_items: 'Rembourser articles',
  forgot_password: 'Mot de passe oublié?',
  register_device: 'Enregistrez appareil',
  device_not_registered: 'Cet appareil n\'est pas enregistrez',
  want_to_add_to_account: 'L\'enregistrez maintenant?',
  if_ios_previously_registered: 'Si vous avez récemment réinstallé Countr, vous devez vous enregistrer à nouveau votre tablette.',
  new_device_info: 'Vous serez invité à l\'écran prochaine de lier votre tablette à un de vos magasins',
  not_now: 'Pas maintenant',
  proceed: 'Continuer',
  more_info_click: 'Plus d\'information? Cliquez ici',
  your_detail: 'Vos détails',
  vat_no: 'Numéro de TVA',
  billing_details: 'Les détails de facturation',
  add_store: 'Ajouter magasin',
  or_add_to_existing_store: 'Ou ajouter à bestaande magasin',
  add_new_store: 'Ajouter nouveau magasin',
  store_information: 'Informations de magasin',
  store_name: 'Nom du magasin',
  go_to_web_dashboard: 'Aller au tableau de bord web',
  error_create_account: 'Email déjà enregistré.',
  error_creation_error: 'Une erreur est survenue pendant enregistration',
  error_wrong_phone_number: 'S\'il vous plaît entrez un numéro de téléphone valide.',
  error_try_again: 'Problème de connexion. S\'il vous plaît essayer à nouveau.',
  error_from_the_server: 'Impossible d\'établir connexion avec le serveur.',
  error_update_cc_info: 'Vous ne pouvez pas mettre à jour les informations de carte de crédit.',
  error_add_device: 'Impossible d\'ajouter appareil au magasin actuel.',
  error_add_store: 'Vous ne pouvez pas ajouter un nouveau magasin.',
  are_not_valid: 'ne sont pas valides',
  is_not_valid: 'n\'est pas valide',
  are_empty: 'sont vides',
  is_empty: 'est vide',
  are_not_selected: 'ne sont pas sélectionnés',
  is_not_selected: 'n\'est pas sélectionné',
  bill: 'Facture',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Fermer',
  no_product_found: 'Aucun produit trouvé',
  please_go_to_dashboard: 'Pour ajouter des produits, visitez https://backoffice.countrhq.com',
  select_interface: 'Sélectionner l\'interface',
  refund_all: 'Rembourser tout',
  purchased_items: 'Les articles achetés',
  refundable: 'Remboursable',
  to_be_refunded: 'Pour être remboursé',
  out_of: 'en dehors de',
  enter_a_printer_name: 'Entrez un nom d\'imprimante',
  cart_is_empty: 'Le panier est vide',
  searching_printers: 'Rechercher d\'imprimante en cours',
  no_printers_found: 'Aucune imprimante trouvée.',
  total_due: 'Total à payer',
  payment_not_successful: 'Paiement abandonné.',
  error_processing_payment: 'Erreur de traitement de paiement.',
  purchase: 'Achat',
  transaction_aborted: 'Transaction annulée',
  authorization_error: 'Erreur d\'autorisation',
  authorization_ok: 'Autorisation OK',
  transaction_cancelled: 'Transaction annulée',
  clearance_error: 'Erreur de clearing par le processeur',
  clearance_ok: 'Clearing OK confirmé par le processeur',
  transaction_declined: 'Transaction refusée',
  transaction_complete: 'Transaction finie',
  initiating: 'Lancement',
  application_selected: 'Application sélectionnée',
  scheduled_for_clearance: 'Prévu pour le hors ligne clearing du processeur',
  terminal_error: 'Erreur terminal',
  unknown_status: 'Statut inconnu',
  waiting_for_clearance: 'En attente de confirmation hors ligne de clearing de processeur',
  amount_exceeded: 'Montant maximum dépassé',
  authorization_timeout: 'Timeout d\'attente d\'autorisation',
  batch_processing_failed: 'Le traitement par lots a échoué',
  cancelled: 'Annulé',
  card_blocked: 'Carte bloquée',
  card_invalid: 'Carte invalide',
  card_declined: 'Carte refusée',
  transaction_failed: 'La transaction a échoué',
  insufficient_funds: 'L\'insuffisance des fonds',
  terminal_not_paired: 'Terminal pas connecté',
  pin_incorrect_last_retry: 'PIN erroné -dernière tentative',
  pin_incorrect_retry: 'PIN erroné - ressayez',
  printer_busy: 'Imprimante occupée',
  printer_error: 'Erreur d\'imprimante',
  printer_not_present: 'Imprimante pas présente',
  printer_out_of_paper: 'pas de papier',
  success: 'Succès',
  system_error: 'Erreur système',
  transaction_not_found: 'Transaction non trouvée',
  unknown_device: 'Appareil inconnu',
  apply_discount: 'Appliquer remise',
  seqr_id: 'SEQR Merchant ID',
  seqr_password: 'SEQR Merchant mot de passe',
  bitpay_api_token: 'Bitpay API token',
  registration_successful: 'Terminal enregistré',
  unregistration_successful: 'Desinscription validée ;',
  payment_init_not_successful: 'Paiement non initialisée - vérifiez la connexion réseau.',
  always_show_addons: 'Toujours afficher les extras',
  show_product_image: 'Voir l\'image du produit',
  hide_product_img: 'Masquer l\'image du produit',
  settings_connected_printers: 'Imprimante connectées',
  settings_add_new_printer: 'Ajouter nouvelle imprimante',
  wifi_lan: 'WiFi ou LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Lecteur(s) de code barre connecté(s)',
  account: 'Compte',
  refresh_products: 'Actualiser les produits',
  server_synchronisation: 'Synchronisation du serveur',
  sales: 'Ventes',
  today: 'Aujourd\'hui',
  refunds: 'Remboursements',
  product: 'Produit',
  product_by_category: 'Produits par catégorie',
  see_categories_details: 'Voir les détails par catégories',
  open_orders_finish_payment_or_empty: 'Commande en cours.Valider le paiement ou vider le(s) panier(s).',
  name: 'Nom',
  loading_products: 'Chargement des produits',
  you_should_select_at_least_one_item: 'Vous devez sélectionner au moins un article',
  select_card_provider: 'Sélectionnez un fournisseur de carte',
  user_cancelled: 'Paiement annulé par le client',
  card_payment: 'Paiement par carte',
  manage_products: 'Gérer les produits',
  edit_mode: 'Mode édition',
  lock: 'verrouiller',
  loyalty: 'Fidélité',
  account_card: 'Carte de compte',
  account_lookup: 'Trouver compte',
  Activate: 'Activer',
  add_anonymous_customer: 'Ajouter client anonyme',
  add_customer: 'Ajouter client',
  add_customer_to_cart: 'Choisissez client',
  add_new_account: 'Ajouter un nouveau compte',
  add_new_customer: 'Ajouter un nouveau client',
  add_note_hint: 'Ajouter une note',
  add_tax_to_price: 'Prix hors taxe',
  add_tip: 'pourboire',
  add_to_store: 'Ajouter magasin',
  addons: 'Extras',
  admin_pin: 'PIN Admin',
  admin_pin_saved: 'Nouveau PIN admin enregistré',
  admin_pin_should_be: 'Administrateur PIN doit être un nombre compris entre 4 et 6 chiffres.',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Autoriser des rabais sur les paniers',
  allow_custom_cart_discounts: 'Autoriser des rabais personnalisées sur les paniers',
  allow_custom_items: 'Autoriser des éléments personnalisés',
  allow_product_discounts: 'Autoriser des rabais sur les produits',
  allow_custom_product_discounts: 'Autoriser des rabais personnalisées sur les produits',
  amount_hint: '999',
  app_version: 'Version Countr',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Activer les pourboires',
  authorizationCode: 'authorizationCode',
  available_points: 'Points disponibles',
  available_rewards: 'Récompenses disponibles',
  back: 'Retour',
  barcode_scanner_disconnected: 'Votre lecteur de codes barres est connecté, mais il peut être désactivé. Essayez de le mettre en marche.',
  barcode_scanner_not_found: 'Aucun scanner de code barre trouvé.',
  barcode_scanner_not_found_desc: 'Connectez un nouveau scanner - activez le bluetooth dans les paramètres de votre appareil et cherchez le scanner avec le bouton de recherche ci-dessous',
  bunq: 'bunq',
  bunq_admin_approval: 'Un administrateur de bunq examinera et approuvera votre caisse enregistreuse avant de pouvoir être utilisé.',
  bunq_instructions: '1) Ouvrir bunq et saisir le code PIN (si nécessaire)\n2) Choisissez "Scan QR code" / "QR" à partir écran principal\n3) Scannez le code à l\'écran\n4) Ajouter une gratification si désiré\n5) Confirmer le paiement',
  can_only_refund_entire_amount: 'Remboursement montant total via {{payment_method}}',
  cancel: 'Arrêter',
  cancel_payment: 'Annuler le paiement',
  cannot_pay_later_more_than_remaining_value: 'Vous ne pouvez pas payer plus que la valeur restante ({{amount}}) avec la méthode de rémunération plus tard.',
  cannot_refund_transaction_with_partial_postponed_payments: 'Désolé, cette transaction contient un ou plusieurs paiements partiels reportés, payer la transaction première ou rembourser tout',
  cant_find_products: 'Vous ne trouvez pas tous vos produits? Utilisez la boîte de recherche ci-dessus ou d\'un scanner de codes à barres pour les trouver.',
  card_scheme: 'Card Scheme',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Carte mois d\'expiration MM',
  cardExpiryYear: 'Carte année d\'expiration YY',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'Le panier est gelé. Terminer le paiement avant toute action.',
  cash_difference: 'Différence',
  cash_drawer: 'Tiroir-caisse',
  cash_in: 'Espèces entrant',
  cash_in_out: 'Espèces in / out',
  cash_out: 'Espèces sortant',
  change: 'Monnaie',
  change_currency: 'Changer de devise',
  change_given: 'Monnaie donné',
  change_open_orders_amount: 'Changer le nombre de ordres ouvertes',
  check_location_settings: 'Vérifiez les paramètres de localisation',
  check_network: 'Vérifiez la connexion réseau',
  choose_category: 'Choisissez une catégorie d\'affaires ...',
  choose_mobile_provider: 'Plusieurs fournisseurs ont permis - s\'il vous plaît choisir un',
  clear_settings: 'Effacer les paramètres',
  clear_settings_successful: 'Avec succès effacé les paramètres.',
  clear_settings_warning: 'L\'effacement de ces paramètres permet de réinitialiser vos informations de connexion. Cliquez confirmer pour continuer.',
  client_ticket: 'clientTicket',
  close_shift: 'Fermer caisse',
  company_address: 'Adresse d\'affaires',
  company_registration_no: 'Numéro d\'entreprise',
  completed_payments: 'Paiements complétés',
  confirm: 'Confirmer',
  confirm_cash_payment: 'Confirmer le montant du paiement en espèces',
  confirm_new_currency: 'Confirmer le changement de devise',
  confirm_open_orders_amount_change: 'Confirmer changement de nombre de ordres ouvertes?',
  confirm_payment: 'Confirmer le paiement',
  confirm_with_change: 'Confirmer avec change',
  confirm_with_tip: 'Confirmer avec pourboire',
  connect_barcode: 'Connectez scanner de codes à barres (Socket CHS)',
  connect_to_webshop: 'Voulez-vous vous connecter à votre boutique en ligne?',
  connected_printer: 'Imprimantes connectées',
  contact_information: 'Coordonnées',
  contact_us: 'support@countrhq.com',
  create_product: 'Créez un produit',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'Le POS est actuellement en mode test. Vos transactions seront effacées toutes les quatre heures.',
  currently_trial_mode_visit_dashboard: 'Le POS est actuellement en mode test. Vous pouvez passer en mode direct en visitant votre tableau de bord en ligne.',
  customer: 'Client',
  customer_card: 'Cart de client',
  customer_info: 'Informations client',
  customer_instructions: 'Instructions à la clientèle',
  customer_lookup: 'Trouver client',
  customer_lookup_error: 'Erreur de serveur',
  customer_name: 'Client',
  customer_not_valid: 'Client pas valide',
  customer_receipt: 'Reçu client',
  customer_since: 'Client depuis',
  Date: 'Date',
  deregister_terminal: 'Supprimer Terminal',
  description_mandatory: 'Une description est obligatoire pour la trésorerie et les opérations d\'encaissement.',
  device_locked: 'L\'appareil est verrouillé',
  device_locked_enter_pin: 'Cet appareil est verrouillé.\nVeuillez entrer votre PIN admin pour le déverrouiller',
  did_cheque_refund_succeed: 'Chèque retourné au client?',
  did_mobile_payment_succeed: 'Paiement mobile t-il réussi?',
  did_mobile_refund_succeed: 'Remboursement mobile t-il réussi?',
  direct_connection: 'Directement connecté',
  disabled_product: 'Produit désactivé',
  discount_currency: 'Rabais ({{currency}})',
  do_you_want_remove_payment: 'Voulez-vous supprimer le paiement?',
  download_adyen_app: 'Download Adyen App',
  download_izettle_app: 'Download iZettle App',
  download_payleven_app: 'Download Payleven App',
  download_sumup_app: 'Download Sumup App',
  download_yodo_app: 'Télécharger Yodo Rocket app',
  downloading_categories: 'Téléchargement catégories ...',
  downloading_taxes: 'Téléchargement des impôts ...',
  downloading_transactions: 'Téléchargement des transactions ...',
  ecommerce_none: 'Je vais créer des produits moi-même',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Email facture',
  employee_name: 'Employé',
  employee_pin: 'PIN employé',
  empty_cart: 'Vider le panier',
  enable_staff_pin: 'Activer connection NIP',
  enter_a_valid_search_value: 'Entrez une valeur de recherche valide',
  enter_api_key: 'Entrez votre clé API',
  enter_base_url: 'Entrez votre base URL',
  enter_merchant_auth_code_payconiq: 'Enter your Payconiq Authorization header',
  enter_merchant_iban_payconiq: 'Enter your Payconiq-registered IBAN',
  enter_merchant_id_payconiq: 'Enter your Payconiq Merchant ID',
  enter_order_name: 'Entrez un nom d\'ordre',
  enter_reseller_id: 'Entrez votre Reseller ID',
  enter_user_number: 'Entrez votre User Number',
  enter_user_password: 'Entrez votre User Password',
  error_adding_device: 'Une erreur est survenue lors de l\'inscription de votre appareil.',
  error_refreshing_merchant: 'Erreur lors de la récupération des informations de compte.',
  error_retrieve_categories: 'Erreur lors de la récupération des catégories. \nS\'il vous plaît vérifier votre connexion Internet et essayez à nouveau',
  error_retrieve_products: 'Erreur lors de la récupération des produits. \nS\'il vous plaît vérifier votre connexion Internet et essayez à nouveau',
  error_retrieve_taxes: 'Erreur lors de la récupération des impôts. \nS\'il vous plaît vérifier votre connexion Internet et essayez à nouveau',
  error_while_retrieving_results: 'Erreur lors de la récupération des résultats',
  errorCode: 'errorCode',
  errorMessage: 'errorMessage',
  ersReference: 'ersReference',
  excess_amount: 'Montant extra',
  expected_amount: 'Montant prévu',
  expected_change_gratuity: 'Rendu ou pourboire',
  external_url: 'URL externe',
  failed_cancel: 'Impossible d\'annuler la transaction',
  feature_only_android: 'Cette fonctionnalité est uniquement disponible pour Android',
  find_terminal: 'Recherche terminaux',
  for_security_enter_password: 'Pour des raisons de sécurité, s\'il vous plaît entrer de nouveau votre mot de passe',
  for_this_device: 'Seulement pour cet appareil',
  for_this_store: 'Seulement pour ce magasin',
  four_inch: '4 inch',
  generate_keys: 'Générer des clés',
  generating_keypair: 'Génération paire de clés ... \nCela peut prendre plusieurs minutes',
  generating_reports: 'Génération de rapports...',
  generic_usb: 'USB générique',
  get_your_free_pos: 'Obtenez votre POS sur www.countrhq.com',
  gift_code: 'Code cadeau',
  gratuity_amount: 'Montant de pourboire',
  gratuity_percentage: 'Percentage de pourboure',
  group_items_in_cart: 'Groupe mêmes éléments ensemble dans le panier',
  shared_secret: 'Secret partagé - entrez uniquement si le terminal énumérés ci-dessus',
  impossible_refresh_you_have_transactions: 'Impossible de rafraîchir. \nVous avez {{transaction_number}} transaction(s) non synchronisé avec le serveur. \nAssurez vous avez une connexion Internet, attendez pour eux de synchroniser et d\'essayer à nouveau dans quelques instants.',
  impossible_signout_you_have_transactions: 'Impossible de se déconnecter. \nVous avez {{transaction_number}} transaction(s) non synchronisé avec le serveur. \nAssurez vous avez une connexion Internet, attendez pour eux de synchroniser et d\'essayer à nouveau dans quelques instants.',
  insert_printer_name: 'S\'il vous plaît insérer un (3 caractères ou plus) nom pour votre imprimante.',
  inserted_login_not_valid: 'Identifiant incorrect',
  inserted_value_not_valid: 'Valeur n\'est pas valide',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Numéro de carte de crédit doit être de 16 chiffres',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'Adresse IP',
  is_business: 'Est-ce une entreprise?',
  is_correct_amount_tendered: 'Est {{amount}} le montant correcte?',
  isDone: 'Pas plus de charger ...',
  isError: 'Tapez ici pour réessayer ...',
  isTap: 'Tapez ici pour charger plus ...',
  join_thousands_of_merchants: 'Milliers d\'entreprises ont rejoint Countr. Aujourd\'hui, il est à votre tour. Inscription gratuite',
  language: 'Langue',
  last_visit: 'Visite précédente',
  leave_tour: 'Départ de démo',
  leaving_tour: 'Laissant le démo',
  loyal_customer: 'Client fidèle',
  mac_address: 'Adresse MAC',
  mailing_list_permission: 'Ajouter à la liste email',
  manual_mobile_payment: 'Paiement mobile',
  manual_mobile_refund: 'Remboursement mobile',
  max_amount_exceeded: 'Montant dépasse le maximum autorisé pour ce mode de paiement',
  menu_item_12char: 'Nom article de menu ne peut pas être plus de 12 caractères',
  menu_item_name: 'Nom article de menu (max. 12 caractères)',
  merchant_receipt: 'Reçu de magasin',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'merchantID',
  mid: 'MID',
  mobile_number: 'Numéro mobile',
  mobile_payment: 'Mobile',
  mobile_payments: 'Paiements mobiles',
  new_price: 'Nouveau prix',
  no_available_addons_for_product: 'Aucun add ons disponibles pour le produit sélectionné',
  no_connected_printer: 'Aucune imprimante(s) connectée',
  no_connected_terminal: 'Aucun terminal connecté',
  no_customer_found: 'Aucun client trouvé',
  no_customer_selected: 'NA',
  no_printer_configured: 'Aucune imprimante configurée',
  no_printer_found: 'Aucune imprimante trouvée \nCliquez ici si vous avez une imprimante Star Micronics USB connecté',
  no_product_in_cart: 'Aucun produit dans le panier',
  no_terminals_found: 'Pas de terminaux trouvés',
  no_url_specified: 'Erreur, aucune URL spécifiée.',
  none: 'Aucun',
  note_mandatory_cash_difference: 'Une note est obligatoire s\'il y a une différence entre les montants attendus et obtenus',
  note_optional: 'Note (en option)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Pavé numérique',
  offline: 'Offline',
  ok: 'D\'accord',
  one_or_more_carts_are_frozen_finish_payment: 'Un ou plusieurs paniers sont gelés. Terminer le paiement avant toute action.',
  online: 'Online',
  open_market: 'Voulez-vous ouvrir l\'app location?',
  open_register_before_transaction: 'Vous devez ouvrir le registre avant de pouvoir faire des transactions',
  open_shift: 'Ouvrir',
  order_printer: 'Imprimante pour les ordres',
  orderId: 'orderID',
  orderRef: 'orderRef',
  other_text: 'Autre texte',
  paid: 'Payé',
  paid_so_far: 'Payé jusqu\'à présent',
  paid_with_method: 'Payé [{{method}}]',
  pan: 'PAN',
  panLastDigits: 'Pan Last Digits',
  panMasked: 'Pan Masked',
  PANSeq: 'PANSeq',
  partial_payment: 'Paiement partiel',
  partial_payment_not_allowed_for: 'Les paiements partiels ne sont pas autorisés pour',
  partial_payment_paid_successfully: 'Le paiement partiel a été payé avec succès',
  pay_later: 'Payer plus tard',
  pay_later_allows_only_refund_all: 'Avec le paiement plus tard, vous pouvez seulement rembourser la totalité. Pour les remboursements partiels compléter le paiement s\'il vous plait.',
  pay_later_refund: 'Annuler payer plus tard',
  pay_on_account: 'Payez avec compte client',
  payconiq: 'Payconiq',
  paying_now: 'Payer maintenant',
  payment_cancelled: 'Paiement annulé',
  payment_error: 'Erreur de paiement',
  payment_info: 'Info de paiement',
  payment_insert_card: 'Insérer la carte',
  payment_method_not_configured: '{{payment_method}} pas correctement configuré sur cet appareil',
  payment_number: 'Nombre de paiement',
  payment_processor_options: 'Paramètres de paiement',
  payment_provider: 'Prestataire de services de paiement',
  payment_success: 'Paiement avec succès',
  payment_terms_in_net_days: 'Conditions de paiement (jours nets)',
  personal_information: 'Informations personnelles',
  pickup_rewards: 'Recueillir des récompenses',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Entrez un montant valide',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Imprimer',
  print_also_product_details: 'Imprimer aussi les détails du produit?',
  print_error: 'Erreur d\'imprimante',
  print_product_details: 'Imprimer les détails du produit',
  print_successful: 'Impression réussie',
  printer_connect_desc: 'Les imprimantes WiFi doivent être connectées au même réseau que cet appareil. \nImprimantes Bluetooth doivent être jumelées via les paramètres du système avant d\'être disponible ici',
  printer_removed: 'Imprimante retirée',
  printer_test: 'Test de l\'imprimante',
  printing_report: 'Impression en cours...',
  proceed_with_next_payment: 'Procéder au paiement suivant.',
  processorName: 'processorName',
  product_is_disable_continue_anyway: 'Le produit que vous avez sélectionné est désactivé. Voulez-vous ajouter au panier de toute façon?',
  product_sync: 'Chargez des produits',
  profile_average: 'Achat moyen',
  protected_sections: 'Sections protégées',
  receipt_printer: 'Imprimante pour reçus',
  redeem_reward: 'Échangez récompense',
  referenceNumber: 'referenceNumber',
  refresh_customers: 'Actualiser la liste des clients',
  refresh_merchant: 'Actualiser les informations du compte',
  refresh_transactions: 'Actualiser les transactions',
  refreshing_merchant: 'Actualisation des informations de compte ...',
  refund_number: 'Nombre de remboursement',
  refunds_not_available_for_provider: 'Les remboursements ne sont pas disponibles pour le fournisseur: {{payment_provider}}',
  register_terminal: 'Ajouter Terminal',
  reissue: 'Rééditer',
  remaining_payment: 'Paiement restant',
  remove_customer_from_cart: 'Retirer du panier',
  remove_payment: 'Retirer le paiement',
  remove_printer: 'Supprimer imprimante',
  removing_products: 'Actualisation des produits ...',
  reopen_shift: 'Rouvrir',
  replace_customer: 'Remplacer client',
  report_by_shift: 'Activer les rapports d\'ouverture et fermeture',
  report_printer: 'Imprimante pour les rapports',
  requested_amount: 'Montant demandé',
  reward: 'Récompenses',
  round_cash_transactions: 'Transactions en espèces arrondies à 0,05',
  save_printer: 'Enregistrer imprimante',
  scan: 'Scannez',
  scan_barcode_instruction_one: 'Scannez le code à barres pour mettre le scanner Socket Bluetooth en mode d\'appairage',
  scan_barcode_instruction_two: 'Dans les paramètres de votre appareil, assurez-vous que Bluetooth est activé, puis apparier le scanner Socket CHS',
  scan_code_not_exist: 'Code scannée non reconnu: {{barcode}}',
  scan_qr_code: 'Scannez le code QR',
  scanner: 'Scanner',
  scanner_connected: 'Barcode scanner connecté',
  scanner_disconnected: 'Barcode scanner déconnecté',
  scanner_pair_completed: 'Barcode scanner appairage complété',
  search_for_customer: 'Trouver client',
  search_report: 'Chercher',
  search_transaction: 'Trouver transaction',
  searching_terminals: 'Recherche de terminal(s) ...',
  security_and_permissions: 'Sécurité et autorisations',
  select_one_payment_method: 'Au moins un mode de paiement doit être activé',
  select_payment_method: 'S\'il vous plaît sélectionner la carte AID dans la liste ci-dessous',
  select_terminal: 'Choisir un terminal',
  send_email: 'Envoyer un mail',
  send_to_another_customer: 'Envoyer à un autre client',
  send_to_current_customer: 'Envoyer au client actuelle',
  send_to_customer: 'Envoyer au client',
  sending_receipt: 'Envoi reçu ...',
  server_error: 'Erreur du serveur',
  session: 'Session',
  set_new_price: 'Nouveau prix',
  setting_saved: 'Paramètres enregistrés',
  settings_connected_terminals: 'Terminal connecté',
  settings_printer_available_printers: 'Imprimantes disponibles',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Définissez votre NIP admin dans Paramètres, sous l\'onglet Compte.',
  shift_closed: 'La caisse a été fermé',
  shift_opened: 'La caisse a été ouvert',
  shifts: 'Shifts',
  shopper_reference: 'ShopperReference',
  show_customer: 'Afficher les infos du client sur le reçu',
  show_employee: 'Afficher les infos de l\'employé sur le reçu',
  show_customer_payconiq: 'Choisir un check-in client ou scannez le code-barre / NFC du client',
  show_new_product_tile: 'Afficher la case "créer un produit"',
  show_password: 'Afficher mon mot de passe',
  sign_out_header: 'Êtes-vous sûr?',
  sign_out_warning: 'Déconnexion réinitialise tous vos paramètres de l\'application, y compris les fournisseurs de paiement et des imprimantes. S\'il vous plaît choisir d\'annuler, définir un code NIP d\'administration et utiliser la fonction de verrouillage si vous ne souhaitez pas effacer vos paramètres. Choisissez confirmer pour continuer avec le déconnection.',
  sign_up_to_print: 'Inscrivez-vous pour un compte d\'essai gratuit pour imprimer un reçu. Aucune carte de crédit nécessaire, annuler à tout moment.',
  sign_up_to_send_email: 'Inscrivez-vous pour un compte d\'essai gratuit pour envoyer un reçu par courriel. Aucune carte de crédit nécessaire, annuler à tout moment.',
  signing_out: 'Déconnecter…',
  signup_for_mailing_list: 'Demander l\'abonnement à la liste de diffusion',
  sorry_report_not_available: 'Désolé, ce rapport n\'est pas disponible, s\'il vous plaît aller au Countr dashboard pour la voir.',
  start_using_pos: 'Commencez à vendre avec Countr',
  state: 'État',
  status: 'Situation',
  store_powered_by: 'Ce magasin utilise',
  street: 'Rue',
  suggested_amount: 'Montant suggéré',
  suggested_tip: 'Pourboire suggéré',
  support: 'Support',
  telephone: 'Téléphone',
  terminal_port: 'Port configuré pour le terminal',
  terminal_selected: 'Terminal enregistré',
  terminalId: 'terminalId',
  test: 'C\'est un test',
  three_inch: '3 inch',
  tid: 'TID',
  Time: 'Temps',
  tip: 'Pourboire',
  tip_added: 'Pourboire ajouté',
  to_pay: 'À payer',
  total_amount: 'Montant total',
  transaction_code: 'transactionCode',
  transaction_date: 'DateDeLaTransaction',
  transaction_finalized: 'Transaction finalisée',
  transaction_pending: 'Transaction en attente',
  transaction_started: 'Transaction a commencé',
  transaction_succeeded: 'Transaction réussi',
  transaction_time: 'Temps de transaction',
  transaction_timeout: 'Transaction timeout',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Essayez-le maintenant',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 inch',
  uncategorized: 'Sans catégorie',
  valid_phone_number: 'Numéro de téléphone valide',
  validating_fields: 'Validation…',
  validation_in_progress: 'Validation en cours…',
  verification_code: 'Code de vérification',
  verification_method: 'Méthode de vérification',
  waiting_for_customer: 'En attente de client',
  want_to_sync_products: 'Voulez-vous synchroniser vos produits?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Vous devez sélectionner un magasin',
  add_name_device: 'Ajouter un nom à votre appareil (obligatoire)',
  card_number: 'CardNumber',
  change_language: 'Changer de langue',
  choose_currency: 'Choisissez la devise principale',
  clear_cart_after_finish: 'Réinitialiser le nom après le paiement',
  confirm_new_language: 'Confirmer la nouvelle langue? \nVous devrez fermer et recommencer complètement l\'application pour appliquer cette modification',
  customer_signature: 'Signature du client',
  day: 'Jour',
  device_name_mandatory: 'Le nom de l\'appareil est obligatoire',
  downloading_refunds: 'Téléchargement des remboursements …',
  email_report: 'Rapport par e-mail',
  email_successfully_sent_test: 'Test d\'e-mail envoyé à votre compte',
  enter_table_name: 'Entrez le nom de la table',
  error: 'faute',
  error_retrieve_refunds: 'Faute lors de la téléchargement des remboursements',
  error_retrieve_transactions: 'Faute lors de la téléchargement des transactions',
  for_this_device_see_dashboard: 'Pour cet appareil seulement - voir Dashboard pour plus de rapports',
  hide_new_sale: 'Masquer l\'écran \'Nouvelle vente\'',
  last_transaction_result: 'Dernier résultat de transaction',
  merge_warning: 'Cela va fusionner le contenu de ces paniers / tables',
  month: 'Mois',
  post_code: 'Code Postal',
  print_order: 'Afficher \'envoyer à l\'imprimante des ordres\'',
  products_added_removed_refresh: 'Les produits ont été ajoutés ou supprimés - actualiser la liste?',
  quarter: 'Trimestre',
  refresh: 'Actualiser',
  refreshing_products: 'Actualisation des produits…',
  removing_categories: 'Suppression des anciennes catégories …',
  removing_taxes: 'Suppression des anciens taxes…',
  select_interface_mandatory: 'La sélection d\'une connexion est obligatoire',
  sms_successfully_sent_test: 'Test de SMS envoyé à votre compte',
  splitOrderPrintByCategory: 'Imprimer en ordonnant par catégorie',
  stored_digitally: 'Enregistré',
  stripe_id: 'stripeID',
  sync_support_info: 'Synchroniser les informations de support',
  syncing_support_info: 'Synchronisation des informations de support …',
  table: 'Table',
  table_is_empty: 'Table est vide',
  tables_or_carts: 'Mode restaurant',
  uncertain_card_payment_success: 'Succès inconnu au paiement par carte',
  unknown_category: 'Catégorie inconnue',
  unsynced_transactions_report_incorrect: 'Vous avez {{transaction_number}} transaction(s) non synchronisée(s) avec le serveur - les rapports ne reflètent pas exactement la dernière situation',
  update_terminal_firmware: 'Mettre à jour le firmware du terminal',
  week: 'Semaine',
  year: 'Année',
  email_queued: 'Email en cours',
  order_received: 'Nouvelle commande / mise à jour reçue',
  go_to_orders: 'Afficher les commandes',
  open_carts: 'Chariots ouverts',
  auto_print_order: 'Imprimer les commandes non imprimées contre paiement',
  auto_print_order_cart: 'Imprimer les commandes non imprimées lors du changement de panier / table',
  external: 'Autre',
  no_printer_found_tcp: 'Imprimante non détectée\nCliquez ici pour saisir manuellement les informations sur l\'imprimante',
  downloading_carts: 'Téléchargement des paniers ...',
  pay_later_hybrid: 'Payer plus tard',
  error_retrieve_carts: 'Erreur lors de la récupération des paniers',
  no_ip_address: 'Pas d\'adresse',
  building_number: 'Numéro du bâtiment',
  confirm_manual_payment: 'Confirmer le paiement manuel',
  shipping_address: 'Adresse de livraison',
  birth_date: 'Date de naissance',
  for_this_store_see_dashboard: 'Pour ce magasin uniquement - voir le tableau de bord pour plus de rapports',
  customers: 'Les clients',
  tickets_in: 'Billets en',
  tickets_out: 'Billets sortis',
  tickets_in_out: 'Billets d\'entrée / sortie',
  unsynced_transaction_cant_refund: 'Transaction non encore synchronisée avec le serveur - remboursement impossible',
  show_stock_level: 'Afficher le stock en cours (si disponible)',
  available: 'disponible',
  stock_not_available: 'stock non suivi',
  order_another_store: 'Commander depuis un autre endroit',
  order_products: 'Activer la commande de produits à partir d\'autres emplacements',
  trial_expired: 'Votre période d\'essai a expiré. La fonctionnalité POS a été désactivée.',
  left_menu: 'Menu latéral gauche',
  right_menu: 'Menu latéral droit',
  direct_pay_if_one_method: 'Paiement automatique si une méthode est mise en place',
  processing_vouchers: 'Traitement de la fidélité du panier et des bons ...',
  display_2_rows_of_categories: 'Afficher 2 lignes de catégories',
  unknown_barcodes_as_vouchers: 'Les codes barres inconnus sont des bons',
  validate_signature: 'Signature du client valide?',
  group_items_in_receipt_and_cart: 'Regrouper les mêmes articles dans le panier et le reçu',
  ask_customer_subscribe_email: 'Demander au client de fournir une adresse e-mail',
  hide_categories: 'Masquer les vignettes de catégories',
  hide_all_products_category: 'Masquer la vignette de la catégorie Tous les produits',
  provisional_receipt: '*** DOCUMENT PROVISOIRE ***',
  reprint: '*** Reçu réédition ***',
  block_reprints: 'Ne pas autoriser la réimpression d\'articles',
  reports_at_store_level: 'Afficher les rapports pour le magasin',
  max_addon_qty_exceeded: 'Maximum de {{maxQty}} addons autorisés pour ce groupe',
  min_addon_qty_unmet: 'Minimum de {{minQty}} addons requis de {{groupName}}',
  press_button_to_wake_terminal: 'S\'il vous plaît appuyez sur le bouton vert pour réveiller le terminal PIN',
  payments: 'Paiements',
  average_sale_duration_mins: 'Durée moyenne de vente (minutes)',
  save_order_move_next: 'Enregistrer l\'ordre\', continuer à la table vide suivante',
  max_orders_reached: 'Nombre maximum d\'ordres ouvertes atteint',
  show_open_orders: 'Afficher les ordres ouvertes en premier dans la liste de commande',
  tender_created: 'Transaction a commencé',
  invalid_request: 'Requête invalide',
  already_processed: 'Transaction déjà traitée',
  invalid_terminal: 'Terminal de paiement invalide',
  network_error: 'Erreur réseau',
  bad_credentials: 'Informations d\'identification non valides',
  server_issue: 'Impossible de contacter le serveur',
  pin_entered: 'Code PIN saisi',
  initial: 'Initialiser',
  card_inserted: 'Carte insérée',
  card_swiped: 'Carte balayée',
  wait_app_selection: 'En attente de sélection d\'application',
  wait_amount_adjustent: 'En attente d\'ajustement de montant',
  ask_gratuity: 'Demander un pourboire',
  gratuity_entered: 'Pourboire entrée',
  ask_dcc: 'Demander DCC',
  dcc_accepted: 'DCC accepté',
  dcc_rejected: 'DCC rejeté',
  processing_tender: 'Paiement de traitement',
  wait_pin: 'En attente de code PIN',
  provide_card_details: 'Fournir les détails de la carte',
  card_details_provided: 'Détails de la carte fournis',
  receipt_printed: 'Reçu imprimé',
  referral: 'Renvoi',
  referral_checked: 'Renvoi vérifié',
  check_signature: 'Vérifiez la signature',
  signature_checked: 'Signature vérifiée',
  additional_data_available: 'Données supplémentaires disponibles',
  transaction_acknowledged: 'Transaction reconnue',
  device_already_registered: 'Appareil déjà enregistré',
  add_in_progress: 'Ajouter un appareil déjà en cours',
  app_not_registered: 'App non enregistré',
  unknown_error: 'Erreur inconnue',
  timeout: 'Expiration du délai',
  verify_failed: 'Impossible de vérifier le périphérique',
  identify_failed: 'Impossible d\'identifier le périphérique',
  registration_error: 'Erreur d\'enregistrement',
  sync_issue: 'Impossible de synchroniser le périphérique',
  show_select_customer: 'Afficher le sélecteur de clients',
  store_name_mandatory: 'Le nom du magasin est obligatoire',
  choose_store_dashboard_for_more: 'Choisissez un magasin - plus peut être ajouté via le tableau de bord back-office',
  recovering_incomplete_payment: 'Récupération d\'un paiement incomplet - cela peut prendre jusqu\'à 30 secondes',
  allow_negative_sale: 'Autoriser un montant de vente négatif',
  move_current_table_to: 'Déplacer la table actuelle ({{table_name}}) vers',
  store: 'Boutique',
  print_specific_categories: 'Imprimer des catégories spécifiques',
  customer_display_attached: 'Affichage client attaché',
  sale: 'Vente',
  reps_and_waste_button_in_cart: 'Afficher le bouton des représentants et des déchets sur le panier',
  small_product_tiles: 'Réduire la taille des carreaux de produit',
  large_product_tiles: 'Augmenter la taille des carreaux de produit',
  show_product_price_on_tile: 'Prix ​​du produit sur la tuile',
  printer_roles: 'Rôles d\'imprimante',
  paper_size: 'Taille de papier',
  customer_display: 'Affichage client',
  enable_realtime_updates: 'Activer les mises à jour en temps réel du serveur',
  show_open_orders_first: 'Afficher les commandes ouvertes en premier dans la liste des commandes',
  blank_lines_order_ticket: 'Lignes vierges en haut du ticket de commande',
  blank_lines_bottom_order_ticket: 'Lignes vierges au bas du ticket de commande',
  scan_card: 'Scanner la carte',
  scan_nfc_or_barcode: 'Scanner la carte ou le code NFC',
  show_product_keypad: 'Afficher le clavier sur le panier',
  extra_payment_methods: 'Modes de paiement supplémentaires',
  failed_recovery: 'Échec du recouvrement du paiement',
  blank_line_between_order_items: 'Ligne vide entre les articles du ticket de commande',
  look_up_customers_using_barcodes: 'Ajouter un client au panier à l\'aide du scanner',
  error_loading_configuration: 'Erreur lors du chargement de la configuration',
  error_verifying_payment_terminal: 'Erreur lors de la vérification du terminal PIN',
  error_identifying_payment_terminal: 'Erreur lors de l\'identification du terminal PIN',
  error_saving_configuration: 'Erreur lors de l\'enregistrement de la configuration',
  conflicting_server_modes: 'Le terminal à broches et le serveur ont des modes conflictuels (TEST vs PRODUCTION)',
  nothing_to_print: 'Aucun élément à imprimer',
  large_item_name: 'Augmenter la taille de l\'article imprimé',
  print_ean_sku: 'Imprimer EAN et SKU sur reçu',
  select_category_on_startup: 'Sélectionnez la catégorie une fois au démarrage',
  split_tickets_item: 'Impression fractionnée des commandes par article',
  split_tickets_amount: 'Impression fractionnée des commandes par quantité',
  split_print: 'Impression fractionnée',
  refreshing_customers: 'Rafraîchir les clients ...',
  downloading_customers: 'Téléchargement des clients ...',
  error_retrieve_customers: 'Erreur lors de la récupération des clients. \nVeuillez vérifier votre connexion Internet et réessayer',
  server_error_please_retry: 'Erreur du serveur. Veuillez réessayer',
  vibrate_on_click: 'Retour de vibration sur le clic de l\'élément',
  keep_screen_on: 'Ne pas laisser l\'écran dormir',
  refund_same_method: 'Forcer les remboursements à utiliser le mode de paiement d\'origine',
  cannot_refund_more_than_paid: 'Ne peut rembourser qu\'un maximum de {{max_amount}} via {{payment_method}}',
  tablet_date_incorrect: 'Date de l\'appareil mal réglée - risque de sécurité possible',
  extra_non_revenue_info: 'Demandez des informations supplémentaires pour les éléments non générateurs de revenus',
  enter_extra_info: 'Entrez des informations supplémentaires sur l\'article',
  giftcard_or_reference_no: 'Carte cadeau ou numéro de référence',
  inserted_code_not_valid: 'Code incorrect',
  validate_user: 'Validation utilisateur ({{user_address}})',
  enter_validation_code: 'Veuillez saisir le code de validation que vous avez reçu par SMS ou email',
  tax_rate: 'Taux d\'imposition',
  pretax: 'Net',
  deposit: 'Dépôt',
  can_not_refund_with_different_provider: 'Impossible de rembourser {{txn_payment_method}} transactions avec {{selected_payment_method}}',
  external_scanner_connected: 'Scanner externe connecté',
  cancelling: 'Annulation',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Échange',
  ask_exchange: 'Autoriser les échanges de produits',
  exchange_error: 'Erreur d\'échange',
  exchange_items: 'Échanger des articles',
  exchangeable: 'Échangeable',
  to_be_exchanged: 'À échanger',
  exchange_all: 'Tout échanger',
  negative_sale_amount: 'Le montant à payer est inférieur à 0',
  download_rabosmartpin_app: 'Téléchargez l\'application Rabo SmartPin',
  transaction_disabled: 'Paiements à des tiers non autorisés sur ce contrat',
  transaction_in_progress: 'Un autre paiement est déjà en cours',
  refund_offline: 'Les remboursements ne sont pas possibles en mode restreint',
  refund_not_allowed: 'Les remboursements ne sont pas autorisés pour l\'utilisateur connecté',
  cash_forbidden: 'Paiements en espèces non autorisés sur ce contrat',
  notify_new_online_order: 'Afficher la notification lors de la réception d\'une nouvelle commande en ligne',
  print_new_online_order: 'Imprimer automatiquement les nouvelles commandes en ligne une fois reçues',
  delivery_information: 'Informations de livraison',
  voucher_already_processed: 'Bon déjà traité',
  no_applicable_items_in_cart: 'Aucun article applicable trouvé dans le panier',
  discount_applied: 'Remise appliquée',
  invalid_voucher: 'Coupon invalide',
  allow_voucher_scan: 'Vérifiez les bons à l\'aide du scanner de codes-barres',
  enable_drop_shipping: 'Activer la livraison directe',
  ask_no_covers_or_takeaway: 'Toujours demander la configuration de la table',
  table_configuration: 'Type de table',
  num_covers: 'Nombre d\'invités',
  takeaway: 'À emporter',
  guests: 'Invités',
  show_floorplan_after_sale: 'Afficher le plan après chaque vente',
  lock_after_x_minutes: 'Verrouiller après x minutes d\'inactivité',
  show_deposit_items: 'Afficher les éléments de dépôt',
  pickup: 'Ramasser',
  delivery: 'Livraison',
  transaction_fee: 'Frais de transaction',
  print_description: 'Imprimer la description de l\'article sur le reçu',
  only_cash_for_negative_amounts: 'Seul le paiement en espèces ou manuel par carte peut être sélectionné lorsque le montant est inférieur à 0',
  phone_number_example: 'Téléphone - par ex. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp n\'est pas installé sur cet appareil',
  receipt_not_yet_synchronised: 'Synchronisation de la transaction avec le serveur - veuillez réessayer dans quelques instants',
  notify_online_orders_with_sound: 'Jouez du son lorsque les commandes en ligne sont reçues',
  restrict_online_orders_by_floor: 'Accepter uniquement les commandes en ligne pour certains étages',
  remove_product: 'Supprimer le produit',
  admin_access_needed: 'Cette fonction n\'est disponible que pour les utilisateurs Admin',
  customer_or_employee_name: 'Nom du client ou de l\'employé',
  show_cart_on_left: 'Afficher le panier sur le côté gauche',
  groupOrderPrintByCategory: 'Regrouper les commandes par catégorie lors de l\'impression',
  handpoint_shared_secret: 'SharedSecret',
  fiscal_offline: 'Pour des raisons de conformité fiscale, POS et FDM doit être en ligne pour traiter les transactions',
  unsynced_transaction_cant_reissue: 'Transaction non encore synchronisée avec serveur - réédition non possible',
  fiscal_info: 'Données de controle',
  create_bundle: 'Créer un bundle',
  select_items_to_bundle: 'Sélectionnez les articles à regrouper',
  bundle_price: 'Prix ​​de l\'offre groupée',
  create_bundle_items: 'Créez un lot de produits dans le panier',
  bundle: 'Empaqueter',
  giftcard_or_reference_no_mandatory: 'GiftCard- ou numéro de référence obligatoire',
  show_camera: 'Utilisez la caméra de périphérique (si disponible) comme scanner',
  switch_camera: 'Caméra',
  camera: 'Caméra',
  print_qr_on_receipts: 'Imprimer le code QR sur les reçus',
  no_floorplans_available: 'Aucun plan d\'étage disponible',
  receive_online_orders_for_multiple_stores: 'Recevez des commandes en ligne pour plusieurs emplacements',
  inventory: 'Inventaire',
  description: 'La description',
  brand: 'Marque',
  allergens: 'Allergènes',
  hide_total_num_items_on_order: 'Masquer le nombre total d\'articles sur commande',
  loss: 'Loss',
  stolen: 'Stolen',
  own_use: 'Own Use',
  giveaway: 'Giveaway',
  breakage: 'Breakage',
  student_check: 'vérification d\'identité d\'étudiant',
  overview: 'Vue d\'ensemble',
  average: 'Moyenne',
  busiest_hours: 'Heures les plus chargées',
  rotation_time_in_secs: 'Temps de rotation de l\'image en secondes',
  show: 'Spectacle',
  uploaded: 'téléversé',
  videos: 'Vidéo',
  photos: 'Photos',
  display_video_or_picture: 'Afficher des fichiers vidéo, photos ou pdf',
  store_logo: 'Logo du magasin',
  add_selected_addon: 'Ajouter / supprimer des modules complémentaires',
  add_selected_to_stores: 'Ajouter / supprimer aux magasins',
  add_selected_categories: 'Ajouter / supprimer les catégories sélectionnées',
  select_categories: 'Sélectionnez les catégories',
  add_selected_addon_group: 'Ajouter / supprimer un groupe d\'addons',
  company: 'Entreprise',
  assortment: 'Assortiment',
  sales_history: 'Histoire des ventes',
  employees: 'Employé(e)s',
  extensions: 'Les extensions',
  desktop: 'Bureau',
  helpdesk: 'Bureau d\'aide',
  specific: 'Spécifique',
  no_data_for_this_period: 'Aucune donnée trouvée pour cette période.',
  gross_revenue: 'Revenu brut',
  avg_per_transaction: 'Moy. par transaction',
  from: 'de',
  select_period: 'Sélectionnez une période',
  date_start: 'Date de début',
  date_end: 'Date de fin',
  select_store: 'Sélectionnez magasin',
  select_stores: 'Sélectionnez des magasins',
  all_stores: 'Tous les magasins',
  all_employees: 'Tous les employés',
  all_devices: 'Tous les dispositifs',
  no_transactions_in_this_period: 'Aucune transaction dans cette période.',
  transactions_in_this_period: 'Transactions en ce moment.',
  report_graph: 'Report Graph',
  no_reports_found_for_this_period: 'Aucun rapport trouvé pour cette période',
  top_sales: 'Meilleures Ventes',
  top_sellers: 'Meilleures Ventes',
  bottom_sellers: 'Vendeurs du bas',
  no_top_sales_for_this_period: 'Pas de top ventes pour cette période',
  best_selling_products_for_this_period: 'Produits les plus vendus pour la période',
  save_email: 'Sauvegarder l\'email',
  new_password: 'Nouveau mot de passe',
  verify_password: 'Confirmez le mot de passe',
  save_password: 'Sauvegarder le mot de passe',
  contact: 'Contact',
  name_first: 'Prénom',
  name_last: 'Nom de famille',
  zip_code: 'Code postal',
  region: 'Région',
  billing: 'Facturation',
  optional: 'en option',
  business_name: 'Nom de l\'entreprise',
  vat: 'TVA',
  btw: 'TVA',
  save_profile_info: 'Enregistrer les informations de profil',
  integrations: 'Intégrations',
  connect_to_your_existing: 'Connectez-vous à votre existant',
  invoices: 'Factures',
  code: 'Code',
  isPaid: 'est Payé',
  invoice_paid: 'Facture Payee',
  actions: 'Actes',
  stores: 'Magasins',
  updated: 'Mis à jour',
  updated_at: 'Mis à jour à',
  create: 'Créez',
  info: 'info',
  currencies: 'monnaies',
  save_edit_details: 'Sauvegarder et éditer les détails',
  assigned_employees_of_this_stores: 'Employés assignés de ce magasin:',
  not_assigned_employees: 'Employés non affectés:',
  add_payment: 'Ajouter un paiement',
  payment_name: 'Nom du paiement',
  printer_name: 'Nom de l\'imprimante',
  edit_storess_main_details: 'Modifier les principaux détails des magasins.',
  edition: 'édition',
  stores_address_details: 'Adresse du magasin.',
  stores_contact_details: 'Coordonnées du magasin.',
  assign_employees_for_your_stores: 'Attribuez des employés à votre magasin.',
  create_custom_payment_methods_for_your_store: 'Créez des méthodes de paiement personnalisées pour votre magasin.',
  devices: 'Devices',
  device: 'Device',
  hardware_info: 'Informations sur le matériel',
  uuid: 'UUID',
  manufacturer: 'Fabricant',
  os_name: 'OS Prénom',
  os_version: 'OS Version',
  pos_version: 'POS Version',
  receipt_layout: 'Présentation du reçu',
  table_layout: 'Disposition de la table',
  default_receipt: 'Reçu par défaut',
  email_receipt: 'E-mail reçu',
  change_image: 'Changer l\'image',
  Tuesday: 'Mardi',
  june: 'Juin',
  your_store_address: 'Votre adresse de magasin',
  edit: 'Modifier',
  your_text_here: 'Votre texte ici',
  cheap: 'Pas cher',
  expensive: 'Coûteux',
  client_receipt: 'Reçu du client',
  transaction_agreement: 'Accord de transaction',
  your_footer_message_here: 'Votre message de bas de page ici',
  image: 'Image',
  price: 'Prix',
  edit_products_main_details: 'Modifier les détails principaux du produit.',
  details: 'Détails',
  visible: 'Visible',
  in_pos: 'en POS',
  cost: 'coût',
  cost_price: 'Prix de revient',
  delivery_price: 'Prix ​​de livraison',
  upload_progress: 'Upload progress',
  change_products_image: 'Changer l\'image du produit',
  variants: 'Des variantes',
  variant: 'Une variante',
  units: 'Unités',
  alert: 'Alerte',
  additional: 'Additionnel',
  ledger: 'Ledger',
  subledger: 'Subledger',
  addon: 'Addon',
  addon_groups: 'Groupes d\'addon',
  addon_group: 'Groupe d\'addons',
  add_delete_and_edit_products_variants: 'Ajouter, supprimer et éditer les variantes du produit.',
  assing_product_to_store: 'Attribuez le produit à un magasin spécifique (si aucun magasin n\'est sélectionné, le produit apparaîtra dans chaque magasin).',
  products_addtional_fields: 'Champs supplémentaires du produit.',
  manage_addons_group: 'Groupe d\'addons',
  sold_by_weight: 'Vendu au poids',
  dynamic_price: 'Prix ​​Dynamique',
  dynamic: 'Prix ​​Dynamique',
  min_age: 'Âge minimum',
  packaging: 'Emballage',
  contents: 'contenu',
  form: 'forme',
  products_packaging_and_suppliers_details: 'Emballage du produit et détails du fournisseur.',
  stock: 'Stock',
  management: 'La gestion',
  count: 'compter',
  filter_name: 'Appuyez sur pour filtrer la colonne du nom...',
  find_products_category: 'Trouver des produits par catégorie',
  find_products_addons: 'Trouver des produits par addons',
  in_stock: 'En stock',
  order_below: 'Ordre ci-dessous',
  order_quantity: 'Quantité d\'ordre',
  advance: 'Avance',
  view_history: 'Voir l\'historique',
  click_here_to_select_source: 'Click here to select source',
  record_missing_item: 'Enregistrer l\'élément manquant',
  lost: 'Perdu(e)',
  current_stock: 'Stock actuel',
  current_report: 'Rapport actuel',
  view_stock_history: 'Voir l\'historique des stocks',
  color: 'Couleur',
  duplicate_item: 'Article en double',
  want_duplicate_item: 'Voulez-vous dupliquer cet article?',
  delete_item: 'Effacer l\'article',
  want_delete_item: 'Voulez-vous supprimer cet article?',
  categories_visibility_changed: 'La visibilité des catégories a changé',
  change_visibility: 'Changer la visibilité',
  category: 'Catégorie',
  parent: 'Parent',
  select: 'Sélectionner',
  rate: 'Taux',
  delete_selected_items: 'Supprimer les éléments sélectionnés',
  aplly_diff_rates: 'Voulez-vous appliquer un taux d\'imposition différent pour différents pays?',
  countries_added: 'Pays ajoutés',
  groups: 'Groupes',
  group: 'Groupe',
  standard: 'Default',
  default_text: 'Texte par défaut',
  business_rules: 'Règles de gestion',
  business_rule: 'Règle d\'affaires',
  title: 'Titre',
  is_active: 'c\'est Actif',
  startdate: 'Date de début',
  enddate: 'Date de fin',
  rules: 'Règles',
  rule: 'Règle',
  edit_business_rules_main_details: 'Modifiez les détails principaux de la règle de gestion.',
  active: 'Actif',
  time_start: 'Heure de début',
  time_end: 'Heure de fin',
  select_specific_days: 'Sélectionnez un jour spécifique',
  conditions: 'Conditions',
  condition: 'Condition',
  change_business_rules_conditions: 'Modifier les conditions de la règle de gestion.',
  change_business_rules_outcome: 'Changer le résultat de la règle de gestion.',
  minimum_amount: 'Montant minimal',
  minimum: 'Minimal',
  maximum: 'Maximale',
  maximum_amount: 'Montant maximale',
  ignore_for: 'Ignorer pour',
  outcome: 'Outcome',
  voucher_outcome: 'voucher outcome',
  new_price_rule: 'Nouveau prix pour la règle de gestion',
  min_pack_value: 'Valeur minimale du paquet',
  max_pack_value: 'Valeur maximale du paquet',
  enable: 'Activer les règles',
  disable: 'Désactiver les règles',
  all: 'tout',
  update_time: 'Mettre à jour l\'heure de début/fin',
  update_date: 'Mettre à jour la date de début/fin',
  catalog_order: 'Commande de catalogue',
  drag_and_drop_items_to_reorder: 'Faites glisser et déposez les éléments à réorganiser',
  see_reports: 'Vous pouvez voir vos rapports dans le tableau de bord en sélectionnant',
  generate_report: 'Générer un rapport',
  or: 'ou',
  send_report: 'Vous pouvez envoyer des données brutes à votre email',
  send_report_button: 'Envoyer un rapport',
  send_to: 'Envoyer à',
  recipient: 'recipient',
  available_reports: 'Rapports disponibles pour cette période',
  select_currency_text: 'Veuillez sélectionner une devise ou un magasin spécifique.',
  tables: 'Tables',
  revenue: 'Revenu',
  see_revenue: 'Voir votre revenu brut, coût, impôts et bénéfices',
  net_revenue: 'Revenu net',
  profit: 'Profit',
  see_transaction: 'Voir votre méthode de transaction, le nombre et le montant total',
  see_payments: 'Voir votre méthode de paiement, le nombre et le montant total',
  see_refunds: 'Voir votre méthode de remboursement, le nombre et le montant total',
  see_taxes: 'Voir vos taxes, le nombre et le montant total',
  brands: 'Marques',
  channels: 'Canaux',
  channel: 'Canal',
  see_brands: 'Voir vos produits par marque, le nombre et le montant total',
  see_channels: 'Voir vos produits par canal, le nombre et le montant total',
  see_categories: 'Voir vos catégories, le nombre et le montant total',
  click_details: 'Cliquez ici pour voir les détails',
  report_select_other_period: 'Veuillez sélectionner une autre période pour voir le rapport disponible',
  created: 'Créé',
  discounts: 'Des remises',
  add_discount: 'Ajouter une remise',
  discount_percent: 'Pourcentage de réduction',
  register_opertations: 'Enregistrement des opérations',
  operations: 'Des opérations',
  first: 'First',
  last: 'Last',
  phone: 'Téléphone',
  permission_to_contact: 'Permission de contacter',
  identifiers_type: 'Type d\'identifiant client',
  identifiers_value: 'Valeur d\'identifiant',
  shipping: 'Livraison',
  diff_billing: 'si différent de la facturation',
  rights: 'Droits',
  employee: 'Employé',
  token: 'Jeton',
  uninstall_ext: 'Désinstaller extension',
  active_ext: 'Cette extension est active',
  subscribe: 'Souscrire',
  remove_ext_msg: 'Êtes-vous sûr de vouloir supprimer cette extension?',
  no_thanks: 'Non merci',
  subs_to_ext: 'S\'abonner aux extensions',
  period: 'Période',
  payment_details: 'Détails de paiement',
  card_name: 'Nom sur carte:',
  paymnet_method: 'Mode de paiement:',
  code_country: 'Country Code:',
  diff_way_pay_msg: 'Ou choisissez un autre moyen de payer',
  phone_support: 'Assistance téléphonique',
  support_msg: 'Contactez le support client pour obtenir de l\'aide:',
  email_support: 'Support par email',
  support_email: 'Support par email:',
  info_email: 'Info e-mail:',
  sales_email: 'Ventes e-mail:',
  page: 'page',
  dark_theme: 'Thème sombre',
  report_spec_time: 'Générer un rapport avec une heure spécifique',
  vat_btw_no: 'TVA/BTW No.',
  store_unable_delete: 'Le magasin ne peut pas être supprimé car un appareil est lié. Veuillez retirer l\'appareil avant de supprimer le magasin.',
  important: 'Important:',
  store_device_delete_msg: 'Veuillez vous assurer que vous êtes déconnecté de cet appareil avant de le supprimer.',
  email_test_receipt: 'Email un reçu de test',
  merchant: 'Merchant',
  per_item: 'per articel',
  img_width_msg: 'La largeur maximale autorisée du fichier est 250px.',
  err_img_msg: 'Erreur: la largeur de l\'image ne doit pas dépasser 250px',
  rules_actions: 'Actions de règles',
  enable_all: 'Activer toutes les règles de gestion',
  want_enable_all: 'Voulez-vous activer toutes les règles commerciales?',
  disable_all: 'Désactiver toutes les règles de gestion',
  want_disable_all: 'Voulez-vous désactiver toutes les règles de gestion?',
  product_cat_error: 'Veuillez choisir une catégorie',
  field_not_empty: 'Le champ ne doit pas être vide.',
  drop_files: 'Drop your file here to upload the image.',
  save_variant_before_stock: 'Enregistrez la nouvelle variante avant de modifier le stock.',
  no_employees: 'Vous n\'avez aucun employé. Vous pouvez créer de nouveaux ou ajouter employé(e)s',
  here: 'ici',
  no_stock: 'Il n\'y a pas de produits en stock dans ce magasin',
  product_exists: 'Ce produit existe seulement dans',
  add_units_stock: 'Ajout d\'unités au stock',
  remove_units_stock: 'Retrait des unités du stock',
  saving: 'Est économiser',
  transfer_units: 'Ajouter le nombre d\'unités à transférer',
  remove_units_store: 'Ajouter la quantité d\'unités à supprimer de ce magasin',
  notification: 'notification',
  set_new_start_end_time: 'Définir la nouvelle heure de début/fin',
  set_new_start_end_date: 'Définir une nouvelle date de début/fin',
  by_email: 'par email',
  charts_graphs: 'Charts/Graphs',
  compare_reports: 'Comparer les rapports',
  new_report: 'Nouveau rapport',
  basic: 'Basic',
  no_acount_to_send_report: 'Vous ne pouvez pas envoyer ce rapport car votre compte est',
  upgrade_account_msg: 'Vous devez mettre à jour votre compte!',
  gen_report_msg: 'Vous pouvez générer votre rapport et voir les résultats dans le tableau de bord.',
  disabled_report: 'Rapports désactivés alors que les paiements sont en attente',
  please_check: 'S\'il te plaît verifie ton',
  email_wrong_format: 'Le format du courrier électronique n\'est pas correct.',
  bar_chart: 'Bar Chart',
  method: 'Méthode',
  total_taxed_amount: 'Montant total imposé',
  total_price_product: 'Prix ​​total des produits',
  enter_value: 'Vous devez entrer une valeur',
  not_valid_mail: 'Email non valide',
  enter_email: 'Entrer votre Email',
  unit_price: 'Prix ​​unitaire',
  extras: 'Extras',
  monthly_billed: 'facturé mensuellement',
  removing_ext: 'Cette extension sera supprimée au prochain cycle de facturation',
  local_ip: 'IP locale',
  printer_ip_ad: 'Adresse IP de l\'imprimante',
  printer_categories: 'Catégories d\'imprimantes',
  printers: 'Imprimantes',
  payment_processor: 'Processeur de paiement',
  question: 'As-tu une question ?',
  check_here: 'Vérifiez ici notre',
  knowledge_center: 'Centre de connaissances.',
  support_articles_msg: 'Nous avons plusieurs articles d\'assistance qui peuvent vous aider à démarrer, à créer des produits, à connecter votre boutique en ligne et plus encore....',
  support_ques: 'Si vous ne trouvez toujours pas la réponse que vous voulez, vous pouvez obtenir',
  in_touch: 'en contact avec nous',
  select_floorplan: 'Sélectionnez le plan',
  floors: 'Des sols',
  add_floorplan: 'Ajouter un plan',
  delete_floorplan: 'Supprimer le plan',
  floorplan: 'Plan du sol',
  add_tables: 'Ajouter des tables',
  sign_up_msg: 'Des milliers de marchands ont rejoint Countr. Aujourd’hui, c’est à vous. Inscription gratuite.',
  user_required: 'Nom d\'utilisateur est nécessaire.',
  name_required: 'Le nom est requis.',
  company_name_required: 'Le nom de l\'entreprise est requis.',
  phone_required: 'Le numéro de téléphone est requis.',
  pass_required: 'Mot de passe requis.',
  already_account: 'Vous avez déjà un compte?',
  accept_sign_up: 'En m\'inscrivant, j\'accepte les',
  terms_conditions: 'Termes et conditions',
  policy: 'Politique de confidentialité',
  licence_agree: 'License Agreement',
  your_name: 'Votre nom',
  company_web: 'Site web de l\'entreprise (non obligatoire)',
  forgot_pass: 'Mot de passe oublié?',
  login: 'Connection',
  no_account_link: 'Vous n\'avez pas de compte?',
  email_required: 'Email est requis.',
  send_link: 'Envoyer un lien',
  download_file: 'Télécharger les produits avec stock',
  account_number: 'Numéro de compte',
  me_rules: 'Règle',
  by_volume: 'Produits les plus vendus (en volume) cette période',
  number_sold: 'Nombre vendu',
  by_volume_bottom: 'Produits vendus au prix le plus bas (en volume) cette période',
  create_compare_report: 'Créer un nouveau rapport à comparer',
  last_per: 'Dernier',
  no_per_data: 'n\'a pas de rapports disponibles',
  create_new_rep: 'Créer un nouveau rapport',
  reset_pass_text: 'Saisissez votre adresse email pour réinitialisez votre mot de passe',
  enter_bus_info: 'S\'il vous plaît entrer les informations de votre entreprise',
  organization: 'Nom de l\'organisation',
  mandatory_fields: 'Tous les champs sont obligatoires !!!',
  invalid_eu: 'Numéro de TVA invalide pour le pays de l\'UE',
  trial_expires: 'Votre essai expire dans',
  select_package: 'Sélectionnez le forfait',
  excluse_vat: 'hors TVA',
  instead: 'au lieu de',
  save_payment: 'Enregistrer ce mode de paiement pour les transactions futures',
  procced_paymet: 'Procéder au paiement',
  selected_package: 'Forfait sélectionné',
  selected_ext: 'Extensions sélectionnées',
  old_dashboard: 'Retour au Tableau de bord Hérité',
  subscription_msg: 'Félicitations, vous êtes sur le point de vivre avec votre magasin! Vos transactions seront sauvegardées pour toujours et toutes les marques de test disparaîtront. Avant de commencer, assurez-vous de choisir les extensions que vous souhaitez continuer à utiliser.',
  transfer_from: 'Unités de transfert de',
  customer_purchase: 'Achat client',
  total_spend: 'Dépense totale',
  last_customers_transactions: 'Dernières transactions du client',
  email_recipient: 'Email recipient',
  show_revenue: 'Afficher les revenus',
  show_sales: 'Montrer les ventes',
  visits: 'Visites totales',
  select_action: 'Sélectionnez l\'action',
  show_receipt: 'Montrer le reçu',
  set_values_for: 'Définir des valeurs pour',
  update: 'Mise à jour',
  select_addons_group: 'Sélectionnez le groupe d\'addons',
  select_addon: 'Sélectionnez l\'addon',
  all_addon_groups_are_in_use: 'La liste est vide, tous les groupes d\'addon sont utilisés',
  store_hours: 'Heures d\'ouverture',
  opening_hours: 'Horaires d\'ouvertures',
  no_opening_hours: 'Pas d\'heures d\'ouverture',
  set_opening_hours: 'Définir les heures d\'ouverture',
  delivery_hours: 'Heures de livraison',
  no_delivery_hours: 'Pas d\'heures de livraison',
  set_delivery_hours: 'Définir les heures de livraison',
  ordering_hours: 'Heures de commande',
  no_ordering_hours: 'Pas d\'heures de commande',
  set_ordering_hours: 'Définir les heures de commande',
  takeaway_hours: 'Heures à emporter',
  no_takeaway_hours: 'Keine Stunden zum Mitnehmen',
  set_takeaway_hours: 'Définir les heures de livraison',
  delivery_zones: 'Zones de livraison',
  no_delivery_zones: 'Pas de zones de livraison',
  set_delivery_zones: 'Définir les zones de livraison',
  Monday: 'Lundi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',
  set_opening_store: 'Fixer les horaires d\'ouverture du magasin',
  set_delivery_store: 'Définir les heures de livraison pour le magasin',
  set_ordering_store: 'Définir les heures de commande pour le magasin',
  set_takeaway_store: 'Définir les heures de livraison pour le magasin',
  set_zones_store: 'Définir les zones de livraison pour le magasin',
  no_data_added: 'Aucune donnée ajoutée  ',
  add_hours: 'Ajouter des heures',
  add_zones: 'Ajouter des zones de livraison',
  order_sub: 'Sous-catégories de commande',
  new_item: 'nouvelle',
  tips: 'pointe',
  see_busiest_hours: 'Voir les heures les plus chargées en fonction de la période que vous avez sélectionnée',
  see_employee_transactions: 'Voir les transactions par employé pour la période sélectionnée',
  see_employee_tips: 'Voir le pourboire par employé pour la période sélectionnée',
  see_employee_refunds: 'Voir les remboursements par employé pour la période sélectionnée',
  see_customer_transactions: 'Voir les transactions par type de client pour la période sélectionnée',
  see_customer_refunds: 'Voir les remboursements par type de client pour la période sélectionnée',
  new_customer: 'Nouveau client',
  returning_customer: 'Déjà client',
  no_customer: 'Aucune donnée client',
  reps_waste: 'Reps and waste',
  see_reps_waste: 'See reps and waste by reason',
  see_employee_reps_waste: 'See reps and waste by employee',
  employee_report_graph: 'Voir les ventes des employés par fois',
  not_sold: 'Non vendu',
  non_selling_products_this_period: 'Produits non vendus cette période (Produits avec 0 ventes)',
  compare_stores: 'Comparer les magasins',
  average_amount_spent: 'Montant moyen dépensé',
  average_amount_refunded: 'Montant moyen remboursé',
  bundle_parts: 'Pièces groupées',
  create_giftcard: 'Créer une carte cadeau',
  giftcards: 'Cartes cadeaux',
  select_graphs_type: 'Sélectionnez le type de graphique',
  see_discount_percentage: 'Voir les transactions avec remise en pourcentage',
  see_discount_by_product_percentage: 'Voir les remises accordées aux produits (les remises numériques sont calculées en pourcentage)',
  discount_percentage: 'Discount (pourcentage)',
  discount_numeric: 'Remise (numérique)',
  see_discount_numeric: 'Voir les transactions avec remise numérique',
  number_of_discounts: 'et le nombre de fois où cette remise s\'est produite au niveau du produit ou du panier',
  delivery_cost: 'Frais de port',
  total_cash_after_tips_and_change: 'Total des espèces après pourboires et monnaie',
  assigned_stores: 'Magasins affectés',
  chat_iframe: 'Chat',
  mobile_phone: 'Téléphone portable',
  notifications: 'Notifications',
  annualy_billed: 'facturé annuellement',
  download_stock: 'Télécharger le stock',
  download_all_stock: 'Téléchargez tous les produits',
  no_stock_products: 'Définir des produits sans stock',
  go_products_with_stock: 'Aller aux produits en stock',
  save_all_changes: 'Enregistrer toutes les modifications',
  need_track_stock: 'Vous devez suivre le stock à modifier.',
  track_stock: 'Stock de piste',
  set_min_man: 'Ensemble min et max pour les groupes d\'addon spécifiques',
  duplicate: 'Dupliquer',
  clear_unsaved: 'Supprimer toutes les modifications',
  select_allergens: 'Sélectionnez les allergènes',
  translate_categories: 'Traduire les catégories',
  translate_groups: 'Traduire des groupes',
  add_cat_translation: 'Ajouter des traductions à la catégorie',
  add_group_translation: 'Ajouter des traductions au groupe',
  assigned_groups: 'Groupes affectés',
  extension: 'Extension',
  vouchers: 'Pièces justificatives',
  show_open_carts_from_store: 'Afficher les commandes ouvertes de l\'emplacement',
  ean: 'Code à barres (ean)',
  sku: 'Sku',
  want_delete_all_items: 'Voulez-vous supprimer tous les éléments sélectionnés?',
  want_to_change_visibility: 'Voulez-vous changer de visibilité de tous les éléments sélectionnés?',
  import_export: 'Importer / Exporter',
  methods: 'Mode de paiement',
  stored_payment_info: 'Voir vos informations sur la méthode de paiement',
  stored_payment: 'Méthodes de paiement stockées',
  report_spec_zone: 'Sélectionnez une horaire différente',
  no_stock_stores: 'Vous n\'avez pas d\'emplacement. Veuillez créer un emplacement et attribuer des produits à l\'inventaire.',
  create_custom_payment: 'Créez des méthodes de paiement personnalisées pour votre emplacement.',
  min_chars: 'au moins 3 caractères',
  enable_deposit: 'Activer le dépôt',
  enable_daily_report: 'Activer le rapport quotidien',
  reorder_email: 'Email de la réordination des stocks',
  enable_non_revenue: 'Définir comme non-revenu',
  total_visits: 'Total visites',
  how_it_works: 'Comment ça fonctionne',
  info_text_catalog_order_one: '• Vous pouvez faire glisser vos produits en plaçant le curseur au-dessus d\'un élément et en maintenant le bouton gauche de la souris.',
  info_text_catalog_order_two: '• Pour placer un produit sur une page différente, placez le curseur au-dessus du produit, cliquez avec le bouton droit de la souris et sélectionnez la page que vous souhaitez l\'envoyer.',
  verify_dialog: 'Un code de vérification est envoyé à votre adresse e-mail.',
  verify_proceed_dialog: 'Veuillez sélectionner un type d\'industrie de votre entreprise, puis procédez à votre compte.',
  export_customer: 'Exporter des informations client',
  disabled_floor: 'Étage handicapé',
  filter_by_category: 'Filtrer par catégorie',
  current_timezone: 'Timezone local:',
  important_note: 'Note importante:',
  transaction_note: 'Vous ne serez pas facturé, mais nous avons besoin de vos détails de paiement afin de pouvoir valider votre demande d\'aller vivre.',
  translate_name: 'Définir la traduction des langues sélectionnées',
  translate_tool: 'Donner une traduction au nom',
  payment_method_note: 'Vous ne pouvez supprimer que le mode de paiement et ne pas ajouter ou modifier. Vous pouvez ajouter un nouveau mode de paiement lorsque vous payez',
  add_new_payment: 'ou ajouter un nouveau',
  no_payment_method: 'Il n\'y a pas de méthode de paiement stockée',
  daily_report: 'Heure du rapport quotidien (horloge 24 heures)',
  it_seems_store: 'Il semble que l\'emplacement',
  no_stock_items: 'n\'a pas assez d\'articles en stock. Es-tu sur de vouloir continuer?',
  export_file_finished: 'Votre fichier d\'exportation est terminé, voici votre lien de téléchargement:',
  import_file_finished: 'Votre fichier d\'importation a terminé avec succès. Produits Total:',
  stock_import_file_finished: 'Votre fichier d\'importateur de stock a terminé avec succès.',
  error_stock_finished: 'Une erreur s\'est produite, veuillez vérifier votre format de fichier et votre contenu.',
  import_file_init: 'Nous traitons votre dossier. Vous recevrez une notification lorsqu\'il sera prêt.',
  export_file_init: 'Nous traitons votre dossier. Vous recevrez une notification lorsqu\'il sera prêt.',
  create_voucher: 'Créer votre bon',
  total_uses: 'Montant utilisable',
  scope: 'Portée',
  numeric_discount: 'Remise numérique',
  start_date: 'Date de début',
  expiry_date: 'Date d\'expiration',
  products_categories: 'Produits ou catégories',
  search_products: 'Recherche de produits',
  selected_products: 'Produits sélectionnés',
  search_categories: 'Catégories de recherche',
  selected_categories: 'Catégories sélectionnées',
  selected_items: 'Articles sélectionnés',
  local_prices: 'Prix ​​locaux',
  translate: 'Traduire',
  value: 'Valeur',
  code_type: 'Type de code',
  non_revenue: 'Non revenus',
  giftcard_product_alert: 'Le produit de la carte-cadeau doit toujours être un produit non revenu.',
  multi_countries_tax_rules: 'Taux d\'imposition multi-pays',
  countries_rates: 'Tarifs de pays',
  edit_store_themes: 'Modifier les thèmes de l\'emplacement (uniquement disponible pour le kiosque de campagne)',
  themes: 'Thèmes',
  font: 'Police de caractère',
  current_theme: 'Thème actuel',
  add_theme: 'Ajouter du thème',
  delete_theme: 'Supprimer le thème',
  body_background: 'Couleur de l\'arrière plan',
  body_text: 'Couleur de texte',
  primary_text: 'Couleur de texte primaire',
  secondary_text: 'Couleur de texte secondaire',
  header: 'Couleur de fond d\'en-tête',
  header_text: 'Couleur de texte d\'en-tête',
  toolbar: 'Couleur de fond de la barre d\'outils',
  toolbar_text: 'Couleur de texte de la barre d\'outils',
  button_primary_text: 'Couleur du texte du bouton principal',
  button_primary_background: 'Couleur de fond du bouton principal',
  button_secondary_text: 'Bouton secondaire Couleur de texte',
  button_secondary_background: 'Couleur de fond de bouton secondaire',
  button_neutro_text: 'Bouton neutre Couleur de texte',
  button_neutro_background: 'Bouton de fond de bouton neutrien',
  isActive: 'c\'est actif',
  Wednesday: 'Mercredi',
  answered: 'répondu',
  common: {"welcome": "Bonjour {{nom}}, vous avez {{comptez}} neuf {{messages}}!"},
  username: 'Nom d\'utilisateur',
  show_price: 'Montrer le prix',
  companyid: 'Nom d\'entreprise',
  invalid_merchantid: 'Personne de contact invalide',
  invalid_businessid: 'Nom d\'entreprise non valide',
  all_categories: 'Toutes catégories',
  close_search_product: 'Fermer la recherche et supprimer le filtre de recherche',
  firstid: 'Prénom',
  lastid: 'Nom de famille',
  storeid: 'nom de la localisation',
  enter_a_printerid: 'Entrez un nom d\'imprimante',
  enter_orderid: 'Entrez le nom de la commande',
  enter_tableid: 'Entrez le nom de la table',
  customerid: 'Client',
  insert_printerid: 'Veuillez insérer un nom (3 caractères ou plus) pour votre imprimante.',
  not_paid: 'Impayé',
  menu_itemid: 'Nom de l\'élément de menu (max. 12 caractères)',
  employeeid: 'Employé',
  deviceid: 'Appareil',
  addid_device: 'Nommez votre appareil',
  deviceid_mandatory: 'Le nom de l\'appareil est obligatoire',
  no_floorplans: 'Aucun plan d\'étage disponible',
  table_layout_advice: 'Veuillez créer un plan d\'étage dans le back-office afin d\'utiliser cette fonctionnalité',
  merge_tables: 'Tables de fusion',
  custom_item_not_valid: 'Article personnalisé non valide',
  no_connection_sync: 'Pour synchroniser toutes les transactions, veuillez vous assurer que la connexion est rétablie avant de quitter l\'application.',
  new_device_name: 'Nom du nouveau périphérique',
  change_device_name: 'Changer le nom du périphérique',
  change_store: 'Changer de lieu',
  alert_change_store: 'Vous devrez complètement fermer et redémarrer l\'application pour appliquer ce changement.',
  customer_total_spend: 'Dépenses totales du client',
  skip_payment_success_info: 'Ne pas montrer les informations de réussite du paiement',
  cart_always_open: 'Toujours montrer l\'ordre à droite de l\'écran',
  show_calculator: 'Show calculatrice',
  selected: 'Choisi',
  categories_tiles_position: 'Catégorie Position des carreaux',
  kiosk_mode: 'Mode kiosque',
  kiosk_mode_disabled: 'Mode Kiosque désactivé',
  change_table: 'Changer le nom de la table',
  change_cart: 'Nom de commande de changement',
  change_cart_tip: 'Il devrait avoir au moins 2 lettres',
  a4_paper: 'Utilisez du papier A4 pour imprimer',
  auto_print: 'Reçu imprimé automatiquement après succès de paiement',
  select_report: 'Sélectionnez Type de rapport',
  report_not_found: 'Signaler non trouvé',
  new_cart: 'nouvelle commande',
  new_table: 'Nouvelle table',
  delete_cart: 'Supprimer l\'ordre',
  delete_cart_confirmation: 'Êtes-vous sûr de vouloir supprimer cette commande?',
  product_tile_size: 'Augmenter la taille des carreaux de produits',
  load_more_transactions: 'Charger plus de transactions',
  select_variant: 'Sélectionner la variante',
  remember_password: 'Se souvenir du mot de passe',
  go_dashboard: 'Aller à mon back office',
  enable_cart_item_remove: 'Permettre l\'élimination des éléments de l\'ordre',
  remove_item: 'Retirer l\'objet',
  show_current_cart: 'Afficher l\'ordre actuel',
  show_carts: 'Afficher la liste des commandes',
  show_tables: 'Spectacle planplan',
  open_receipt: 'Réception ouverte',
  kds: 'Écran d\'affichage de cuisine',
  archive: 'Archiver',
  send_archive: 'Envoyer à archiver',
  awaiting_payment: 'En attente de paiement',
  all_items: 'Tous les articles',
  printed: 'Nouvelle',
  pending: 'Nouvelle',
  preparing: 'En train de préparer',
  ready: 'Prêt',
  completed: 'Complété',
  send_new: 'Revenir à nouveau',
  send_progress: 'Définir comme en cours',
  send_ready: 'Définir comme prêt',
  send_completed: 'Défini comme terminé',
  active_orders: 'Ordres actifs',
  all_orders: 'Tous les ordres',
  select_employee: 'Sélectionnez Employé',
  select_status: 'Sélectionner le statut',
  send_all_to_status: 'Définir tous les produits au statut',
  no_open_orders: 'Aucune commande disponible',
  click_refresh: 'Cliquez ici pour rafraîchir',
  deliveredBy: 'Livré par',
  by: 'Livré par',
  deliveryTime: 'Délai de livraison',
  pickupTime: 'Temps de ramassage',
  customerPhone: 'Téléphone client',
  customerName: 'Nom du client',
  orderNote: 'Note de commande',
  serviceCharge: 'Frais de service',
  orderType: 'type de livraison',
  deliveryCost: 'Frais de port',
  discountTotal: 'Remise',
  channelOrderDisplayId: 'Numéro de commande',
  orderIsAlreadyPaid: 'Est payé',
  paymentMethod: 'Mode de paiement',
  deliveryFee: 'Frais de livraison',
  deliveryAddress: 'Adresse',
  deliveryPostcode: 'Code postal',
  deliveryCity: 'Ville',
  extraAddressInfo: 'Informaitons supplémentaires',
  postalCode: 'Code postal',
  streetNumber: 'numéro de rue',
  postponed: 'Reporté',
  warning_delay: 'Commandes de retard d\'avertissement (en minutes)',
  load_orders_past: 'Charger des commandes des jours passés',
  hotkeys: 'Hauts chauds',
  show_categories_separator: 'Afficher la catégorie Séparateur sur l\'article de chaque commande',
  auto_refresh: 'Actualiser automatiquement la liste des commandes toutes les minutes.',
  auto_complete_order: 'Terminez automatiquement les commandes après X minutes',
  hide_completed_items: 'Masquer les articles terminés de la commande',
  remove_grid_gaps: 'Supprimer les lacunes entre les commandes de la grille',
  off: 'Désactivé',
  languages: {"restart_msg": "Veuillez enregistrer vos paramètres et rafraîchir votre navigateur pour commencer à utiliser les nouvelles traductions.", "en": "anglais", "nl": "pays bas", "de": "Deutsch", "pt":" Português "," es":" Español "," fr":" francais","is":" Íslenska ","it":" Italiano ","no":" NORSK "},
  hotkeys_obj: {"error": "Impossible de répéter les touches!", "selecct_order": "Sélectionnez les commandes de 1 à 9", "change_status_info": "Modifier l'état de la commande sélectionnée avec:", "change_status": "Changer le statut de commande"},
  last_update: 'Dernière mise à jour',
  customer_information: {"first_name": "Prénom", "last_name": "Nom", "phone": "Téléphone"},
  in_progress: 'En cours',
  deliveryBy: 'Livré par',
  init_all_services: 'Initialiser tous les services ...',
  email: 'E-mail',
  signin: 'S\'identifier',
  signin_error: 'Quelque chose a mal tourné pendant la connexion',
  device_name: 'Nom de l\'appareil',
  close_details: 'Détail',
  no_orders_available: 'Aucune commande disponible',
  order_has_been_completed: 'Commander {{receipt_id}} est terminé',
  no_products: 'Aucun produit',
  print_device: 'Appareil d\'impression',
  send_order: 'Envoyer l\'ordre',
  add_products: 'Ajouter des produits',
  no_categories: 'Aucune catégorie trouvée',
  empty_table: 'Table vide',
  order_not_sent: 'Commande non envoyée à la cuisine',
  product_list_view: 'Liste de produits Vue',
  no_description: 'Ce produit n\'a pas de description. Vous pouvez en ajouter un en appuyant sur le bouton "Modifier" ou visiter votre tableau de bord.',
  change_description: 'Veuillez saisir la description du produit.',
  main_cart_is_empty: 'Le panier principal est vide',
  apply_to_all_carts: 'Appliquer à tous les paniers',
  per_value_payment: '(Paiement par valeur)',
  select_a_seat: 'Sélectionnez un siège',
  sub_total: 'Total',
  desktop_settings: 'Bureau de bureau',
  carts: 'Ordres',
  signing_in: 'Connectez-vous...',
  total_taxes: 'Total des taxes',
  provide_refund_item: 'Vous devez sélectionner un élément à rembourser',
  manual_cheque_refund: 'Remboursement manuel de la vérification',
  downloading_employees: 'Télécharger des employés ...',
  show_product_image_list: 'Afficher les produits Image dans la liste des produits',
  refresh_delta: 'Actualiser les produits / Catégories',
  admin_pin_incorrect: 'ADMIN PIN incorrect! Veuillez réessayer.',
  customer_not_valid_vat: 'TVA client non valide ou vide',
  allow_partial_payment: 'Autoriser le paiement partiel',
  allow_qrcode_scanner: 'Autoriser le scanner de code QR à trouver une transaction',
  qrcode_not_found: 'Code QR non trouvé',
  empty_pin: 'La broche doit avoir au moins 1 caractère',
  employee_select_error: 'Mauvais épingle des employés',
  employee_select_success: 'Employé {{employee}} sélectionné.',
  employee_lock: 'Toujours verrouiller l\'employé après la transaction',
  no_employee_store: 'Il n\'y a pas d\'employé assigné à cet endroit',
  cash_in_out_failed: 'Impossible d\'économiser de l\'opération de trésorerie / sortie',
  cash_in_out_success: 'Succès d\'exploitation de trésorerie / sortie',
  categories_alphabetic_order: 'Afficher les catégories dans l\'ordre alphabétique',
  products_alphabetic_order: 'Afficher les produits dans l\'ordre alphabétique',
  weight_not_valid: 'Poids rempli non valide',
  kiosk_mode_requirement: 'Vous ne pouvez pas définir le mode kiosque sans broche d\'administration',
  canceled: 'Annulé',
  partial_payment_value: 'Par valeur',
  partial_payment_item: 'Par objet',
  continue_payment: 'Continuer le paiement',
  unit: 'Unité',
  cheque: 'Chèque',
  need_store_register_device: 'Vous devez d\'abord créer un emplacement pour vous connecter. Veuillez visiter votre tableau de bord pour créer votre premier emplacement.',
  search_transaction_hint: 'Au moins 3 caractères et appuyez sur Entrée',
  select_pay_method: 'Choisissez une méthode de paiement',
  delete_all_empty_cart: 'Supprimer toutes les commandes vides',
  delete_all_empty_table: 'Supprimer toutes les tables vides',
  delete_all_empty_cart_confirmation: 'Êtes-vous sûr de vouloir supprimer toutes les commandes vides?',
  all_empty_carts_deleted: 'Supprimé ({{cartsNumber}} commandes)',
  reps: 'Représentants',
  show_reps_waste: 'Montrer des reps et des déchets sur commande',
  giveaway_reason: 'Giveaway ou Raison usée',
  countr_desktop_not_found: 'Pays Desktop non trouvé',
  clear_search: 'Effacer la recherche',
  recovery_transaction_complete: 'Transaction ({{ref}}) terminé.',
  activate_simple_tiles: 'Activer la liste de produits simples',
  not_print_receipt_cash: 'Ne pas réception automatique d\'impression pour l\'argent et la carte-cadeau',
  allow_negative_payments: 'Permettre un montant de vente négatif',
  negative_payments_warning: 'Veuillez activer les paiements négatifs dans les paramètres (onglet Transactions) avant de le faire.',
  enable_method: 'Activer la méthode',
  error_addon_groups: 'Quelque chose ne va pas avec le groupe Addon de ce produit, veuillez vérifier dans le back-office.',
  billed_to: 'Facturé à',
  payment_due: 'Paiement dû',
  amount_due: 'Montant dû',
  total_price: 'Prix ​​total',
  show_invoice: 'Montrer la facture',
  hide_invoice: 'Cacher la facture',
  show_ean_sku_receipt: 'Montrer ean en réception',
  show_simple_receipt: 'Montrer un reçu simple',
  not_tracked: 'Non suivi',
  kitchen_print: 'Imprimé de cuisine',
  kitchen_delivery_print: 'Imprimé de livraison de cuisine',
  syncing_transactions: 'Synchroniser les transactions ...',
  syncing_refunds: 'Synchroniser les remboursements ...',
  barcode_customer: 'Utilisez Scanner de code à barres pour rechercher le client',
  customer_add_cart: 'Client ajouté à la commande',
  transaction_not_sync_yet: 'La transaction n\'a pas encore été synchronisée sur le serveur.',
  intercom_error: 'Impossible d\'initier le plugin Intercom, veuillez jeter un oeil si vous avez une extension de bloc d\'annonce ou essayez de l\'utiliser dans le tableau de bord.',
  pay_before_open_error: 'Vous devez ouvrir votre quart avant de faire des transactions',
  cash_in_not_open_day: 'Vous devez ouvrir votre quart avant d\'enregistrer de l\'argent dans / out',
  cash_in_greater_start: 'Montant non valide, si vous avez plus d\'argent que celui déclaré sur Démarrer Shift, veuillez d\'abord faire de l\'argent en action.',
  open_close_shift: 'Open & Close Shift',
  opening_shift: 'Maj d\'ouverture ...',
  closing_shift: 'Clôture de fermeture ...',
  not_valid_input: 'Le montant n\'est pas valide',
  previous_start_amount: 'Montant attendu',
  reason_mandatory_helper: '* Il y a une différence, veuillez entrer une raison',
  printer_settings: 'Paramètres de l\'imprimante',
  printer_ip: 'Imprimante IP',
  test_printers: 'Imprimantes de test',
  printers_settings_saved: 'Paramètres des imprimantes enregistrées',
  error_shift_start_day: 'Jour de début de travail non trouvé',
  no_connection_payment: 'Pas de connexion Internet, assurez-vous de rétablir la connexion avant de payer avec une carte.',
  show_order_bill: 'Afficher les boutons d\'ordre / facture dans l\'en-tête',
  split_orders_items: 'Divisez les commandes d\'impression par articles',
  split_orders_amount: 'Département des commandes d\'impression par montant',
  partial_payment_print: 'Réception d\'impression pour chaque paiement partiel',
  minimum_age: 'Vérification de l\'âge Minumum',
  minimum_age_alert: 'Client: {{customer}}. Veuillez vous assurer que le client est supérieur à {{age}} ans',
  customer_required_title: 'Le client requis',
  customer_required_text: 'Le client est requis pour ce mode de paiement',
  show_pretax_receipt: 'Montrer sur le montant des taxes',
  show_full_receipt_after_payment: 'Afficher le reçu complet après le paiement',
  new_version_title: 'Nouvelle version disponible',
  new_version_text: 'Un nouveau contenu est disponible, veuillez vous rafraîchir.',
  new_version_text_hint: 'Vous pouvez vérifier les notes de publication dans le menu de gauche.',
  reload: 'Recharger',
  release_note: 'Note de sortie',
  play_sound_success_scan: 'Jouer au son sur le code à barres numérisés avec succès',
  manual_sync_transactions: 'Transactions de synchronisation manuelle',
  manual_sync_transactions_question: 'Voulez-vous synchroniser manuellement ces transactions?',
  improvement: 'AMÉLIORATION',
  fixed: 'FIXÉ',
  recover_carts: 'Récupérer des commandes',
  recover_carts_alert: 'Êtes-vous sûr de vouloir transférer la commande à cet appareil? Une fois que vous avez transféré la commande, il sera supprimé de l\'autre périphérique.',
  recover_carts_complete: 'Récupérer les commandes complètes. Maintenant, vous pouvez le sélectionner dans la population de sélection de commande.',
  select_all_carts: 'Sélectionnez toutes les commandes',
  payment_started: 'Le paiement a commencé',
  outstanding_inovices: 'Vous avez des factures exceptionnelles - veuillez visiter votre tableau de bord pour réconcilier ces',
  quota_warning_title: 'Le quota de stockage atteint une capacité maximale',
  quota_warning_text: 'Vous utilisez {{percent}}% de capacité.',
  quota_warning_text_hint: 'S\'il vous plaît essayez de libérer de l\'espace sur votre appareil.',
  welcome_to: 'Bienvenue à',
  kassa_open: 'Cash Registre disponible',
  kassa_closed: 'Cash fermé',
  kassa_closed_hint: 'Aller à un autre registre',
  enable_customer_screen: 'Activer l\'écran du client',
  cds_settings: 'Paramètres d\'affichage du client',
  cds_port: 'Port d\'affichage client',
  web_pos: 'Post-web',
  desktop_pos: 'Postop pos',
  pos: 'App pos',
  no_internet_connection: 'Vous ne pouvez pas effectuer cette action. Pas de connexion Internet.',
  storage: 'Stockage',
  storage_data: 'Stockage utilisé: {{quota}}%',
  extra_info_title: 'Entrez des informations supplémentaires sur l\'article',
  extra_info_sub_title: 'Carte-cadeau ou numéro de référence',
  net: 'Rapporter',
  delivery_details: 'Détails de livraison',
  delivery_time: 'Délai de livraison',
  pickup_time: 'Temps de ramassage',
  order_type: 'Type de commande',
  order_courier: 'Livré par',
  delivery_order_warning: 'Ceci est un ordre de livraison de {{source}}, ne peut pas y ajouter de nouveau produit.',
  delivery_toast: 'Commande de livraison reçue: {{by}} - {{receipt_id}}',
  delivery_requested: 'Livraison demandée: {{message}}',
  tables_or_carts_desc: 'Afficher les noms de commande sous forme de tables (E.G Commande 1 devient Tableau 1).',
  cart_always_open_desc: 'Gardez l\'ordre (articles, total, taxes, ...) Ouvert sur le côté droit de l\'écran.',
  show_product_image_desc: 'Afficher l\'image du produit dans la liste des articles de commande.',
  allow_cart_discounts_desc: 'Permettre des réductions sur la commande complète.',
  allow_product_discounts_desc: 'Permettre des réductions sur un seul produit.',
  enable_cart_item_remove_desc: 'Bouton Activer pour supprimer l\'élément de l\'ordre dans la liste Éléments de la commande.',
  show_calculator_desc: 'Montrez à Numpad pour sélectionner le montant des produits.',
  show_reps_waste_desc: 'Afficher les reps et le bouton de déchets dans l\'en-tête de l\'ordre.',
  barcode_customer_desc: 'Autoriser le scanner de code à barres à numériser l\'identifiant du client et ajouter le client à la commande.',
  show_stock_level_desc: 'Afficher les unités d\'actions du produit dans la carreau du produit.',
  show_price_desc: 'Afficher le prix du produit dans la tuile du produit.',
  barcode_scanner_desc: 'Activez le scanner de code à barres de numériser un produit et de l\'ajouter à la commande.',
  play_sound_success_scan_desc: 'Play Sound Lorsque vous numérisez avec succès un produit et l\'ajoutez à la commande.',
  show_product_image_list_desc: 'Afficher l\'image du produit dans la liste des produits.',
  activate_simple_tiles_desc: 'Retirez les animations et les effets de carreaux de produits (recommandés pour les anciens ordinateurs).',
  product_tile_size_desc: 'Taille de tuile du double produit',
  hide_categories_desc: 'Masquer la liste des catégories et ajoutez un modal pour le sélectionner.',
  categories_alphabetic_order_desc: 'Afficher la liste des catégories dans l\'ordre alphabétique.',
  products_alphabetic_order_desc: 'Afficher la liste des produits par ordre alphabétique.',
  skip_payment_success_info_desc: 'Ne pas afficher la boîte de dialogue de réussite du paiement (CV de transaction) après un paiement réussi.',
  allow_partial_payment_desc: 'Autoriser le paiement partiel (le client peut payer le total avec des méthodes de paiements différents).',
  allow_negative_payments_desc: 'Autoriser les paiements avec une valeur négative.',
  allow_qrcode_scanner_desc: 'Afficher le lecteur QRCode dans l\'en-tête de la commande.',
  show_pretax_receipt_desc: 'Montrer le montant de la taxe préalable dans la réception.',
  show_full_receipt_after_payment_desc: 'Afficher le récépissé complet dans le résumé de la transaction après un paiement réussi.',
  refund_same_method_desc: 'S\'assurer que les remboursements ne peuvent être effectués que sur la même méthode que la transaction d\'origine.',
  enable_customer_screen_desc: 'Activez les détails de l\'écran du client (besoin d\'afficher le matériel d\'affichage client).',
  kiosk_mode_desc: 'Activer le mode kiosque.',
  admin_pin_desc: 'Activer la goupille d\'administration (pour verrouiller / déverrouiller le mode kiosque).',
  employee_pin_desc: 'Activer la sélection des employés.',
  employee_lock_desc: 'Si le code PIN de l\'employé est activé, verrouillez le POS après chaque transaction et chaque employé de la force de ré-entrer à la broche.',
  open_close_shift_desc: 'Activer les changements ouverts / fermer.',
  reports_at_store_level_desc: 'Affiche les rapports au niveau de l\'emplacement (compter toutes les transactions de tous les périphériques).',
  language_desc: 'Changer la langue postale.',
  kitchen_print_desc: 'Ordre d\'impression après succès de transaction.',
  kitchen_delivery_print_desc: 'Ordre d\'impression Après avoir reçu une transaction réussie via un système de livraison.',
  show_order_bill_desc: 'Afficher la commande d\'impression et la facture dans l\'en-tête de la commande.',
  split_orders_items_desc: 'Diviser l\'ordre d\'impression dans des articles.',
  split_orders_amount_desc: 'Diviser l\'ordre d\'impression en montant.',
  partial_payment_print_desc: 'Imprimer la réception partielle après chaque paiement partiel.',
  show_ean_sku_receipt_desc: 'Montrer EAN / SKU dans la réception.',
  a4_paper_desc: 'Activer A4 Imprimer.',
  show_carts_list_alphabetic: 'Afficher la liste des commandes par ordre alphabétique',
  show_carts_list_alphabetic_desc: 'Afficher la liste des commandes par ordre alphabétique',
  show_cart_name_transaction: 'Afficher le nom de la commande dans la réception',
  show_cart_name_transaction_desc: 'Montrez le nom de la commande dans la réception (liste de transactions ou transaction complète).',
  open_kds: 'KDS',
  open_chat: 'Bavarder',
  partial_payment_bigger_zero: 'La valeur de paiement partielle doit être plus grande que 0',
  allow_product_exchanges: 'Autoriser les échanges de produits',
  allow_product_exchanges_desc: 'Ajouter des produits que le client souhaite échanger comme un montant négatif dans la commande',
  exchangeable_items: 'Articles échangeables',
  to_be_exchangeable: 'Être échangé',
  exchange_source: 'Source d\'échange',
  inventory_not_found: 'Inventaire du produit non trouvé',
  drop_ship: 'Goutte',
  enable_drop_ship: 'Activer le transport aller-retour',
  enable_drop_ship_desc: 'Activer la fonction d\'expédition déroulante.',
  drop_ship_warning: 'Drop-navire - Aucun client sélectionné',
  cart_as_tables_not_available: 'Les commandes en tant que tables La fonction n\'a aucun effet avec des plans d\'étage activés.',
  enable_deposit_button: 'Activer le bouton de dépôt',
  enable_deposit_button_desc: 'Activer le bouton de dépôt dans la sous-titre de l\'ordre',
  allow_voucher_scan_desc: 'Utilisez Scanner de code à barres pour rechercher un bon de réduction et appliquer.',
  remove_customer: 'Supprimer le client',
  remove_customer_question: 'Voulez-vous supprimer le client de cette commande?',
  search_product_barcode_server: 'Rechercher des produits sur le serveur',
  search_product_barcode_server_desc: 'Rechercher des produits sur le serveur s\'il n\'est pas trouvé localement.',
  show_floorplan_after_sale_desc: 'Afficher la mise en page de plancher après chaque vente au lieu de montrer la liste de produits',
  ccv_settings: 'Paramètres CCV',
  ask_no_covers_or_takeaway_desc: 'Toujours demander la configuration de la table',
  send_order_kds: 'Envoi de commande à KDS',
  mollie: 'Mollie',
  paynl: 'Paie',
  transactionFee: 'Frais de transaction',
  pay_later_note_partial: '* Il est seulement possible d\'avoir 1 paiement de paiement ultérieur par transaction.',
  no_print_delivery: 'Aucune information de livraison sur cette commande.',
  load_linked_cart: 'Veuillez cliquer sur la table pour charger des informations liées.',
  show_item_status: 'Afficher le statut de l\'article à l\'entrée de commande',
  show_item_status_desc: 'Montrez une bordure de surbrillance à gauche de la saisie de commande avec l\'état actuel de cet article.',
  access_denied: 'Accès refusé! S\'il vous plaît essayer de vous connecter avec le compte maître.',
  current_floor: 'Étage actuel',
  total_to_pay: 'Total de payer: {{currency}} {{amount}}',
  total_amount_paid: 'Total payé: {{currency}} {{amount}}',
  total_remaining: 'Total restant: {{currency}} {{amount}}',
  redeem: 'Racheter',
  current_balance: 'Solde actuel',
  card_activity: 'Activité de la carte',
  scan_fail: 'La recherche de code numérisé échoue',
  disallow_offline: 'POS ne peut pas fonctionner hors ligne',
  disallow_offline_fiscal: 'POS ne peut pas fonctionner hors ligne en raison de la loi fiscale.',
  fiscal_print_error: 'Impossible d\'imprimer un reçu sans info fiscal',
  enable_giftcard_scan: 'Activer l\'analyse de la carte-cadeau',
  enable_giftcard_scan_desc: 'Activez la fonctionnalité de numériser la carte-cadeau et de réduire son équilibre.',
  print_qr: 'Imprimer le code QR dans la réception',
  print_qr_desc: 'Imprimez le code QR au bas de la réception.',
  theme: 'Thème',
  theme_desc: 'Allumez le thème par défaut sur celui que vous aimez (la liste thème est fournie par emplacement).',
  invalid_addon_groups: 'Quelque chose ne va pas avec les groupes d\'addon du produit. S\'il vous plaît aller à votre tableau de bord et vérifier avant de l\'utiliser.',
  products_not_found: 'Produits non trouvés',
  show_suggestion_amounts: 'Afficher les boutons Montant de suggestion',
  show_suggestion_amounts_desc: 'Afficher / masquer les boutons Montant de suggestion en paiement Modal.',
  save_table: 'Enregistrer',
  save_cart: 'Enregistrer',
  enable_groups: 'Articles de commande de groupe',
  highlight_items: 'Éléments de surbrillance',
  set_ready_print: 'Définir comme prêt et imprimé',
  start_preparing: 'Commencer à préparer',
  group_qty: 'Quantité de groupe',
  show_more: 'Afficher plus d\'informations',
  notes_per_qty: 'NOTES PAR QUANTITÉE',
  print_online_order_receipts: 'Imprimer automatiquement les reçus pour les commandes en ligne payantes',
  select_items: 'Sélectionnez les articles',
  partial_payment_not_allowed_by_value: 'Les paiements partiels ne sont pas autorisés',
  product_media: 'Médias',
  show_product_media: 'Afficher les médias du produit',
  change_seat: 'Changer de siège',
  add_seat: 'Ajouter du siège',
  continue_partial_payment: 'Faire un autre paiement partiel?',
  seat: 'Siège',
  partial_payment_seat: 'Par siège',
  processing: 'Traitement',
  groupOrderPrintBySeat: 'Groupe Print des commandes par siège',
  fiscal_error: 'Erreur de module fiscal',
  switch_employee: 'Commutateur',
  no_order_removed: 'Non ordonné produits supprimés',
  connected_device: 'Dispositif connecté',
  deleted: 'Supprimé',
  ready_pickup: 'prêt à ramasser',
  employee_pin_changed: 'Pin employé a changé avec succès',
  order_kds_no_printer_msg: 'Commande envoyée à KDS mais pas à imprimante, définissez le périphérique d\'imprimante pour envoyer la commande',
  order_kds_printer_msg: 'Commande envoyée à KDS et à l\'imprimante',
  add_table: 'ajouté à la table',
  product_add_cart: 'Produit ajouté à la commande',
  product_ordered: 'Produit commandé',
  product_not_ordered: 'Produit non commandé',
  save_guests: 'Sauver les invités',
  guests_num: 'Nombre d\'invités',
  add_guest_num: 'Ajouter le nombre d\'invités',
  no_stores_created: 'Aucun emplacement créé',
  device_print_msg: 'Le périphérique sélectionné sera responsable d\'imprimer le reçu.',
  prep_time: 'Temps de préparation',
  no_tables_store: 'Pas de tables pour cet emplacement',
  go_back: 'Retourner!',
  remove_not_order_msg: 'Êtes-vous sûr de vouloir supprimer les éléments «non ordonnés» de',
  order_not_sent_back: 'La commande n\'a pas été envoyée à KDS! Êtes-vous sûr de vouloir y retourner?',
  no_available_addons_for_products: 'Aucun addons disponibles pour le produit sélectionné.',
  no_logged_employee: 'Aucun employé n\'est connecté!',
  empty_tables: 'Tables vides',
  close_search: 'Fermer la barre de recherche',
  no_empty_tables: 'Il n\'y a pas de tables vides!',
  my_tables: 'Mes tables',
  current_device: 'Appareil current',
  employee_info: 'Information de l\'employé',
  show_cart: 'Bonjour',
  show_seat_separator: 'Voir le séparateur de siège',
  set_print_device: 'Définir le périphérique d\'impression',
  table_search: 'Tables de recherche',
  order_by_seat: 'Commande par siège',
  table_list_view: 'Afficher les tables comme liste',
  customer_groups: 'Groupes de clients',
  sales_insights: 'Informations sur les ventes',
  product_insights: 'Informations sur les produits',
  store_insights: 'Informations sur le magasin',
  allow_ticket_scan: 'Vérifiez les billets à l\'aide du scanner de codes-barres',
  no_pin_employee: 'Employé sans PIN',
  allow_ticket_scan_desc: 'Utilisez le lecteur de codes-barres pour rechercher des billets.',
  admin_only_custom_item: 'Activer l\'élément personnalisé uniquement pour l\'administrateur',
  admin_only_custom_item_desc: 'Seuls les utilisateurs disposant de droits d\'administrateur peuvent créer un élément personnalisé. Vous devez activer également PIN employé.',
  mandatory_employee_pin: 'Activation obligatoire du code PIN de l\'employé.',
  admin_only_refunds: 'Seuls les administrateurs peuvent effectuer des remboursements',
  admin_only_refunds_desc: 'Seuls les administrateurs peuvent effectuer des remboursements. Vous devez également activer le code PIN de l\'employé.',
  round_cash_transactions_desc: 'Arrondir automatiquement les paiements en espèces au 0,05 le plus proche',
  unlimited_usage: 'Utilisation illimitée',
  infinite: 'Illimité',
  disable_custom_discounts: 'Désactiver les remises personnalisées',
  disable_custom_discounts_desc: 'Désactivez les remises personnalisées mais autorisez celles prédéfinies.',
  guest: 'Invité',
  select_method: 'Sélectionnez la méthode',
  manual_insert: 'Insertion manuelle',
  enter_code: 'Entrez le code',
  existing_by_value_payment: '* Vous ne pouvez pas mélanger le paiement par valeur et le paiement par siège et/ou article',
  order_sent: 'Commande Envoyée',
  select_multiple_seats: 'Sélectionnez plusieurs sièges',
  change_seat_or_guest_num: 'Changer de siège ou de numéro d\'invité',
  change_guest_num: 'Changer le numéro d\'invité',
  giftcard_low_value: '* Le solde de la carte-cadeau est inférieur à la valeur des articles sélectionnés. Essayez plutôt de payer en valeur.',
  order_by_seat_desc: 'Activer la fonction de commande par siège.',
  legacy_images: 'Imprimer des images à l\'aide de format obsolète',
  received_amount: 'Montant reçu',
  order_products_action: 'Commander des produits',
  ordered_products: 'Produits commandés',
  select_table: 'Sélectionner le tableau',
  ordered: 'Commandé',
  scan_or_type: 'Scannez ou tapez',
  type_code: 'Tapez le code',
  number_extension: 'Numéro d\'extension',
  move_processing_ticket_first: 'Déplacez d\'abord les tickets de traitement',
  disable_adblocker: 'Veuillez désactiver votre AdBlocker et actualiser la page',
  remove_partial_payment_by_item: 'Paiement partiel par article détecté ! Veuillez le supprimer afin de poursuivre cette action !',
  add_nutrients: 'Ajouter des nutriments',
  giftcard_warning_msg: 'La validité standard d\'une carte-cadeau est de 2 ans. Êtes-vous sûr de vouloir diminuer cela ?',
  edit_devicess_main_details: 'Modifiez les principaux détails des appareils.',
  create_custom_payment_methods_for_your_device: 'Créez des méthodes de paiement personnalisées pour votre devi.',
  show_open_carts_from_device: 'Afficher les commandes ouvertes de l\'appareil',
  create_discounts_for_your_stores: 'Créez des réductions pour votre emplacement.',
  create_reps_waste_for_your_stores: 'Créez des représentants et des déchets pour votre emplacement.',
  show_cat_bar: 'Afficher la barre des catégories',
  bulk_update: 'Éléments de mise à jour en masse',
  show_category_product_info: 'Afficher les informations sur la catégorie dans le produit',
  cart_will_empty: 'Le panier se videra automatiquement',
  seconds: 'secondes',
  corrupted_addons: 'Le produit a des addons corrompus. Veuillez vérifier dans le Backoffice.',
  add_more_items: 'Ajouter plus d\'articles',
  paying_with: 'Payer avec {{method}}',
  screen_saver_delay: 'Délai d\'économiseur d\'écran (secondes)',
  screen_saver: 'Économiseur d\'écran',
  enable_screen_saver: 'Activer l\'économiseur d\'écran',
  no_screen_saver: 'Impossible de détecter l\'économiseur d\'écran',
  screen_saver_info: 'Informations sur l\'économiseur d\'écran',
  type: 'Type',
  running_payment: 'En cours',
  print_brand: 'Imprimer la marque sur le reçu',
  show_product_options_long_press: 'Appuyez longuement pour afficher les options du produit',
  asap: 'Au plus vite',
  order_source: 'Source',
  ready_items: 'Articles prêts',
  disabled: 'Désactivée',
  ready_orders: 'Commandes prêtes',
  admin_only: 'Uniquement disponible pour les administrateurs',
  ask_buzzer_info: 'Information bipeur requis',
  ask_buzzer_info_desc: 'Exiger la saisie d\'informations de bipeurs pour chaque commande - par ex. le numéro ou ID du bipeur',
  buzzer: 'Bipeur',
  print_tickets: 'Imprimer les codes-barres des billets',
  print_tickets_desc: 'Imprimez des codes-barres pour tous les billets trouvés dans le reçu sur le reçu lui-même',
  show_employee_on_product: 'Montrer quel employé a ajouté chaque élément',
  surcharge: 'Surtaxe',
  changes_list: 'Liste des modifications',
  action_type: 'Type d\'action',
  previous_change: 'Changement précédent',
  new_changes: 'Nouveaux changements',
  removed_fields: 'Champs supprimés',
  updated_fields: 'Champs mis à jour',
  full_history: 'Historique complet',
  changes_history: 'Historique des modifications',
  product_history: 'Historique du produit',
  category_history: 'Historique des catégories',
  device_history: 'Historique de l\'appareil',
  enable_mandatory_customer_address: 'Adresse du client obligatoire',
  please_fill_all_fields: 'Veuillez remplir tous les champs obligatoires : numéro du bâtiment, rue, ville, état/province, code postal, pays, prénom et nom, adresse e-mail ou numéro de téléphone',
  show_product_price_cat: 'Afficher le prix et la catégorie du produit',
  open_cash_drawer_giftcard: 'Ouverture automatique du tiroir pour carte-cadeau Countr',
  generate_barcode: 'Générer un code-barres',
  select_file: 'Choisir le dossier',
  drop_file: 'Déposer le fichier',
  create_media: 'Créer des médias pour',
  upload_image: 'Télécharger une image',
  upload_file: 'Téléverser un fichier',
  add_custom_img: 'Ajoutez votre image personnalisée',
  select_media: 'Sélectionnez le type de média',
  external_url_iframe: 'Iframe d\'URL externe',
  select_ticket_print: 'Sélectionnez le type à imprimer',
  external_service_provider: 'Prestataire de services externe',
  barcode_input: 'Saisie de code-barres',
  stock_load_msg: 'Vérifiez tous les produits pour le stock. Cela peut prendre un certain temps. S\'il vous plaît, attendez!',
  no_available_stores: 'Aucun magasin disponible.',
  buy_combination_get_deal_new_price: 'Combiner des articles pour un prix contractuel',
  discount_specific_products_categories: 'Remise sur des produits ou catégories spécifiques',
  discount_all_prodcucts: 'Remise sur tous les produits',
  buy_x_items_get_cheapest_discount: 'Achetez x articles, obtenez le moins cher à prix réduit',
  spend_certain_amount_get_discount: 'Dépensez un certain montant, obtenez une réduction',
  customer_group_item_discount: 'Remise sur des groupes de clients spécifiques',
  item_surcharge: 'Supplément sur des produits, catégories ou groupes de clients spécifiques',
  cart_surcharge: 'Supplément sur toute la commande',
  import: 'importer',
  export: 'Exportation',
  simple: 'Simple',
  advanced: 'Avancée',
  delete_import: 'Supprimer l\'importation de produits',
  upload_csv: 'Téléchargez votre fichier CSV',
  open_file: 'Ouvrez le fichier en utilisant',
  save_file: 'Enregistrez le fichier au format .csv',
  value_remaining: 'Valeur restante :',
  qr_codes_for_line_items: 'Imprimer le code QR au lieu du code-barres pour les éléments de ligne',
  barcode_prefix: 'Préfixe de code-barres',
  barcode_digits: 'Longueur du code-barres / chiffres',
  external_identifier: 'Identificateur externe',
  cost_centre: 'Centre de coûts',
  shared_shift: 'Partager l\"équipe',
  shared_shift_desc: 'Changer d\"employé ne nécessitera pas de rouvrir le registre',
  empty_cart_voucher_warning: 'Ajoutez des produits pour utiliser le coupon',
  ask_for_help: 'Demander de l\'aide',
  help_is_coming: 'L\'aide arrive',
  you_paid: 'Tu as payé.',
  end_msg: 'Merci et au revoir.',
  scan_products: 'Scannez votre produit pour commencer',
  welcome_valk: 'Bienvenue à Valk',
  solution_checkout: 'Paiement des solutions!',
  basket_check_complete: 'État de la vérification du panier',
  start_scanning: 'Veuillez scanner votre produit',
  close_auto: 'Fermer automatiquement dans',
  open_close_employee: 'Ouvrir & Fermer par employé',
  print_table_name: 'Imprimer le nom de la table sur le reçu',
  print_note: 'Imprimer le note sur le reçu',
  reset_state: 'Réinitialiser l\'état de l\'application',
  sample_check_msg_top: 'S\'il vous plaît, attendez. Il devrait y avoir un contrôle.',
  sample_check_msg_bottom: 'Un employé viendra dès que possible.',
  disallow_close_register_open_tables: 'Ne fermez pas le POS si les ordres sont toujours ouvertes',
  opening_amount: 'Montant initial',
  cash_payments: 'Paiement en éspèces',
  msg_nix: 'Produit Nix18 a été ajouté, en attente de vérification avant de procéder au paiement',
  receipt_type: 'Veuillez choisir le type de reçu',
  full_receipt: 'Reçu complet',
  short_receipt: 'Court reçu',
  start: 'START',
  go_to_cart: 'Vers le panier',
  full_basket_check: 'Vérification du panier complet',
  missing_items_amount: 'Montant des articles manquants',
  extra_items_scanned: 'Quantité d\'articles supplémentaires',
  finish_check: 'Terminer la vérification',
  original_items: 'Articles originaux',
  checked_items: 'Articles cochés',
  no_receipt: 'Pas de reçu',
  refresh_categories: 'Actualiser les catégories',
  pay_img_text_up: 'Payez avec le Carte Bancaire, <br /> suivez les instructions sur le dispositif sur la gauche',
  pay_img_text_down: 'Après le paiement, le reçu suivra',
  old: 'Agé de',
  new: 'Nouveau',
  calculating: 'Calculateur',
  merging: 'Fusion en cours',
  select_table_from_list: 'Sélectionnez une table dans la liste pour fusionner toutes les autres tables sélectionnées',
  cant_merge_same: 'Impossible de fusionner le même tableau, veuillez sélectionner un autre tableau à fusionner',
  remove_from_table: 'supprimer du tableau',
  login_then_reboot: 'Veuillez d\'abord vous connecter, puis redémarrer l\'application',
  eat_in: 'Mange ici',
  preparing_ods: 'En train de préparer',
  ready_ods: 'Prêt',
  locked_pos: 'Le point de vente est verrouillé',
  fiscal_store_employee: 'Les magasins fiscaux doivent avoir un employé du registre afin d exploiter un point de vente',
  invalid_employee: 'Employé invalide',
  update_employee_ssn: 'Veuillez mettre à jour le numéro de sécurité sociale de l employé',
  social_security_number: 'Numéro de sécurité sociale',
  fiscal_one_employee: 'Vous devez avoir au moins un employé enregistré dans un magasin fiscal',
  fiscal_employee_ssn: 'Les employés inscrits dans les magasins fiscaux doivent avoir un numéro de sécurité sociale valide',
  employee_unable_delete: 'Impossible de supprimer l\'employé car il est lié à un magasin fiscal. Veuillez d\'abord retirer l\'employé du magasin.',
  vat_receipt: 'TICKET DE CAISSE TVA',
  not_vat_receipt: 'CECI N’EST PAS UN TICKET DE CAISSE TVA VALABLE',
  deposit_error: 'Erreur de dépôt',
  deposit_error_message: 'Vous ne pouvez pas avoir un dépôt négatif attaché à un produit avec un prix positif. Seuls les produits dont le prix est inférieur ou égal à 0 peuvent avoir un dépôt négatif.',
  assign_printers_to_your_location: 'Attribuez des imprimantes à votre emplacement',
  save_new_printer: 'Enregistrer la nouvelle imprimante',
  update_printer: 'Mettre à jour l\'imprimante',
  location_no_saved_printers: 'Il n\'y a pas d\'imprimantes enregistrées pour cet emplacement',
  work_in: 'TRAVAIL IN',
  work_out: 'TRAVAIL OUT',
  sort_items_by_action: 'Trier les éléments par action (en préparation, nouveau, prêt)',
  xreport: 'rapport X',
  zreport: 'rapport Z',
  xuserreport: 'X employée',
  zuserreport: 'Z employée',
  rounding: 'Arrondi',
  fiscal_settings: 'Paramètres fiscaux',
  pos_serial_num: 'Numéro de série',
  print_x_report: 'Imprimer le rapport X',
  shox_x_report: 'Afficher le rapport X',
  print_z_report: 'Imprimer le rapport Z',
  show_z_report: 'Afficher le rapport Z',
  unfiscalised_transactions_report_incorrect: 'Vous avez {{transaction_number}} transaction(s) non fiscalisée(s) - les rapports ne reflètent pas exactement la dernière situation',
  zip_mandatory: 'Code postal requis',
  number_mandatory: 'Numéro de bâtiment requis',
  multiple_options_found: 'Plusieurs options trouvées',
  print_card_info: 'Imprimer les informations de paiement par carte',
  second_screen: 'Affichage secondaire disponible',
  cashdrawer_partial_payment: 'Ouvrir le tiroir-caisse en cas de paiement partiel',
  right: 'Droite',
  left: 'Gauche',
  top: 'Haut',
  bottom: 'Bas',
  refund_print: 'Refund',
  rounding_print: 'Rounding',
  print_vat_name: 'Imprimer le nom de TVA par article sur le reçu',
  proforma_ticket: 'Pro-Forma Ticket',
  receipt_already_printed: 'Reçu déjà imprimé',
  allow_product_refunds: 'Autoriser les remboursements de produits',
  allow_product_refunds_desc: 'Permet le remboursement des produits',
  shift_per_store: 'Ouvrir/fermer la caisse enregistreuse par magasin',
  shift_per_store_desc: 'Shift sera persistant sur tous les appareils de la boutique',
  create_custom_loyalty_methods_for_your_store: 'Ajoutez des méthodes de fidélité pour votre magasin.',
  cannot_combine_positive_and_negative: 'Impossible d\'avoir à la fois des prix positifs et négatifs sur le même reçu',
  list_horizontal: 'Afficher les commandes horizontalement',
  opened_delivery_notes: 'Bons de livraison en attente',
  payment_on_account: 'Payer sur compte',
  receipt_id: 'ID du reçu',
  invoice_number: 'Numéro de facture',
  customer_id: 'N ° de client',
  delivery_notes: 'Bons de livraison',
  delivery_note_info: 'Informations sur le bon de livraison',
  download_invoice: 'Télécharger la facture',
  invoice_info: 'Informations sur la facture',
  total_number: 'Nombre total',
  create_invoice: 'Créer une facture',
  rows_per_page: 'Lignes par page',
  plus_deposit_of: 'Prix ​​plus dépôt de',
  add_note_to_item: 'Ajouter une note à l\'élément',
  round_electronic_transactions: 'Arrondir les paiements électroniques au 0,05 le plus proche',
  maximum_amount_single_transaction_excedeed: 'La transaction dépasse la limite de montant maximum',
  cannot_process_voucher_after_other_methods: 'Impossible de traiter les modes de paiement non remboursables après d\'autres paiements',
  all_giftcards_vouchers_processed: 'Toutes les cartes cadeaux et/ou bons d\'achat ont-ils été traités ?',
  all_cash_processed: 'Tous les paiements en espèces ont-ils été traités ?',
  disable_print_removed_items: 'Désactiver l\'impression automatique des éléments supprimés',
  disable_print_removed_items_desc: 'Désactivez l\'impression automatique des éléments supprimés - par ex. article annulé',
  note_too_long: 'La note est trop longue',
  one_ticket_per_cart: 'Un ticket par panier autorisé',
  no_timeslots_available: 'Aucun créneau horaire disponible'
}

export default frLang
