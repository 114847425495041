import { memo } from 'react';
import { Text } from 'react-internationalization'
import Util from '../../../utils/Util'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import './../Cart.scss'

const DeliveryDetails = memo(({ cart }) => {
  return (
    <div className="delivery-details">
      <ExpansionPanel defaultExpanded={true} className="delivery-details-panel">
        <ExpansionPanelSummary
          expandIcon={<span className="icon-left-arrow delivery-details-panel-icon" />}
          aria-controls="panel1a-content"
          className="delivery-details-panel-header">
          <strong>
            <Text id="delivery_details" />
          </strong>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="delivery-details-panel-details">
          <div className="print">
            {!!cart.extras.delivery.note && (
              <div className="row">
                <strong style={{ color: '#fe1111' }}>
                  <b>
                    <Text id="note" />
                  </b>
                  : {cart.extras.delivery.note}
                </strong>
              </div>
            )}
            {!!cart.extras.delivery.print ? (
              Object.keys(cart.extras.delivery.print).map(
                printKey =>
                  !!cart.extras.delivery.print[printKey] && (
                    <div className="row" key={printKey}>
                      <strong>
                        <Text id={printKey} />:
                      </strong>{' '}
                      {Util.printField(cart.extras.delivery.print, printKey)}
                    </div>
                  )
              )
            ) : (
              <div className="row">
                <span>
                  <Text id="no_print_delivery" />
                </span>
              </div>
            )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
})

export default DeliveryDetails
