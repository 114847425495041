const ADD_ACTION = 'add'
const REMOVE_ACTION = 'remove'

export default class AddonUtils {
  static getAddonName = (addon, lang = 'en') => {
    return addon.translations?.names[lang] ?? addon.name
  }

  static getAddonGroupName = (group, lang = 'en') => {
    return group._id.translations?.names[lang] ?? group._id.name
  }

  static getAddonNamePrice = (addon, storeId, currency, language) => {
    return `${this.getAddonName(addon, language)} (${currency} ${this.getAddonPrice(
      storeId,
      addon
    ).toFixed(2)}${this.isSoldByWeight(addon) ? '/g)' : ')'}`
  }

  static calculateAddonAmount = (addonsList, addon) => {
    if (!addonsList.length) {
      return 0
    }

    return addonsList.findIndex(a => a._id === addon._id) >= 0
      ? addonsList.find(a => a._id === addon._id).amount
      : 0
  }

  static isAddonGroups = product => {
    return !!product.addonGroups.length
  }

  static isGroupsInvalid = groups => {
    return groups.map(g => !!g._id).indexOf(false) >= 0
  }

  static returnMinMaxGroup = (group, type) => {
    // Fallback for corrupted groups
    if (!group._id) {
      return 0
    }

    // Need zero check because if !!group[type] will return false
    return !!group[type] || group[type] === 0
      ? group[type]
      : !!group._id[`default_${type}`]
        ? group._id[`default_${type}`]
        : 0
  }

  static addonBelongsGroup = (addonId, group) => {
    return group._id.addons.findIndex(a => a._id === addonId) >= 0
  }

  static getAddonPrice = (storeId, addon, type = 'price') => {
    if (addon.local_prices?.length) {
      const priceIndex = addon.local_prices.findIndex(local => local.store === storeId)

      if (priceIndex >= 0) {
        return addon.local_prices[priceIndex][type] ? addon.local_prices[priceIndex][type] : 0
      }
    }

    return addon[type] ? addon[type] : 0
  }

  static getAddonProduct = (addon, amount, storeId) => {
    const addonProduct = {
      _id: addon._id,
      name: addon.name,
      amount: amount,
      price: this.getAddonPrice(storeId, addon),
      cost_price: this.getAddonPrice(storeId, addon, 'cost_price')
    }

    return addon.tax ? { ...addonProduct, tax: addon.tax } : addonProduct
  }

  static editAddon = (currentAddons, action, addon, storeId, amount) => {
    const aux = [...currentAddons]
    const index = currentAddons.findIndex(a => a._id === addon._id)

    if (amount) {
      if (index >= 0) {
        aux[index].amount = amount
      } else {
        aux.push(this.getAddonProduct(addon, amount, storeId))
      }

      return aux
    }

    if (action === ADD_ACTION) {
      if (index >= 0) {
        aux[index].amount++
      } else {
        aux.push(this.getAddonProduct(addon, 1, storeId))
      }
    } else if (action === REMOVE_ACTION && index >= 0) {
      if (aux[index].amount > 1) {
        aux[index].amount--
      } else {
        aux.splice(index, 1)
      }
    }

    return aux
  }

  static validateCurrentAddons = (current, product) => {
    const isValid = []

    product.addonGroups.forEach(group => {
      isValid.push(this.validateCurrentGroup(current, group))
    })

    return isValid.indexOf(false) >= 0
  }

  static validateCurrentGroup = (current, group) => {
    const sum = this.sumAllCurrentAddonsFromGroup(group, current)
    const min = AddonUtils.returnMinMaxGroup(group, 'min')
    const max = AddonUtils.returnMinMaxGroup(group, 'max')
    return sum >= min && max === 0 ? true : sum >= min && sum <= max
  }

  static sumAllCurrentAddonsFromGroup = (group, current) => {
    return current.reduce((acc, addon) => {
      return this.addonBelongsGroup(addon._id, group) ? acc + addon.amount : acc
    }, 0)
  }

  static isSoldByWeight = addon => {
    return !!addon.options && !!addon.options.sold_by_weight
  }
}
