import { IntercomAPI } from 'react-intercom'
import DesktopUtils from './DesktopUtils'

const TRACK_EVENT = 'trackEvent'

export default class IntercomUtils {
  static returnIntercomEventType() {
    return DesktopUtils.isDesktop() ? 'pos_desktop' : 'pos_web'
  }

  static trackEvent(event) {
    if (process.env.REACT_APP_PROD === 'true') {
      IntercomAPI(TRACK_EVENT, `${this.returnIntercomEventType()}:${event}`)
    }
  }
}
