import { PureComponent } from 'react';
import { connect } from 'react-redux'

import { cartUtils } from './../../utils/cartUtils'

import Grid from '@material-ui/core/Grid'

import { Text } from 'react-internationalization'
import countriesList from './../../utils/countriesList'

import './Invoice.css'

const mapStateToProps = state => {
  return {
    user: state.user,
    devices: state.devices
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

class Invoice extends PureComponent {
  returnStoreLogo = () => {
    const store = this.props.devices.store

    if (store.receipt) {
      const img = store.receipt.header.find(h => h.type === 'image')

      if (img) {
        return img.content
      }
    }

    return 'https://ci4.googleusercontent.com/proxy/cb1Gvx2_aVOFEWnAJ_mxYRV4F4JEkMRDhPstt6lxEkETloEdI8Kt2ppo6ZnzoK8BKGHY6nFqW68X1QBTsoeGFHfktUhs72UCF_ZwRCah9u8BbrArIEJOkWVXqsZ8GuBwQkeKC_4Be-fEOYIOaAI148HSHmttximURKVfKw=s0-d-e1-ft#https://f300a2c61bdfd53531e6-5cf66e41624a35dd734b848e9c8ea320.ssl.cf3.rackcdn.com/countr-vertical-tag.png'
  }

  returnCountry = countryCode => {
    const country = countriesList.find(c => c.code === countryCode)

    return country ? country.name : ''
  }

  returnDuePayment = transaction => {
    const currentTime = new Date(transaction.date)

    if (transaction.customer.billing.payment_term_days) {
      currentTime.setDate(currentTime.getDate() + transaction.customer.billing.payment_term_days)
    } else {
      currentTime.setDate(currentTime.getDate() + 14)
    }

    return currentTime.toDateString()
  }

  render() {
    return (
      <Grid container alignItems="center" justifyContent="center" className="center">
        <Grid item xs={12}>
          <div className="invoice-div">
            <div>
              <div className="invoice-section">
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  className="invoice-table"
                  align="center"
                  border={0}>
                  <tbody>
                    <tr>
                      <td className="invoice-tbody-td">
                        <table
                          cellPadding={0}
                          cellSpacing={0}
                          className="invoice-table-inner"
                          width="100%"
                          border={0}>
                          <tbody>
                            <tr className="b-b">
                              <td className="invoice-tbody-td-inner">
                                <img
                                  alt="Company img"
                                  height="136px"
                                  src={this.returnStoreLogo()}
                                  width="136px"
                                  className="CToWUd"
                                />
                              </td>
                              <td style={{ borderCollapse: 'collapse' }} />
                              <td style={{ textAlign: 'right', borderCollapse: 'collapse' }}>
                                <h1>&nbsp;</h1>
                                <b>{this.props.user.user.billing_info.organization}</b>
                                <p className="invoice-td-p">
                                  {this.props.user.user.billing_info.address1} <br />
                                  {this.props.user.user.billing_info.zip} -{' '}
                                  {this.props.user.user.billing_info.city}, <br />
                                  {this.returnCountry(
                                    this.props.user.user.billing_info.country
                                  )}{' '}
                                  <br />
                                  KvK - {this.props.user.user.billing_info.registration} <br />
                                  BTW - {this.props.user.user.billing_info.vat}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="invoice-section">
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  className="invoice-table"
                  align="center"
                  border={0}>
                  <tbody>
                    <tr>
                      <td className="invoice-tbody-td">
                        <table
                          cellPadding={0}
                          cellSpacing={0}
                          className="invoice-table-inner"
                          width="100%"
                          border={0}>
                          <tbody>
                            <tr>
                              <td className="invoice-tbody-td-inner">
                                <h2 style={{ marginTop: '0!important' }}>
                                  <Text id="billed_to" />
                                </h2>
                                <b>
                                  <a href={`mailto:${this.props.transaction.customer.email}`}>
                                    {this.props.transaction.customer.email}
                                  </a>
                                </b>
                                <br />
                                <p className="invoice-td-p">
                                  BTW - {this.props.transaction.customer.billing.vat}
                                  <br />
                                </p>
                                <p className="invoice-td-p">
                                  KvK - {this.props.transaction.customer.billing.registration}
                                  <br />
                                </p>
                                <p className="invoice-td-p">
                                  {this.props.devices.store.name}
                                  <br />
                                </p>
                              </td>
                              <td style={{ borderCollapse: 'collapse' }} />
                              <td
                                style={{
                                  verticalAlign: 'top',
                                  textAlign: 'right',
                                  borderCollapse: 'collapse'
                                }}>
                                <span>
                                  <b>
                                    <span className="il">
                                      <Text id="invoice" />:{' '}
                                    </span>
                                  </b>
                                  # {this.props.transaction.receipt_id}
                                </span>
                                <br />
                                <span>
                                  <b>
                                    <span className="il">
                                      <Text id="invoice" />
                                    </span>{' '}
                                    <Text id="date" />:{' '}
                                  </b>
                                  {new Date(this.props.transaction.date).toDateString()}
                                </span>
                                <br />
                                <span>
                                  <b>
                                    <Text id="payment_due" />:{' '}
                                  </b>
                                  {this.returnDuePayment(this.props.transaction)}
                                </span>
                                <br />
                                <span>
                                  <b>
                                    <Text id="amount_due" />:{' '}
                                  </b>
                                  {this.props.transaction.currency.symbol}{' '}
                                  {parseFloat(this.props.transaction.total).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="invoice-section">
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  className="invoice-table"
                  align="center"
                  border={0}>
                  <tbody>
                    <tr>
                      <td className="invoice-tbody-td">
                        <table
                          cellPadding={0}
                          cellSpacing={0}
                          className="invoice-table-inner"
                          width="100%"
                          border={0}>
                          <tbody>
                            <tr
                              style={{
                                fontSize: '15px',
                                borderBottom: '1px solid #ecedee',
                                textAlign: 'left',
                                padding: '15px 0'
                              }}>
                              <th style={{ padding: '5px' }}>
                                <Text id="product" />
                              </th>
                              <th style={{ padding: '5px' }}>
                                <Text id="quantity" />
                              </th>
                              <th style={{ padding: '5px' }}>
                                <Text id="unit_price" /> <small>({<Text id="discount" />})</small>
                              </th>
                              <th style={{ padding: '5px', textAlign: 'right' }}>
                                <Text id="total_price" />
                              </th>
                            </tr>
                            {this.props.transaction.items.map(item => (
                              <tr
                                style={{ textAlign: 'left' }}
                                key={`${cartUtils.getCartEntryId(item)}`}>
                                <td style={{ padding: '5px', borderCollapse: 'collapse' }}>
                                  <b>
                                    {item.product.name}
                                    {item.product.current_variant.name !== 'Default'
                                      ? ` - ${item.product.current_variant.name}`
                                      : ''}
                                  </b>
                                </td>
                                <td style={{ padding: '5px', borderCollapse: 'collapse' }}>
                                  {item.amount}
                                </td>
                                <td style={{ padding: '5px', borderCollapse: 'collapse' }}>
                                  {this.props.transaction.currency.symbol}{' '}
                                  {parseFloat(
                                    item.product.current_variant.price *
                                      (1 - item.product.discount !== 'undefined' ?? 0)
                                  ).toFixed(2)}{' '}
                                  {item.product.discount > 0 && (
                                    <font style={{ color: '#ff0000' }}>
                                      (-{parseFloat(item.product.discount * 100).toFixed(2)}%)
                                    </font>
                                  )}
                                </td>
                                <td
                                  style={{
                                    padding: '5px',
                                    textAlign: 'right',
                                    borderCollapse: 'collapse'
                                  }}>
                                  {this.props.transaction.currency.symbol}{' '}
                                  {parseFloat(
                                    item.product.current_variant.price *
                                      (1 - item.product.discount !== 'undefined' ?? 0) *
                                      item.amount
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="invoice-section">
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  className="invoice-table"
                  align="center"
                  border={0}>
                  <tbody>
                    <tr>
                      <td className="invoice-tbody-td">
                        <table
                          cellPadding={0}
                          cellSpacing={0}
                          className="invoice-table-inner"
                          width="100%"
                          border={0}>
                          <tbody>
                            <tr style={{ textAlign: 'right' }}>
                              <td style={{ borderCollapse: 'collapse' }} />
                              <td
                                style={{
                                  padding: '5px',
                                  borderBottom: '1px solid #dde1e2',
                                  borderCollapse: 'collapse'
                                }}>
                                <span>
                                  <b>
                                    <Text id="subtotal" />:{' '}
                                  </b>{' '}
                                  {this.props.transaction.currency.symbol}{' '}
                                  {parseFloat(this.props.transaction.sub_total).toFixed(2)}
                                </span>
                                <br />
                                <span>
                                  <Text id="discount" />: {this.props.transaction.currency.symbol}{' '}
                                  {parseFloat(
                                    this.props.transaction.total * this.props.transaction.discount
                                  ).toFixed(2)}{' '}
                                  ({this.props.transaction?.discount ?? 0 * 100}%)
                                </span>
                                <br />
                                <span>
                                  <Text id="taxes" />: {this.props.transaction.currency.symbol} --
                                  {cartUtils.getCartTaxes(this.props.transaction)}
                                </span>
                                <br />
                              </td>
                            </tr>
                            <tr style={{ textAlign: 'right' }}>
                              <td style={{ borderCollapse: 'collapse' }} />
                              <td style={{ padding: '5px', borderCollapse: 'collapse' }}>
                                <span>
                                  <b>
                                    <Text id="total" />:{' '}
                                  </b>{' '}
                                  {this.props.transaction.currency.symbol}{' '}
                                  {console.log(this.props.transaction)}
                                  {parseFloat(this.props.transaction.total).toFixed(2)}
                                </span>
                                <br />
                                <span>
                                  <b>
                                    <Text id="amount_due" />:{' '}
                                  </b>{' '}
                                  {this.props.transaction.currency.symbol}{' '}
                                  {parseFloat(this.props.transaction.total).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const InvoiceConnected = connect(mapStateToProps, mapDispatchToProps)(Invoice)
export default InvoiceConnected
