import { memo } from 'react';

import { cartUtils } from '../../utils/cartUtils'
import CartItem from './CartItem'

import List from '@material-ui/core/List'
import './CartItemsList.css'

const CartItemsList = memo(props => {
  return (
    <List component="nav" disablePadding={true} className="cart-items-list">
      {props.cart.items.map((item, index) => (
        <CartItem
          key={
            props.item
              ? `${cartUtils.getCartEntryId(props.item)}-${props.item.product.discount}`
              : index
          }
          hasDivider={props.hasDivider}
          cart={props.cart}
          item={item}
          index={index}
          isModal={props.isModal}
        />
      ))}
    </List>
  )
})

export default CartItemsList
