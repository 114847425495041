import { cartUtils } from '../../utils/cartUtils'

import Grid from '@material-ui/core/Grid'
import { AddRemoveButtons } from '@countr/ui'

import './NewPaymentModal.scss'

const PayPerItemButtons = props => {
  return (
    <Grid
      container
      key={cartUtils.getCartEntryId(props.item)}
      style={{ minHeight: 74 }}
      justifyContent="center"
      alignItems="center">
      <AddRemoveButtons
        handleAdd={() =>
          props.disabled ? null : props.handleUpdateCurrent(props.item, 'add', props.quantity)
        }
        handleRemove={() =>
          props.disabled ? null : props.handleUpdateCurrent(props.item, 'remove', props.quantity)
        }
        value={
          (props.quantity && props.getPaidAmount(props.item, props.cart) === props.quantity) ||
          props.getPaidAmount(props.item, props.cart) === props.item.amount ? (
            <span className="icon-close" />
          ) : (
            props.getCurrentValue(props.item, props.cart)
          )
        }
      />
    </Grid>
  )
}

export default PayPerItemButtons
