import { Component } from 'react';
import { connect } from 'react-redux'

import { AppInstances } from './../../../utils/countrSdkInstance'
import { PrinterUtils } from './../../../utils/PrinterUtils'
import { RequestQueue } from './../../../utils/RequestQueue'
import Util from '../../../utils/Util'

import { loadingFalse } from '../../../store/actions/loading'
import { selectCart, editCart } from '../../../store/actions/carts'
import { setRefundExtras } from '../../../store/actions/transactions'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { Text, translate } from 'react-internationalization'
import LoadBar from '../../generic/LoadBar'
import { CONST } from './const'
import './PayplazaModal.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user,
    carts: state.carts,
    devices: state.devices,
    settings: state.settings,
    payments: state.payments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadingFalse: () => dispatch(loadingFalse()),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    setRefundExtras: extras => dispatch(setRefundExtras(extras))
  }
}

const LOG_TAG = '[PAYPLAZA]'
const PROVIDER = 'payplaza'

class PayplazaModal extends Component {
  state = {
    transactionUpdateMsg: '',
    isTransactionComplete: true,
    transactionSucceeded: false,
    transactionStatus: '',
    resultCode: '',
    printContent: '',
    currentRef: '',
    disableCancelButton: false,
    isAuthorizationOk: false,
    printResponse: {}
  }

  action = args => {
    const cartId = this.props.carts.selectedCart._id

    if (!cartId) {
      return Promise.reject(CONST.errors.NO_CART_ID)
    }

    const payArgs = {
      amount: args.amount,
      reference: args.reference,
      provider: PROVIDER,
      credentials: { ecr_id: args.ecrId },
      description: args.description,
      currencyCode: args.currency,
      transactionType: this.props.type
    }

    return AppInstances.getCountrSdk().then(countr => {
      // Register status listiners
      countr.ws.on('c' + cartId + ':payment.message', this.transactionMessage)
      countr.ws.on('c' + cartId + ':payment.print', this.print)
      countr.ws.on('c' + cartId + ':payment.started', this.transactionMessage)
      countr.ws.on('c' + cartId + ':payment.terminalWakeup', this.wakeUpTerminal)
      countr.ws.on('c' + cartId + ':payment.selectMethod', this.transactionSelectMethod)
      countr.ws.on('c' + cartId + ':payment.result', this.result)
      countr.ws.on('c' + cartId + ':payment.cancelled', this.cancelled)

      return countr.carts.readOne.pay(cartId, payArgs).then(
        result => {
          return Promise.resolve(result)
        },
        error => {
          return Promise.reject(error)
        }
      )
    })
  }

  handleCancelPayplazaPayment = () => {
    console.log('### ' + LOG_TAG + ' cancelling payment...')
    AppInstances.getCountrSdk().then(countr => {
      console.log(`### ${LOG_TAG} cart: #${this.props.carts.selectedCart._id} -> payment.cancel`)
      countr.ws.emit('c' + this.props.carts.selectedCart._id + ':payment.cancel', {}, {})
      this.setState({ disableCancelButton: true })
    })
  }

  transactionMessage = message => {
    const msg = translate(CONST.transactionStatus[message])
    const translatedMessage = msg && msg.length ? msg : message

    let isOk = JSON.parse(JSON.stringify(this.state.isAuthorizationOk))

    if (message === 'AUTHORIZATION_OK') {
      isOk = true
    }

    this.setState({ transactionUpdateMsg: translatedMessage, isAuthorizationOk: isOk }, () => {
      if (isOk) {
        this.setState({ disableCancelButton: true })
      }
    })
  }

  transactionSelectMethod = response => {
    console.log('### ' + LOG_TAG + ' transactionSelectMethod: ' + JSON.stringify(response))
    AppInstances.getCountrSdk().then(countr => {
      countr.ws.emit('c' + this.props.carts.selectedCart._id + ':transaction:response')
    })
  }

  wakeUpTerminal = payload => {
    if (payload && payload.wakeUpIp && payload.wakeUpPort) {
      const wakeUpIp = payload.wakeUpIp
      const wakeUpPort = payload.wakeUpPort
      console.log(`### ${LOG_TAG} wakeUpTerminal -> ${wakeUpIp}:${wakeUpPort}`)
      let web = new WebSocket(`wss://${wakeUpIp}:${wakeUpPort}`)
      web.onopen = event => {
        console.log(`### ${LOG_TAG} wakeUpTerminalSeding -> ${JSON.stringify(event)}`)
        web.send(JSON.stringify(event))
      }
      web.onmessage = function incoming(message) {
        message = JSON.parse(message.data)
        console.log(`### ${LOG_TAG} wakeUpTerminalMessage -> ${message}`)
      }
      web.onerror = function error(error) {
        console.log(`### ${LOG_TAG} wakeUpTerminalError -> ${JSON.stringify(error)}`)
        web.close()
        web = null
      }
    } else {
      // payment.terminalWakeup event return no ip
      const result = {
        response: {
          errorMessage: translate(CONST.transactionStatus.TERMINAL_ERROR)
        }
      }
      this.result(result)
    }
  }

  print = response => {
    console.log('### ' + LOG_TAG + ' print: ')
    console.log(response)
    this.setState({ printResponse: response }, () => {
      if (response.orderRef && response.orderRef === this.state.currentRef.toString()) {
        this.setState({
          printContent:
            response.cardParsedData.clientTicket !== undefined
              ? response.cardParsedData.clientTicket
              : response.cardParsedData.failureTicket
        })

        this.updateCartsInfo(response)
      }

      if (response.transactionSucceeded) {
        const succeeded = response.transactionSucceeded === 'false' ? false : true
        const msg = translate(CONST.transactionStatus[response.transactionStatus])
        this.setState({ transactionSucceeded: succeeded, transactionStatus: msg }, () => {
          AppInstances.getCountrSdk().then(countr => {
            console.log(
              `### ${LOG_TAG} cart: #${this.props.carts.selectedCart._id} -> printoutcome`
            )
            countr.ws.emit(
              'c' + this.props.carts.selectedCart._id + ':payment.printoutcome',
              { success: true },
              {}
            )

            // Payment not succeeded, create a error entry in mongo
            // if (!succeeded) {
            //   this.createError(countr, response)
            // }
          })
        })
      }
    })
  }

  createError = (countr, response) => {
    countr.e
      .create({
        source: Util.returnPOSType(),
        message: `[${Util.returnPOSType()}] Payment failed`,
        user: this.props.user.user._id,
        store: this.props.devices.store._id,
        device: this.props.devices.device._id,
        provider: 'payplaza',
        info: {
          cardParsedData: response.cardParsedData,
          provider: 'payplaza',
          orderRef: response.orderRef,
          description:
            ((this.props.devices.store || {}).name || '').substr(0, 10) + ' #' + response.orderRef,
          cart: this.props.carts.selectedCart._id
        }
      })
      .then(function (response) {
        console.log(`### ${LOG_TAG} Error create response: ` + JSON.stringify(response))
      })
      .catch(function (error) {
        console.log(`### ${LOG_TAG} Error creating server error: ` + JSON.stringify(error))
      })
  }

  getPrintResponse = result => {
    if (result.history && result.history.length) {
      const printCommand = result.history.find(h => h.eventName === 'print:command')

      if (printCommand && printCommand.response) {
        const orderRef = printCommand.response.orderRef
        const transactionSucceeded =
          printCommand.response.transactionSucceeded === 'true' ? true : false

        this.setState({
          printContent:
            printCommand.response.cardParsedData.clientTicket !== undefined
              ? printCommand.response.cardParsedData.clientTicket
              : printCommand.response.cardParsedData.failureTicket
        })

        this.updateCartsInfo(printCommand.response)

        return { orderRef, transactionSucceeded }
      } else {
        return {}
      }
    } else {
      return {}
    }
  }

  updateCartsInfo = response => {
    const isRefundRecovery = JSON.parse(localStorage.getItem('CountrWeb:RefundInProgress'))
    if (this.props.type === 'REFUND' || isRefundRecovery) {
      this.props.setRefundExtras(response)
    }

    const cart = Object.assign({}, this.props.carts.selectedCart)
    const info = Object.assign({}, response)
    let card_print_info = {}

    if (info.cardParsedData) {
      card_print_info = Object.assign({}, info.cardParsedData)
      delete info.cardParsedData
    }

    cart.info = info
    cart.card_print_info = card_print_info
    this.props.selectCart(cart)
    this.props.editCart(cart)

    AppInstances.getCountrSdk().then(socket => {
      socket.carts.update(cart._id, cart).then(
        cart => {},
        error => {
          RequestQueue.enqueueAction({
            type: 'carts',
            action: 'update',
            payload: cart
          })
        }
      )
    })
  }

  checkSuccess = result => {
    if (this.props.type === 'REPRINT_LAST') {
      let orderRef = this.state.printResponse.orderRef
      let transactionSucceeded = this.state.transactionSucceeded

      // Didnt triggered this.print method, trying to recovery information from history
      if (Object.keys(this.state.printResponse).length === 0 && !orderRef) {
        const printResponse = this.getPrintResponse(result)

        if (printResponse) {
          orderRef = printResponse.orderRef
          transactionSucceeded = printResponse.transactionSucceeded
        }
      }
      return (
        result.success &&
        result.response.resultCode &&
        this.state.currentRef.toString() === orderRef &&
        transactionSucceeded
      )
    } else {
      return result.success && result.response.resultCode
    }
  }

  result = async result => {
    console.log(`###${LOG_TAG} result: `, result)

    // Send the acknowledge to the server
    const countr = await AppInstances.getCountrSdk()
    countr.ws.emit('c' + this.props.carts.selectedCart._id + ':payment.acknowledge_result', {}, {})

    if (result && result.response) {
      if (this.checkSuccess(result)) {
        this.setState({
          isTransactionComplete: true,
          transactionUpdateMsg: this.state.transactionStatus.length
            ? this.state.transactionStatus
            : translate(result.response.resultCode.toLowerCase()),
          resultCode: result.response.resultCode
        })

        // Closing payplaza modal sending result code back to payment modal
        this.props.completePayplazaTransaction(result.response.resultCode)
      } else if (result.response.errorMessage) {
        this.createErrorNotSuccess(result)
        this.setState({
          isTransactionComplete: true,
          transactionUpdateMsg: result.response.errorMessage,
          resultCode: result.response.errorMessage
        })
      } else {
        if (result.response.reason) {
          this.createErrorNotSuccess(result)
          this.setState({
            isTransactionComplete: true,
            transactionUpdateMsg: result.response.reason,
            resultCode: result.response.reason
          })
        } else {
          this.createErrorNotSuccess(result)
          this.setState({
            isTransactionComplete: true,
            transactionUpdateMsg: translate(CONST.resultCode.CANCELED),
            resultCode: CONST.resultCode.CANCELED
          })
        }
      }
    } else {
      this.createErrorNotSuccess(result)
      this.setState({
        isTransactionComplete: true,
        transactionUpdateMsg: translate(CONST.resultCode.CANCELED),
        resultCode: CONST.resultCode.CANCELED
      })
    }

    // payment was completed (it can be success or fail) so it doesnt need to performe a recovery action
    localStorage.removeItem('CountrWeb:PayplazaRecovery')
    this.removeListeners()
  }

  cancelled = response => {
    console.log('### ' + LOG_TAG + ' cancelled: ' + JSON.stringify(response))
  }

  createErrorNotSuccess = async result => {
    const countr = await AppInstances.getCountrSdk()
    countr.e
      .create({
        source: Util.returnPOSType(),
        message: `[${Util.returnPOSType()}] Payment failed`,
        user: this.props.user.user._id,
        store: this.props.devices.store._id,
        device: this.props.devices.device._id,
        provider: 'payplaza',
        info: {
          provider: 'payplaza',
          orderRef: this.state.currentRef.toString(),
          result: JSON.stringify(result)
        }
      })
      .then(function (response) {})
      .catch(function (error) {
        console.log(`### ${LOG_TAG} Error creating server error: ` + JSON.stringify(error))
      })
  }

  removeListeners = () => {
    AppInstances.getCountrSdk().then(countr => {
      const cartId = this.props.carts.selectedCart._id
      countr.ws.off('c' + cartId + ':payment.message')
      countr.ws.off('c' + cartId + ':payment.print')
      countr.ws.off('c' + cartId + ':payment.started')
      countr.ws.off('c' + cartId + ':payment.terminalWakeup')
      countr.ws.off('c' + cartId + ':payment.selectMethod')
      countr.ws.off('c' + cartId + ':payment.result')
      countr.ws.off('c' + cartId + ':payment.cancelled')
    })
  }

  printPayplazaReceipt = () => {
    // this.state.printContent
    if (this.state.printContent && this.state.printContent.length) {
      PrinterUtils.payplazaReceipt(this.state.printContent)
    }
  }

  createPayArgs = () => {
    const recovery = JSON.parse(localStorage.getItem('CountrWeb:PayplazaRecovery'))

    if (recovery !== null) {
      this.setState({ currentRef: recovery.args.reference.toString() })
      return recovery.args
    } else {
      const ref =
        ((this.props.devices.store || {}).store_id || '0') +
        '-' +
        (this.props.devices.device.device_id || '0') +
        '-' +
        Math.round(new Date().getTime(), null)

      this.setState({ currentRef: ref.toString() })

      const payplazaPayment = this.props.payments.paymentMethods.find(
        method => method.method === 'payplaza'
      )
      const payplazaECR = payplazaPayment.extra

      return {
        reference: ref,
        description: ((this.props.devices.store || {}).name || '').substr(0, 10) + ' #' + ref,
        ecrId: payplazaECR,
        amount: Math.round(this.props.totalToPay * 100),
        currency: this.props.currency
      }
    }
  }

  createRecoveryAction = args => {
    const recovery = {
      cartId: this.props.carts.selectedCart._id,
      args: args
    }

    localStorage.setItem('CountrWeb:PayplazaRecovery', JSON.stringify(recovery))
  }

  componentDidMount = () => {
    this.props.loadingFalse()
    const args = this.createPayArgs()
    this.createRecoveryAction({ ...args })

    console.log(this.props.type)

    this.setState({ isTransactionComplete: false })

    this.action(args).then(
      success => {
        console.log(`### [${this.props.type}] PaymentModal -> payPayPlaza -> success`, success)
      },
      error => {
        console.log(`### [${this.props.type}] PaymentModal -> payPayPlaza -> error`, error)
        this.setState({
          isTransactionComplete: true,
          transactionUpdateMsg: error.message,
          resultCode: CONST.resultCode.CANCELED
        })
        localStorage.removeItem('CountrWeb:PayplazaRecovery')
        this.removeListeners()
        AppInstances.getCountrSdk().then(countr => {
          countr.e
            .create({
              source: 'pos',
              message: '[webpos] Payplaza Missing parameters failed',
              user: this.props.user.user._id,
              store: this.props.devices.store._id,
              device: this.props.devices.device._id,
              provider: 'payplaza',
              info: {
                error: JSON.stringify(error)
              }
            })
            .then(function (response) {
              console.log(`### ${LOG_TAG} Error create response: ` + JSON.stringify(response))
            })
            .catch(function (error) {
              console.log(`### ${LOG_TAG} Error creating server error: ` + JSON.stringify(error))
            })
        })
      }
    )
  }

  render() {
    return (
      <Dialog
        open={this.props.openPayplazaModal}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(reason, this.props.handleClosePayplazaModal)
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={true}>
        {!this.state.isTransactionComplete && <LoadBar />}
        <DialogTitle id="form-dialog-title">
          <div>Payplaza</div>
        </DialogTitle>
        <DialogContent className="payplaza-content">
          <Grid container alignItems="center" justifyContent="center" className="center">
            <Grid item xs={12} className="payplaza-result">
              {this.state.transactionUpdateMsg}
            </Grid>
          </Grid>
          {this.state.isTransactionComplete &&
          this.state.printContent &&
          this.state.printContent.length ? (
            <div ref={el => (this.printComponent = el)} className="payplaza-print-content">
              {this.state.printContent}
            </div>
          ) : (
            <div />
          )}
        </DialogContent>
        <DialogActions>
          {!this.state.isTransactionComplete ? (
            <Button
              color="secondary"
              onClick={this.handleCancelPayplazaPayment}
              disabled={this.state.disableCancelButton}>
              <Text id="cancel" />
            </Button>
          ) : (
            <Button
              className="payment-button"
              onClick={() => this.props.handleClosePayplazaModal(this.state.resultCode)}>
              <Text id="ok" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const PayplazaModalConnected = connect(mapStateToProps, mapDispatchToProps)(PayplazaModal)
export default PayplazaModalConnected
