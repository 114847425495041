import { useEffect, useState, useCallback } from 'react';
import { Text } from 'react-internationalization'
import QrReader from 'react-qr-reader'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Divider } from '@countr/ui'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import { setGiftcardCode } from '../../store/actions/payments'

import './ScanOptions.css'

const TYPE_OPTIONS = ['manual_insert', 'scanner', 'camera']

const BTN_STYLE = {
  width: '75%',
  margin: 6,
  fontSize: 14
}

const BACK_BTN_STYLE = {
  width: 100,
  float: 'right',
  fontSize: 14
}

const ScanOptions = props => {
  const dispatch = useDispatch()
  const resetScannedCode = useCallback(t => dispatch(setGiftcardCode(t)), [dispatch])
  const codeScanned = useSelector(state => state.payments.giftcardCode)
  const [typeSelected, setTypeSelected] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [searching, setSearching] = useState(false)

  const handleChange = e => {
    setCodeValue(e.target.value)
  }

  const handleCodeSearch = () => {
    if (codeValue) {
      setSearching(true)
      props.handleScanResult(codeValue)
    }
  }

  const handleBarcodeSearch = () => {
    props.handleScanResult(codeScanned)
    resetScannedCode('')
  }

  useEffect(() => {
    if (typeSelected === 'scanner' && codeScanned) {
      handleBarcodeSearch()
    }
  }, [codeScanned])

  const handleTypeSelected = () => {
    if (typeSelected === 'manual_insert') {
      return (
        <Grid container className="selection-manual-input">
          <Grid item xs={12}>
            <div className="selection-header manual-input">
              <Text id="manual_insert" />
            </div>
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              className="search-code-textfield"
              label={<Text id="enter_code" />}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={handleCodeSearch} disabled={!codeValue}>
              {searching ? (
                <CircularProgress className="bar-color" color="inherit" />
              ) : (
                <div
                  className={
                    codeValue ? 'icon-button-container' : 'icon-button-container-disabled'
                  }>
                  <span className="icon-search" style={{ color: '#f0f0f0' }} />
                </div>
              )}
            </IconButton>
          </Grid>
        </Grid>
      )
    } else if (typeSelected === 'scanner') {
      return (
        <div>
          <div className="selection-header">
            <Text id="scanner" />
          </div>
          <div className="scanner-width">
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
              direction="row"
              className="scanner-container bar-color">
              <CircularProgress color="inherit" />
            </Grid>
          </div>
        </div>
      )
    } else if (typeSelected === 'camera') {
      return (
        <QrReader
          delay={1000}
          onError={props.handleScanError}
          onScan={props.handleScanResult}
          style={{ width: 400 }}
        />
      )
    }
  }

  return (
    <>
      {typeSelected && (
        <div className="back-button">
          <Button
            onClick={() => setTypeSelected('')}
            label={<Text id={'back'} />}
            styles={BACK_BTN_STYLE}
          />
        </div>
      )}
      <Grid container alignItems="center" direction="row">
        {typeSelected ? (
          <>
            <Divider />
            {handleTypeSelected()}
            <Divider />
          </>
        ) : (
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            direction="row"
            className="selection-container">
            <div className="selection-header">
              <Text id="select_method" />
            </div>
            {TYPE_OPTIONS.map(type => (
              <Button
                key={type}
                onClick={() => setTypeSelected(type)}
                label={<Text id={type} />}
                styles={BTN_STYLE}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default ScanOptions
