import { memo } from 'react';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import { Text } from 'react-internationalization'
import './../Menu.scss'

const SignOut = memo(({ handleOpenConfirmation }) => {
  return (
    <div className="main-menu-bottom-logout">
      <Divider />
      <List component="nav" style={{ padding: 0 }}>
        <ListItem button onClick={handleOpenConfirmation}>
          <span className="icon-side-signout" />
          <div className="text">
            <span>{<Text id="sign_out" />}</span>
          </div>
        </ListItem>
      </List>
    </div>
  )
})

export default SignOut
