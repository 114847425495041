import { AppInstances } from './countrSdkInstance'
import { CountrListeners } from './CountrListeners'
import Util from './Util'
import store from './../index'
import { addTax, updateTax, deleteTax } from './../store/actions/taxes'

export default class TaxesUtils {
  static logTaxNotFoundError = (prodId, taxes, id) => {
    const errorObj = {
      source: Util.returnPOSType(),
      message: `Merchant: ${taxes[0].merchant} - Product: ${prodId} has no tax (should have Tax: ${id})`,
      user: taxes[0].merchant,
      date: new Date()
    }

    AppInstances.logError(errorObj)
  }

  static returnTaxRulesById = (prodId, taxes, id) => {
    this.logTaxNotFoundError(prodId, taxes, id)
    const tax = taxes.find(tax => tax._id === id)
    return tax.rules
  }

  static taxesListenerCallback = (result, event) => {
    switch (event) {
      case CountrListeners.getConstants().TAX_CREATED:
        store.dispatch(addTax(result))
        break
      case CountrListeners.getConstants().TAX_UPDATED:
        store.dispatch(updateTax(result))
        break
      case CountrListeners.getConstants().TAX_DELETED:
        store.dispatch(deleteTax(result))
        break
      default:
        break
    }
  }
}
