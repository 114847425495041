import { memo, useMemo } from 'react';
import { useTracking } from 'react-tracking'
import Button from '@material-ui/core/Button'
import { cartUtils } from '../../../utils/cartUtils'
import Util from '../../../utils/Util'
import { CategoryUtils } from '@countr/utils'
import { TRACKER_EVENTS } from '../../../utils/trackerEvents'

const PARENT_LEVEL = 100
const CHILD_LEVEL = 10

const CategoryButton = memo(
  ({ category, index, getCategory, activeCategory, selectCategory, language }) => {
    const { trackEvent } = useTracking()
    const getCategoryColor = category => {
      if (category !== undefined && category.color !== undefined) {
        return cartUtils.colourNameToHex(category.color)
      } else {
        return '#B6C2CD'
      }
    }

    const fontColor = useMemo(() => Util.isBgColorlightOrDark(getCategoryColor(category)))

    const getChildLevel = category => {
      // parent = 100%
      // children 100% - 10% per each level
      return category.parent === undefined || category.parent === null
        ? PARENT_LEVEL
        : getChildLevel(getCategory(category.parent)) - CHILD_LEVEL
    }

    const returnButtonClass = () => {
      const base = 'categories-list-item-btn'
      return activeCategory !== category._id ? base : `${base} category-selected`
    }

    const returnButtonStyle = () => {
      return {
        backgroundColor: getCategoryColor(category),
        width: getChildLevel(category) + '%',
        color: fontColor
      }
    }

    const renderChildMark = () => {
      if (category.children && category.children.length > 0) {
        return (
          <span className="category-has-child">
            {activeCategory === category._id ? (
              <span className="icon-right-arrow" />
            ) : (
              <span className="icon-dropdown" />
            )}
          </span>
        )
      }
    }

    const handleClick = () => {
      selectCategory(category, index)

      if (category._id === 'all_categories') {
        return
      }

      const event = {
        event: TRACKER_EVENTS.CATEGORY_CLICK,
        data: { _id: category._id },
        merchant: category.merchant || ''
      }

      trackEvent(event)
    }

    return (
      <div className="categories-list-grid-item">
        <Button
          className={returnButtonClass()}
          size="small"
          fullWidth={true}
          style={returnButtonStyle()}
          onClick={handleClick}>
          <span className="categories-list-item-btn-label">
            {category.name ? CategoryUtils.getCategoryName(category, language) : 'Unnamed'}
          </span>
          {renderChildMark()}
        </Button>
      </div>
    )
  }
)

export default CategoryButton
