import uncaught from 'uncaught'
import { AppInstances } from './countrSdkInstance'
import Util from './Util'

export const ErrorHandler = (function () {
  return {
    initErrorListener: function () {
      if (process.env.REACT_APP_PROD === 'true') {
        uncaught.start()
        uncaught.addListener(function (error) {
          const user = JSON.parse(localStorage.getItem('user'))
          const device = JSON.parse(localStorage.getItem('CountrLite:Device'))

          if (!device?.store?._id) {
            return
          }

          let errorMessage = ''

          if (user && device) {
            errorMessage = `[${Util.returnPOSType()}] ${user.email} - ${user._id} - ${
              error.message
            }`
          } else {
            errorMessage = `[${Util.returnPOSType()}] ${error.message}`
          }

          const errorObj = {
            source: Util.returnPOSType(),
            message: errorMessage,
            user: user,
            store: device.store._id,
            device: device._id,
            stack: error.stack,
            date: new Date()
          }

          AppInstances.logError(errorObj)
        })
      }
    }
  }
})()
