import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Text } from 'react-internationalization'

import { PaymentUtils } from '../../../utils/PaymentUtils'
import { cartUtils } from '../../../utils/cartUtils'
import { SELECT_CART, SET_TOAST_MESSAGE } from '../../../store/actions/actionTypes'
import { AppInstances } from '../../../utils/countrSdkInstance'

import { Button } from '@countr/ui'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'

import './TableLayout.scss'

const MergingTablesModal = ({
  openMergeDialog,
  handleCloseMergeDialog,
  tableToMerge,
  handleFinishMerge
}) => {
  const dispatch = useDispatch()
  const setCurrentCart = useCallback(c => dispatch({ type: SELECT_CART, payload: c }), [dispatch])
  const showMsg = useCallback(
    msg =>
      dispatch({
        type: SET_TOAST_MESSAGE,
        payload: msg
      }),
    [dispatch]
  )
  const { selectedCart, carts } = useSelector(state => state.carts)
  const { device, store } = useSelector(state => state.devices)
  const { user } = useSelector(state => state.user)

  const [mergingStarted, setMergingStarted] = useState(false)
  const cartToMerge = carts.find(c => c._id === tableToMerge.linked_cart)

  const handleUpdateMainItems = (mainItem, itemToMerge) => {
    const availableStatus = ['new', 'pending', 'printed', 'preparing', 'ready', 'completed']
    const mainItemCopy = { ...mainItem }
    availableStatus.forEach(status => {
      const mainStatusIndex = mainItem.status.findIndex(s => s.state === status)
      const toMergeStatusIndex = itemToMerge.status.findIndex(s => s.state === status)

      if (mainStatusIndex > -1 && toMergeStatusIndex > -1) {
        mainItemCopy.status[mainStatusIndex].amount += itemToMerge.status[toMergeStatusIndex].amount
        mainItemCopy.status[mainStatusIndex].last_update = new Date()
      } else if (!mainStatusIndex > -1 && toMergeStatusIndex > -1) {
        itemToMerge.status[toMergeStatusIndex].last_update = new Date()
        mainItemCopy.status.push(itemToMerge.status[toMergeStatusIndex])
      }
    })
    mainItemCopy.amount += itemToMerge.amount
    return mainItemCopy
  }

  const handleConfirmMerge = async () => {
    setMergingStarted(true)
    const selectedCartItems = [...selectedCart?.items]
    cartToMerge.items.forEach(item => {
      const cartEntryId = cartUtils.getCartEntryId(item)
      const existing = selectedCartItems.findIndex(i => cartUtils.getCartEntryId(i) === cartEntryId)

      if (existing > -1) {
        selectedCartItems[existing] = handleUpdateMainItems(selectedCartItems[existing], item)
      } else {
        selectedCartItems.push(item)
      }
    })

    // Clear merged cart
    const cartIndex = carts.findIndex(c => c.device_id === cartToMerge._id)
    const cleanedCart = PaymentUtils.cleaningCart(cartToMerge, cartIndex, true)

    try {
      const cleanedUpdated = await cartUtils.updateCartServer(cleanedCart)
      cartUtils.updateCartLocally(cleanedUpdated)

      selectedCart.items = selectedCartItems.filter(i => i?.amount)

      const toUpdateCart = {
        ...selectedCart,
        updated_at: new Date(),
        originator: device._id,
        server_modified: false
      }
      const updated = await cartUtils.updateCartServer(toUpdateCart)

      cartUtils.updateCartLocally(updated)
      setCurrentCart(updated)
      handleFinishMerge()
    } catch (error) {
      const msg = !!error.message ? error.message : JSON.stringify(error)
      showMsg({ message: msg })
      logError(error)
      setMergingStarted(false)
    }
  }

  const logError = async obj => {
    const errorObj = {
      message: `${obj.msg}, users: ${user.username}, 
        _ID: ${user._id}, device id: ${device._id}`,
      user: user._id,
      store: store._id,
      device: device._id,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  return (
    <Dialog
      open={openMergeDialog}
      onClose={handleCloseMergeDialog}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Text id="merge_tables" />
      </DialogTitle>
      <DialogContent className="modal-mergin-container">
        {cartToMerge?._id === selectedCart?._id ? (
          <Text id="cant_merge_same" />
        ) : (
          <Text id="merge_warning" />
        )}
        {mergingStarted && <CircularProgress color="primary" />}
      </DialogContent>

      <DialogActions>
        <Button
          label={<Text id="cancel" />}
          onClick={handleCloseMergeDialog}
          className="secondary"
        />
        <Button
          className="primary"
          label={<Text id="ok" />}
          onClick={handleConfirmMerge}
          disabled={cartToMerge?._id === selectedCart?._id}
        />
      </DialogActions>
    </Dialog>
  )
}

export default MergingTablesModal
