import { Component } from 'react';
import { connect } from 'react-redux'

import { Text, translate } from 'react-internationalization'

import Fab from '@material-ui/core/Fab'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Switch } from '@countr/ui'

import { AppInstances } from '../../utils/countrSdkInstance'
import RegisterOperationsUtils from '../../utils/RegisterOperationsUtils'

import {
  getStartDay,
  getEndDay,
  evaluateCurrentShiftState
} from '../../utils/EmployeeUtils'

import { setToastMessage } from './../../store/actions/app'

import './styles.scss'

const mapStateToProps = state => {
  return {
    user: state.user,
    devices: state.devices,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt))
  }
}

export class CashInOut extends Component {
  state = {
    currentStartAmount: 0,
    loading: false,
    success: false,
    amount: '',
    reason: '',
    tickets: 0,
    movement: '',
    haveStartDay: false,
    haveEndDay: false
  }

  handlechange(val, field) {
    this.setState({
      [field]: val
    })
  }

  renderButtonFlow() {
    // if (!this.state.success) {
    // 	return <i className="icon-cash-refund" />
    // }

    return <i className="icon-check" />
  }

  componentDidMount() {
    const { haveStartDay, haveEndDay } = evaluateCurrentShiftState(
      getStartDay(this.props.employeeId || this.props.devices.device._id),
      getEndDay(this.props.employeeId || this.props.devices.device._id)
    )

    const currentStartAmount =
      localStorage.getItem(
        `${this.props.employeeId || this.props.devices.device._id}_startDayAmount`
      ) || 0

    this.setState({
      currentStartAmount,
      haveStartDay,
      haveEndDay
    })
  }

  saveOperation() {
    if (!this.state.loading) {
      this.setState(
        {
          success: false,
          loading: true
        },
         () => {
          try {
            const operationArgs = {
              action: 'movement',
              cash:
                this.state.movement === 'out' ? -Math.abs(this.state.amount) : this.state.amount,
              tickets: this.state.tickets,
              note: this.state.reason
            }

            RegisterOperationsUtils.applyOperation(operationArgs)

            this.props.setToastMessage('cash_in_out_success')

            this.cleanState()

            this.setState(
              {
                success: true
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    success: false
                  })
                }, 1000)
              }
            )
          } catch (e) {
            this.props.setToastMessage('cash_in_out_failed')

            const errorObj = {
              source: process.env.REACT_APP_ERROR_SOURCE,
              message: `Failed to save Cash in/out operation Reason: ${this.state.reason}, Amount: ${this.state.amount}`,
              user: this.props.user.user._id,
              store: this.props.devices.store._id,
              device: this.props.devices.device._id,
              stack: e.stack,
              date: new Date().toISOString()
            }

            AppInstances.getCountrSdk().then(AppInstances.logError(errorObj))

            this.cleanState()
          }
        }
      )
    }
  }

  cleanState() {
    this.setState({
      success: false,
      loading: false,
      reason: '',
      amount: '',
      movement: ''
    })
  }

  isGreaterThanStartAmount() {
    if (this.state.amount > this.state.currentStartAmount) {
      return true
    }

    return false
  }

  checkIfInvalid() {
    const formattedAmount = parseFloat(this.state.amount)
    // if (isNaN(formattedAmount) || this.isGreaterThanStartAmount()) {
    if (isNaN(formattedAmount)) {
      return true
    }

    return (
      this.state.loading ||
      this.state.movement === '' ||
      this.state.reason === '' ||
      this.state.amount === '' ||
      formattedAmount === 0
    )
  }

  setMovement = newValue => {
    this.setState({ movement: newValue })
  }

  render() {
    const { loading } = this.state

    return this.state.haveStartDay ? (
      <div className="cashinout-wrapper">
        <Input
          // error={this.isGreaterThanStartAmount()}
          className="cashinout-input cashinout-amount"
          value={this.state.amount}
          type="number"
          autoFocus={true}
          fullWidth={true}
          placeholder={translate('enter_amount')}
          onChange={event => this.handlechange(event.target.value, 'amount')}
          startAdornment={
            <InputAdornment position="start">
              {this.props.devices.store.currency.symbol}
            </InputAdornment>
          }
        />

        <TextField
          className="cashinout-input"
          placeholder={translate('add_note_hint')}
          value={this.state.reason}
          fullWidth={true}
          onChange={event => this.handlechange(event.target.value, 'reason')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="icon-note" />
              </InputAdornment>
            )
          }}
        />

        {/* {
          this.isGreaterThanStartAmount() && (
            <small><Text id="cash_in_greater_start" /></small>
          )
        } */}

        <div className="cashinout-group">
          <div className="action">
            <span>Cash In:</span>
            <Switch value={this.state.movement === 'in'} setValue={() => this.setMovement('in')} />
          </div>
          <div className="action">
            <span>Cash Out:</span>
            <Switch
              value={this.state.movement === 'out'}
              setValue={() => this.setMovement('out')}
            />
          </div>
        </div>

        <div className="cashinout-fab-wrapper">
          <Fab
            disabled={this.checkIfInvalid()}
            color="primary"
            aria-label="Save"
            size="small"
            className={`cashinout-fab-button ${this.state.success ? 'cashinout-in__success' : ''}`}
            onClick={() => this.saveOperation()}>
            {this.renderButtonFlow()}
            {loading && <CircularProgress size={47} className="fabProgress" />}
          </Fab>
        </div>
      </div>
    ) : (
      <small>
        <Text id="cash_in_not_open_day" />
      </small>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashInOut)
