import {
  ADD_DEVICE,
  UPDATE_DEVICE,
  ADD_STORE,
  ADD_CURRENCY,
  SET_LAST_TRANSACTION
} from './../actions/actionTypes'

const initialState = {
  device: {},
  store: {},
  currency: {},
  lastTransaction: 1
}

const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEVICE:
      return {
        ...state,
        device: action.payload
      }
    case ADD_CURRENCY:
      return {
        ...state,
        currency: action.payload
      }
    case UPDATE_DEVICE:
      return {
        ...state,
        device: action.payload
      }
    case ADD_STORE:
      return {
        ...state,
        store: action.payload
      }
    case SET_LAST_TRANSACTION:
      return {
        ...state,
        lastTransaction: action.payload
      }
    default:
      return state
  }
}

export default devicesReducer
