import { memo, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'

import CatalogHeader from './CatalogHeader'
import ProductsList from './Products/ProductsList'
import CategoriesList from './Categories/CategoriesList'
import FabPayButton from './FabPayButton'

import './Catalog.scss'

const WOOCOMMERCE = 'woocommerce'
const SHOPIFY = 'shopify'
const OPENCART = 'opencart'

const mapStateToProps = state => {
  return {
    user: state.user.user,
    settings: state.settings
  }
}

const Catalog = memo(function Catalog({ user, settings }) {
  const [hasIntegrations, setHasIntegrations] = useState(false)

  useEffect(() => {
    const integration = user.integration || ''
    setHasIntegrations(
      integration.indexOf(WOOCOMMERCE) >= 0 ||
        integration.indexOf(SHOPIFY) >= 0 ||
        integration.indexOf(OPENCART) >= 0
    )
  }, [])

  const isAuth = () => {
    return !!user._id
  }

  return (
    <Fragment>
      {isAuth() && (
        <Fragment>
          <CatalogHeader hasIntegrations={hasIntegrations} />
          <div id="catalog" className="catalog">
            {!settings.hideCategoriesTile && (
              <div className="categories-list-section">
                <CategoriesList />
              </div>
            )}
            <div className="products-list-section">
              <ProductsList />
            </div>
          </div>
          {!settings.cartAlwaysOpen && <FabPayButton />}
        </Fragment>
      )}
    </Fragment>
  );
})

export default connect(mapStateToProps, null)(Catalog)
