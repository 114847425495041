import {
  ADD_TRANSACTION,
  ADD_ALL_TRANSACTIONS,
  UPDATE_TRANSACTION,
  ADD_REFUND,
  ADD_ALL_REFUNDS,
  ADD_TRANSACTION_HEAD,
  ADD_REFUND_HEAD,
  EDIT_TRANSACTION,
  SET_REFUND_EXTRAS
} from './../actions/actionTypes'

const initialState = {
  transactions: [],
  refunds: [],
  showTransaction: {},
  refundExtras: {}
}

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.concat(action.payload)
      }
    case ADD_ALL_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      }
    case UPDATE_TRANSACTION:
      const updatedList = state.transactions.map(transaction =>
        transaction._id === action.payload._id ? action.payload : transaction
      )
      return {
        ...state,
        transactions: updatedList
      }
    case ADD_REFUND:
      return {
        ...state,
        refunds: state.refunds.concat(action.payload)
      }
    case ADD_ALL_REFUNDS:
      return {
        ...state,
        refunds: action.payload
      }
    case ADD_TRANSACTION_HEAD:
      const index = state.transactions.findIndex(t => t._id === (action.payload || {})._id)
      return {
        ...state,
        transactions:
          index >= 0
            ? state.transactions
            : (action.payload ? [action.payload] : []).concat(state.transactions)
      }
    case ADD_REFUND_HEAD:
      return {
        ...state,
        refunds: [action.payload].concat(state.refunds)
      }
    case EDIT_TRANSACTION:
      const editedList = state.transactions.map(transaction => {
        return transaction._id === action.payload._id ? action.payload : transaction
      })
      return {
        ...state,
        transactions: editedList
      }
    case SET_REFUND_EXTRAS:
      return {
        ...state,
        refundExtras: action.payload
      }
    default:
      return state
  }
}

export default transactionsReducer
