// App actions - some app controll
export const SHOW_CART = 'SHOW_CART'
export const SHOW_KDS = 'SHOW_KDS'
export const SHOW_CHAT = 'SHOW_CHAT'
export const SHOW_SETTINGS = 'SHOW_SETTINGS'
export const SHOW_MAIN_MENU = 'SHOW_MAIN_MENU'
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE'
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const SET_TRIAL_EXPIRED = 'SET_TRIAL_EXPIRED'
export const SET_OUTSTANDING_INVOICES = 'SET_OUTSTANDING_INVOICES'
export const SET_DISCONNECTED_TIME = 'SET_DISCONNECTED_TIME'
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP'
export const SET_THEME = 'SET_THEME'
export const SHOW_EXTERNAL_URL = 'SHOW_EXTERNAL_URL'
export const SHOW_DELIVERY_NOTES = 'SHOW_DELIVERY_NOTES'

// Loading actions
export const LOADING_TRUE = 'LOADING_TRUE'
export const LOADING_FALSE = 'LOADING_FALSE'
export const SET_LOADING_MSG = 'SET_LOADING_MSG'
export const SET_LOADING_VALUE = 'SET_LOADING_VALUE'

// Users actions
export const ADD_USER = 'ADD_USER'

// Products actions
export const SET_PRODUCT_COUNT = 'SET_PRODUCT_COUNT'
export const SET_CALCULATOR = 'SET_CALCULATOR'
export const BARCODE_SCANNER = 'BARCODE_SCANNER'
export const SHOW_DEPOSIT_PRODUCTS = 'SHOW_DEPOSIT_PRODUCTS'
export const SET_PRODUCT_SEARCH_INPUT = 'SET_PRODUCT_SEARCH_INPUT'
export const RUN_PRODUCT_SEARCH = 'RUN_PRODUCT_SEARCH'

// Categories actions
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const ADD_ALL_CATEGORIES = 'ADD_ALL_CATEGORIES'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY'

// Taxes actions
export const ADD_TAX = 'ADD_TAX'
export const ADD_ALL_TAXES = 'ADD_ALL_TAXES'
export const UPDATE_TAX = 'UPDATE_TAX'
export const DELETE_TAX = 'ADD_TAX'

// Devices actions
export const ADD_DEVICE = 'ADD_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const ADD_STORE = 'ADD_STORE'
export const SET_LAST_TRANSACTION = 'SET_LAST_TRANSACTION'
export const ADD_CURRENCY = 'ADD_CURRENCY'

// Carts actions
export const ADD_CART = 'ADD_CART'
export const EDIT_CART = 'EDIT_CART'
export const SELECT_CART = 'SELECT_CART'
export const CLEAN_CART = 'CLEAN_CART'
export const SELECT_CART_ITEM = 'SELECT_CART_ITEM'
export const SET_TRANSACTION = 'SET_TRANSACTION'
export const ADD_CUSTOMER_CART = 'ADD_CUSTOMER_CART'
export const DELETE_CART = 'DELETE_CART'
export const TABLE_SEAT = 'TABLE_SEAT'

// Customers actions
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_ALL_CUSTOMERS = 'ADD_ALL_CUSTOMERS'

// Employees actions
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_ALL_EMPLOYEES = 'ADD_ALL_EMPLOYEES'
export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE'
export const SHOW_EMPLOYEES_MODAL = 'SHOW_EMPLOYEES_MODAL'

// Settings actions
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_DESKTOP_SETTINGS = 'SET_DESKTOP_SETTINGS'
export const DISABLE_KIOSK = 'DISABLE_KIOSK'
export const ENABLE_A4_PRINT = 'ENABLE_A4_PRINT'
export const LISTENING_STORES = 'LISTENING_STORES'
export const LISTENING_CATEGORIES = 'LISTENING_CATEGORIES'

// Transactions/Refunds actions
export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const ADD_ALL_TRANSACTIONS = 'ADD_ALL_TRANSACTIONS'
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION'
export const ADD_REFUND = 'ADD_REFUND'
export const ADD_ALL_REFUNDS = 'ADD_ALL_REFUNDS'
export const ADD_TRANSACTION_HEAD = 'ADD_TRANSACTION_HEAD'
export const ADD_REFUND_HEAD = 'ADD_REFUND_HEAD'
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION'
export const SET_REFUND_EXTRAS = 'SET_REFUND_EXTRAS'

// Payments methods actions
export const SET_PAYMENTS = 'SET_PAYMENTS'
export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS'
export const LOAD_PAYMENT_METHODS_EXTRA = 'LOAD_PAYMENT_METHODS_EXTRA'
export const SET_METHOD = 'SET_METHOD'
export const SET_METHOD_CASHDRAWER = 'SET_METHOD_CASHDRAWER'
export const SET_METHOD_PRINT = 'SET_METHOD_PRINT'
export const SET_METHOD_EXTRA = 'SET_METHOD_EXTRA'
export const GIFTCARD_CODE = 'GIFTCARD_CODE'

// Queue actions
export const ADD_QUEUE = 'ADD_QUEUE'
export const REMOVE_QUEUE = 'REMOVE_QUEUE'
export const REMOVE_ACTION_QUEUE = 'REMOVE_ACTION_QUEUE'
export const REMOVE_DUPLICATE = 'REMOVE_DUPLICATE'

// Desktop Connection actions
export const SET_DESKTOP_CONNECTED = 'SET_DESKTOP_CONNECTED'
