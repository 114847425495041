import {
  ADD_CATEGORY,
  ADD_ALL_CATEGORIES,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  SET_ACTIVE_CATEGORY
} from './../actions/actionTypes'

const initialState = {
  categories: [],
  activeCategory: 'all_categories'
}

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return {
        ...state,
        categories: state.categories.concat(action.payload)
      }
    case ADD_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case UPDATE_CATEGORY:
      const updatedCategories = state.categories.map(category => {
        return category._id === action.payload._id ? action.payload : category
      })
      return {
        ...state,
        categories: updatedCategories
      }
    case DELETE_CATEGORY:
      const notDeleteCategories = state.categories.filter(category => {
        return category._id !== action.payload
      })
      return {
        ...state,
        categories: notDeleteCategories
      }
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: action.payload
      }
    default:
      return state
  }
}

export default categoriesReducer
