import { memo, useEffect, useState } from 'react';
import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'
import { AppInstances } from './../../utils/countrSdkInstance'
import Util from '../../utils/Util'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

const styleButton = {
  backgroundColor: '#318ed5',
  color: '#fff'
}

const ManualSyncTransactionsModal = memo(props => {
  const [hasInternet, setHasInternet] = useState(false)
  const [transactionsSynced, setTransactionsSynced] = useState(0)
  const [totalTransactions, setTotalTransactions] = useState(0)
  const [isSyncRunning, setIsSyncRunning] = useState(false)

  useEffect(() => {
    setHasInternet(navigator.onLine)

    let recoveryTransaction = 0
    for (var key in localStorage) {
      if (key.indexOf('CountrWeb:LastTransactionInProgress-') >= 0) {
        recoveryTransaction++
      }
    }

    setTotalTransactions(recoveryTransaction)
  }, [props.open])

  const returnContentText = () => {
    if (!hasInternet) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Text id="no_internet_connection" />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Text id="manual_sync_transactions_question" />
            {createTransactionsProgress()}
          </Grid>
        </Grid>
      )
    }
  }

  const createTransactionsProgress = () => {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <br />
          {transactionsSynced}/{totalTransactions} <Text id="transactions" />
          <LinearProgress
            color="primary"
            variant="determinate"
            value={(transactionsSynced / totalTransactions) * 100}
          />
        </Grid>
      </Grid>
    )
  }

  const syncTransactions = async () => {
    setIsSyncRunning(true)
    const countr = await AppInstances.getCountrSdk()

    const inProgress = []
    for (var key in localStorage) {
      if (key.indexOf('CountrWeb:LastTransactionInProgress-') >= 0) {
        const lastTransaction = JSON.parse(localStorage.getItem(key))
        if (lastTransaction && lastTransaction !== null) {
          inProgress.push(lastTransaction)
        }
      }
    }

    inProgress.sort((a, b) => {
      if (a.date < b.date) {
        return -1
      } else if (a.date > b.date) {
        return 1
      } else {
        return 0
      }
    })

    const promises = []
    let count = 0

    inProgress.forEach(lastTransaction => {
      const index = props.transactions.findIndex(t => t.receipt_id === lastTransaction.receipt_id)

      if (index < 0) {
        promises.push(
          countr.transactions.create(lastTransaction).then(transaction => {
            props.addTransactionHead(transaction)
            count++
            setTransactionsSynced(count)
          })
        )
      }
    })

    Promise.all(promises).then(result => {
      for (var key in localStorage) {
        if (key.indexOf('CountrWeb:LastTransactionInProgress-') >= 0) {
          localStorage.removeItem(key)
        }
      }

      setIsSyncRunning(false)
      props.handleClose(true)
    })
  }

  return (
    <Dialog
      fullScreen={isMobileOnly}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, props.handleClose)
      }}
      aria-labelledby="simple-dialog-title"
      open={props.open}>
      <DialogTitle id="simple-dialog-title">
        <Text id="manual_sync_transactions" />
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>{returnContentText()}</DialogContent>
      {hasInternet ? (
        <DialogActions>
          <Button
            variant="contained"
            style={styleButton}
            onClick={syncTransactions}
            disabled={isSyncRunning}>
            <Text id="sync" />
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={props.handleClose}>
            <Text id="cancel" />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
})

export default ManualSyncTransactionsModal
