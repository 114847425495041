import { memo, useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { Text } from 'react-internationalization'

const ChangePropertyModal = memo(props => {
  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    setTextValue(props.initialValue && props.initialValue.length > 0 ? props.initialValue : '')
  }, [])

  const handleChange = name => event => {
    setTextValue(event.target.value)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleConfirm()
    }
  }

  const handleClose = () => {
    props.handleClose()
  }

  const handleConfirm = () => {
    props.handleConfirm(textValue)
  }

  return (
    <Dialog
      open={props.openChangeProperty}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Text id={props.changePropertyTitle} />
      </DialogTitle>
      <DialogContent style={{ minWidth: 450 }}>
        <TextField
          label={<Text id={props.changePropertyText} />}
          helperText={<Text id={props.changePropertyHelpText} />}
          fullWidth={true}
          value={textValue}
          onChange={handleChange('textValue')}
          onKeyPress={handleKeyPress}
          error={
            textValue.length < props.errorCounterMin || textValue.length > props.errorCounterMax
          }
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          <Text id="cancel" />
        </Button>
        <Button
          style={{ color: '#318ed5' }}
          onClick={handleConfirm}
          disabled={
            textValue.length < props.errorCounterMin || textValue.length > props.errorCounterMax
          }>
          <Text id="ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ChangePropertyModal
