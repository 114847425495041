import { memo, useMemo } from 'react';
import { Button } from '@countr/ui'
import './Generic.scss'

const defaultStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const KeyBoardComponent = memo(function KeyBoardComponent({
  handleChange,
  enterValue,
  enterAction,
  limitedHeight = false
}) {
  const values = useMemo(() => {
    return [...Array(10).keys()].splice(1, 10)
  }, [])
  const styles = limitedHeight ? { ...defaultStyles, maxHeight: 30 } : defaultStyles

  return (
    <div className="keyboard-generic">
      {values.map(num => (
        <Button
          key={num}
          label={num}
          styles={styles}
          onClick={() => handleChange(num)}
          className="neutro"
        />
      ))}
      <Button label="C" styles={styles} onClick={() => handleChange('C')} className="neutro" />
      <Button label="0" styles={styles} onClick={() => handleChange('0')} className="neutro" />
      {enterValue && (
        <Button
          className="primary confirm"
          styles={styles}
          label={enterValue}
          onClick={enterAction}
        />
      )}
    </div>
  )
})

export default KeyBoardComponent
