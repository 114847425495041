import { Component } from 'react';
import { connect } from 'react-redux'
import { AppInstances } from '../../utils/countrSdkInstance'

import { loadingFalse, loadingTrue } from './../../store/actions/loading'
import { setToastMessage } from '../../store/actions/app'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import { Text } from 'react-internationalization'
import { Input } from '@countr/ui'

const mapStateToProps = state => {
  return {
    transactions: state.transactions,
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

class SearchTransaction extends Component {
  state = {
    isSearchEnable: false,
    searchInput: ''
  }
  style = {
    color: this.props.theme.colors.text
  }

  handleEnableSearch = () => {
    this.setState({ isSearchEnable: !this.state.isSearchEnable, searchInput: '' }, () => {
      // If close search, should return to initial state
      if (!this.state.isSearchEnable) {
        this.props.searchTransaction(
          this.mergeTransactionsWithRefunds(
            this.props.transactions.transactions,
            this.props.transactions.refunds
          )
        )
      }
    })
  }

  handleChange = value => {
    this.setState({ searchInput: value })
    if (value?.length < 3) {
      this.props.searchTransaction(
        this.mergeTransactionsWithRefunds(
          this.props.transactions.transactions,
          this.props.transactions.refunds
        )
      )
    }
  }

  handleKeyPress = () => {
    if (this.state.searchInput.length > 2) {
      this.filterTransaction()
    }
  }

  mergeTransactionsWithRefunds = (transactions, refunds) => {
    // Mergesort with transactions and refunds
    const list = []
    let i = 0
    let j = 0
    while (i < transactions.length && j < refunds.length) {
      if (transactions[i].created_at > refunds[j].created_at) {
        list.push(transactions[i])
        i++
      } else {
        list.push(refunds[j])
        j++
      }
    }

    // Adding the rest of the transactions to the end of the array
    if (i < transactions.length) {
      for (let x = i; x < transactions.length; x++) {
        list.push(transactions[x])
      }
    }
    // Adding the rest of the refunds to the end of the array
    if (j < refunds.length) {
      for (let y = j; y < refunds.length; y++) {
        list.push(refunds[y])
      }
    }

    return list
  }

  filterTransaction = () => {
    const value = this.state.searchInput
    let transactionsList = []
    let refundsList = []

    AppInstances.getCountrSdk().then(socket => {
      const promises = []
      this.props.loadingTrue()

      promises.push(
        socket.transactions
          .search({ limit: 10, text: value, sort: '-created_at' })
          .then(
            transactions => {
              transactionsList = transactions
            },
            error => {
              transactionsList = this.props.transactions.transactions
              this.props.setToastMessage('error_try_again')
            }
          )
      )
      promises.push(
        socket.refunds
          .search({ limit: 10, text: value, sort: '-created_at' })
          .then(
            refunds => {
              refundsList = refunds
            },
            error => {
              refundsList = this.props.transactions.refunds
            }
          )
      )

      Promise.all(promises).then(result => {
        this.props.searchTransaction(
          this.mergeTransactionsWithRefunds(transactionsList, refundsList)
        )
        this.props.loadingFalse()
      })
    })
  }

  componentDidMount = () => {
    this.props.searchTransaction(
      this.mergeTransactionsWithRefunds(
        this.props.transactions.transactions,
        this.props.transactions.refunds
      )
    )
  }

  render() {
    return (
      <Grid container>
        {this.state.isSearchEnable ? (
          <Grid item xs={12}>
            <Input
              label={<Text id="search_transaction" />}
              value={this.state.searchInput}
              onChange={this.handleChange}
              handleEnter={this.handleKeyPress}
              hint={<Text id="search_transaction_hint" />}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <IconButton onClick={this.handleEnableSearch}>
              <span className="icon-search" style={this.style} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    )
  }
}

const SearchTransactionConnected = connect(mapStateToProps, mapDispatchToProps)(SearchTransaction)
export default SearchTransactionConnected
