import { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { selectEmployee } from './../../store/actions/employees'
import { setToastMessage } from '../../store/actions/app'
import { setActiveCategory } from '../../store/actions/categories'
import Util from '../../utils/Util'
import RegisterOperationsUtils from '../../utils/RegisterOperationsUtils'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import EmployeeTile from './EmployeeTile'
import KeyboardComponent from './../generic/KeyboardComponent'

import { Text } from 'react-internationalization'

import './Employees.css'

const MAX_PIN_SIZE = 6

const mapStateToProps = state => {
  return {
    devices: state.devices,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectEmployee: employee => dispatch(selectEmployee(employee)),
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt)),
    setActiveCategory: id => dispatch(setActiveCategory(id))
  }
}

const Employees = memo(props => {
  const [employeesList, setEmployeeList] = useState([])
  const [employeePin, setEmployeePin] = useState('')
  const [employeePinOutput, setEmployeePinOutput] = useState('')

  useEffect(() => {
    if (props.devices.store.employees !== undefined) {
      const list = []

      props.devices.store.employees.forEach(emp => {
        // If delete an employee from employee's list and this employee ID
        // stay in store, with will be undefined
        const employee = props.employees.employees.find(e => e._id === emp)
        if (employee !== undefined) {
          list.push(employee)
        }
      })

      if (list.length === 0) {
        props.setToastMessage('no_employee_store')
        handleCancelEmployeesDialog()
      } else {
        setEmployeeList(list)
      }
    }
  }, [])

  const handleChangePinKeyboard = key => {
    if (key === 'C') {
      setEmployeePin('')
      setEmployeePinOutput('')
    } else {
      setEmployeePin(employeePin + key)
      setEmployeePinOutput('*'.repeat((employeePin + key).length))
    }
  }

  const checkEmployeePin = () => {
    if (employeePin.length) {
      const employee = employeesList.find(employee => employee.pin === employeePin)

      if (employee) {
        // Register logout only if a previous employee was logged in
        if (props?.employees?.selectedEmployee?._id) {
          const operationArgs = {
            action: 'employee_sign_out'
          }
    
          RegisterOperationsUtils.applyOperation(operationArgs)
        }
        
        props.selectEmployee(employee)
        props.setActiveCategory('all_categories')
        props.handleCloseEmployeesModal()
        const name = employee.first_name + ' ' + employee.last_name
        props.setToastMessage('employee_select_success', { employee: name })
        // Register new signed in employee
        const operationArgs = {
          action: 'employee_sign_in'
        }
  
        RegisterOperationsUtils.applyOperation(operationArgs)
      } else {
        setEmployeePin('')
        setEmployeePinOutput('')
        props.setToastMessage('employee_select_error')
      }
    } else {
      setEmployeePin('')
      setEmployeePinOutput('')
      props.setToastMessage('empty_pin')
    }
  }

  const handleSelectEmployee = employee => {
    props.selectEmployee(employee)
    props.handleCloseEmployeesModal()
    const name = employee.first_name + ' ' + employee.last_name
    props.setToastMessage('employee_select_success', { employee: name })
  }

  const handleCancelEmployeesDialog = () => {
    props.selectEmployee({})
    props.handleCloseEmployeesModal()
  }

  return (
    <Dialog
      open={props.openEmployeesModal}
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, handleCancelEmployeesDialog)
      }}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      disableEscapeKeyDown={true}>
      <DialogTitle id="form-dialog-title">
        <Text id="select_employee" />
      </DialogTitle>
      <DialogContent>
        <Grid container className="employees-modal" justifyContent="center" alignItems="center">
          {employeesList.map(
            employee =>
              (!employee.pin || employee.pin.length === 0) && (
                <Grid item xs={true} key={employee._id} style={{ display: 'flex' }}>
                  <EmployeeTile employee={employee} handleSelectEmployee={handleSelectEmployee} />
                </Grid>
              )
          )}
        </Grid>
        <Grid container className="employees-modal" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <KeyboardComponent
              handleChange={handleChangePinKeyboard}
              enterAction={checkEmployeePin}
              enterValue={<Text id="confirm" />}
            />
          </Grid>
          <Grid item xs={12}>
            <strong>{employeePinOutput}</strong>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
})

const EmployeesConnected = connect(mapStateToProps, mapDispatchToProps)(Employees)
export default EmployeesConnected
