import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import { AppInstances } from '../../../../utils/countrSdkInstance'
import ProductUtils from '../../../../utils/ProductUtils'
import ProductDescription from './ProductDescription'
import Inventory from './Inventory'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from '@countr/ui'

import './ProductDetails.scss'

const ProductDetails = memo(({ showInventory, handleClose, product, language }) => {
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }
  const [inventory, setInventory] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadProductInventory()
  }, [])

  const loadProductInventory = async () => {
    const countr = await AppInstances.getCountrSdk()
    const inv = await countr.products.readOne.inventory(product._id)

    if (!!inv) {
      setInventory(inv)
    }
    setIsLoading(false)
  }

  return (
    <Dialog open={showInventory} onClose={handleClose}>
      <DialogTitle id="form-dialog-title" style={style}>
        {ProductUtils.getProductName(product, language)}
      </DialogTitle>
      <DialogContent style={style}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            {isLoading ? (
              <div className="loader">
                <CircularProgress size={20} />
              </div>
            ) : (
              <List className="modal-list" subheader={<li />}>
                {/* Item's Brand */}
                {product.brand && (
                  <>
                    <div className="title-div">
                      <Text id="brand" />
                    </div>
                    <div className="sub-text">
                      <Text>{product.brand}</Text>
                    </div>
                  </>
                )}
                {/* Item's description */}
                <ProductDescription product={product} language={language} />
                {/* Product's allergens */}
                {product.allergens && product.allergens.length > 0 && (
                  <>
                    <div className="title-div">
                      <Text id="allergens" />
                    </div>
                    {product.allergens.map(allergen => (
                      <ListItemText
                        className="sub-header"
                        style={style}
                        key={allergen._id}
                        primary={`• ${allergen.name}`}
                      />
                    ))}
                  </>
                )}
                {/* Item's inventory */}
                <Inventory
                  product={product}
                  language={language}
                  inventory={inventory}
                  style={style}
                />
              </List>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="close" />} onClick={handleClose} className="secondary" />
      </DialogActions>
    </Dialog>
  )
})

export default ProductDetails
