import { SET_SETTINGS, SET_DESKTOP_SETTINGS, DISABLE_KIOSK, ENABLE_A4_PRINT, LISTENING_STORES, LISTENING_CATEGORIES } from './actionTypes'

export const setSettings = settings => ({
  type: SET_SETTINGS,
  payload: settings
})

export const setDesktopSettings = desktop => ({
  type: SET_DESKTOP_SETTINGS,
  payload: desktop
})

export const disableKioskMode = () => ({
  type: DISABLE_KIOSK
})

export const enableA4Print = () => ({
  type: ENABLE_A4_PRINT
})

export const setListeningStores = stores => ({
  type: LISTENING_STORES,
  payload: stores
})

export const setListeningCategories = categories => ({
  type: LISTENING_CATEGORIES,
  payload: categories
})