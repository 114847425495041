/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const ptLang = {
  student: 'aluno',
  id_check_alert: 'Cliente: {{customer}}. Verifique os seguintes IDs dos clientes: {{id}}.',
  id_check: 'Verificação de ID',
  cart: 'Carrinho de compras',
  item: 'Item',
  items: 'Itens',
  subtotal: 'Subtotal',
  discount: 'Desconto',
  total: 'Total',
  pay: 'Pagar',
  card: 'Cartão',
  cash: 'Dinheiro',
  invoice: 'Fatura',
  giftcard: 'Cartão presente',
  countr_giftcard: 'Countr Cartão presente',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Método de pagamento',
  cash_received: 'Quantidade recebida',
  or_enter_amount: 'Ou insira quantidade',
  payment_succeeded: 'Pagamento efetuado com sucesso',
  change_due: 'Troco a receber',
  total_paid: 'Total pago',
  send_receipt: 'Envie recibo',
  send_invoice: 'Envie fatura',
  send: 'Enviar',
  delete: 'Deletar',
  new_sale: 'Nova transação',
  custom_price: 'Preço personalizado',
  item_description: '(opcional) descrição/nome do item',
  products: 'Produtos',
  transactions: 'Transações',
  reports: 'Relatórios',
  report: 'Relatório',
  settings: 'Configurações',
  sign_out: 'Sair',
  receipt: 'Recibo',
  refund: 'Reembolsar',
  home: 'Home',
  clear: 'Limpar',
  add: 'Adicionar',
  sign_up: 'Registrar',
  sign_up_email: 'Registrar com email',
  sign_in: 'Faça seu login',
  company_name: 'Nome da empresa',
  merchant_name: 'Contato',
  email_address: 'Endereço de email',
  phone_number: 'Número de telefone válido',
  password: 'Senha',
  confirm_password: 'Confirmar senha',
  forgot_password_question: 'Esqueceu sua senha?',
  next: 'Próximo',
  demo: 'Teste',
  skip: 'Ignorar',
  skip_registration: 'Ignore registro',
  take_a_tour: 'Faça um tour',
  items_returned: 'Itens devolvidos',
  wrong_billing: 'Faturamento errado',
  order_cancelled: 'Pedido cancelado',
  other: 'Outro',
  enter_valid_amount: 'Insira uma quantidade válida',
  invalid_merchant_name: 'Contato inválido',
  invalid_business_name: 'Nome da empresa inválido',
  invalid_email_address: 'Endereço de email inválido',
  invalid_phone_number: 'Telefone inválido',
  invalid_password: 'Senha inválida - deve possuir pelo menos 8 caracteres',
  password_mismatch: 'Senha incompatível',
  coming_soon: 'em breve',
  to_use_signup: 'Para usar essa função, por favor registre-se',
  login_error: 'Problemas no login',
  signup_error: 'Erro ao sair da conta',
  transaction_history_empty: 'Histórico de transação está vazio',
  categories: 'Categorias',
  all_products: 'Todos os produtos',
  search_product: 'Pesquisar produto',
  reissue_receipt: 'Emitir recibo',
  print_receipt: 'Imprimir recibo',
  or_send_receipt: 'Ou envie o recibo',
  save: 'Salvar',
  saved: 'Salvo',
  search: 'Pesquisa',
  custom_item: 'Item Personalizado',
  submit: 'Envie',
  submitted: 'Enviado',
  note: 'Notas',
  message: 'Mensagem',
  amount: 'Quantidade',
  number: 'Número',
  taxes: 'Taxas',
  tax: 'Taxa',
  enter_valid_number: 'Por favor insira um número válido',
  enter_valid_text: 'Por favor insira um texto válido',
  enter_valid_phone: 'Por favor insira um número de telefone válido',
  enter_valid_email: 'Por favor insira um endereço de email válido',
  feature_not_in_demo: 'Essa funcionalidade não está disponível no modo teste',
  provide_refund_reason: 'Você deve selecionar uma razão para reembolso',
  provide_valid_refund_amount: 'Você deve providenciar uma quantidade válida para reembolso',
  refund_exceed_refundable: 'Quantidade de reembolso excede o máximo reembolsável neste recibo',
  refund_saved: 'Reembolso salvo',
  or_customize_discount: 'Ou personalize seu desconto',
  enter_percentage: 'Insira percentagem',
  manual_card_payment: 'Pagamento por cartão manualmente',
  did_card_payment_succeed: 'O pagamento foi efetivado?',
  manual_card_refund: 'Reembolse por cartão manualmente',
  did_card_refund_succeed: 'O reembolso foi efetivado?',
  giftcard_refund: 'Reembolso do cartão presente',
  did_giftcard_refund_succeed: 'O novo cartão presente foi emitido ao cliente?',
  yes: 'Sim',
  no: 'Não',
  general: 'Geral',
  printer: 'Impressora',
  barcode_scanner: 'Leitor de código de barra',
  payment: 'Pagamento',
  profile: 'Perfil',
  currency: 'Moeda',
  printing_receipt: 'Imprimindo recibo...',
  enter_amount: 'Insira quantidade',
  expected_change: 'Troco a receber',
  still_remaining: 'Restante',
  issue_invoice: 'Emissão de fatura',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Endereço',
  address_continued: 'Endereço (opcional)',
  city: 'Cidade',
  zip: 'CEP ou caixa postal',
  country: 'País',
  country_iso: 'Land: ISO 3166-2 (por exemplo, "nl", "de", "gb")',
  invalid_address: 'Endereço inválido ou vazio',
  invalid_city: 'Nome da cidade inválido ou vazio',
  invalid_country: 'Nome do País invalido ou vazio',
  invalid_ecommerce: 'Nenhuma plataforma de e-commerce escolhida',
  signup_error_contact_support: 'Erro ao sair da conta, por favor contate support@countrhq.com',
  open_orders: 'Pedidos em aberto',
  order: 'Pedido',
  enter_weight_g: 'Insira o peso (g)',
  search_scanner: 'Pesquisar scanner Socket CHS',
  order_settings: 'Configurações de pedido',
  order_amount: 'Número de pedidos em aberto disponíveis',
  receipts: 'Recibos',
  print_receipts: 'Imprimir recibos automaticamente',
  open_cash_drawer: 'Abrir caixa registradora automaticamente',
  backup: 'backup',
  email_backup: 'Email backup',
  server_sync: 'Sincronizar o servidor',
  external_payment: 'Outros',
  search_printer: 'Pesquisar impressora',
  sms_successfully_sent: 'SMS enviado com sucesso',
  error_sending_sms: 'Erro no envio de SMS, tente mais tarde',
  email_successfully_sent: 'Email enviado com sucesso',
  error_sending_email: 'Erro no envio do email, tente mais tarde',
  transaction: 'Transação',
  logging_in: 'Fazendo o login',
  downloading_products: 'Baixando produtos...',
  add_to_cart: 'Adicionar ao carrinho',
  invalid_price: 'Preço inválido',
  invalid_discount: 'Desconto inválido',
  invalid_new_price: 'Novo preço inválido - deve ser menos que o preço original',
  invalid_amount: 'Quantidade inválida',
  edit_price: 'Editar preço',
  discount_perc: 'Desconto (%)',
  quantity: 'Quantidade',
  remove: 'Remover',
  sales_info: 'Detalhes da venda',
  summary: 'Resumo',
  refunded_information: 'Detalhes de reembolso',
  total_sale: 'Total vendido',
  total_amount_refunded: 'Total reembolsado',
  remaining: 'Restante',
  sold_by: 'Vendido por',
  source_receipt_number: 'Origem do número do recibo',
  issued_date: 'Data de emissão',
  issued_time: 'Hora de emissão',
  refunded_by: 'Reembolsado por',
  reason: 'Razão',
  receipt_number: 'Número do recibo',
  refunded_date: 'Data de reembolso',
  refunded_time: 'Hora de reembolso',
  first_name: 'Nome',
  last_name: 'Sobrenome',
  shop_category: 'Categoria de sua loja',
  choose_country: 'Selecionar um País',
  you_will_receive_email: 'Parabéns, seu registro está completo',
  wait_email: 'Você irá receber um email com instruções de como conectar sua plataforma de e-commerce com Countr',
  open_dashboard: 'Você agora pode administrar sua conta e criar produtos no seu Countr dashboard',
  by_signing_up: 'Ao assinar eu concordo com o(s)',
  terms_and_conditions: 'Termos e condições',
  and: 'e',
  privacy_policy: 'Politica de privacidade',
  refund_items: 'Reembolsar itens',
  forgot_password: 'Esqueceu sua senha?',
  register_device: 'Registrar tablet',
  device_not_registered: 'Está tablet não está registrada.',
  want_to_add_to_account: 'Registrar agora?',
  if_ios_previously_registered: 'Você re-instalou Countr recentemente, por favor, registre sua tablet novamente.',
  new_device_info: 'Você será redirecionado para sincronizar sua tablet com uma de suas lojas.',
  not_now: 'Agora não',
  proceed: 'Proceder',
  more_info_click: 'Mais informações? Clique aqui.',
  your_detail: 'Seus detalhes',
  vat_no: 'Taxa',
  billing_details: 'Detalhes do pagamento',
  add_store: 'Adicionar loja',
  or_add_to_existing_store: 'Ou adicione à uma loja existente',
  add_new_store: 'Adicionar nova loja',
  store_information: 'Detalhes da loja',
  store_name: 'Nome da loja',
  go_to_web_dashboard: 'Vá para o dashboard online',
  error_create_account: 'Erro na criação da conta - email já existente',
  error_creation_error: 'Ocorreu um erro durante o registro',
  error_wrong_phone_number: 'Por favor insira um número telefone válido.',
  error_try_again: 'Problemas na conexão. Por favor, tente novamente.',
  error_from_the_server: 'Impossível estabelecer conexão com o servidor',
  error_update_cc_info: 'Não é possível atualizar informação do cartão de crédito',
  error_add_device: 'Não é possível adicionar tablet à loja',
  error_add_store: 'Não é possível adicionar nova loja',
  are_not_valid: 'não são válidos',
  is_not_valid: 'inválido',
  are_empty: 'estão vazios',
  is_empty: 'vazio',
  are_not_selected: 'não estão selecionados',
  is_not_selected: 'não selecionado',
  bill: 'Pagamento',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Fechar',
  no_product_found: 'Nenhum produto encontrado',
  please_go_to_dashboard: 'Para adicionar produtos, visite https://dahsboard.countrhq.com',
  select_interface: 'Selecione interface',
  refund_all: 'Reembolsar total',
  purchased_items: 'Itens adquiridos',
  refundable: 'Reembolsável',
  to_be_refunded: 'A ser reembolsado',
  out_of: 'de',
  enter_a_printer_name: 'Insira um nome para a impressora',
  cart_is_empty: 'O carrinho de compras está vazio',
  searching_printers: 'Procurando por impressoras...',
  no_printers_found: 'Nenhuma impressora encontrada. Se você está tentando conectar uma impressora bluetooth, certifique-se de que seu bluetooth está ativado nas configurações de sua tablet.',
  total_due: 'Total a ser pago',
  payment_not_successful: 'Pagamento não sucedido',
  error_processing_payment: 'Erro no processo de pagamento',
  purchase: 'Compra',
  transaction_aborted: 'Transação abortada',
  authorization_error: 'Erro de autorização',
  authorization_ok: 'Autorização OK',
  transaction_cancelled: 'Transação cancelada',
  clearance_error: 'Erro de liberação pelo processador',
  clearance_ok: 'Liberação aceita pelo processador',
  transaction_declined: 'Transação recusada',
  transaction_complete: 'Transação completa',
  initiating: 'Inicializando',
  application_selected: 'Esperando resposta do terminal de pagamento',
  scheduled_for_clearance: 'Agendado para liberação offline pelo processador',
  terminal_error: 'Erro no terminal',
  unknown_status: 'Status desconhecido',
  waiting_for_clearance: 'Esperando confirmação de liberação pelo provedor',
  amount_exceeded: 'Quantidade máxima excedida',
  authorization_timeout: 'Autorização timeout',
  batch_processing_failed: 'Batch processing failed',
  cancelled: 'Cancelado',
  card_blocked: 'Cartão bloqueado',
  card_invalid: 'Cartão inválido',
  card_declined: 'Cartão recusado',
  transaction_failed: 'Transação falhou',
  insufficient_funds: 'Fundo insuficiente',
  terminal_not_paired: 'Terminal não pareado',
  pin_incorrect_last_retry: 'Senha incorreta - última tentativa',
  pin_incorrect_retry: 'Senha incorreta - tente novamente',
  printer_busy: 'Impressora ocupada',
  printer_error: 'Erro na impressora',
  printer_not_present: 'Impressora não disponível',
  printer_out_of_paper: 'Impressora sem papel',
  success: 'Sucesso',
  system_error: 'Erro no sistema',
  transaction_not_found: 'Transação não encontrada',
  unknown_device: 'Tablet desconhecida',
  apply_discount: 'Aplicar desconto',
  seqr_id: 'SEQR ID de comerciante',
  seqr_password: 'SEQR senha de comerciante',
  bitpay_api_token: 'Bitpay API Token',
  registration_successful: 'Terminal registrado com sucesso',
  unregistration_successful: 'Terminal descadastrado com sucesso',
  payment_init_not_successful: 'Pagamento não foi inicializado -verifique sua conexão de internet',
  always_show_addons: 'Sempre mostrar addons',
  show_product_image: 'Mostre imagens de produtos',
  settings_connected_printers: 'Impressoras conectadas',
  settings_add_new_printer: 'Adicionar nova impressora',
  wifi_lan: 'WiFi ou LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Leitores de código de barra pareados',
  account: 'Conta',
  refresh_products: 'Atualizar produtos',
  server_synchronisation: 'Sincronização do servidor',
  sales: 'Vendas',
  today: 'Hoje',
  refunds: 'Reembolsos',
  product: 'Produto',
  product_by_category: 'Produtos por categoria',
  see_categories_details: 'Detalhes de categoria',
  open_orders_finish_payment_or_empty: 'Ainda existem pedidos em aberto. Termine o pagamento ou esvazie o carrinho de compras.',
  name: 'Nome',
  loading_products: 'Carregando produtos',
  you_should_select_at_least_one_item: 'Você deve selecionar pelo menos um item',
  select_card_provider: 'Selecione um provedor de pagamento',
  user_cancelled: 'Pagamento cancelado pelo cliente',
  card_payment: 'Pagamento por cartão',
  manage_products: 'Administrar produtos',
  edit_mode: 'Modo de edição',
  lock: 'bloquear',
  loyalty: 'Fidelidade',
  account_card: 'Cartão de conta',
  account_lookup: 'Pesquisa de conta',
  Activate: 'Ativar',
  add_anonymous_customer: 'Adicionar cliente anônimo',
  add_customer: 'Adicionar cliente',
  add_customer_to_cart: 'Atribuir ao carrinho de compras',
  add_new_account: 'Adicionar conta',
  add_new_customer: 'Adicionar novo cliente',
  add_note_hint: 'Adicionar comentário',
  add_tax_to_price: 'Preços excluindo taxa',
  add_tip: 'Adicionar gorjeta',
  add_to_store: 'Adicionar loja',
  addons: 'Addons',
  admin_pin: 'Senha de administrador',
  admin_pin_saved: 'Nova senha de administrador salva',
  admin_pin_should_be: 'A senha de administrador deve ser um número entre 4 e 6 dígitos',
  aid: 'AID',
  aidCard: 'Cartão AID',
  allow_cart_discounts: 'Permitir descontos no carrinho',
  allow_custom_cart_discounts: 'Permitir descontos personalizados no carrinho',
  allow_custom_items: 'Permitir itens personalizados',
  allow_product_discounts: 'Permitir descontos no produto',
  allow_custom_product_discounts: 'Permitir descontos personalizados em produtos',
  amount_hint: 'Quantidade indicada',
  app_version: 'Versão Countr',
  applicationLabel: 'Etiqueta do aplicativo',
  applicationName: 'Nome de aplicativo',
  applicationPreferredName: 'Nome preferido do aplicativo',
  appName: 'NomeApp',
  ask_tips: 'Sugerir gorjeta',
  authorizationCode: 'Código de autorização',
  available_points: 'Pontos disponíveis',
  available_rewards: 'Recompensas disponíveis',
  back: 'Voltar',
  barcode_scanner_disconnected: 'Seu leitor de código de barra mode estar desligado. Tente liga-lo novamente.',
  barcode_scanner_not_found: 'Nenhum leitor de código de barra encontrado',
  barcode_scanner_not_found_desc: 'Conecte um novo leitor de código de barra - ative seu bluetooth dentro das configurações de sua tablet e pesquise abaixo por um leitor de código de barra',
  bunq: 'bunq',
  bunq_admin_approval: 'Um administrador da Bunq irá rever e aprovar seu registro',
  bunq_instructions: '1) Abra o aplicativo da Bunq e insira sua senha (se necessário)\n2) Selecione "Scaneie QR code" / "QR"\n3)Scaneie o código da tel\n4)Adicione gorjeta se desejado\n5)Confirme pagamento.',
  can_only_refund_entire_amount: 'O reembolso total só é possível via {{payment_method}}',
  cancel: 'Cancelar',
  cancel_payment: 'Cancelar pagamento',
  cannot_pay_later_more_than_remaining_value: 'Você não pode (verb is missing here) mais do que o valor restante em pagamentos futuros',
  cannot_refund_transaction_with_partial_postponed_payments: 'Essa transação contém um ou mais pagamentos parciais e futuros, termine a transação agora ou reembolse tudo',
  cant_find_products: 'Não está encontrando todos os seus produtos no PDV? Use a ferramenta de busca acima ou um leitor de código de barras para encontrá-los',
  card_scheme: 'Card Scheme',
  cardBrand: 'Marca do cartão',
  cardExpiryMonth: 'Validade do cartão Mês MM',
  cardExpiryYear: 'Validade do cartão Ano AA',
  cardType: 'Tipo de cartão',
  cart_is_frozen_finish_payment: 'O carrinho de compras está congelado. Termine a transação antes de continuar com outra ação',
  cash_difference: 'Diferença',
  cash_drawer: 'Caixa registradora',
  cash_in: 'Saque',
  cash_in_out: 'Saque/depósito',
  cash_out: 'Depósito',
  change: 'Troco',
  change_currency: 'Mudar a moeda',
  change_given: 'Troco recebido',
  change_open_orders_amount: 'Altere a quantidade de pedidos em aberto',
  check_location_settings: 'Verificar configurações de localização',
  check_network: 'Cheque sua conexão de internet',
  choose_category: 'Selecionar um ramo de mercado',
  choose_mobile_provider: 'Vários provedores ativados - por favor escolha um',
  clear_settings: 'Verificar',
  clear_settings_successful: 'Configurações apagadas com sucesso',
  clear_settings_warning: 'Apagar suas configurações irá apagar suas informações de conexão. Confirme para continuar',
  client_ticket: 'clientTicket',
  close_shift: 'Fechar registradora',
  company_address: 'Endereço da empresa',
  company_registration_no: 'Número de registro da empresa',
  completed_payments: 'Pagamentos completos',
  confirm: 'Confirmar',
  confirm_cash_payment: 'Confirmar quantia em pagamento em dinheiro',
  confirm_new_currency: 'Confirmar mudança de moeda',
  confirm_open_orders_amount_change: 'Confirmar alteração de pedidos em aberto?',
  confirm_payment: 'Confirmar pagamento',
  confirm_with_change: 'Confirmar com troco',
  confirm_with_tip: 'Confirmar com gorjeta',
  connect_barcode: 'conectar leitor de código de barra (Socket CHS)',
  connect_to_webshop: 'Gostaria de conectar com sua Loja online?',
  connected_printer: 'Impressoras conectadas',
  contact_information: 'Dados pessoais',
  contact_us: 'support@countrhq.com',
  create_product: 'Novo produto',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'Seu PDV está em versão teste. Suas transações serão deletadas a cada 4 horas.',
  currently_trial_mode_visit_dashboard: 'Seu PDV está em versão teste. Você pode ativar sua conta no seu dashboard',
  customer: 'Cliente',
  customer_card: 'Cartão do cliente',
  customer_info: 'Informação do cliente',
  customer_instructions: 'Instruções do cliente',
  customer_lookup: 'Consulta do cliente',
  customer_lookup_error: 'Problema no servidor de consulta do cliente',
  customer_name: 'Cliente',
  customer_not_valid: 'Cliente inválido',
  customer_receipt: 'Recibo do cliente',
  customer_since: 'Cliente desde',
  Date: 'Data',
  deregister_terminal: 'Cancelar terminal',
  description_mandatory: 'Uma descrição é obrigatória para transações de saque/depósito',
  device_locked: 'Tablet bloqueada',
  device_locked_enter_pin: 'Esta tablet está bloqueada. Insira a senha de administrador para desbloqueá-la',
  did_cheque_refund_succeed: 'O cheque foi retornado ao cliente?',
  did_mobile_payment_succeed: 'O pagamento móvel foi feito com êxito?',
  did_mobile_refund_succeed: 'O reembolso com celular foi feito com êxito?',
  direct_connection: 'Conectado diretamente',
  disabled_product: 'Indisponibilizar produto',
  discount_currency: 'Desconto ({{currency}})',
  do_you_want_remove_payment: 'Você deseja remover o pagamento?',
  download_adyen_app: 'Baixe Adyen App',
  download_izettle_app: 'Baixe iZettle App',
  download_payleven_app: 'Baixe Payleven App',
  download_sumup_app: 'Baixe Sumup App',
  download_yodo_app: 'Download do aplicativo Yodo Rocket',
  downloading_categories: 'Baixando categorias…',
  downloading_taxes: 'Baixando taxas…',
  downloading_transactions: 'Baixando transações',
  ecommerce_none: 'Eu criarei meus próprios produtos',
  ecrId: 'ecrld',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Email de fatura',
  employee_name: 'Funcionário',
  employee_pin: 'Senha do funcionário',
  empty_cart: 'Esvaziar carrinho de compras',
  enable_staff_pin: 'Disponibilizar senha de funcionários',
  enter_a_valid_search_value: 'Insira um valor de busca válido',
  enter_api_key: 'Insira chave de API',
  enter_base_url: 'Insira sua URL',
  enter_merchant_auth_code_payconiq: 'Insira seu código de autorização do Payconiq',
  enter_merchant_iban_payconiq: 'Insira seu IBAN  registrado no Payconiq',
  enter_merchant_id_payconiq: 'Insira sua ID de comerciante do Payconiq',
  enter_order_name: 'Insira nome do pedido',
  enter_reseller_id: 'Insira sua ID de revendedor',
  enter_user_number: 'Insira seu número de usuário',
  enter_user_password: 'Insira sua senha de usuário',
  error_adding_device: 'Ocorreu um erro durante o registro da tablet',
  error_refreshing_merchant: 'Erro na recuperação das informações da conta',
  error_retrieve_categories: 'Erro na recuperação de categorias. \nPor favor cheque sua conexão de internet e tente novamente.',
  error_retrieve_products: 'Erro na recuperação dos produtos. Por favor contate support@countrhq.com',
  error_retrieve_taxes: 'Erro na recuperação de taxas. \nPor favor cheque sua conexão de internet e tente novamente.',
  error_while_retrieving_results: 'Erro na recuperação de dados',
  errorCode: 'Erro de código',
  errorMessage: 'Erro de mensagem',
  ersReference: 'ersReference',
  excess_amount: 'Quantidade excedida',
  expected_amount: 'Quantidade esperada',
  expected_change_gratuity: 'Troco ou gorjeta esperada',
  external_url: 'URL externa',
  failed_cancel: 'Falha no cancelamento de transação',
  feature_only_android: 'Essa funcionalidade só está disponível em Android',
  find_terminal: 'Pesquisar terminais',
  for_security_enter_password: 'Por medidas de segurança, por favor insira sua senha novamente',
  for_this_device: 'Somente para esse aparelho',
  for_this_store: 'Somente para esse loja',
  four_inch: '4 polegadas',
  generate_keys: 'Gerar chave',
  generating_keypair: 'Gerando chaves',
  generating_reports: 'Gerando relatórios',
  generic_usb: 'USB genérico',
  get_your_free_pos: 'Baixe sua PDV grátis aqui www.countrhq.com',
  gift_code: 'Código do presente',
  gratuity_amount: 'Gorjeta (quantidade)',
  gratuity_percentage: 'Gorjeta (porcetagem)',
  group_items_in_cart: 'Agrupar items idênticos no carrinho de compras',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions: 'Não é possível atualizar. Você tem {{transaction_number}} transações não sincronizadas. Certifique-se de que você tenha conexão de internet, espera a sincronização e tente novamente.',
  impossible_signout_you_have_transactions: 'Não é possível fazer signout. Você tem {{transaction_number}} transações não sincronizadas. Certifique-se de que você tenha conexão de internet, espera a sincronização e tente novamente.',
  insert_printer_name: 'Por favor insira um nome (3 caracteres ou mais) para sua impressora',
  inserted_login_not_valid: 'Login incorreto',
  inserted_value_not_valid: 'Valor inserido não é válido',
  installmentAmount: 'Quantidade de prestações',
  invalid_credit_card_number: 'O número do cartão e crédito deve ter 16 dígitos',
  invoiceReference: 'Referência da fatura',
  invoiceStatus: 'Status da fatura',
  ip_address: 'Endereço de IP',
  is_business: 'Empresa?',
  is_correct_amount_tendered: '{{amount}} é a quantidade correta?',
  isDone: 'Nada para carregar',
  isError: 'Toque para tentar novamente…',
  isTap: 'Toque para carregar mais…',
  join_thousands_of_merchants: 'Milhares de comerciantes usam Countr. Hoje é a sua vez. Registre-se gratuitamente.',
  language: 'Língua',
  last_visit: 'Última visita',
  leave_tour: 'Sair do tour',
  leaving_tour: 'Saindo do tour...',
  loyal_customer: 'Consumidor fiel',
  mac_address: 'endereço do MAC',
  mailing_list_permission: 'Adicionar ao informativo',
  manual_mobile_payment: 'Pagamento móvel',
  manual_mobile_refund: 'Reembolso móvel',
  max_amount_exceeded: 'Quantidade excede o máximo permitido para esse método de pagamento',
  menu_item_12char: 'Item no menu não pode ser maior que 12 caracteres',
  menu_item_name: 'Item no menu (máx. 12 caract)',
  merchant_receipt: 'Recibo do comerciante',
  merchantDisplayName: 'nome do comerciante',
  merchantId: 'ID do comerciante',
  mid: 'MID',
  mobile_number: 'Número de celular',
  mobile_payment: 'Telefone celular',
  mobile_payments: 'Pagamento móvel',
  new_price: 'Novo preço (moeda)',
  no_available_addons_for_product: 'Nenhum addon disponível para esse produto.',
  no_connected_printer: 'Nenhuma impressora conectada',
  no_connected_terminal: 'Nenhum terminal conectado',
  no_customer_found: 'Nenhum cliente encontrado',
  no_customer_selected: 'Não disponível',
  no_printer_configured: 'Nenhuma impressora configurada',
  no_printer_found: 'Nenhuma impressora encontrada. Clique aqui se você tem uma impressora Star com conexão USB',
  no_product_in_cart: 'Nenhum produto no carrinho de compras',
  no_terminals_found: 'Nenhum terminal encontrado',
  no_url_specified: 'Erro, nenhuma URL especificada',
  none: 'Nada',
  note_mandatory_cash_difference: 'Um comentário é obrigatório se houver diferença entre quantidade esperada e atual',
  note_optional: 'Notas (opcional)',
  numberOfInstallments: 'Quantidade de prestações',
  numpad: 'Teclado numérico',
  offline: 'Offline',
  ok: 'OK',
  one_or_more_carts_are_frozen_finish_payment: 'Um ou mais carrinhos de compras congelados. Termine o pagamento antes de proceder com outra ação',
  online: 'Online',
  open_market: 'Do you want to open the market?',
  open_register_before_transaction: 'Abra a registradora antes de fazer transações',
  open_shift: 'Abra a registradora',
  order_printer: 'Impressora de pedidos',
  orderId: 'Pedido ID',
  orderRef: 'Referência do pedido',
  other_text: 'Texto adicional',
  paid: 'Pago',
  paid_so_far: 'Pago até agora',
  paid_with_method: 'Pago com {{method}}',
  pan: 'PAN',
  panLastDigits: 'Pan Last Digits',
  panMasked: 'Pan Masked',
  PANSeq: 'PANSeq',
  partial_payment: 'Pagamento parcial',
  partial_payment_not_allowed_for: 'Pagamentos parciais não são permitidos para',
  partial_payment_paid_successfully: 'Pagamento parcial foi pago com sucesso',
  pay_later: 'Pagamento futuro',
  pay_later_allows_only_refund_all: 'Com pagamento futuro você só pode reembolsar o valor total. Para pagamento parcial, termine o pagamento',
  pay_later_refund: 'Cancelar pagamento futuro',
  pay_on_account: 'Pagar em conta bancária',
  payconiq: 'Payconiq',
  paying_now: 'Pagando agora',
  payment_cancelled: 'Pagamento cancelado',
  payment_error: 'Erro no pagamento',
  payment_info: 'Detalhes do pagamento',
  payment_insert_card: 'Insira seu cartão',
  payment_method_not_configured: '{{método de pagamento}} não configurado nesta tablet',
  payment_number: 'Número do pagamento',
  payment_processor_options: 'Opções de processadores de pagamento',
  payment_provider: 'Provedor de pagamento',
  payment_success: 'Pagamento efetuado com sucesso',
  payment_terms_in_net_days: 'Termos de pagamento (net days)',
  personal_information: 'Informações pessoais',
  pickup_rewards: 'Coletar recompensas',
  pin: 'Senha',
  please_insert_a_valid_amount: 'Por favor insira uma quantidade válida',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Imprimir',
  print_also_product_details: 'Você gostaria de imprimir os detalhes do produto?',
  print_error: 'Erro na impressão',
  print_product_details: 'Imprimir detalhes do produto',
  print_successful: 'Impressão bem sucedida',
  printer_connect_desc: 'Impressora wifi precisa estar conectada na mesma rede que sua tablet. \nImpressoras bluetooth precisam estar pareadas nas configurações de sua tablet antes de aparecerem disponíves nesta lista',
  printer_removed: 'Impressora removida',
  printer_test: 'Imprimir teste',
  printing_report: 'Imprimindo relatório...',
  proceed_with_next_payment: 'Proceder com o próximo pagamento',
  processorName: 'Nome do processador',
  product_is_disable_continue_anyway: 'O produto que você selecionou está indisponível. Gostaria de adicionar ao carrinho de compras mesmo assim?',
  product_sync: 'Sincronização de produtos',
  profile_average: 'Valor médio de compra',
  protected_sections: 'Seções protegidas por senha',
  receipt_printer: 'Impressora de recibo',
  redeem_reward: 'Resgatar recompensa',
  referenceNumber: 'Número de referência',
  refresh_customers: 'Atualizar lista de clientes',
  refresh_merchant: 'Atualizar detalhes da conta',
  refresh_transactions: 'Atualizar transações',
  refreshing_merchant: 'Atualizando detalhes da conta...',
  refund_number: 'Número de reembolso',
  refunds_not_available_for_provider: 'Reembolso não disponível para provedor: {{payment_provider}}',
  register_terminal: 'Registrar terminal',
  reissue: 'Nova emissão',
  remaining_payment: 'Pagamento restante',
  remove_customer_from_cart: 'Remover do carrinho de compras',
  remove_payment: 'Remover pagamento',
  remove_printer: 'Remover impressora',
  removing_products: 'Atualizar produtos',
  reopen_shift: 'Reabrir a registradora',
  replace_customer: 'Substituir cliente',
  report_by_shift: 'Permitir abertura e fechamento de registro diários',
  report_printer: 'Impressora de relatórios',
  requested_amount: 'Quantidade requerida',
  reward: 'Recompensas',
  round_cash_transactions: 'Arredondar transação para cima/baixo',
  save_printer: 'Salvar impressora',
  scan: 'Scan',
  scan_barcode_instruction_one: 'Leia o código de barra para colocar o Socket Bluetooth Scanner em modo de pareamento',
  scan_barcode_instruction_two: 'Em configurações da tablet, certifique-se de que o bluetooth esteja ativado para parear com o Socket CHS scanner',
  scan_code_not_exist: 'Código scaneado {{barcode}} não reconhecido',
  scan_qr_code: 'Leia o código QR',
  scanner: 'Scanner',
  scanner_connected: 'Leitor de código de barra conectado',
  scanner_disconnected: 'Leitor de código de barra desconectado',
  scanner_pair_completed: 'Pareamento do leitor de código de barra completo',
  search_for_customer: 'Pesquisar cliente',
  search_report: 'Pesquisar relatórios',
  search_transaction: 'Pesquisar transação',
  searching_terminals: 'Procurando por terminal (terminais)...',
  security_and_permissions: 'Segurança e permissões',
  select_one_payment_method: 'Pelo menos um método de pagamento deve ser selecionado',
  select_payment_method: 'Por favor selecione um cartão AID na lista abaixo',
  select_terminal: 'Selecione um terminal',
  send_email: 'Envie email',
  send_to_another_customer: 'Envie para outro cliente',
  send_to_current_customer: 'Envie para cliente atual',
  send_to_customer: 'Envie para cliente',
  sending_receipt: 'Enviando recibo...',
  server_error: 'Erro no servidor',
  session: 'Sessão',
  set_new_price: 'Preço novo',
  setting_saved: 'Configuração salva',
  settings_connected_terminals: 'Terminais conectados',
  settings_printer_available_printers: 'Impressoras disponíveis',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Defina sua senha de administrador em configurações - conta',
  shift_closed: 'O registro foi fechado',
  shift_opened: 'O registro foi aberto',
  shifts: 'Turnos',
  shopper_reference: 'ShopperReference',
  show_customer: 'Mostrar informação do cliente no recibo',
  show_employee: 'Mostrar o nome do cliente no recibo',
  show_customer_payconiq: 'Selecione um cliente que tenha feito check-in ou leia o código de barra / NFC',
  show_new_product_tile: 'Mostre botão "criar produto"',
  show_password: 'Mostre minha senha',
  sign_out_header: 'Você tem certeza?',
  sign_out_warning: 'Sair da conta irá limpar todos suas configurações do app, incluindo provedores de pagamento e impressoras. Cancele,defina uma senha de administrador e usa a função bloquear se você não deseja limpar suas configurações. Confirme se você deseja continuar com sair da conta',
  sign_up_to_print: 'Registre-se com uma conta gratuita para imprimir recibos.  Sem compromisso ou cartão de crédito, cancele a qualquer hora',
  sign_up_to_send_email: 'Registre-se com uma conta gratuita para enviar recibos por email.  Sem compromisso ou cartão de crédito, cancele a qualquer hora',
  signing_out: 'saindo da conta...',
  signup_for_mailing_list: 'Adicionar cliente à newsletter',
  sorry_report_not_available: 'Esse relatório não está disponível, vá para o dashboard para acessá-lo',
  start_using_pos: 'Comece a vender com Countr',
  state: 'Estado',
  status: 'Status',
  store_powered_by: 'Essa loja é  powered by',
  street: 'Rua',
  suggested_amount: 'Quantidade sugerida',
  suggested_tip: 'Gorjeta sugerida',
  support: 'Ajuda + suporte',
  telephone: 'Telefone',
  terminal_port: 'Port configured for terminal',
  terminal_selected: 'Terminal salvo',
  terminalId: 'ID do terminal',
  test: 'Isso é um teste',
  three_inch: '3 polegadas',
  tid: 'TID',
  Time: 'Hora',
  tip: 'Gorjeta',
  tip_added: 'Gorjeta adicionada',
  to_pay: 'a pagar',
  total_amount: 'total',
  transaction_code: 'Código de transação',
  transaction_date: 'DataDaTransação',
  transaction_finalized: 'Transação finalizada',
  transaction_pending: 'Transação pendente',
  transaction_started: 'Transação iniciada',
  transaction_succeeded: 'Transação sucedida',
  transaction_time: 'Hora da transação',
  transaction_timeout: 'Timeout de transação',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Experimente agora',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 polegadas',
  uncategorized: 'não categorizado',
  valid_phone_number: 'Número de telefone válido',
  validating_fields: 'Validando...',
  validation_in_progress: 'Validação em progresso',
  verification_code: 'Código de verificação',
  verification_method: 'método de verificação',
  waiting_for_customer: 'Esperando por cliente',
  want_to_sync_products: 'Você gostaria de sincronizar seus produtos?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Você deve selecionar uma loja',
  add_name_device: 'Adicione um nome a sua tablet (obrigatório)',
  card_number: 'Número do cartão',
  change_language: 'Alterar idioma',
  choose_currency: 'Escolha principal moeda',
  clear_cart_after_finish: 'Resetar carrinho/mesa após pagamento',
  confirm_new_language: 'Confirmar nova língua? \nFeche seu pplicatiove e reinicie para aplicar a mudança',
  customer_signature: 'Assintura do consumidor',
  day: 'Dia',
  device_name_mandatory: 'Nome da tablet é obrigatório',
  downloading_refunds: 'Baixando reembolsos',
  email_report: 'Relatórios por email',
  email_successfully_sent_test: 'Email teste enviado para seu email',
  enter_table_name: 'Insira nome da mesa',
  error: 'Erro',
  error_retrieve_refunds: 'Erro ao recuperar reembolsos',
  error_retrieve_transactions: 'Erro ao recuperar transações',
  for_this_device_see_dashboard: 'Somente para esta tablet - verifique seu dashboard para mais relatórios',
  hide_new_sale: 'Ocultar \'Nova transação\' tela pós pagamento',
  last_transaction_result: 'Status da última transação',
  merge_warning: 'Essa ação irá fundir o conteúdo destes carrinhos/mesas',
  month: 'Mês',
  post_code: 'CEP',
  print_order: 'Mostrar “enviar para impressora de pedido”',
  products_added_removed_refresh: 'Produtos foram adicionados ou alterados - deseja atualiza sua lista?',
  quarter: 'Trimestre',
  refresh: 'Atualizar',
  refreshing_products: 'Atualizando produtos…',
  removing_categories: 'Removendo categorias antigas…',
  removing_taxes: 'Removendo impostos antigos…',
  select_interface_mandatory: 'A seleção de alguma conexão é obrigatória',
  sms_successfully_sent_test: 'Testar SMS enviado para sua conta',
  splitOrderPrintByCategory: 'Dividir “impressão de pedidos” por categorias',
  stored_digitally: 'Salvar em mídia digital',
  stripe_id: 'stripeID',
  sync_support_info: 'Sincronizar informações de suporte',
  syncing_support_info: 'Sincronizando informações de suporte…',
  table: 'Mesa',
  table_is_empty: 'Mesa vazia',
  tables_or_carts: 'Mostrar carrinho de compras como mesas',
  uncertain_card_payment_success: 'Sucesso de pagamento duvidoso',
  unknown_category: 'Categoria desconhecida ',
  unsynced_transactions_report_incorrect: 'Você tem {{transaction_number}} transações não sincronizadas com o servidor - relatórios não representam a sua situação atual',
  update_terminal_firmware: 'Atualizar firmware do terminal',
  week: 'Semana',
  year: 'Ano',
  email_queued: 'Email em progresso',
  order_received: 'Pedido novo / atualizado recebido',
  go_to_orders: 'Ver pedidos',
  open_carts: 'Carrinhos abertos',
  auto_print_order: 'Imprimir pedidos não impressos no pagamento',
  auto_print_order_cart: 'Imprimir pedidos não impressos no carrinho / mudança de mesa',
  external: 'De outros',
  no_printer_found_tcp: 'Nenhuma impressora encontrada \nClique aqui para inserir manualmente as informações da impressora',
  downloading_carts: 'Baixando carrinhos ...',
  pay_later_hybrid: 'Pague depois',
  error_retrieve_carts: 'Erro ao recuperar carrinhos',
  no_ip_address: 'Sem endereço',
  building_number: 'Número do edifício',
  confirm_manual_payment: 'Confirme o pagamento manual',
  shipping_address: 'Endereço de entrega',
  birth_date: 'Data de nascimento',
  for_this_store_see_dashboard: 'Apenas para esta loja - consulte o Painel para obter mais relatórios',
  customers: 'clientes',
  tickets_in: 'Ingressos em',
  tickets_out: 'Bilhetes fora',
  tickets_in_out: 'Entrada / saída de ingressos',
  unsynced_transaction_cant_refund: 'Transação ainda não sincronizada com o servidor - reembolso não é possível',
  show_stock_level: 'Exibir o nível de estoque atual (se disponível)',
  available: 'em estoque',
  stock_not_available: 'estoque não rastreado',
  order_another_store: 'Peça de outro local',
  order_products: 'Permitir pedidos de produtos de outros locais',
  trial_expired: 'Seu período de teste expirou. A funcionalidade POS foi desativada.',
  left_menu: 'Navbar esquerda',
  right_menu: 'Navbar direita',
  direct_pay_if_one_method: 'Iniciar pagamento automaticamente se 1 método definido',
  processing_vouchers: 'Processando fidelidade do carrinho e vouchers ...',
  display_2_rows_of_categories: 'Exibir 2 linhas de categorias',
  unknown_barcodes_as_vouchers: 'Trate códigos de barras não reconhecidos como vouchers',
  validate_signature: 'Assinatura do cliente válida?',
  group_items_in_receipt_and_cart: 'Agrupe os mesmos itens no carrinho e recibo',
  ask_customer_subscribe_email: 'Peça ao cliente para fornecer o endereço de e-mail',
  hide_categories: 'Ocultar blocos de categorias',
  hide_all_products_category: 'Ocultar bloco de categoria de todos os produtos',
  provisional_receipt: '*** PROVISÓRIO ***',
  reprint: '*** REPRINT ***',
  block_reprints: 'Não permitir reimpressão de itens',
  reports_at_store_level: 'Mostrar relatórios para a loja, não para o dispositivo',
  max_addon_qty_exceeded: 'Máximo de {{maxQty}} addons permitidos para este grupo',
  min_addon_qty_unmet: 'Mínimo de {{minQty}} addons necessários de {{groupName}}',
  press_button_to_wake_terminal: 'Pressione o botão verde para ativar o terminal PIN',
  payments: 'Pagamentos',
  average_sale_duration_mins: 'Duração média da venda (minutos)',
  save_order_move_next: 'Salve o pedido, continue para a próxima mesa vazia',
  max_orders_reached: 'Número máximo de pedidos abertos atingido',
  show_open_orders: 'Mostrar os pedidos abertos primeiro na lista de pedidos',
  tender_created: 'Transação iniciada',
  invalid_request: 'Pedido inválido',
  already_processed: 'Transação já processada',
  invalid_terminal: 'Terminal de pagamento inválido',
  network_error: 'Erro de rede',
  bad_credentials: 'Credenciais inválidas',
  server_issue: 'Não é possível contatar o servidor',
  pin_entered: 'PIN inserido',
  initial: 'Inicializando',
  card_inserted: 'Cartão inserido',
  card_swiped: 'Cartão passado',
  wait_app_selection: 'Aguardando seleção de aplicativo',
  wait_amount_adjustent: 'Aguardando ajuste de valor',
  ask_gratuity: 'Peça gratificação',
  gratuity_entered: 'Gratuidade inscrita',
  ask_dcc: 'Pedir DCC',
  dcc_accepted: 'DCC aceito',
  dcc_rejected: 'DCC rejeitado',
  processing_tender: 'Processamento de propostas',
  wait_pin: 'Aguardando PIN',
  provide_card_details: 'Forneça detalhes do cartão',
  card_details_provided: 'Detalhes do cartão fornecidos',
  receipt_printed: 'Recibo impresso',
  referral: 'Referência',
  referral_checked: 'Referência verificada',
  check_signature: 'Verifique a assinatura',
  signature_checked: 'Assinatura verificada',
  additional_data_available: 'Dados adicionais disponíveis',
  transaction_acknowledged: 'Transação reconhecida',
  device_already_registered: 'Dispositivo já registrado',
  add_in_progress: 'Já adicionando dispositivo',
  app_not_registered: 'App não registrado',
  unknown_error: 'Erro desconhecido',
  timeout: 'Tempo esgotado',
  verify_failed: 'Não foi possível verificar o dispositivo',
  identify_failed: 'Não foi possível identificar o dispositivo',
  registration_error: 'Erro de Registo',
  sync_issue: 'Não foi possível sincronizar o dispositivo',
  show_select_customer: 'Mostrar seletor de clientes',
  store_name_mandatory: 'O nome da loja é obrigatório',
  choose_store_dashboard_for_more: 'Escolha a loja - mais podem ser adicionados através do painel de back-office',
  recovering_incomplete_payment: 'Recuperando pagamento incompleto - isso pode levar até 30 segundos',
  allow_negative_sale: 'Permitir valor de venda negativo',
  move_current_table_to: 'Mover a tabela atual ({{table_name}}) PARA',
  store: 'Loja',
  print_specific_categories: 'Imprimir categorias específicas',
  customer_display_attached: 'Display do cliente anexado',
  sale: 'Venda',
  reps_and_waste_button_in_cart: 'Mostrar representantes e botão de desperdício no carrinho',
  small_product_tiles: 'Reduza o tamanho dos blocos do produto',
  large_product_tiles: 'Aumentar o tamanho dos blocos do produto',
  show_product_price_on_tile: 'Preço do produto na telha',
  printer_roles: 'Funções de impressora',
  paper_size: 'Tamanho do papel',
  customer_display: 'Exibição do cliente',
  enable_realtime_updates: 'Habilite atualizações em tempo real do servidor',
  show_open_orders_first: 'Mostrar os pedidos abertos primeiro na lista de pedidos',
  blank_lines_order_ticket: 'Linhas em branco no topo do tíquete de pedido',
  blank_lines_bottom_order_ticket: 'Linhas em branco na parte inferior do tíquete de pedido',
  scan_card: 'Digitalizar cartão',
  scan_nfc_or_barcode: 'Digitalize o cartão ou código NFC',
  show_product_keypad: 'Mostrar teclado no carrinho',
  extra_payment_methods: 'Métodos de pagamento extras',
  failed_recovery: 'Falha ao recuperar o pagamento',
  blank_line_between_order_items: 'Linha em branco entre os itens do tíquete do pedido',
  look_up_customers_using_barcodes: 'Adicionar cliente ao carrinho usando scanner',
  error_loading_configuration: 'Erro ao carregar configuração',
  error_verifying_payment_terminal: 'Erro ao verificar terminal de PIN',
  error_identifying_payment_terminal: 'Erro ao identificar terminal de PIN',
  error_saving_configuration: 'Erro ao salvar configuração',
  conflicting_server_modes: 'Terminal de pinos e servidor têm modos conflitantes (TESTE x PRODUÇÃO)',
  nothing_to_print: 'Nenhum item para imprimir',
  large_item_name: 'Aumentar o tamanho do item impresso',
  print_ean_sku: 'Imprimir EAN e SKU no recibo',
  select_category_on_startup: 'Selecione a categoria uma vez na inicialização',
  split_tickets_item: 'Divida a impressão de pedidos por item',
  split_tickets_amount: 'Divida a impressão de pedidos por quantidade',
  split_print: 'Dividir impressão',
  refreshing_customers: 'Atualizando clientes ...',
  downloading_customers: 'Baixando clientes ...',
  error_retrieve_customers: 'Erro ao recuperar clientes. \nVerifique sua conexão de internet e tente novamente',
  server_error_please_retry: 'Erro de servidor. Por favor tente novamente',
  vibrate_on_click: 'Feedback de vibração no clique do item',
  keep_screen_on: 'Não permitir que a tela hiberne',
  refund_same_method: 'Forçar os reembolsos com o método de pagamento original',
  cannot_refund_more_than_paid: 'Só pode reembolsar no máximo {{max_amount}} via {{payment_method}}',
  tablet_date_incorrect: 'Data do dispositivo definida incorretamente - possível risco de segurança',
  extra_non_revenue_info: 'Peça informações adicionais para itens não lucrativos',
  enter_extra_info: 'Insira informações extras do item',
  giftcard_or_reference_no: 'Vale-presente ou número de referência',
  inserted_code_not_valid: 'Código incorreto',
  validate_user: 'Validação de usuário ({{user_address}})',
  enter_validation_code: 'Digite o código de validação que você recebeu por SMS ou e-mail',
  tax_rate: 'Taxa de imposto',
  pretax: 'Internet',
  deposit: 'Depósito',
  can_not_refund_with_different_provider: 'Não é possível reembolsar transações de {{txn_payment_method}} usando {{selected_payment_method}}',
  external_scanner_connected: 'Scanner externo conectado',
  cancelling: 'Cancelando',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Troca',
  ask_exchange: 'Permitir trocas de produtos',
  exchange_error: 'Erro de troca',
  exchange_items: 'Trocar itens',
  exchangeable: 'Permutável',
  to_be_exchanged: 'Para ser trocado',
  exchange_all: 'Trocar tudo',
  negative_sale_amount: 'O valor a pagar é menor que 0',
  download_rabosmartpin_app: 'Baixe o aplicativo Rabo SmartPin',
  transaction_disabled: 'Pagamentos de terceiros não permitidos neste contrato',
  transaction_in_progress: 'Outro pagamento já está em andamento',
  refund_offline: 'Reembolsos não são possíveis no modo restrito',
  refund_not_allowed: 'Reembolsos não são permitidos para o usuário conectado',
  cash_forbidden: 'Pagamentos em dinheiro não permitidos neste contrato',
  notify_new_online_order: 'Mostrar notificação quando um novo pedido online for recebido',
  print_new_online_order: 'Imprime automaticamente novos pedidos online quando recebidos',
  delivery_information: 'informação de entrega',
  voucher_already_processed: 'Voucher já processado',
  no_applicable_items_in_cart: 'Nenhum item aplicável encontrado no carrinho',
  discount_applied: 'Desconto aplicado',
  invalid_voucher: 'Voucher inválido',
  allow_voucher_scan: 'Verifique se há vouchers usando o leitor de código de barras',
  enable_drop_shipping: 'Ativar envio direto',
  ask_no_covers_or_takeaway: 'Sempre pergunte a configuração da mesa',
  table_configuration: 'Tipo de mesa',
  num_covers: 'Número de convidados',
  takeaway: 'Leve embora',
  guests: 'Convidados',
  show_floorplan_after_sale: 'Mostrar planta baixa após cada venda',
  lock_after_x_minutes: 'Bloquear após x minutos de inatividade',
  show_deposit_items: 'Mostrar itens de depósito',
  pickup: 'Pegar',
  delivery: 'Entrega',
  transaction_fee: 'Taxa de transação',
  print_description: 'Imprimir descrição do item no recibo',
  only_cash_for_negative_amounts: 'Somente dinheiro ou pagamento manual com cartão podem ser selecionados quando o valor for menor que 0',
  phone_number_example: 'Telefone - por exemplo 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp não instalado neste dispositivo',
  receipt_not_yet_synchronised: 'Sincronização da transação com o servidor - tente novamente em alguns instantes',
  notify_online_orders_with_sound: 'Tocar som quando pedidos online recebidos',
  restrict_online_orders_by_floor: 'Aceite apenas pedidos online para determinados andares',
  remove_product: 'Remover Produto',
  admin_access_needed: 'Esta função está disponível apenas para usuários Admin',
  customer_or_employee_name: 'Nome do cliente ou funcionário',
  show_cart_on_left: 'Mostrar carrinho no lado esquerdo',
  groupOrderPrintByCategory: 'Impressão de grupos de pedidos por categoria',
  handpoint_shared_secret: 'SharedSecret',
  fiscal_offline: 'Fiscal offline',
  unsynced_transaction_cant_reissue: 'Transação não sincronizada; não pode ser reemitida',
  fiscal_info: 'Informação fiscal',
  create_bundle: 'Criar pacote',
  select_items_to_bundle: 'Selecione os itens para empacotar',
  bundle_price: 'Preço do pacote',
  create_bundle_items: 'Crie um pacote de produtos no carrinho',
  bundle: 'Pacote',
  giftcard_or_reference_no_mandatory: 'Gift Card ou Número de Referência obrigatório',
  show_camera: 'Mostrar câmera',
  switch_camera: 'Trocar Câmera',
  camera: 'Câmera',
  print_qr_on_receipts: 'Imprimir o QR code nos recibos',
  no_floorplans_available: 'Você não tem plantas disponíveis',
  receive_online_orders_for_multiple_stores: 'Receber ordens de várias lojas',
  inventory: 'Inventário',
  description: 'Descrição',
  brand: 'Marca',
  allergens: 'Alergias',
  hide_total_num_items_on_order: 'Esconder o total de items no recibo de ordem.',
  loss: 'Perda',
  stolen: 'Furtado',
  own_use: 'Uso Próprio',
  giveaway: 'Oferecido',
  breakage: 'Danificado',
  student_check: 'verificação de carteira de estudante',
  overview: 'Visão global',
  average: 'Média',
  busiest_hours: 'Horário mais movimentado',
  rotation_time_in_secs: 'Tempo de rotação da imagem em segundos',
  show: 'Mostrar',
  uploaded: 'carregado',
  videos: 'Video',
  photos: 'Fotos',
  display_video_or_picture: 'Exibir vídeo, fotos ou arquivos PDF',
  store_logo: 'Logotipo da loja',
  add_selected_addon: 'Adicionar / remover complementos',
  add_selected_to_stores: 'Adicionar / Remover às lojas',
  add_selected_categories: 'Adicionar / Remover categorias selecionadas',
  select_categories: 'Selecione as categorias',
  add_selected_addon_group: 'Grupo Adicionar / Remover Complementos',
  company: 'Empresa',
  assortment: 'Sortimento',
  sales_history: 'História de vendas',
  employees: 'Funcionários',
  extensions: 'Extensões',
  desktop: 'Desktop',
  helpdesk: 'Central de Ajuda',
  specific: 'Específico',
  no_data_for_this_period: 'Nenhum dado encontrado para este período.',
  gross_revenue: 'Receita bruta',
  avg_per_transaction: 'Média por transação',
  from: 'de',
  select_period: 'Selecione o período',
  date_start: 'Data de início',
  date_end: 'Data final',
  select_store: 'Selecione loja',
  select_stores: 'Selecione lojas',
  all_stores: 'Todas as lojas',
  all_employees: 'Todos os empregados',
  all_devices: 'Todos os dispositivos',
  no_transactions_in_this_period: 'Nenhuma transação neste período.',
  transactions_in_this_period: 'Transações nesse período.',
  report_graph: 'Relatório Gráfico',
  no_reports_found_for_this_period: 'Nenhum relatório encontrado para este período',
  top_sales: 'Top Vendas',
  top_sellers: 'Mais vendidos',
  bottom_sellers: 'Itens menos vendidos',
  no_top_sales_for_this_period: 'Não há grandes vendas para este período',
  best_selling_products_for_this_period: 'Produtos mais vendidos para o período',
  save_email: 'Salvar e-mail',
  new_password: 'Nova senha',
  verify_password: 'Confirmar senha',
  save_password: 'Salvar senha',
  contact: 'Contato',
  name_first: 'Nome',
  name_last: 'Sobrenome',
  zip_code: 'CEP ou caixa postal',
  region: 'Região',
  billing: 'Faturamento',
  optional: 'Opcional',
  business_name: 'Nome da empresa',
  vat: 'Taxa',
  btw: 'BTW',
  save_profile_info: 'Salvar informações do perfil',
  integrations: 'Integrações',
  connect_to_your_existing: 'Conecte-se ao seu existente',
  invoices: 'Faturas',
  code: 'Código',
  isPaid: 'é Pago',
  invoice_paid: 'Fatura paga',
  actions: 'Ações',
  stores: 'Lojas',
  updated: 'Atualizado',
  updated_at: 'Updated em',
  create: 'Crio',
  info: 'info',
  currencies: 'moedas correntes',
  save_edit_details: 'Salvar e editar detalhes',
  assigned_employees_of_this_stores: 'Funcionários designados para esta loja:',
  not_assigned_employees: 'Não funcionários atribuídos:',
  add_payment: 'Adicionar pagamento',
  payment_name: 'Nome do pagamento',
  printer_name: 'Nome da impressora',
  edit_storess_main_details: 'Editar detalhes da loja principal.',
  edition: 'edição',
  stores_address_details: 'Detalhes do endereço da loja.',
  stores_contact_details: 'Endereço de contato da loja.',
  assign_employees_for_your_stores: 'Atribuir funcionários à sua loja.',
  create_custom_payment_methods_for_your_store: 'Crie métodos de pagamento personalizados para sua loja.',
  devices: 'Dispositivos',
  device: 'Dispositivo',
  hardware_info: 'Informação de Hardware',
  uuid: 'UUID',
  manufacturer: 'Fabricante',
  os_name: 'OS Nome',
  os_version: 'OS Versão',
  pos_version: 'POS Versão',
  receipt_layout: 'Layout de recibo',
  table_layout: 'Layout de tabela',
  default_receipt: 'Recibo padrão',
  email_receipt: 'Recibo de e-mail',
  change_image: 'Alterar imagem',
  Tuesday: 'Terça',
  june: 'Junho',
  your_store_address: 'O endereço da sua loja',
  edit: 'Editar',
  your_text_here: 'Seu texto aqui',
  cheap: 'Barato',
  expensive: 'Caro',
  client_receipt: 'Recibo do cliente',
  transaction_agreement: 'Contrato de transação',
  your_footer_message_here: 'Sua mensagem de rodapé aqui',
  image: 'Imagem',
  price: 'Preço',
  edit_products_main_details: 'Edite detalhes principais do produto.',
  details: 'Detalhes',
  visible: 'Visível',
  in_pos: 'em POS',
  cost: 'custos',
  cost_price: 'preço de custo',
  delivery_price: 'Preço de entrega',
  upload_progress: 'Upload de progresso',
  change_products_image: 'Alterar a imagem do produto',
  variants: 'Variantes',
  variant: 'Variante',
  units: 'Unidades',
  alert: 'Alerta',
  additional: 'Adicional',
  ledger: 'Livro-razão',
  subledger: 'Extra Livro-razão',
  addon: 'Adicionais',
  addon_groups: 'Grupos de Adicionais',
  addon_group: 'Grupo de Adicionais',
  add_delete_and_edit_products_variants: 'Adicione, exclua e edite variantes de produtos.',
  assing_product_to_store: 'Atribuir produto a uma loja específica (se nenhuma loja for selecionada, o produto aparecerá em todas as lojas).',
  products_addtional_fields: 'Campos adicionais do produto.',
  manage_addons_group: 'Grupo de Addons',
  sold_by_weight: 'Vendido por peso',
  dynamic_price: 'Preço Dinâmico',
  dynamic: 'Preço Dinâmico',
  min_age: 'Idade minima',
  packaging: 'Embalagens',
  contents: 'conteúdo',
  form: 'formulário',
  products_packaging_and_suppliers_details: 'Detalhes de embalagem e fornecedores do produto.',
  stock: 'Estoque',
  management: 'Gestão',
  count: 'contagem',
  filter_name: 'Digite para filtrar a coluna de nome...',
  find_products_category: 'Encontre produtos por categoria',
  find_products_addons: 'Encontrar produtos por addons',
  in_stock: 'Em estoque',
  order_below: 'Ordem abaixo',
  order_quantity: 'Quantidade da ordem',
  advance: 'Avançar',
  view_history: 'Ver histórico',
  click_here_to_select_source: 'Clique aqui para selecionar a fonte',
  record_missing_item: 'Registrar item ausente',
  lost: 'Perdido',
  current_stock: 'Estoque atual',
  current_report: 'Relatório atual',
  view_stock_history: 'Ver histórico de estoque',
  color: 'Cor',
  duplicate_item: 'Item duplicado',
  want_duplicate_item: 'Você quer duplicar este item?',
  delete_item: 'Excluir item',
  want_delete_item: 'Você quer apagar este item?',
  categories_visibility_changed: 'Visibilidade de categorias alterada',
  change_visibility: 'Mude a visibilidade',
  category: 'Categoria',
  parent: 'Parent',
  select: 'Selecione',
  rate: 'Taxa',
  delete_selected_items: 'Excluir itens selecionados',
  aplly_diff_rates: 'Você quer aplicar uma alíquota diferente para diferentes países?',
  countries_added: 'Países adicionados',
  groups: 'Grupos',
  group: 'Grupo',
  standard: 'Default',
  default_text: 'Texto padrão',
  business_rules: 'Regras do negócio',
  business_rule: 'Regra do negócio',
  title: 'Título',
  is_active: 'Ativo',
  startdate: 'Data de início',
  enddate: 'Data final',
  rules: 'Regras',
  rule: 'Regra',
  edit_business_rules_main_details: 'Edite os principais detalhes da regra de negócios.',
  active: 'Ativo',
  time_start: 'Hora de início',
  time_end: 'Fim do tempo',
  select_specific_days: 'Selecione dia(s) específico',
  conditions: 'Condições',
  condition: 'Condição',
  change_business_rules_conditions: 'Altere as condições da regra de negócios.',
  change_business_rules_outcome: 'Alterar o resultado da regra de negócios.',
  minimum_amount: 'Quantidade mínima',
  minimum: 'Mínimo',
  maximum: 'Máximo',
  maximum_amount: 'Quantia máxima',
  ignore_for: 'Ignore por',
  outcome: 'Resultado',
  voucher_outcome: 'voucher resultado',
  new_price_rule: 'Novo preço para regra de negócios',
  min_pack_value: 'Valor mínimo de embalagem',
  max_pack_value: 'Valor máximo de embalagem',
  enable: 'Ativar regras',
  disable: 'Desativar regras',
  all: 'todos',
  update_time: 'Atualizar hora de início/término',
  update_date: 'Atualizar data de início/término',
  catalog_order: 'Encomenda de catálogo',
  drag_and_drop_items_to_reorder: 'Arraste e solte itens para reordenar',
  see_reports: 'Você pode ver seus relatórios no painel selecionando',
  generate_report: 'Gerar relatório',
  or: 'OU',
  send_report: 'Você pode enviar dados brutos para o seu e-mail',
  send_report_button: 'Enviar relatório',
  send_to: 'Enviar para',
  recipient: 'recebedor',
  available_reports: 'Relatórios disponíveis para este período',
  select_currency_text: 'Por favor, selecione uma moeda ou uma loja específica.',
  tables: 'Tabelas',
  revenue: 'Receita',
  see_revenue: 'Veja sua receita bruta, custo, impostos e lucro',
  net_revenue: 'Receita liquida',
  profit: 'Lucro',
  see_transaction: 'Veja o seu método de transação, o número e o montante total',
  see_payments: 'Veja o seu método de pagamento, o número e o montante total',
  see_refunds: 'Veja o seu método de reembolso, o número e o montante total',
  see_taxes: 'Veja seus impostos, o número e o total',
  brands: 'Marcas',
  channels: 'Canais',
  channel: 'Canal',
  see_brands: 'Veja seus produtos por marca, o número e o valor total',
  see_channels: 'Veja seus produtos por canal, o número e o valor total',
  see_categories: 'Veja suas categorias, o número e o total',
  click_details: 'Clique aqui para ver detalhes',
  report_select_other_period: 'Selecione outro período para ver o relatório disponível',
  created: 'Criado',
  discounts: 'Descontos',
  add_discount: 'Adicionar desconto',
  discount_percent: 'Por cento de desconto',
  register_opertations: 'Registre Operações',
  operations: 'Operações',
  first: 'Primeiro',
  last: 'Último',
  phone: 'Telefone',
  permission_to_contact: 'Permissão para entrar em contato',
  identifiers_type: 'Tipo de identificador do cliente',
  identifiers_value: 'Valor dos identificadores',
  shipping: 'Remessa',
  diff_billing: 'se diferente de faturamento',
  rights: 'Direitos',
  employee: 'Empregado',
  token: 'Token',
  uninstall_ext: 'Desinstalar extensão',
  active_ext: 'Esta extensão está ativa',
  subscribe: 'Se inscrever',
  remove_ext_msg: 'Tem certeza de que deseja remover esta extensão?',
  no_thanks: 'Não, obrigado',
  subs_to_ext: 'Inscrever-se para extensões',
  period: 'Periodo',
  payment_details: 'Detalhes do pagamento',
  card_name: 'Nome no cartão:',
  paymnet_method: 'Método de pagamento:',
  code_country: 'Código do país:',
  diff_way_pay_msg: 'Ou escolha uma maneira diferente de pagar',
  phone_support: 'Suporte por telefone',
  support_msg: 'Entre em contato com o suporte ao cliente para obter assistência:',
  email_support: 'Suporte por e-mail',
  support_email: 'Suporte por e-mail:',
  info_email: 'Info e-mail:',
  sales_email: 'E-mail de vendas:',
  page: 'página',
  dark_theme: 'Tema escuro',
  report_spec_time: 'Gerar relatório com um horário específico',
  vat_btw_no: 'Taxa',
  store_unable_delete: 'Não é possível excluir a loja porque um dispositivo está vinculado. Por favor, remova o dispositivo antes de excluir a loja.',
  important: 'Importante:',
  store_device_delete_msg: 'Por favor, certifique-se de que você está desconectado deste dispositivo antes de apagá-lo.',
  email_test_receipt: 'Envie um recibo de teste por e-mail',
  merchant: 'Comerciante',
  per_item: 'por item',
  img_width_msg: 'Maximun permitido largura do arquivo é de 250px.',
  err_img_msg: 'Erro: a largura da imagem não deve exceder 250px',
  rules_actions: 'Ações de regras',
  enable_all: 'Ativar todas as regras de negócios',
  want_enable_all: 'Você deseja ativar todas as regras de negócios?',
  disable_all: 'Desativar todas as regras de negócios',
  want_disable_all: 'Deseja desabilitar todas as regras de negócios?',
  product_cat_error: 'Por favor escolha uma categoria',
  field_not_empty: 'O campo não pode estar vazio.',
  drop_files: 'Largue o seu ficheiro aqui para carregar a imagem.',
  save_variant_before_stock: 'Salve a nova variante antes de editar o estoque.',
  no_employees: 'Você não tem funcionários. Você pode criar novos funcionários',
  here: 'aqui',
  no_stock: 'Não há produtos de estoque nesta loja',
  product_exists: 'Este produto existe apenas em',
  add_units_stock: 'Adicionando unidades ao estoque',
  remove_units_stock: 'Removendo unidades do estoque',
  saving: 'Está salvando',
  transfer_units: 'Adicionar quantidade de unidades para transferir',
  remove_units_store: 'Adicione uma quantidade de unidades para remover desta loja',
  notification: 'notificação',
  set_new_start_end_time: 'Definir novo horário de início/término',
  set_new_start_end_date: 'Definir nova data de início/término',
  by_email: 'por email',
  charts_graphs: 'Charts/Graphs',
  compare_reports: 'Comparar relatórios',
  new_report: 'Novo relatório',
  basic: 'Básico',
  no_acount_to_send_report: 'Você não pode enviar este relatório porque sua conta é',
  upgrade_account_msg: 'Você precisa atualizar sua conta!',
  gen_report_msg: 'Você pode gerar seu relatório e ver os resultados no painel',
  disabled_report: 'Relatórios desativados enquanto o pagamento pendente',
  please_check: 'Por favor, verifique o seu',
  email_wrong_format: 'O formato de e-mail não está correto.',
  bar_chart: 'Bar Chart',
  method: 'Método',
  total_taxed_amount: 'Quantidade total tributada',
  total_price_product: 'Preço total dos produtos',
  enter_value: 'Você deve inserir um valor',
  not_valid_mail: 'Não é um email válido',
  enter_email: 'Insira seu email',
  unit_price: 'Preço unitário',
  extras: 'Extras',
  monthly_billed: 'faturado mensalmente',
  removing_ext: 'Esta extensão será removida no próximo ciclo de faturamento',
  local_ip: 'Local IP',
  printer_ip_ad: 'Endereço IP da impressora',
  printer_categories: 'Categorias de impressoras',
  printers: 'Impressoras',
  payment_processor: 'Processador de pagamento',
  question: 'Você tem uma pergunta ?',
  check_here: 'Confira aqui nossa',
  knowledge_center: 'Centro de conhecimento.',
  support_articles_msg: 'Temos vários artigos de suporte que podem ajudá-lo a começar, criar produtos, conectar sua loja virtual e muito mais...',
  support_ques: 'Se você ainda não encontrou a resposta desejada, você pode obter',
  in_touch: 'em contato conosco',
  select_floorplan: 'Selecione Planta',
  floors: 'Andares',
  add_floorplan: 'Adicionar Planta',
  delete_floorplan: 'Excluir Planta',
  floorplan: 'Planta',
  add_tables: 'Adicionar tabelas',
  sign_up_msg: 'Milhares de comerciantes se juntaram a Countr. Hoje é a sua vez. Inscreva-se gratuitamente.',
  user_required: 'Nome de usuário é requerido.',
  name_required: 'Nome é obrigatório.',
  company_name_required: 'Nome da empresa é obrigatório.',
  phone_required: 'Número de telefone é necessário.',
  pass_required: 'Senha requerida.',
  already_account: 'Já tem uma conta?',
  accept_sign_up: 'Ao inscrever-se eu aceito o',
  terms_conditions: 'Termo de Condições',
  policy: 'Politica de privacidade',
  licence_agree: 'Contrato de licença',
  your_name: 'Seu nome',
  company_web: 'Website da empresa (não obrigatório)',
  forgot_pass: 'Esqueceu sua senha?',
  login: 'Faça seu login',
  no_account_link: 'Não tem uma conta?',
  email_required: 'E-mail é obrigatório.',
  send_link: 'Enviar link',
  download_file: 'Fazer download do arquivo',
  account_number: 'Número da conta',
  me_rules: 'Regras',
  by_volume: 'Produtos mais vendidos (por volume) período',
  number_sold: 'Número vendido',
  by_volume_bottom: 'Produtos de venda de fundo (por volume) período',
  create_compare_report: 'Crie um novo relatório para comparar',
  last_per: 'Último',
  no_per_data: 'não tem relatórios disponíveis',
  create_new_rep: 'Criar novo relatório',
  reset_pass_text: 'Digite seu endereço de e-mail para redefinir sua senha',
  enter_bus_info: 'Por favor insira as informações da sua empresa',
  organization: 'Nome da organização',
  mandatory_fields: 'Todos os campos são obrigatórios !!!',
  invalid_eu: 'Número de IVA inválido para o país da UE',
  trial_expires: 'Seu teste expira em',
  select_package: 'Selecione o pacote',
  excluse_vat: 'exclusivo de IVA',
  instead: 'ao invés de',
  save_payment: 'Guarde este método de pagamento para transações futuras',
  procced_paymet: 'Prossiga para o pagamento',
  selected_package: 'Pacote Selecionado',
  selected_ext: 'Extensões Selecionadas',
  old_dashboard: 'Versão antiga do Dashboard',
  subscription_msg: 'Parabéns, você está prestes a ir morar com sua loja! Suas transações serão salvas para sempre e todas as marcas de teste desaparecerão. Antes de você ir ao vivo, escolha as extensões que deseja continuar usando.',
  transfer_from: 'Transferir unidades de',
  customer_purchase: 'Compra de cliente',
  total_spend: 'Gasto total',
  last_customers_transactions: 'Transações do último cliente',
  email_recipient: 'Email recebedor',
  show_revenue: 'Mostrar receita',
  show_sales: 'Mostrar vendas',
  visits: 'Total de visitas',
  select_action: 'Selecionar ação',
  show_receipt: 'Mostrar recibo',
  set_values_for: 'Defina valores para',
  update: 'Atualizar',
  select_addons_group: 'Selecionar grupo de complementos',
  select_addon: 'Selecionar complemento',
  all_addon_groups_are_in_use: 'A lista está vazia, todos os grupos de complementos estão em uso',
  store_hours: 'Horário da loja',
  opening_hours: 'Horário de funcionamento',
  no_opening_hours: 'Sem horário de funcionamento',
  set_opening_hours: 'Definir horário de funcionamento',
  delivery_hours: 'Horário de entrega',
  no_delivery_hours: 'Sem horário de entrega',
  set_delivery_hours: 'Definir horário de entrega',
  ordering_hours: 'Horário de encomenda',
  no_ordering_hours: 'Sem hora de encomenda',
  set_ordering_hours: 'Definir o horário de pedidos',
  takeaway_hours: 'Horário para viagem',
  no_takeaway_hours: 'Sem hora para levar',
  set_takeaway_hours: 'Definir horas para viagem',
  delivery_zones: 'Zonas de entrega',
  no_delivery_zones: 'Sem zonas de entrega',
  set_delivery_zones: 'Definir zonas de entrega',
  Monday: 'Segunda',
  Thursday: 'Quinta',
  Friday: 'Sexta-feira',
  Saturday: 'Sábado',
  Sunday: 'Domingo',
  set_opening_store: 'Definir horário de funcionamento da loja',
  set_delivery_store: 'Defina o horário de entrega da loja',
  set_ordering_store: 'Definir horário de pedidos para a loja',
  set_takeaway_store: 'Defina o horário de entrega da loja',
  set_zones_store: 'Definir zonas de entrega para a loja',
  no_data_added: 'Nenhum dado adicionado',
  add_hours: 'Adicionar horas',
  add_zones: 'Adicionar zonas de entrega',
  order_sub: 'Subcategorias de pedidos',
  new_item: 'nova',
  tips: 'dica',
  see_busiest_hours: 'Veja as horas de maior movimento com base no período que você selecionou',
  see_employee_transactions: 'Veja as transações por funcionário para o período selecionado',
  see_employee_tips: 'Veja a dica de seus funcionários para o período selecionado',
  see_employee_refunds: 'Veja os reembolsos por funcionário para o período selecionado',
  see_customer_transactions: 'Veja as transações por tipo de cliente para o período selecionado',
  see_customer_refunds: 'Veja os reembolsos por tipo de cliente para o período selecionado',
  new_customer: 'Novo cliente',
  returning_customer: 'Cliente recorrente',
  no_customer: 'Sem dados do cliente',
  reps_waste: 'Duplicados e desperdício',
  see_reps_waste: 'Ver razão de duplicados e desperdiçados',
  see_employee_reps_waste: 'Ver duplicados e desperdiçados por funcionário',
  employee_report_graph: 'Veja as vendas dos funcionários por tempo',
  not_sold: 'Não comercializado',
  non_selling_products_this_period: 'Produtos sem venda neste período (produtos com 0 vendas)',
  compare_stores: 'Compare lojas',
  average_amount_spent: 'Valor médio gasto',
  average_amount_refunded: 'Valor médio reembolsado',
  bundle_parts: 'Pacote de peças',
  create_giftcard: 'Criar vale-presente',
  giftcards: 'Cartões de presente',
  select_graphs_type: 'Selecione o tipo de gráfico',
  see_discount_percentage: 'Veja as transações com desconto percentual',
  see_discount_by_product_percentage: 'Veja o desconto dado aos produtos (descontos numéricos são calculados como porcentagem)',
  discount_percentage: 'Desconto (porcentagem)',
  discount_numeric: 'Desconto (numérico)',
  see_discount_numeric: 'Veja as transações com desconto numérico',
  number_of_discounts: 'e o número de vezes que esse desconto ocorreu no nível do produto ou carrinho',
  delivery_cost: 'Custo de entrega',
  total_cash_after_tips_and_change: 'Dinheiro total após gorjetas e troco',
  assigned_stores: 'Lojas atribuídas',
  chat_iframe: 'Chat',
  mobile_phone: 'Celular',
  notifications: 'Notificações',
  annualy_billed: 'faturado anualmente',
  download_stock: 'Baixe produtos com estoque',
  download_all_stock: 'Baixe todos os produtos',
  no_stock_products: 'Definir produtos sem estoque',
  go_products_with_stock: 'Ir para produtos com estoque',
  save_all_changes: 'Salve todas as alterações',
  need_track_stock: 'Você precisa acompanhar o estoque para editar.',
  track_stock: 'Rastrear estoque',
  set_min_man: 'Configurar mínimo e máximo para grupo de adicionais',
  duplicate: 'Duplicado',
  clear_unsaved: 'Remover todas as alergias',
  select_allergens: 'Selecionar alergias',
  translate_categories: 'Traduzir categorias',
  translate_groups: 'Traduzir grupos',
  add_cat_translation: 'Adicionar tradução da categoria',
  add_group_translation: 'Adicionar tradução do grupo',
  assigned_groups: 'Grupos atribuídos',
  extension: 'Extensão',
  vouchers: 'Voucher',
  show_open_carts_from_store: 'Mostrar ordens disponíveis para loja',
  ean: 'Código de barra',
  sku: 'Identificação adicional',
  want_delete_all_items: 'Deletar todos os items selecionados?',
  want_to_change_visibility: 'Trocar a visibilidade de todos os items selecionados?',
  import_export: 'Importar/Exportar',
  methods: 'Método de pagamento',
  stored_payment_info: 'Ver informação de métodos de pagamento',
  stored_payment: 'Métodos de pagamento da loja',
  report_spec_zone: 'Selecionar outro horário mundial',
  no_stock_stores: 'Você não tem uma loja criada. Crie uma loja para adicionar productos ao inventário',
  create_custom_payment: 'Criar método de pagamento personalizado',
  min_chars: 'Mínimo de letras',
  enable_deposit: 'Ativar depósitos',
  enable_daily_report: 'Ativar relatórios diários',
  reorder_email: 'Reenviar email',
  enable_non_revenue: 'Ativar sem lucro',
  total_visits: 'Total de visitas',
  how_it_works: 'Como funciona',
  info_text_catalog_order_one: 'Informação do primeiro catálogo',
  info_text_catalog_order_two: 'Informação do segundo catálogo',
  verify_dialog: 'Verificar caixa de diálogo',
  verify_proceed_dialog: 'Verificar caixa de diálogo de procedimento',
  export_customer: 'Exportar cliente',
  disabled_floor: 'Desabilitar área',
  filter_by_category: 'Filtrar por categoria',
  current_timezone: 'Atual fusohorário',
  important_note: 'Nota importante',
  transaction_note: 'Nota de transação',
  translate_name: 'Traduzir nome',
  translate_tool: 'Traduzir ferramenta',
  payment_method_note: 'Nota de método de pagamento',
  add_new_payment: 'Adicionar novo pagamento',
  no_payment_method: 'Sem método de pagamento',
  daily_report: 'Reporte diário',
  it_seems_store: 'Parece loja',
  no_stock_items: 'Sem itens de estoque',
  export_file_finished: 'Exportação de arquivo finalizada',
  import_file_finished: 'Importação de arquivo finalizada',
  stock_import_file_finished: 'Importação de arquivo de estoque finalizada',
  error_stock_finished: 'Erro de estoque finalizado',
  import_file_init: 'Importação de arquivo iniciada',
  export_file_init: 'Exportação de arquivo iniciada',
  create_voucher: 'Criar voucher',
  total_uses: 'Uso total',
  scope: 'Alcance',
  numeric_discount: 'Disconto numérico',
  start_date: 'Data de início',
  expiry_date: 'Data de expiração',
  products_categories: 'Categorias de produtos',
  search_products: 'Procurar produtos',
  selected_products: 'Produtos selecionados',
  search_categories: 'Procurar categorias',
  selected_categories: 'categorias selecionadas',
  selected_items: 'Itens selecionados',
  local_prices: 'Preços locais',
  translate: 'Tradução',
  value: 'Valor',
  code_type: 'Tipo de código',
  non_revenue: 'Nenhuma receita',
  giftcard_product_alert: 'Alerta de produto vale-presente',
  multi_countries_tax_rules: 'Regras de taxação de vários países',
  countries_rates: 'Taxas de países',
  edit_store_themes: 'Editar temas da loja',
  themes: 'Temas',
  font: 'Fonte',
  current_theme: 'Tema atual',
  add_theme: 'Adicionar tema',
  delete_theme: 'Deletar tema',
  body_background: 'Corpo de fundo',
  body_text: 'Corpo de texto',
  primary_text: 'Texto primário',
  secondary_text: 'Texto secundário',
  header: 'Cabeçalho',
  header_text: 'Texto de cabeçalho',
  toolbar: 'Barra de ferramentas',
  toolbar_text: 'Barra de ferramentas de texto',
  button_primary_text: 'Texto primário do botão',
  button_primary_background: 'Fundo primário do botão',
  button_secondary_text: 'Texto secundário do botão',
  button_secondary_background: 'Fundo secundário do botão',
  button_neutro_text: 'Texto neutro do botão',
  button_neutro_background: 'Fundo neutro do botão',
  isActive: 'está Ativo',
  Wednesday: 'Quarta',
  answered: 'Respondido',
  common: 'Comum',
  username: 'Nome de usuário',
  show_price: 'Mostrar preço',
  companyid: 'ID da campanhia - Identificação da companhia',
  invalid_merchantid: 'Identificação do comerciante inválida',
  invalid_businessid: 'Identificação do negócio inválida',
  all_categories: 'Todas as categorias',
  close_search_product: 'Fechar procura de produtos',
  firstid: 'Primeira identificação',
  lastid: 'Última identificação',
  storeid: 'Identificação da loja',
  enter_a_printerid: 'Insira identificação da impressora',
  enter_orderid: 'Insira identificação da ordem',
  enter_tableid: 'Insira identificação da mesa',
  customerid: 'Identificação do cliente',
  insert_printerid: 'Insira identifição da impressora',
  not_paid: 'Não pago',
  menu_itemid: 'Identificação de item do menu',
  employeeid: 'Identificação do funcionário',
  deviceid: 'Identificação do aparelho',
  addid_device: 'Adicionar identificação do aparelho',
  deviceid_mandatory: 'Obrigatório identificação do aparelho',
  no_floorplans: 'Sem planta baixa',
  table_layout_advice: 'Conselho de layout de mesa',
  merge_tables: 'Unir mesas',
  custom_item_not_valid: 'Personalização de item não válida',
  no_connection_sync: 'Sem sincronização de conexão',
  new_device_name: 'Novo nome de aparelho',
  change_device_name: 'Alterar nome de aparelho',
  change_store: 'Alterar loja',
  alert_change_store: 'Alerta de alteração de loja',
  customer_total_spend: 'Gasto total do consumidor',
  skip_payment_success_info: 'Pular informações de sucesso de pagamento',
  cart_always_open: 'Carrinho sempre aberto',
  show_calculator: 'Mostrar calculadora',
  selected: 'Selecionado',
  categories_tiles_position: 'Posição dos blocos de categorias',
  kiosk_mode: 'Modo quiosque',
  kiosk_mode_disabled: 'Modo quiosque desabilitado',
  change_table: 'Alterar mesa',
  change_cart: 'Alterar carrinho',
  change_cart_tip: 'Alterar dica de carrinho',
  a4_paper: 'Papel A4',
  auto_print: 'Impressão automática',
  select_report: 'Selecionar relatório',
  report_not_found: 'Relatório não encontrado',
  new_cart: 'Novo carrinho',
  new_table: 'Nova mesa',
  delete_cart: 'Apagar carrinho',
  delete_cart_confirmation: 'Apagar confirmação de carrinho',
  product_tile_size: 'Tamanho da peça do produto',
  load_more_transactions: 'Carregar mais transações',
  select_variant: 'Selecionar variante',
  remember_password: 'Lembrar senha',
  go_dashboard: 'Ir para painel de controle',
  enable_cart_item_remove: 'Habilitar remoção de item do carrinho',
  remove_item: 'Remover item',
  show_current_cart: 'Mostrar carrinho atual',
  show_carts: 'Mostrar carrinhos',
  show_tables: 'Mostrar mesas',
  open_receipt: 'Abrir recibo',
  kds: 'Sistema de cozinha',
  archive: 'Arquivo',
  send_archive: 'Mandar arquivo',
  awaiting_payment: 'Aguardando pagamento',
  all_items: 'Todos os itens',
  printed: 'Impresso',
  pending: 'Pendente',
  preparing: 'Preparando',
  ready: 'Pronto',
  completed: 'Completo',
  send_new: 'Mandar novo',
  send_progress: 'Mandar progresso',
  send_ready: 'Mandar pronto',
  send_completed: 'Mandar completo',
  active_orders: 'Ativar ordens',
  all_orders: 'Todas as ordens',
  select_employee: 'Selecionar funcionário',
  select_status: 'Selecionar estado/posição',
  send_all_to_status: 'Mandar tudo para status',
  no_open_orders: 'Sem ordens abertas',
  click_refresh: 'Clicar em atualizar',
  deliveredBy: 'Entregue por',
  by: 'Por',
  deliveryTime: 'Tempo de entrega',
  pickupTime: 'Tempo de coleta',
  customerPhone: 'Telefone do cliente',
  customerName: 'Nome do cliente',
  orderNote: 'Note de ordem/pedido',
  serviceCharge: 'Taxa de serviço',
  orderType: 'Tipo de ordem/pedido',
  deliveryCost: 'Custa de entrega',
  discountTotal: 'Disconto total',
  channelOrderDisplayId: 'Identificação de exibição do canal de pedido',
  orderIsAlreadyPaid: 'Pedido já está pago',
  paymentMethod: 'Método de pagamento',
  deliveryFee: 'Taxa de entrega',
  deliveryAddress: 'Endereço de entrega',
  deliveryPostcode: 'CEP de entrega',
  deliveryCity: 'Cidade de entrega',
  extraAddressInfo: 'Informação extra de endereço',
  postalCode: 'CEP',
  streetNumber: 'Número da rua',
  postponed: 'Adiado',
  warning_delay: 'Aviso de atraso',
  load_orders_past: 'Carregar ordens passadas',
  hotkeys: 'Teclas de atalhos',
  show_categories_separator: 'Mostrar separador de categorias',
  auto_refresh: 'Auto atualização',
  auto_complete_order: 'Pedido de conclusão automática',
  hide_completed_items: 'Esconder itens completos',
  remove_grid_gaps: 'Remover lacunas de grades',
  off: 'Desligado',
  languages: 'Linguagens',
  hotkeys_obj: 'Teclas de atalhos para objetos',
  last_update: 'Última atualização',
  customer_information: {"first_name":"First name","last_name":"Last name","phone":"Phone"},
  in_progress: 'Em progresso',
  deliveryBy: 'Entregue por',
  init_all_services: 'Iniciar todos os serviços',
  email: 'E-mail',
  signin: 'Inscrição',
  signin_error: 'Erro de inscrição',
  device_name: 'Nome do aparelho',
  close_details: 'Fechar detalhes',
  no_orders_available: 'Sem ordens disponíveis',
  order_has_been_completed: 'Ordem completa',
  no_products: 'Sem produtos',
  print_device: 'Dispositivo de impressão',
  send_order: 'Enviar pedido',
  add_products: 'Adicionar produtos',
  no_categories: 'Sem categorias',
  empty_table: 'Mesa vazia',
  order_not_sent: 'Ordem não enviada',
  product_list_view: 'Visualizar lista de produtos',
  no_description: 'Este produto não tem descrição. Você pode adicionar um pressionando o botão "Alterar" ou visitar seu painel.',
  change_description: 'Por favor, insira a descrição do produto.',
  main_cart_is_empty: 'Carrinho principal está vazio',
  apply_to_all_carts: 'Aplicar para todos os carrinhos',
  per_value_payment: 'Pagamento por valor',
  select_a_seat: 'Selecione um assento',
  sub_total: 'Sub total',
  desktop_settings: 'Configurações do Desktop',
  carts: 'Carrinhos',
  signing_in: 'Entrando',
  total_taxes: 'Taxas totais',
  provide_refund_item: 'Fornecer item de reembolso',
  manual_cheque_refund: 'Verificação manual de reembolso',
  downloading_employees: 'Importando funcionários',
  show_product_image_list: 'Mostrar imagem de lista de produtos',
  refresh_delta: 'Atualizar delta',
  admin_pin_incorrect: 'Senha de administrador incorreta ',
  customer_not_valid_vat: 'Imposto não válido para o cliente',
  allow_partial_payment: 'Permitir pagamento parcial',
  allow_qrcode_scanner: 'Permitir escanear QRCode',
  qrcode_not_found: 'QRCode não encontrado',
  empty_pin: 'Senha vazia',
  employee_select_error: 'Erro ao selecionar funcionário',
  employee_select_success: 'Sucesso ao selecionar funcionário',
  employee_lock: 'Funcionário bloqueado',
  no_employee_store: 'Sem funcionário de loja',
  cash_in_out_failed: 'Transação em dinheiro fracassada',
  cash_in_out_success: 'Transação em dinheiro bem sucedida',
  categories_alphabetic_order: 'Categorias em ordem alfabética',
  products_alphabetic_order: 'Produtos em ordem alfabética',
  weight_not_valid: 'Peso não válido',
  kiosk_mode_requirement: 'Modo quiosque solicitado',
  canceled: 'Cancelado',
  partial_payment_value: 'Valor de pagamento parcial',
  partial_payment_item: 'Item de pagamento parcial',
  continue_payment: 'Continuar pagamento',
  unit: 'Unidade',
  cheque: 'Cheque',
  need_store_register_device: 'Necessário reiniciar dispositivo da loja',
  search_transaction_hint: 'Procurar dica de transação',
  select_pay_method: 'Selecionar método de pagamento',
  delete_all_empty_cart: 'Apagar todo carrinho vazio',
  delete_all_empty_table: 'Apagar toda mesa vazia',
  delete_all_empty_cart_confirmation: 'Apagar toda confirmação de carrinho vazio',
  all_empty_carts_deleted: 'Todos os carrinhos vazios apagados',
  reps: 'Reposição',
  show_reps_waste: 'Mostrar botão de reposição e desperdício no carrinho',
  giveaway_reason: 'Razão da doação',
  countr_desktop_not_found: 'CountrDesktop não encontrado',
  clear_search: 'Limpar procura',
  recovery_transaction_complete: 'Completar recuperação de transação',
  activate_simple_tiles: 'Ativar peças simples',
  not_print_receipt_cash: 'Não imprimir recibo em dinheiro',
  allow_negative_payments: 'Permitir pagamentos negativos',
  negative_payments_warning: 'Alerta de pagamentos negativos',
  enable_method: 'Ativar método',
  error_addon_groups: 'Erro de grupo adicional',
  billed_to: 'Faturado para',
  payment_due: 'Pagamento devido',
  amount_due: 'Montante devido',
  total_price: 'Preço total',
  show_invoice: 'Mostrar fatura',
  hide_invoice: 'Esconder fatura',
  show_ean_sku_receipt: 'Mostrar EAN/SKU no recibo',
  show_simple_receipt: 'Mostrar recibo simples',
  not_tracked: 'Não rastreado',
  kitchen_print: 'Impressão na cozinha',
  kitchen_delivery_print: 'Entrega de impressão na cozinha',
  syncing_transactions: 'Sincronizando transações',
  syncing_refunds: 'Sincronizar reembolsos',
  barcode_customer: 'Código de barras do cliente',
  customer_add_cart: 'Adicionar carrinho do cliente',
  transaction_not_sync_yet: 'Transação não sincronizada ainda',
  intercom_error: 'Erro no aplicativo de suporte',
  pay_before_open_error: 'Erro ao sincronizar pagamento em fila',
  cash_in_not_open_day: 'Enrada de dinheiro em dia não aberto',
  cash_in_greater_start: 'Entrada maior de dinheiro no começo',
  open_close_shift: 'Abrir fechar turno',
  opening_shift: 'Abertura de turno',
  closing_shift: 'Encerramento de turno',
  not_valid_input: 'Entrada não válida',
  previous_start_amount: 'Montante inicial anterior',
  reason_mandatory_helper: 'Motivo de ajuda obrigatório',
  printer_settings: 'Configurações de impressora',
  printer_ip: 'IP da impressora',
  test_printers: 'Impressoras de teste',
  printers_settings_saved: 'Configurações de impressora salvas',
  error_shift_start_day: 'Erro de início do turno do dia',
  no_connection_payment: 'Sem conexão de pagamento',
  show_order_bill: 'Mostar ordem de fatura',
  split_orders_items: 'Separar itens por ordem',
  split_orders_amount: 'Separar quantidades por ordem',
  partial_payment_print: 'Imprimir pagamento parcial',
  minimum_age: 'Idade mínima',
  minimum_age_alert: 'Alerta de idade mínima',
  customer_required_title: 'Peça de cliente necessária',
  customer_required_text: 'Texto de cliente necessário',
  show_pretax_receipt: 'Mostrar recibo antes de taxação',
  show_full_receipt_after_payment: 'Mostrar recibo completo após pagamento',
  new_version_title: 'Nova versão de peça',
  new_version_text: 'Nova versão de texto',
  new_version_text_hint: 'Dica de nova versão de texto',
  reload: 'Recarregar',
  release_note: 'Lançar nota',
  play_sound_success_scan: 'Emitir som ao escanear com sucesso',
  manual_sync_transactions: 'Sincronizar transações manualmente',
  manual_sync_transactions_question: 'Questões sobre sincronização manual de transações',
  improvement: 'Melhoria',
  fixed: 'Consertado',
  recover_carts: 'Recuperar carrinhos',
  recover_carts_alert: 'Alerta para recuperar carrinho',
  recover_carts_complete: 'Recuperar carrinhos completo',
  select_all_carts: 'Selecionar todos os carrinhos',
  payment_started: 'Pagamento iniciado',
  outstanding_inovices: 'Faturas pendentes',
  quota_warning_title: 'Título de aviso de quota',
  quota_warning_text: 'Texto de aviso de quota',
  quota_warning_text_hint: 'Dica de texto de aviso de quota',
  welcome_to: 'Bem vinda a ',
  kassa_open: 'Abrir caixa',
  kassa_closed: 'Caixa fechado',
  kassa_closed_hint: 'Dica de caixa fechado',
  enable_customer_screen: 'Habilitar tela do cliente',
  cds_settings: 'Configurações de exibição da tela do cliente',
  cds_port: 'Porta de exibição da telo do cliente',
  web_pos: 'Ponto de vendas web',
  desktop_pos: 'Ponto de vendas local/tela',
  pos: 'Ponto de vendas',
  no_internet_connection: 'Sem conexão de internet',
  storage: 'Armazém',
  storage_data: 'Informação de armazenamento',
  extra_info_title: 'Título de informação extra',
  extra_info_sub_title: 'Sub título de informação extra',
  net: 'Rede',
  delivery_details: 'Detalhes da entrega',
  delivery_time: 'Momento da entrega',
  pickup_time: 'Momento da coleta',
  order_type: 'Tipo de entrega',
  order_courier: 'Ordem do mensageiro',
  delivery_order_warning: 'Alerta de ordem de entrega',
  delivery_toast: 'Entregar ordem recebida',
  delivery_requested: 'Pedido de entrega',
  tables_or_carts_desc: 'Descrição de mesas ou carrinhos',
  cart_always_open_desc: 'Descrição de carrinho sempre aberta',
  show_product_image_desc: 'Mostrar imagem do produto',
  allow_cart_discounts_desc: 'Permitir desconto no carrinho todo',
  allow_product_discounts_desc: 'Permitir desconto por produto',
  enable_cart_item_remove_desc: 'Habilitar remoção de item do carrinho',
  show_calculator_desc: 'Mostrar calculadora',
  show_reps_waste_desc: 'Mostrar botão de reposição e desperdício no cabeçalho do carrinho ',
  barcode_customer_desc: 'Permitir leitor de código de barras escanear a identidade do cliente e adicionar ao carrinho',
  show_stock_level_desc: 'Mostrar as unidades de estoque do produto',
  show_price_desc: 'Mostrar preço',
  barcode_scanner_desc: 'Escanear código de barras',
  play_sound_success_scan_desc: 'Tocar som ao escanear produto',
  show_product_image_list_desc: 'Mostrar imagem do produto em lista de produtos',
  activate_simple_tiles_desc: 'Ativar peças simples',
  product_tile_size_desc: 'Duplicar o tamanho do título dos produtos',
  hide_categories_desc: 'Esconder categorias',
  categories_alphabetic_order_desc: 'Mostrar categorias em ordem alfabética',
  products_alphabetic_order_desc: 'Mostrar produtos em ordem alfabética',
  skip_payment_success_info_desc: 'Não mostrar informação de sucesso de pagamento',
  allow_partial_payment_desc: 'Permitir pagamento parcial',
  allow_negative_payments_desc: 'Permitir pagamento negativo',
  allow_qrcode_scanner_desc: 'Permitir escanear QRCode',
  show_pretax_receipt_desc: 'Mostrar descrição de taxas no recibo',
  show_full_receipt_after_payment_desc: 'Mostrar recibo completo após pagamento',
  refund_same_method_desc: 'Reembolso pelo mesmo método',
  enable_customer_screen_desc: 'Habilitar tela do cliente',
  kiosk_mode_desc: 'Habilitar modo quiosque ',
  admin_pin_desc: 'Habilitar senha do administrador',
  employee_pin_desc: 'Habilitar senha de funcionário',
  open_close_shift_desc: 'Abrir/fechar turno',
  reports_at_store_level_desc: 'Mostrar relatório de toda loja',
  language_desc: 'Linguagem',
  kitchen_print_desc: 'Impressão ordem na cozinha cozinha após transição com sucesso',
  kitchen_delivery_print_desc: 'Imprimir ordem na cozinha após sucesso na transação através do sistema de delivery',
  show_order_bill_desc: 'Mostrar ordem e conta no cabeçalho do carrinho',
  split_orders_items_desc: 'Separar itens pedidos',
  split_orders_amount_desc: 'Separar quantidade de pedidos',
  partial_payment_print_desc: 'Imprimir pagamento parcial',
  show_ean_sku_receipt_desc: 'Mostrar EAN/SKU no recibo',
  a4_paper_desc: 'Habilitar impressão de papel A4',
  show_carts_list_alphabetic: 'Mostrar carrinhos em ordem alfabética',
  show_carts_list_alphabetic_desc: 'Mostrar carrinhos em ordem alfabética',
  show_cart_name_transaction: 'Mostrar nome do carrinho na transição',
  show_cart_name_transaction_desc: 'Mostrar nome do carrinho na transição',
  open_kds: 'KDS',
  open_chat: 'Abrir conversa',
  partial_payment_bigger_zero: 'Pagamento parcial maior que zero',
  allow_product_exchanges: 'Permitir mudança de produto',
  allow_product_exchanges_desc: 'Permitir mudança de produto',
  exchangeable_items: 'Proutos que podem ser trocados',
  to_be_exchangeable: 'A ser trocado',
  exchange_source: 'Motivo da troca',
  inventory_not_found: 'Inventário não encontrado',
  drop_ship: 'Cancelar entrega',
  enable_drop_ship: 'Habilitar cancelamento de entrega',
  enable_drop_ship_desc: 'Habilitar cancelamento de entrega',
  drop_ship_warning: 'Alerta de cancelamento de entrega',
  cart_as_tables_not_available: 'Não validar carrinho como mesa',
  enable_deposit_button: 'Habilitar botão de depósito',
  enable_deposit_button_desc: 'Habilitar botão de depósito',
  allow_voucher_scan_desc: 'Permitir escanear voucher',
  remove_customer: 'Remover cliente',
  remove_customer_question: 'Remover pergunta de cliente ',
  search_product_barcode_server: 'Procurar código de barras de produto no servidor',
  search_product_barcode_server_desc: 'Procurar código de barras de produto no servidor',
  show_floorplan_after_sale_desc: 'Mostrar atualização do salão após venda',
  ccv_settings: 'Ferramentas CCV ',
  ask_no_covers_or_takeaway_desc: 'Perguntar configuração da mesa',
  send_order_kds: 'Mandar ordem para tela da cozinha',
  mollie: 'Mollie',
  paynl: 'Paynl',
  transactionFee: 'Taxa de transação',
  pay_later_note_partial: 'Nota de pagamento parcial posterior',
  no_print_delivery: 'Sem impressão de entrega',
  load_linked_cart: 'Carregar informação do carrinho',
  show_item_status: 'Mostrar estado do carrinho',
  show_item_status_desc: 'Mostrar estado do produto',
  access_denied: 'Acesso negado',
  current_floor: 'Salão atual',
  total_to_pay: 'Total a pagar',
  total_amount_paid: 'Montante total pago',
  total_remaining: 'Total pendente',
  redeem: 'Resgatar',
  current_balance: 'Balanço atual',
  card_activity: 'Atividade no cartão',
  scan_fail: 'Falha ao escanear',
  disallow_offline: 'Ponto de vendas não pode operar offline',
  disallow_offline_fiscal: 'Ponto de vendas não pode operar offline por questões fiscais',
  fiscal_print_error: 'Não pode imprimir sem informação fiscal',
  enable_giftcard_scan: 'Habilitar escanear cartão presente',
  enable_giftcard_scan_desc: 'Habilitar escanear cartão presente e deduzir balanço',
  print_qr: 'Imprimir QRCode',
  print_qr_desc: 'Imprimir QRCode no final do recibo',
  theme: 'Tema',
  theme_desc: 'Alterar tema padrão para outro tema escolhido',
  products_not_found: 'Produtos não encontrados',
  show_suggestion_amounts: 'Mostrar botão de sugestão de quantidade',
  show_suggestion_amounts_desc: 'Mostrar botão de sugestão de quantidade',
  save_table: 'Salvar Mesa',
  save_cart: 'Salvar Carrinho',
  enable_groups: 'Habilitar grupos',
  highlight_items: 'Enfatizar itens',
  set_ready_print: 'Definir como pronto para impressão',
  start_preparing: 'Inicar preparo',
  group_qty: 'Agrupar quantidade',
  show_more: 'Mostrar mais',
  notes_per_qty: 'Notas por quantidade',
  print_online_order_receipts: 'Imprimir recibos de ordens online',
  select_items: 'Selecione os itens',
  partial_payment_not_allowed_by_value: 'Pagamentos parciais não são permitidos',
  product_media: 'Midia do produto',
  show_product_media: 'Mostrar midia do produto',
  change_seat: 'Trocar assento',
  add_seat: 'Adicionar assento',
  continue_partial_payment: 'Continuar pagamento parcial',
  seat: 'Assento',
  partial_payment_seat: 'Pagamento parcial por assento',
  processing: 'Processando',
  groupOrderPrintBySeat: 'Agrupar impressão de pedido por assento',
  fiscal_error: 'Erro fiscal',
  switch_employee: 'Trocar funcionário',
  no_order_removed: 'Poduto não pedido removido',
  connected_device: 'Dispositívo conectado',
  deleted: 'Removido',
  ready_pickup: 'Pronto para entrega',
  employee_pin_changed: 'Código de funcionário alterado com sucesso',
  order_kds_no_printer_msg: 'Pedido enviado ao sistema de cozinha mas não impresso, configure um dispositívo de impressão para enviar o pedido',
  order_kds_printer_msg: 'Pedido enviado ao sistema de cozinha e impresso',
  add_table: 'Adicionado a mesa',
  product_add_cart: 'Produto adicionado ao carrinho',
  product_ordered: 'Produto não pedido',
  product_not_ordered: 'Produto não ordenado',
  save_guests: 'Salvar convidados',
  guests_num: 'Número de convidados',
  add_guest_num: 'Adicionar número de convidados',
  no_stores_created: 'Sem lojas criadas',
  device_print_msg: 'O dispositívo selecionado será responsável por imprimir recibos',
  prep_time: 'Tempo de preparação',
  no_tables_store: 'Sem mesas nessa loja',
  go_back: 'Voltar',
  remove_not_order_msg: 'Deseja remover pedido não enviado',
  order_not_sent_back: 'Pedido não enviado ao sistema de cozinha! Deseja voltar mesmo assim?',
  no_available_addons_for_products: 'Sem adicionais para o produto selecionado',
  no_logged_employee: 'Sem funcionários logados',
  empty_tables: 'Mesas vazias',
  close_search: 'Fechar busca',
  no_empty_tables: 'Nenhuma mesa livre',
  my_tables: 'Minhas mesas',
  current_device: 'Dispositívo atual',
  employee_info: 'Informação dos funcionários',
  show_cart: 'Mostrar Carrinho',
  show_seat_separator: 'Mostrar separador de assentos',
  set_print_device: 'Configurar dispositívo de impressão',
  table_search: 'Procurar Mesa',
  order_by_seat: 'Pedidos por assentos',
  table_list_view: 'Mostrar mesas como lista',
  customer_groups: 'Grupos de clientes',
  sales_insights: 'Insights de vendas',
  product_insights: 'Informações do produto',
  store_insights: 'Percepções da loja',
  allow_ticket_scan: 'Verifique se há ingressos usando o leitor de código de barras',
  no_pin_employee: 'Funcionário sem PIN',
  allow_ticket_scan_desc: 'Use o leitor de código de barras para procurar ingressos.',
  admin_only_custom_item: 'Habilitar item personalizado apenas para administrador',
  admin_only_custom_item_desc: 'Apenas usuários com direitos de administrador podem criar um item personalizado. Você também deve ativar o PIN do funcionário.',
  mandatory_employee_pin: 'Ativação obrigatória do PIN do funcionário.',
  admin_only_refunds: 'Apenas administradores podem realizar reembolsos',
  admin_only_refunds_desc: 'Apenas os administradores podem realizar reembolsos. Você também deve ativar o PIN do funcionário.',
  round_cash_transactions_desc: 'Arredonda automaticamente os pagamentos em dinheiro para o 0,05 mais próximo',
  unlimited_usage: 'Uso ilimitado',
  infinite: 'Ilimitado',
  disable_custom_discounts: 'Desativar descontos personalizados',
  disable_custom_discounts_desc: 'Desative os descontos personalizados, mas permita os predefinidos.',
  guest: 'Convidado',
  select_method: 'Selecione o método',
  manual_insert: 'Inserção manual',
  enter_code: 'Coloque o código',
  existing_by_value_payment: '* Você não pode misturar pagamento por valor e pagamento por assento e / ou item',
  order_sent: 'Pedido Enviado',
  select_multiple_seats: 'Selecione vários assentos',
  change_seat_or_guest_num: 'Alterar assento ou número de convidado',
  change_guest_num: 'Alterar o número do convidado',
  giftcard_low_value: '* O saldo do vale-presente é inferior ao valor do (s) item (ns) selecionado (s). Em vez disso, tente pagar por valor.',
  order_by_seat_desc: 'Ative o recurso de pedido por assento.',
  received_amount: 'Valor recebido',
  order_products_action: 'Encomendar produtos',
  ordered_products: 'Produtos encomendados',
  select_table: 'Selecione a mesa',
  ordered: 'Ordenado',
  scan_or_type: 'Digitalize ou digite',
  type_code: 'Digite o código',
  number_extension: 'Extensão de número',
  move_processing_ticket_first: 'Mova os tíquetes de processamento primeiro',
  disable_adblocker: 'Desative seu AdBlocker e atualize a página',
  remove_partial_payment_by_item: 'Pagamento parcial por item detectado! Por favor, remova-o para prosseguir com esta ação!',
  add_nutrients: 'Adicione nutrientes',
  giftcard_warning_msg: 'A validade padrão de um cartão-presente é de 2 anos. Tem certeza de que deseja diminuir isso?',
  edit_devicess_main_details: 'Edite os principais detalhes dos dispositivos.',
  create_custom_payment_methods_for_your_device: 'Crie métodos de pagamento personalizados para o seu dispositivo.',
  show_open_carts_from_device: 'Mostrar pedidos abertos do dispositivo',
  create_discounts_for_your_stores: 'Crie descontos para sua localização.',
  create_reps_waste_for_your_stores: 'Crie representantes e resíduos para a sua localização.',
  show_cat_bar: 'Mostrar barra de categoria',
  bulk_update: 'Itens de atualização em massa',
  show_category_product_info: 'Mostrar informações da categoria no produto',
  running_payment: 'Em progresso',
  ready_items: 'Itens prontos',
  disabled: 'Desativado',
  ready_orders: 'Pedidos prontos',
  changes_list: 'Lista de alterações',
  action_type: 'Tipo de acão',
  previous_change: 'Alteração anterior',
  new_changes: 'Novas alterações',
  removed_fields: 'Campos removidos',
  updated_fields: 'Campos atualizados',
  full_history: 'Histórico completo',
  changes_history: 'Histórico de alterações',
  product_history: 'Histórico do produto',
  category_history: 'Histórico da categoria',
  device_history: 'Histórico do dispositivo',
  enable_mandatory_customer_address: 'Endereço do cliente obrigatório',
  please_fill_all_fields: 'Preencha todos os campos obrigatórios: número do prédio, rua, cidade, estado/província, CEP ou código postal, país, nome e sobrenome, endereço de e-mail ou número de telefone',
  select_file: 'Selecione o arquivo',
  drop_file: 'Soltar arquivo',
  create_media: 'Criar mídia para',
  upload_image: 'Enviar Imagem',
  upload_file: 'Subir arquivo',
  add_custom_img: 'Adicione sua imagem personalizada',
  select_media: 'Selecione o tipo de mídia',
  external_url_iframe: 'URL externo Iframe',
  select_ticket_print: 'Selecione o tipo para imprimir',
  external_service_provider: 'Prestador de serviço externo',
  barcode_input: 'Entrada de código de barras',
  stock_load_msg: 'Verifique todos os produtos para estoque. Isto pode tomar algum tempo. Por favor, aguarde!',
  no_available_stores: 'Nenhuma loja disponível.',
  buy_combination_get_deal_new_price: 'Combine itens por um preço de contrato',
  discount_specific_products_categories: 'Desconto em produtos ou categorias específicas',
  discount_all_prodcucts: 'Desconto em todos os produtos',
  buy_x_items_get_cheapest_discount: 'Compre x itens, obtenha o mais barato com desconto',
  spend_certain_amount_get_discount: 'Gaste uma certa quantia, ganhe um desconto',
  customer_group_item_discount: 'Desconto em grupos específicos de clientes',
  item_surcharge: 'Sobretaxa em produtos, categorias ou grupos de clientes específicos',
  cart_surcharge: 'Sobretaxa em todo o pedido',
  import: 'importar',
  export: 'Exportar',
  simple: 'Simples',
  advanced: 'Avançado',
  delete_import: 'Excluir importação de produtos',
  upload_csv: 'Carregue seu arquivo CSV',
  open_file: 'Abra o arquivo usando',
  save_file: 'Salve o arquivo como .csv',
  value_remaining: 'Valor restante:',
  shared_shift: 'Compartilhar turno',
  shared_shift_desc: 'A mudança de funcionário não exigirá a reabertura do registro',
  empty_cart_voucher_warning: 'Adicione Productos ao Carrinho antes de scannear o Voucher!',
  print_table_name: 'Imprimir nome da mesa no recibo',
  print_note: 'Imprimir notas no recibo',
  reset_state: 'Resetando o estado atual do aplicativo',
  sample_check_msg_top: 'Por favor, espere. Deve haver um check-up.',
  sample_check_msg_bottom: 'Um funcionário virá o mais rápido possível.',
  full_basket_check: 'Verificação da cesta completa',
  missing_items_amount: 'Quantidade de itens ausentes',
  extra_items_scanned: 'Quantidade de itens extras',
  finish_check: 'Concluir verificação',
  original_items: 'Itens originais',
  checked_items: 'Itens verificados',
  merging: 'Mesclagem em andamento',
  select_table_from_list: 'Selecione uma tabela da lista para mesclar todas as outras tabelas selecionadas',
  cant_merge_same: 'Não é possível mesclar a mesma tabela. Selecione outra tabela para mesclar',
  remove_from_table: 'remover da mesa',
  eat_in: 'Coma em',
  preparing_ods: 'Preparando',
  ready_ods: 'Pronto',
  cancel_order_pin: 'Cancelar PIN do pedido',
  locked_pos: 'PDV está bloqueado',
  fiscal_store_employee: 'As lojas fiscais devem ter um funcionário de registro para operar um PDV',
  invalid_employee: 'Funcionário inválido',
  update_employee_ssn: 'Atualize o número de segurança social do funcionário',
  social_security_number: 'Número da Segurança Social',
  fiscal_one_employee: 'Você deve ter pelo menos um funcionário registrado em uma loja fiscal',
  fiscal_employee_ssn: 'Os funcionários registrados em lojas fiscais devem ter um número de seguro social válido',
  employee_unable_delete: 'Não foi possível excluir o funcionário porque ele está vinculado a um repositório fiscal. Remova o funcionário da loja primeiro.',
  vat_receipt: 'Este é um recibo de IVA',
  not_vat_receipt: 'Este não é um recibo de IVA',
  deposit_error: 'Erro de depósito',
  deposit_error_message: 'Você não pode ter um depósito negativo vinculado a um produto com preço positivo. Apenas produtos com preço igual ou inferior a 0 podem ter depósito negativo.',
  assign_printers_to_your_location: 'Atribuir impressoras ao seu local',
  save_new_printer: 'Salvar nova impressora',
  update_printer: 'Atualizar impressora',
  location_no_saved_printers: 'Não há impressoras salvas para este local',
  sort_items_by_action: 'Classificar itens por ação (preparando, novo, pronto)',
  print_x_report: 'Imprimir relatório X',
  shox_x_report: 'Mostrar relatório X',
  print_z_report: 'Imprimir relatório Z',
  show_z_report: 'Mostrar relatório Z',
  unfiscalised_transactions_report_incorrect: 'Você tem {{transaction_number}} transações não sincronizadas - relatórios não representam a sua situação atual',
  refund_print: 'Reembolsar'
}

export default ptLang
