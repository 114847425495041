import { LOADING_TRUE, LOADING_FALSE, SET_LOADING_MSG, SET_LOADING_VALUE } from './actionTypes'

export const loadingTrue = () => ({
  type: LOADING_TRUE
})

export const loadingFalse = () => ({
  type: LOADING_FALSE
})

export const setLoadingMsg = msg => ({
  type: SET_LOADING_MSG,
  payload: msg
})

export const setLoadingValue = value => ({
  type: SET_LOADING_VALUE,
  payload: value
})
