import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ErrorHandler } from './utils/ErrorHandler'

import TrackedApp from './TrackedApp'
import configureStore from './store/configureStore'

import registerServiceWorker from './registerServiceWorker'

import { InternationalizationProvider } from 'react-internationalization'
import * as languages from './translations'

import './index.css'

const store = configureStore()
const lang = localStorage.getItem('CountrWeb:Language') || 'en'
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
ErrorHandler.initErrorListener()

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <InternationalizationProvider defaultLanguage={lang} languages={languages}>
        <TrackedApp />
      </InternationalizationProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
registerServiceWorker()

export default store
