import { memo } from 'react';
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { Text } from 'react-internationalization'

const ReportTaxes = memo(({ report, currency }) => {
  const returnKeyType = (key, type) => {
    return report.tables.product_by_taxes.details[key][type]
  }

  const returnTypeReduce = type => {
    return Object.keys(report.tables.product_by_taxes.details).reduce((acc, key) => {
      return acc + returnKeyType(key, type)
    }, 0)
  }

  return (
    <Grid container className="reports-type-container">
      <Grid item xs={12} className="reports-type-title">
        <InputLabel>
          <Text id="taxes" />
        </InputLabel>
      </Grid>
      <Grid item xs={12} className="reports-type-table">
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={3} className="reports-type-table-header">
            <InputLabel>
              <Text id="taxes" />
            </InputLabel>
          </Grid>
          <Grid item xs={3} className="reports-type-table-header">
            <InputLabel>
              <Text id="amount" />
            </InputLabel>
          </Grid>
          <Grid item xs={3} className="reports-type-table-header">
            <InputLabel>
              <Text id="pretax" />
            </InputLabel>
          </Grid>
          <Grid item xs={3} className="reports-type-table-header">
            <InputLabel>
              <Text id="taxes" />
            </InputLabel>
          </Grid>
        </Grid>
        {Object.keys(report.tables.product_by_taxes.details).map(key => (
          <Grid
            key={key}
            container
            justifyContent="space-between"
            className="reports-type-table-content-row">
            <Grid item xs={3} className="reports-type-table-content">
              {key}
            </Grid>
            <Grid item xs={3} className="reports-type-table-content">
              {currency.symbol} {returnKeyType(key, 'amount').toFixed(2)}
            </Grid>
            <Grid item xs={3} className="reports-type-table-content">
              {currency.symbol} {returnKeyType(key, 'preTax').toFixed(2)}
            </Grid>
            <Grid item xs={3} className="reports-type-table-content">
              {currency.symbol} {returnKeyType(key, 'taxes').toFixed(2)}
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="space-between" className="reports-type-table-content-row">
          <Grid item xs={3} className="reports-type-table-header">
            <b>
              <Text id="total" />
            </b>
          </Grid>
          <Grid item xs={3} className="reports-type-table-header">
            <b>
              {currency.symbol} {returnTypeReduce('amount').toFixed(2)}
            </b>
          </Grid>
          <Grid item xs={3} className="reports-type-table-header">
            <b>
              {currency.symbol} {returnTypeReduce('preTax').toFixed(2)}
            </b>
          </Grid>
          <Grid item xs={3} className="reports-type-table-header">
            <b>
              {currency.symbol} {returnTypeReduce('taxes').toFixed(2)}
            </b>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

export default ReportTaxes
