import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { setToastMessage } from '../../../store/actions/app'
import { setCalculator } from '../../../store/actions/products'

import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'
import ProductUtils from '../../../utils/ProductUtils'
import { cartUtils } from '../../../utils/cartUtils'
import Select from 'react-select'
import { Button, Input } from '@countr/ui'
import KeyboardComponent from '../../generic/KeyboardComponent'

import './CustomProductModal.scss'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    categories: state.categories,
    taxes: state.taxes,
    currentCart: state.carts.selectedCart,
    settings: state.settings,
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    setCalculator: num => dispatch(setCalculator(num))
  }
}

const CustomProductModal = memo(props => {
  const style = {
    color: props.theme.colors.text,
    backgroundColor: props.theme.colors.body
  }
  const selectStyles = {
    control: styles => ({ ...styles, ...style }),
    option: styles => {
      return {
        ...styles,
        ...style,
        ':hover': {
          backgroundColor: props.theme.colors.buttonPrimary.background,
          color: props.theme.colors.buttonPrimary.text
        }
      }
    },
    placeholder: styles => ({ ...styles, ...style }),
    singleValue: styles => ({ ...styles, ...style })
  }
  const [customProduct, setCustomProduct] = useState({})
  const [amount, setAmount] = useState(1)
  const [price, setPrice] = useState('0.00')
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [allCategories, setAllCategories] = useState([])
  const [tax, setTax] = useState('')
  const [allTaxes, setAllTaxes] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedTax, setSelectedTax] = useState(null)

  useEffect(() => {
    props.setCalculator(1)
    let cat = JSON.parse(JSON.stringify(props.categories.categories))
    cat = cat.map(c => {
      return { ...c, value: c._id, label: c.name }
    })
    setAllCategories(cat)
    let taxes = JSON.parse(JSON.stringify(props.taxes.taxes))
    taxes = taxes.map(t => {
      return { ...t, value: t._id, label: t.name }
    })
    setAllTaxes(taxes)
    const prod = ProductUtils.createCustomProductObj('Custom Product')
    prod._id = cartUtils.ObjectId()
    prod.variants[0]._id = cartUtils.ObjectId()
    setCustomProduct(prod)
  }, [])

  const handleClose = () => {
    props.setCalculator(0)
    props.handleCloseCustomProduct()
  }

  const handleChangeCategory = option => {
    setSelectedCategory(option)
    setCategory(option._id)
  }

  const handleChangeTax = option => {
    setSelectedTax(option)
    setTax(option._id)
  }

  const handleConfirm = () => {
    if (validateFields()) {
      const custom = JSON.parse(JSON.stringify(customProduct))
      custom.variants[0].price = parseFloat(price)
      const cat = props.categories.categories.find(c => c._id === category)
      custom.categories = [
        {
          category_id: cat.category_id,
          color: cat.color,
          name: cat.name,
          _id: cat._id
        }
      ]
      custom.variants[0].tax = props.taxes.taxes.find(t => t._id === tax)
      custom.name = !!name ? name : custom.name

      cartUtils.addProductToCart(
        props.currentCart,
        custom,
        custom.variants[0],
        [],
        amount,
        props.settings
      )
      handleClose()
    } else {
      props.setToastMessage('custom_item_not_valid')
    }
  }

  const validateFields = () => {
    return (!props.settings.allowNegativePayments && amount <= 0) || isNaN(parseFloat(price)) || category === '' || tax === '' ? false : true
  }

  const handleChangeName = e => {
    setName(e)
  }

  const handleChangePrice = value => {
    if (value === 'C') {
      setPrice('0.00')
    } else {
      const newValue = price + value
      const before = newValue.substr(0, newValue.indexOf('.'))
      const after = newValue.substr(newValue.indexOf('.') + 1)
      const num = before + after[0] + '.' + after.slice(1)
      setPrice(parseFloat(num).toFixed(2))
    }
  }

  const changeQuantity = type => {
    let value = amount

    //Suppress 0-value - not needed, as can just remove the item. If allowing negative sales, allow minus values
    if (value === 1 && type === 'remove' && !props.settings.allowNegativePayments) {
      value = 1
      setAmount(value)
      props.setCalculator(value)
      return
    }

    let newAmount = type === 'add' ? value + 1 : value - 1
    if(newAmount === 0 && props.settings.allowNegativePayments) newAmount += type === 'add' ? 1 : -1
    setAmount(newAmount)
    props.setCalculator(newAmount)
  }

  return (
    <Dialog
      open={props.openCustomProduct}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={isMobileOnly}>
      <DialogTitle id="form-dialog-title" style={style}>
        <Text id="custom_item" />
      </DialogTitle>
      <DialogContent style={style}>
        <div className="custom-product">
          <Input
            id="name"
            label={<Text id="item_description" />}
            className="name"
            value={name}
            onChange={handleChangeName}
            styles={{ width: '100%' }}
          />
        </div>
        <div className="custom-product">
          <div className="amount">
            <span className="title">
              <Text id="quantity" />
            </span>
            <div className="amount-btns">
              <button className="plus-minus" onClick={() => changeQuantity('remove')}>
                <span className="icon-minus" />
              </button>
              <span>
                <strong>{amount}</strong>
              </span>
              <button className="plus-minus" onClick={() => changeQuantity('add')}>
                <span className="icon-plus" />
              </button>
            </div>
          </div>
        </div>
        <div className="custom-product">
          <Text id="price" />: {props.devices.store.currency.symbol} {price}
        </div>
        <KeyboardComponent handleChange={handleChangePrice} />
        <div className="custom-product">
          <div className="category">
            <Select
              menuPlacement="top"
              maxMenuHeight={200}
              value={selectedCategory}
              onChange={handleChangeCategory}
              options={allCategories}
              placeholder={<Text id="categories" />}
              styles={selectStyles}
            />
          </div>
          <div className="tax">
            <Select
              menuPlacement="top"
              maxMenuHeight={200}
              value={selectedTax}
              onChange={handleChangeTax}
              options={allTaxes}
              placeholder={<Text id="taxes" />}
              styles={selectStyles}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        <Button
          className="primary"
          label={<Text id="add_to_cart" />}
          onClick={handleConfirm}
          disabled={!validateFields()}
        />
      </DialogActions>
    </Dialog>
  )
})

const CustomProductModalConnected = connect(mapStateToProps, mapDispatchToProps)(CustomProductModal)
export default CustomProductModalConnected
