import { memo, useState, useMemo } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { AppInstances } from '../../utils/countrSdkInstance'
import { PaymentUtils } from '../../utils/PaymentUtils'
import { RequestQueue } from '../../utils/RequestQueue'

import { editTransaction } from '../../store/actions/transactions'
import { selectCart, editCart } from '../../store/actions/carts'
import { setToastMessage } from '../../store/actions/app'
import { loadingTrue, loadingFalse } from '../../store/actions/loading'

import ConfirmationModal from '../generic/ConfirmationModal'
import PayplazaModal from '../Payments/Providers/PayplazaModal'
import CCVModal from '../Payments/Providers/CCVModal'
import CustomPayment from '../Payments/Providers/CustomPayment'

const MANUAL_CARD_PAYMENT = 'manual_card_payment'
const PAYPLAZA = 'payplaza'
const CCV = 'ccv'

const mapStateToProps = state => {
  return {
    payments: state.payments,
    settings: state.settings,
    device: state.devices.device,
    store: state.devices.store,
    currentCart: state.carts.selectedCart
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editTransaction: transaction => dispatch(editTransaction(transaction)),
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt)),
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse()),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart))
  }
}

const PayLater = memo(props => {
  const [manualCard, setManualCard] = useState(false)
  const [payplaza, setPayplaza] = useState(false)
  const [ccv, setCcv] = useState(false)
  const [customPayment, setCustomPayment] = useState(false)
  const [customMethod, setCustomMethod] = useState({})
  const [reference, setReference] = useState('')
  const remainingPaymentValue = useMemo(() => {
    return props.transaction.payments.reduce(
      (acc, payment) => acc + (payment.postponed ? payment.paid : 0),
      0
    )
  }, [props.transaction])

  const shouldPaymentBeAvailable = method => {
    return method.method === 'pay_later' ? false : method.enabled
  }

  const handlePayLater = async (method, info = null, card_print_info = null) => {
    props.loadingTrue()
    const transaction = { ...props.transaction }
    const payLaterIndex = transaction.payments.findIndex(payment => payment.postponed)
    const payment = {
      ...transaction.payments[payLaterIndex],
      date: new Date(),
      payment_started: new Date(),
      method: PaymentUtils.getPaymentMethod(method.method, props.device),
      provider: PaymentUtils.getPaymentProvider(method.method, props.device),
      postponed: false
    }

    if (!!info && !!card_print_info) {
      payment.info = info
      payment.card_print_info = card_print_info
    }

    transaction.payments[payLaterIndex] = payment
    transaction.paid = PaymentUtils.calculatePaidValue(transaction)

    const countr = await AppInstances.getCountrSdk()

    try {
      const updated = await countr.transactions.update(transaction._id, transaction)
      props.editTransaction(updated)
      props.setToastMessage('recovery_transaction_complete', { ref: `#${updated.receipt_id}` })
    } catch (error) {
      RequestQueue.enqueueAction({
        type: 'transactions',
        action: 'update',
        payload: transaction
      })
    }

    props.loadingFalse()
    props.handleClose()
  }

  const handlePay = method => {
    if (method.isCustom) {
      const custom = props.store.options.extraPaymentMethods.find(
        m => m._id === method.id
      )

      if (!!custom && custom.extras) {
        method.extras = custom.extras
        method.name = method.method
        method.method = custom.method
      }
    }

    if (method.method === MANUAL_CARD_PAYMENT) {
      handleOpenManualCard()
    } else if (method.method === PAYPLAZA) {
      handleOpenPayplaza()
      localStorage.setItem('CountrWeb:PayplazaPayLaterInProgress', props.transaction.receipt_id)
    } else if (method.method === CCV && !method.isCustom && this.props.app.isDesktop) {
      handleOpenCCV()
    } else if (method.isCustom && method.extras) {
      handleOpenCustom(method)
    } else {
      handlePayLater(method)
    }
  }

  const handleOpenManualCard = () => {
    setManualCard(true)
  }

  const handleCloseManualCard = () => {
    setManualCard(false)
  }

  const handleConfirmManualCard = () => {
    handleCloseManualCard()
    handlePayLater({ method: MANUAL_CARD_PAYMENT })
  }
  
  const handleOpenPayplaza = () => {
    setPayplaza(true)
  }

  const handleClosePayplaza = resultCode => {
    setPayplaza(false)
  }

  const handleConfirmPayplaza = resultCode => {
    if (resultCode === 'SUCCESS') {
      const cart = { ...props.currentCart }
      const info = { ...cart.info }
      const card_print_info = { ...cart.card_print_info }
      handlePayLater({ method: PAYPLAZA }, info, card_print_info)

      delete cart.info
      delete cart.card_print_info
      props.selectCart(cart)
      props.editCart(cart)
      localStorage.removeItem('CountrWeb:PayplazaPayLaterInProgress')
    }
  }
  
  const handleOpenCustom = method => {
    const { device, store } = props
    const ref =
      (store.store_id || '0') +
      '-' +
      (device.device_id || '0') +
      '-' +
      Math.round(new Date().getTime(), null)
    setCustomMethod(method)
    setReference(ref)
    setCustomPayment(true)
  }

  const handleCloseCustom = () => {
    setCustomMethod({})
    setReference('')
    setCustomPayment(false)
  }

  const handleResultCustom = result => {
    if (!!result && !!result.success) {
      const custom = { ...customMethod }
      handleCloseCustom()

      const printData = PaymentUtils.flattenPaymentInfoObj(result.printData)
      const info = result
      const card_print_info = {
        clientTicket: !!result.cardParsedData && !!result.cardParsedData.clientTicket
          ? result.cardParsedData.clientTicket
          : result.printData?.clientTicket
          ? result.printData.clientTicket
          : printData || ''
      }
      handlePayLater(custom, info, card_print_info)
    }
  }
  
  const handleOpenCCV = () => {
    setCcv(true)
  }

  const handleCloseCCV = resultCode => {
    setCcv(false)

    if (resultCode === 'AUTHORISED') {
      let clientTicket = ''
      let clientTicketLines = ''
      const parser = new DOMParser()
      const cart = { ...props.currentCart }
      clientTicket = parser.parseFromString(cart.ccvInfo, 'text/xml')
      clientTicket = clientTicket.getElementsByTagName('TextLine')

      for (let i = 0; i < clientTicket.length; i++) {
        clientTicketLines += clientTicket[i].childNodes[0].nodeValue + '\n'
      }

      const info = cart.ccvInfo
      const card_print_info = {
        clientTicket: clientTicketLines
      }
      handlePayLater({ method: CCV }, info, card_print_info)
      delete cart.ccvInfo
      props.selectCart(cart)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle id="form-dialog-title">
        <strong>
          <Text id="remaining_payment" />:{' '}
          {`${props.transaction.currency.symbol} ${remainingPaymentValue.toFixed(2)}`}
        </strong>
      </DialogTitle>
      <DialogContent style={{ maxWidth: 300, textAlign: 'center' }}>
        <Grid container justifyContent="center" alignItems="center">
          {props.payments.paymentMethods.map(
            method =>
              shouldPaymentBeAvailable(method) && (
                <Grid item xs={12} key={method.method} style={{ margin: 5 }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#318ed5', color: '#fff' }}
                    onClick={() => handlePay(method)}>
                    <span className="payment-btn-fit">
                      <Text id={method.method} />
                    </span>
                  </Button>
                </Grid>
              )
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.handleClose}>
          <Text id="cancel" />
        </Button>
      </DialogActions>
      {manualCard && (
        <ConfirmationModal
          openConfirmation={manualCard}
          handleCloseConfirmation={handleCloseManualCard}
          confirmBtn={handleConfirmManualCard}
          confirmationTitle={<Text id="manual_card_payment" />}
          confirmationText={<Text id="did_card_payment_succeed" />}
          type="manual_card_payment"
        />
      )}
      {payplaza && (
        <PayplazaModal
          openPayplazaModal={payplaza}
          handleClosePayplazaModal={handleClosePayplaza}
          completePayplazaTransaction={handleConfirmPayplaza}
          type="PURCHASE"
          currency={props.transaction.currency.code}
          totalToPay={remainingPaymentValue}
        />
      )}
      {customPayment && (
        <CustomPayment
          open={customPayment}
          handleResultPayment={handleResultCustom}
          handleClose={handleCloseCustom}
          method={customMethod}
          amount={remainingPaymentValue}
          currency={props.transaction.currency}
          reference={reference}
        />
      )}
      {ccv && (
        // TODO: Remove when new desktop version is launch
        <CCVModal
          openCCVModal={ccv}
          handleCloseCCVModal={handleCloseCCV}
          currency={props.transaction.currency.code}
          total={remainingPaymentValue}
          language={props.settings.language}
        />
      )}
    </Dialog>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(PayLater)
