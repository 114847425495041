import { memo, useState } from 'react';
import { Text } from 'react-internationalization'
import { useSelector } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { Button, Input } from '@countr/ui'

const ExtraInfo = memo(props => {
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }
  const [extraInfo, setExtraInfo] = useState('')

  const handleClose = () => {
    props.handleClose()
  }

  const handleChange = value => {
    setExtraInfo(value)
  }

  const handleConfirm = () => {
    props.handleConfirm(extraInfo)
  }

  return (
    <Dialog open={props.openExtraInfo} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={style}>
        <Text id="extra_info_title" />
      </DialogTitle>
      <DialogContent style={style}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Input
              label={<Text id="info"/>}
              value={extraInfo}
              onChange={handleChange}
              style={{ width: 250 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        <Button
          className="primary"
          label={<Text id="confirm" />}
          onClick={handleConfirm}
          disabled={extraInfo.length <= 0}
        />
      </DialogActions>
    </Dialog>
  )
})

export default ExtraInfo
