import { memo, useState } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'
import { setToastMessage } from '../../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import KeyBoardComponent from '../../generic/KeyboardComponent'
import { Button } from '@countr/ui'

import './CustomProductModal.scss'

const mapStateToProps = state => {
  return {
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const ProductSoldByWeightModal = memo(props => {
  const [quantity, setQuantity] = useState(0)
  const style = {
    color: props.theme.colors.text,
    backgroundColor: props.theme.colors.body
  }

  const handleClose = () => {
    props.handleCloseSoldByWeight()
  }

  const handleChangeQuantity = value => {
    if (value === 'C') {
      setQuantity(0)
    } else {
      const newValue = quantity + '' + value
      setQuantity(parseInt(newValue, 10))
    }
  }

  const handleConfirm = () => {
    if (quantity <= 1) {
      props.setToastMessage('weight_not_valid')
    } else {
      props.handleConfirmCustomProduct(quantity)
      handleClose()
    }
  }

  return (
    <Dialog
      open={props.openSoldByWeight}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      testid="sold-weight-modal">
      <DialogTitle id="form-dialog-title" style={style}>
        <Text id="sold_by_weight" />
      </DialogTitle>
      <DialogContent style={style}>
        <div className="custom-product">
          <span>{quantity} grams</span>
        </div>
        <KeyBoardComponent handleChange={handleChangeQuantity} />
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        <Button
          testid="sold-weight-add"
          className="primary"
          label={<Text id="add_to_cart" />}
          onClick={handleConfirm}
        />
      </DialogActions>
    </Dialog>
  )
})

const ProductSoldByWeightModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSoldByWeightModal)
export default ProductSoldByWeightModalConnected
