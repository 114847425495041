import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from './../utils/countrSdkInstance'
import { RequestQueue } from './../utils/RequestQueue'
import { PrinterUtils } from './../utils/PrinterUtils'
import { returnUpdatableDevice } from './../utils/DeviceUtils'
import StoreUtils from '../utils/StoreUtils'
import { employeeHasRights } from '../utils/EmployeeUtils'
import RegisterOperationsUtils from '../utils/RegisterOperationsUtils'

import {
  showMainMenu,
  showSettings,
  setToastMessage,
  showKds,
  showChat,
  showDeliveryNotes
} from './../store/actions/app'
import { updateDevice } from './../store/actions/devices'
import { loadingFalse, loadingTrue } from '../store/actions/loading'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import { Text } from 'react-internationalization'
import DesktopUtils from '../utils/DesktopUtils'
import WorkerResourceLoader from '../WorkerResourceLoader'
import MenuItem from './MenuItem'
import ChangeInfoModal from './Login/ChangeInfoModal'
import ConfirmationModal from './generic/ConfirmationModal'
import Transaction from './Transaction/Transaction'
import AggregatedInvoicesModal from './Payments/AggregatedInvoicesModal'
import PaymentMethods from './Settings/PaymentMethods'
import PrinterSettings from './Settings/PrinterSettings'
import CDSSettings from './Settings/CDSSettings'
import RecoveryCarts from './Settings/RecoveryCarts'
import Reports from './Reports/Reports'
import ReleaseNote from './generic/ReleaseNote'
import StorageComponent from './generic/StorageComponent'
import SignOut from './generic/SignOut'
import countrLogoImg from './../assets/color_white_NOtag.png'
import './Menu.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    settings: state.settings,
    user: state.user.user,
    devices: state.devices,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMainMenu: () => dispatch(showMainMenu()),
    showSettings: () => dispatch(showSettings()),
    updateDevice: device => dispatch(updateDevice(device)),
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    showKds: show => dispatch(showKds(show)),
    showChat: show => dispatch(showChat(show)),
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    showDeliveryNotes: () => dispatch(showDeliveryNotes())
  }
}

class Menu extends PureComponent {
  state = {
    openConfirmation: false,
    openChangeInfoModal: false,
    typeChangeInfoModal: '',
    openTransaction: false,
    openReports: false,
    openPaymentMethodsDialog: false,
    openPrinterSettings: false,
    openReleaseNote: false,
    openRecoveryCarts: false,
    openCDSSettings: false,
    openAggregatedInvoices: false,
    openDeliveryNotes: false,
    hasQuota: false,
    quota: 0
  }

  hideMenu = () => {
    this.hideIntercomButton()
    this.props.showMainMenu()
  }

  refreshAll = async () => {
    if (!navigator.onLine) {
      this.props.setToastMessage('disallow_offline')
      return
    }

    const {
      user,
      devices: { store, device },
      loadingTrue,
      loadingFalse
    } = this.props

    loadingTrue()

    const countr = await AppInstances.getCountrSdk()

    try {
      await WorkerResourceLoader.getResourceCount('products')
      await WorkerResourceLoader.getResourceCount('categories')
      const operationsNote = 'Web POS Refresh Products/Categories action'
      const operationArgs = {
        action: 'account_refresh',
        note: operationsNote
      }

      RegisterOperationsUtils.applyOperation(operationArgs)

      // Cleaning all delta data
      localStorage.removeItem(`${device._id}_categories_lastDelta`)
      localStorage.removeItem(`${device._id}_products_lastDelta`)
      localStorage.removeItem(`${device._id}_transactions_lastDelta`)
      WorkerResourceLoader.clearDatabase().then(() => {
        loadingFalse()
        window.location.reload()
      })
    } catch (e) {
      countr.e
        .create({
          message: `Failed Refreshing products and categories`,
          user: user._id,
          store: store._id,
          device: device._id,
          date: new Date().toISOString(),
          source: process.env.REACT_APP_ERROR_SOURCE,
          info: {
            time: new Date().toISOString(),
            ...e
          }
        })
        .catch(error => {
          console.log(error)
        })

      loadingFalse()
    }
  }

  showIntercomButton = () => {
    const inter = document.querySelector('.intercom-launcher')
    if (inter) {
      inter.style.display = 'block'
    } else {
      this.props.setToastMessage('intercom_error')
    }
  }

  showKds = () => {
    this.props.showKds(!this.props.app.showKds)
    this.hideMenu()
  }

  // showChat = () => {
  //   this.props.showChat(!this.props.app.showChat)
  //   this.hideMenu()
  // }

  hideIntercomButton = () => {
    const inter = document.getElementById('intercom-container')
    if (inter) {
      inter.style.display = 'none'
    }
  }

  handleOpenChangeInfo = type => {
    this.setState({ openChangeInfoModal: true, typeChangeInfoModal: type })
  }

  handleCloseChangeInfo = () => {
    this.setState({ openChangeInfoModal: false, typeChangeInfoModal: '' })
  }

  handleConfirmChangeInfo = value => {
    if (this.state.typeChangeInfoModal === 'device') {
      this.updateDeviceName(value)
    }
  }

  updateDeviceName = name => {
    const device = JSON.parse(JSON.stringify(this.props.devices.device))
    device.name = name
    this.props.updateDevice(device)
    this.handleCloseChangeInfo()
    AppInstances.getCountrSdk().then(socket => {
      socket.devices.update(device._id, returnUpdatableDevice(device)).then(
        updatedDevice => {
          console.log('device updated!')
        },
        error => {
          RequestQueue.enqueueAction({
            type: 'devices',
            action: 'update',
            payload: returnUpdatableDevice(device)
          })
        }
      )
    })
  }

  logout = () => {
    this.handleItem('openConfirmation')
    const storedDeviceUuid = localStorage.getItem('StoredDeviceUuid')
    // Register sign out action
    const operationArgs = {
      action: 'sign_out'
    }

    RegisterOperationsUtils.applyOperation(operationArgs)
    localStorage.clear()
    window.location = '/'
  }

  handleCloseTransaction = exchangeResult => {
    this.setState({ openTransaction: false })

    if (!!exchangeResult && exchangeResult === 'CLOSE_TRANSACTION_MODAL') {
      this.hideMenu()
    }
  }

  handleItem = name => {
    this.setState(state => {
      return { [name]: !state[name] }
    })
  }

  handleOpenCashDrawer = () => {
    PrinterUtils.openCashDrawer()
  }

  calculateQuota = async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const estimate = await navigator.storage.estimate()
      const quotaPercent = parseFloat((estimate.usage / estimate.quota) * 100).toFixed(2)
      this.setState({ hasQuota: true, quota: quotaPercent })
    }
  }

  handleDashboard = () => {
    const EXTERNAL_DASH_VERSION = 4
    const url = process.env.REACT_APP_DASHBOARD_URL
    const { isDesktop, desktopVersion } = this.props.app

    if (isDesktop && DesktopUtils.isVersionValid(desktopVersion, EXTERNAL_DASH_VERSION)) {
      const ips = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))

      if (ips?.local_ip) {
        const web = AppInstances.getDesktopConnection(
          `${process.env.REACT_APP_WS_TYPE}://${
            ips.local_ip
          }:${DesktopUtils.getDesktopListenerPort()}`
        )

        if (web?.readyState === 1) {
          const data = JSON.stringify({
            type: 'open-dashboard',
            payload: {
              url
            }
          })
          web.send(data)
        }
      }
    } else {
      window.open(url)
    }
  }

  componentDidMount = () => {
    this.calculateQuota()
  }

  render() {
    const employee = this.props.employees.selectedEmployee

    return (
      <div id="main-menu">
        <div id="main-menu-backdrop" className="main-menu-backdrop" onClick={this.hideMenu} />
        <div
          id="main-menu-content"
          className="main-menu"
          style={{ background: this.props.app.theme.colors.body }}>
          <Grid container>
            <Grid item xs={12}>
              <header className="main-menu-header app-header">
                <IconButton className="main-menu-back" onClick={this.hideMenu}>
                  <span className="icon-left-arrow" />
                </IconButton>
                <img src={countrLogoImg} className="main-menu-header-logo" alt="Countr-logo" />
                <div className="main-menu-app-version">
                  <Text id="app_version" />: {process.env.REACT_APP_VERSION}
                  {this.props.app.isDesktop && (
                    <>
                      <br />
                      <span>Desktop version: {this.props.app.desktopVersion}</span>
                    </>
                  )}
                </div>
                <hr className="main-menu-header-divider" />
                <List component="nav">
                  <MenuItem
                    icon={'icon-profile'}
                    title={this.props.user.email}
                    subtitle={false}
                    action={() => {}}
                    disabled={false}
                    hasFont={true}
                    divider={false}
                  />
                  <MenuItem
                    icon={'icon-business'}
                    title={this.props.devices.store.name}
                    subtitle={false}
                    action={() => {}}
                    disabled={false}
                    hasFont={true}
                    divider={false}
                  />
                  {employeeHasRights(employee, 'change_device_name') ? (
                    <MenuItem
                      icon={'icon-mobile'}
                      title={this.props.devices.device.name}
                      subtitle={false}
                      action={() => this.handleOpenChangeInfo('device')}
                      disabled={false}
                      hasFont={true}
                      divider={false}
                    />
                  ) : (
                    <MenuItem
                      icon={'icon-mobile'}
                      title={this.props.devices.device.name}
                      subtitle={false}
                      action={() => {}}
                      disabled={false}
                      hasFont={true}
                      divider={false}
                    />
                  )}
                </List>
              </header>
              <List
                component="nav"
                className={`main-menu-scroll-items ${this.props.app.isDesktop ? 'desktop' : ''}`}>
                <MenuItem
                  icon={'icon-transactions'}
                  title={'transactions'}
                  subtitle={this.props.app.trialExpired}
                  action={() => this.handleItem('openTransaction')}
                  disabled={this.props.app.trialExpired}
                />
                {employeeHasRights(employee, 'reports') && (
                  <MenuItem
                    icon={'icon-report'}
                    title={'reports'}
                    subtitle={this.props.app.trialExpired}
                    action={() => this.handleItem('openReports')}
                    disabled={this.props.app.trialExpired}
                  />
                )}
                {employeeHasRights(employee, 'payment_method') && (
                  <MenuItem
                    icon={'icon-cash-thin'}
                    title={'payment_method'}
                    subtitle={false}
                    action={() => this.handleItem('openPaymentMethodsDialog')}
                    disabled={false}
                  />
                )}
                {this.props.app.isDesktop && (
                  <MenuItem
                    icon={'icon-cashdrawer'}
                    title={'cash_drawer'}
                    subtitle={false}
                    action={this.handleOpenCashDrawer}
                    disabled={false}
                  />
                )}
                <MenuItem
                  icon={'icon-features-sync2'}
                  title={'refresh_delta'}
                  subtitle={false}
                  action={this.refreshAll}
                  disabled={false}
                />
                <MenuItem
                  icon={'icon-settings-wheel'}
                  title={'Opened Delivery Notes'}
                  subtitle={false}
                  action={() => this.handleItem('openDeliveryNotes')}
                  disabled={false}
                />
                <MenuItem
                  icon={'icon-features-sync2'}
                  title={'Invoices'}
                  subtitle={false}
                  action={() => this.handleItem('openAggregatedInvoices')}
                  disabled={false}
                />
                {employeeHasRights(employee, 'settings') && (
                  <MenuItem
                    icon={'icon-settings-wheel'}
                    title={'settings'}
                    subtitle={false}
                    action={this.props.showSettings}
                    disabled={false}
                  />
                )}
                {this.props.app.isDesktop && employeeHasRights(employee, 'printer_settings') && (
                  <MenuItem
                    icon={'icon-printer'}
                    title={'printer_settings'}
                    subtitle={false}
                    action={() => this.handleItem('openPrinterSettings')}
                    disabled={false}
                  />
                )}
                {this.props.app.isDesktop &&
                  this.props.settings.enableCustomerScreen &&
                  employeeHasRights(employee, 'cds_settings') && (
                    <MenuItem
                      icon={'icon-product2'}
                      title={'cds_settings'}
                      subtitle={false}
                      action={() => this.handleItem('openCDSSettings')}
                      disabled={false}
                    />
                  )}
                {!StoreUtils.hasFloorplans(this.props.devices.store) &&
                  employeeHasRights(employee, 'recover_carts') && (
                    <MenuItem
                      icon={'icon-cart'}
                      title={'recover_carts'}
                      subtitle={false}
                      action={() => this.handleItem('openRecoveryCarts')}
                      disabled={false}
                    />
                  )}
                {employeeHasRights(employee, 'go_dashboard') && (
                  <MenuItem
                    icon={'icon-dashboard-overview'}
                    title={'go_dashboard'}
                    subtitle={false}
                    action={this.handleDashboard}
                    disabled={false}
                  />
                )}
                <MenuItem
                  icon={'icon-phone-support'}
                  title={'support'}
                  subtitle={false}
                  action={this.showIntercomButton}
                  disabled={false}
                />
                <MenuItem
                  icon={'icon-website'}
                  title={'open_kds'}
                  subtitle={false}
                  action={this.showKds}
                  disabled={false}
                />

                <MenuItem
                  icon={'icon-note'}
                  title={'release_note'}
                  subtitle={false}
                  action={() => this.handleItem('openReleaseNote')}
                  disabled={false}
                />

                {/* <MenuItem
                  icon={'icon-news'}
                  title={'what_is_new'}
                  subtitle={false}
                  action={() => this.handleItem('openReleaseNote')}
                  disabled={false}
                /> */}
              </List>
              {/* Storage calculation component */}
              {this.state.hasQuota && (
                <StorageComponent quota={this.state.quota} employee={employee} />
              )}
              {/* Sign Out component */}
              {employeeHasRights(employee, 'sign_out') && (
                <SignOut handleOpenConfirmation={() => this.handleItem('openConfirmation')} />
              )}
            </Grid>
          </Grid>
        </div>
        {this.state.openChangeInfoModal && (
          <ChangeInfoModal
            openChangeInfoModal={this.state.openChangeInfoModal}
            handleClose={this.handleCloseChangeInfo}
            handleConfirm={this.handleConfirmChangeInfo}
            infoType={this.state.typeChangeInfoModal}
          />
        )}
        {this.state.openConfirmation && (
          <ConfirmationModal
            openConfirmation={this.state.openConfirmation}
            handleCloseConfirmation={() => this.handleItem('openConfirmation')}
            confirmBtn={this.logout}
            confirmationTitle={<Text id="sign_out" />}
            confirmationText={<Text id="sign_out_warning" />}
            type="sign_out"
          />
        )}
        {this.state.openTransaction && (
          <Transaction
            openTransaction={this.state.openTransaction}
            handleCloseTransaction={this.handleCloseTransaction}
          />
        )}
        {this.state.openAggregatedInvoices && (
          <AggregatedInvoicesModal
            target="invoices"
            merchantId={this.props.user._id}
            openConfirmation={this.state.openAggregatedInvoices}
            handleClose={() => this.handleItem('openAggregatedInvoices')}
          />
        )}
        {this.state.openDeliveryNotes && (
          <AggregatedInvoicesModal
            target="delivery-notes"
            merchantId={this.props.user._id}
            openConfirmation={this.state.openDeliveryNotes}
            handleClose={() => this.handleItem('openDeliveryNotes')}
          />
        )}
        {this.state.openPaymentMethodsDialog && (
          <PaymentMethods
            openPaymentMethodsDialog={this.state.openPaymentMethodsDialog}
            handleClosePaymentMethods={() => this.handleItem('openPaymentMethodsDialog')}
          />
        )}

        {this.state.openPrinterSettings && (
          <PrinterSettings
            openPrinterSettings={this.state.openPrinterSettings}
            handleClosePrinterSettings={() => this.handleItem('openPrinterSettings')}
          />
        )}

        {this.state.openCDSSettings && (
          <CDSSettings
            openCDSSettings={this.state.openCDSSettings}
            handleClose={() => this.handleItem('openCDSSettings')}
          />
        )}

        {this.state.openRecoveryCarts && (
          <RecoveryCarts
            openRecoveryCarts={this.state.openRecoveryCarts}
            handleCloseRecoveryCarts={() => this.handleItem('openRecoveryCarts')}
            device={this.props.devices.device}
          />
        )}

        {this.state.openReports && (
          <Reports
            openReports={this.state.openReports}
            handleClose={() => this.handleItem('openReports')}
          />
        )}
        {this.state.openReleaseNote && (
          <ReleaseNote
            open={this.state.openReleaseNote}
            handleClose={() => this.handleItem('openReleaseNote')}
          />
        )}
      </div>
    )
  }
}

const MenuConnected = connect(mapStateToProps, mapDispatchToProps)(Menu)
export default MenuConnected
