import { PrinterUtils } from './PrinterUtils'
import { cartUtils } from './cartUtils'
import { translate } from 'react-internationalization'
import DesktopUtils from './DesktopUtils'

const SCREEN = 'screen'
const TOTAL_WIDTH = 20

export default class ScreenUtils {
  static getTranslations() {
    return {
      total: translate('total'),
      change: translate('change'),
      paid: translate('paid'),
      welcome: translate('welcome_to'),
      kassa_open: translate('kassa_open'),
      kassa_closed: translate('kassa_closed'),
      kassa_closed_hint: translate('kassa_closed_hint')
    }
  }

  static spacesBetween(str1, str2) {
    let ret = ''
    if (str1.length + str2.length <= TOTAL_WIDTH) {
      ret = str1 + ' '.repeat(TOTAL_WIDTH - (str1.length + str2.length)) + str2
    } else {
      ret = str1 + ' ' + str2
    }
    return ret
  }

  static spacesAround(str) {
    let ret = ''

    if (str.length) {
      ret =
        ' '.repeat((TOTAL_WIDTH - str.length) / 2) +
        str +
        ' '.repeat((TOTAL_WIDTH - str.length) / 2)

      if (ret.length < TOTAL_WIDTH) {
        const num = TOTAL_WIDTH - ret.length
        ret += ' '.repeat(num)
      }
    }

    return ret
  }

  static getPayload() {
    return JSON.parse(localStorage.getItem('CountrWeb:Customer-Screen-Payload'))
  }

  static savePayload(payload) {
    localStorage.setItem('CountrWeb:Customer-Screen-Payload', JSON.stringify(payload))
  }

  static welcome(store, port) {
    if (!DesktopUtils.isDesktop()) {
      return
    }

    let line1 = ''
    let line2 = ''

    line1 = ScreenUtils.spacesAround(ScreenUtils.getTranslations().welcome)
    const storeName = store?.name ? store.name.substring(0, 19) : 'Store'
    line2 = ScreenUtils.spacesAround(storeName)

    const payload = {
      line1,
      line2
    }

    ScreenUtils.sendPayload(payload, port)
  }

  static addProduct(product, total, currency, port) {
    if (!DesktopUtils.isDesktop()) {
      return
    }

    // Always send the exacly text to the screen
    // 20 chars per line - Example:
    // 01234567890123456789
    // 01234567890123456789
    // or
    // 'Heineken   EUR  7.00'   -> prod.name = max 10 chars
    // 'Total      EUR  7.00'   -> total = max 10 chars

    let line1 = ''
    let line2 = ''

    if (product && Object.keys(product).length) {
      // Product line
      const name = product.current_variant.default
        ? product.name.substring(0, 10)
        : product.current_variant.name.substring(0, 10)
      const price = parseFloat(product.current_variant.price).toFixed(2).split('.')
      const productEuros = price[0]
      const productCents = price[1]
      let productPriceSpace = ''

      if (parseInt(productEuros, 10) < 100) {
        productPriceSpace += ' '
      }

      if (parseInt(productEuros, 10) < 10) {
        productPriceSpace += ' '
      }

      const pricepart = `${currency}${productPriceSpace}${productEuros}.${productCents}`

      line1 = ScreenUtils.spacesBetween(name, pricepart)
    } else {
      line1 = ' '.repeat(TOTAL_WIDTH)
    }

    // Total line
    const money = parseFloat(total).toFixed(2).split('.')
    const totalEuros = money[0]
    const totalCents = money[1]
    let totalPriceSpace = ''

    if (parseInt(totalEuros, 10) < 100) {
      totalPriceSpace += ' '
    }

    if (parseInt(totalEuros, 10) < 10) {
      totalPriceSpace += ' '
    }

    const totalpart = `${currency}${totalPriceSpace}${totalEuros}.${totalCents}`

    line2 = ScreenUtils.spacesBetween(ScreenUtils.getTranslations().total, totalpart)

    const payload = {
      line1,
      line2
    }

    ScreenUtils.sendPayload(payload, port)
  }

  static changeCart(cart, port) {
    if (!DesktopUtils.isDesktop()) {
      return
    }

    let line1 = ''
    let line2 = ''

    const name = cartUtils.getCartName(cart)
    line1 = ScreenUtils.spacesAround(name)

    // Total line
    const money = parseFloat(cart.total).toFixed(2).split('.')
    const totalEuros = money[0]
    const totalCents = money[1]
    let totalPriceSpace = ''

    if (parseInt(totalEuros, 10) < 100) {
      totalPriceSpace += ' '
    }

    if (parseInt(totalEuros, 10) < 10) {
      totalPriceSpace += ' '
    }

    const totalpart = `${cart.currency.code}${totalPriceSpace}${totalEuros}.${totalCents}`
    line2 = ScreenUtils.spacesBetween(ScreenUtils.getTranslations().total, totalpart)

    const payload = {
      line1,
      line2
    }

    ScreenUtils.sendPayload(payload, port)
  }

  static showTotal(cart, port) {
    if (!DesktopUtils.isDesktop()) {
      return
    }

    ScreenUtils.addProduct(undefined, cart.total, cart.currency.code, port)
  }

  static pay(total, change, currency, port) {
    if (!DesktopUtils.isDesktop()) {
      return
    }

    let line1 = ''
    let line2 = ''

    // paid line
    const money = parseFloat(total).toFixed(2).split('.')
    const paidEuros = money[0]
    const paidCents = money[1]
    let paidPriceSpace = ''

    if (parseInt(paidEuros, 10) < 100) {
      paidPriceSpace += ' '
    }

    if (parseInt(paidEuros, 10) < 10) {
      paidPriceSpace += ' '
    }

    const paidpart = `${currency}${paidPriceSpace}${paidEuros}.${paidCents}`
    line1 = ScreenUtils.spacesBetween(ScreenUtils.getTranslations().paid, paidpart)

    // change line
    const ret = change.toFixed(2).split('.')
    const changeEuros = ret[0]
    const changeCents = ret[1]
    let changePriceSpace = ''

    if (parseInt(changeEuros, 10) < 100) {
      changePriceSpace += ' '
    }

    if (parseInt(changeEuros, 10) < 10) {
      changePriceSpace += ' '
    }

    const changepart = `${currency}${changePriceSpace}${changeEuros}.${changeCents}`
    line2 = ScreenUtils.spacesBetween(ScreenUtils.getTranslations().change, changepart)

    const payload = {
      line1,
      line2
    }

    ScreenUtils.sendPayload(payload, port)
  }

  static kassaClosed(closed) {
    // Show kassa Closed in screen, if open/close shift is enable but open shift was not done
    if (closed) {
      localStorage.setItem('CountrWeb:kassaClosed', 'true')
    } else {
      localStorage.removeItem('CountrWeb:kassaClosed')
    }
  }

  static isKassaClosed() {
    if (localStorage.getItem('CountrWeb:kassaClosed') === 'true') {
      let isOpen = false

      for (var key in localStorage) {
        if (key.indexOf('_startDay') >= 0) {
          isOpen = true
        }
      }

      return !isOpen
    } else {
      return false
    }
  }

  static returnKassaOpenPayload() {
    const emptyline = ' '.repeat(TOTAL_WIDTH)
    return {
      line1: ScreenUtils.getTranslations().kassa_open.substring(0, 19),
      line2: emptyline
    }
  }

  static returnKassaClosedPayload() {
    return {
      line1: ScreenUtils.getTranslations().kassa_closed.substring(0, 19),
      line2: ScreenUtils.getTranslations().kassa_closed_hint.substring(0, 19)
    }
  }

  static sendPayload(payload, port) {
    if (ScreenUtils.isKassaClosed()) {
      payload = ScreenUtils.returnKassaClosedPayload()
      payload.port = port
      PrinterUtils.sendToPrinter(SCREEN, payload)
    } else {
      payload.port = port
      ScreenUtils.savePayload(payload)
      PrinterUtils.sendToPrinter(SCREEN, payload)
    }
  }
}
