import { Component } from 'react';

import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

export default class InputCashHelper extends Component {
  constructor(props) {
    super(props)

    this.denominations = Object.keys(this.props.currency).length
      ? this.props.currency.denominations
      : process.env.REACT_APP_CASHLIST.split(',')

    const inputElements = []
    this.denominations.forEach(inputs => {
      inputElements[`cash_${inputs.trim()}`] = 0
    })

    this.state = { inputElements }
  }

  calculateInput = (e, inputAmount) => {
    const alterElements = { ...this.state.inputElements }
    alterElements[`cash_${inputAmount.trim()}`] =
      e.target.value === '' ? 0 : parseFloat(e.target.value) * inputAmount

    this.setState(
      {
        inputElements: alterElements
      },
      () => {
        const finalAmount = Object.values(this.state.inputElements).reduce(
          (ac, amount) => ac + amount
        )

        this.props.handlerMethod(finalAmount.toFixed(2))
      }
    )
  }

  render() {
    return (
      <div className="employee-shift-cash-wrapper">
        <form className="employee-shift-cash-list" id="employee-shift-cash-list">
          {this.denominations.map(cash => (
            <Input
              key={cash}
              classes={{ root: 'employee-shift-cash-list-input' }}
              type="number"
              min={0}
              placeholder={cash.toString()}
              onChange={e => this.calculateInput(e, cash)}
              startAdornment={
                <InputAdornment
                  position="start"
                  classes={{ positionStart: 'employee-shift-cash-list-input-span' }}>
                  <span>
                    {this.props.currency.symbol}
                    {(+cash).toString()}
                  </span>
                </InputAdornment>
              }
            />
          ))}
        </form>
        {/* <div className="employee-shift-tools">
					<button onClick={this.cleanInputs}>clean</button>
				</div> */}
      </div>
    )
  }
}
