import { Fragment, PureComponent } from 'react'
import { translate } from 'react-internationalization'
import { connect } from 'react-redux'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { RequestQueue } from '../../../utils/RequestQueue'
import { cartUtils } from '../../../utils/cartUtils'
import ScreenUtils from '../../../utils/ScreenUtils'
import StoreUtils from '../../../utils/StoreUtils'
import RegisterOperationsUtils from '../../../utils/RegisterOperationsUtils'

import {
  addCart,
  editCart,
  selectCart,
  cleanCart,
  deleteCart,
  setTableSeat
} from '../../../store/actions/carts'
import { loadingTrue, loadingFalse } from '../../../store/actions/loading'
import { setToastMessage } from '../../../store/actions/app'
import { addDevice, addStore } from '../../../store/actions/devices'

import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

import ConfirmationModal from '../../generic/ConfirmationModal'
import ChangePropertyModal from '../../generic/ChangePropertyModal'
import CartSelectionPopover from './CartSelectionPopover'
import TableLayout from '../TableLayout/TableLayout'

import { Text } from 'react-internationalization'
import './CartHeader.scss'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    carts: state.carts,
    settings: state.settings,
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    editCart: cart => dispatch(editCart(cart)),
    addCart: cart => dispatch(addCart(cart)),
    cleanCart: id => dispatch(cleanCart(id)),
    deleteCart: id => dispatch(deleteCart(id)),
    selectCart: cart => dispatch(selectCart(cart)),
    addDevice: device => dispatch(addDevice(device)),
    addStore: store => dispatch(addStore(store)),
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt)),
    setTableSeat: seat => dispatch(setTableSeat(seat))
  }
}

const CLEAN = 'CLEAN'
const DELETE_CART = 'DELETE_CART'
const DELETE_ALL_EMPTY_CART = 'DELETE_ALL_EMPTY_CART'

class CartHeader extends PureComponent {
  state = {
    // Confirmation modal
    openConfirmationModal: false,
    confirmationType: '',
    confirmationModalTitle: '',
    confirmationModalText: '',
    // Table layout
    openTableLayoutModal: false,
    // Change property modal
    changePropertyModal: false,
    changePropertyTitle: '',
    changePropertyText: '',
    changePropertyHelpText: '',
    initialValue: '',
    // Cart selection popover
    openCartSelectionPopover: false,
    deleteCartSelection: ''
  }

  componentDidMount() {
    window.addEventListener('handle-floorplan', this.handleOpenCartSelection)
  }

  componentWillUnmount() {
    window.removeEventListener('handle-floorplan', this.handleOpenCartSelection)
  }

  canCleanCart = () => {
    const cart = { ...this.props.carts.selectedCart }

    return (
      (cart.order_source === 'pos' || cart.order_source === 'web_pos') &&
      !this.cartHasPayments(cart)
    )
  }

  cartHasPayments = cart => {
    if (cart.payments) {
      return cart.payments.length > 0
    } else {
      return false
    }
  }

  handleOpenConfirmationModal = type => {
    if (type === CLEAN) {
      this.setState({
        openConfirmationModal: true,
        confirmationModalType: type,
        confirmationModalTitle: 'empty_cart',
        confirmationModalText: 'sign_out_header'
      })
    }
  }

  handleCloseConfirmationModal = () => {
    this.setState({
      openConfirmationModal: false,
      confirmationModalType: '',
      confirmationModalTitle: '',
      confirmationModalText: '',
      deleteCartSelection: ''
    })
  }

  handleConfirmConfirmationModal = () => {
    if (this.state.confirmationModalType === CLEAN) {
      this.cleanCart()
      const operationArgs = {
        action: 'cart_empty'
      }
      RegisterOperationsUtils.applyOperation(operationArgs)
    }
  }

  cleanCart = async () => {
    // seat seat to 0
    this.props.setTableSeat(0)
    if (cartUtils.isExchangeableCart(this.props.carts.selectedCart)) {
      const id = this.props.carts.selectedCart._id
      const deleted = await cartUtils.deleteCartServer(id)
      const currentCart = JSON.parse(JSON.stringify(this.props.carts.carts[0]))
      this.props.selectCart(currentCart)
      this.props.deleteCart(id)
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(currentCart))
      localStorage.removeItem('EXCHANGE:CountrLite:Cart-' + id)
      return
    }

    this.props.cleanCart()
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(this.props.carts.selectedCart))
    this.props.editCart(this.props.carts.selectedCart)
    localStorage.setItem(
      'CountrLite:Cart-' + this.props.carts.selectedCart._id,
      JSON.stringify(this.props.carts.selectedCart)
    )

    if (this.props.settings.enableCustomerScreen) {
      const port = this.props.settings.customerScreenPort
      if (port && port.length) {
        ScreenUtils.addProduct(
          undefined,
          this.props.carts.selectedCart.total,
          this.props.carts.selectedCart.currency.code,
          port
        )
      }
    }

    AppInstances.getCountrSdk().then(socket => {
      const device = Object.assign({}, this.props.devices.device)
      const toUpdateCart = {
        ...this.props.carts.selectedCart,
        updated_at: new Date(),
        originator: device._id,
        server_modified: false
      }

      socket.carts.update(this.props.carts.selectedCart._id, toUpdateCart).then(
        cart => {},
        error => {
          RequestQueue.enqueueAction({
            type: 'carts',
            action: 'update',
            payload: { ...toUpdateCart }
          })
        }
      )
    })
  }

  handleOpenCartSelection = () => {
    if (StoreUtils.hasFloorplans(this.props.devices.store)) {
      this.setState({ openTableLayoutModal: true })
    } else {
      this.handleOpenCartSelectionPopover()
    }
  }

  handleCloseTableLayout = () => {
    this.setState({ openTableLayoutModal: false })
  }

  handleActionTableLayout = table => {
    if (table !== undefined) {
      if (table.linked_cart === this.props.carts.selectedCart._id) {
        this.handleCloseTableLayout()
      } else {
        const cartIndex = this.props.carts.carts.findIndex(c => c._id === table.linked_cart)

        if (cartIndex >= 0) {
          this.selectOtherTable(table, cartIndex)
        } else {
          // Linked cart does not exist, need to create a new one and linked again
          this.createNewTable(table)
        }
      }
    } else {
      this.handleCloseTableLayout()
    }
  }

  selectOtherTable = (table, index) => {
    const cart = Object.assign({}, this.props.carts.carts[index])

    if (cart.extras) {
      cart.extras.deviceCartName = table.name
    } else {
      cart.extras = {
        deviceCartName: table.name,
        deviceCartNumber: index,
        appVersion: process.env.REACT_APP_VERSION
      }
    }

    if (this.props.settings.enableCustomerScreen) {
      const port = this.props.settings.customerScreenPort
      if (port && port.length) {
        ScreenUtils.changeCart(cart, port)
      }
    }

    this.props.selectCart(cart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
    this.handleCloseTableLayout()
  }

  changeToNewCart = cart => {
    this.props.selectCart(cart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
  }

  createNewTable = table => {
    this.props.loadingTrue()

    const cart = this.props.createCart(table.position)
    cart.extras.deviceCartName = table.name

    AppInstances.getCountrSdk().then(socket => {
      socket.ws.carts.create(cart).then(tableCart => {
        this.props.loadingFalse()
        this.changeToNewCart(tableCart)
        this.updateLinkedTable(tableCart._id, table)
      })
    })
  }

  updateLinkedTable = (id, table) => {
    const store = Object.assign({}, this.props.devices.store)
    let newTableIndex = 0

    const floorIndex = store.floorplans.findIndex(floor => {
      if (floor.tables) {
        newTableIndex = floor.tables.findIndex(
          t => t.name === table.name && t.position === table.position
        )

        return newTableIndex >= 0
      } else {
        return false
      }
    })

    if (floorIndex >= 0) {
      store.floorplans[floorIndex].tables[newTableIndex].linked_cart = id
      AppInstances.getCountrSdk().then(socket => {
        socket.ws.stores.update(store._id, store).then(s => {
          const device = Object.assign({}, this.props.devices.device)
          device.store = s
          this.props.addDevice(device)
          this.props.addStore(s)
          localStorage.setItem('CountrLite:Device', JSON.stringify(device))
        })
      })
    }
  }

  handleRenameCart = cart => {
    const title = this.props.settings.cartsTable ? 'change_table' : 'change_cart'
    const text = this.props.settings.cartsTable ? 'enter_tableid' : 'enter_orderid'
    this.setState({
      changePropertyModal: true,
      changePropertyTitle: title,
      changePropertyText: text,
      changePropertyHelpText: 'change_cart_tip',
      initialValue: cart.extras.deviceCartName
    })
  }

  handleCloseChangeProperty = () => {
    this.setState({ changePropertyModal: false })
  }

  handleConfirmChangeProperty = value => {
    this.setState({
      changePropertyModal: false,
      changePropertyTitle: '',
      changePropertyText: '',
      changePropertyHelpText: '',
      initialValue: ''
    })

    const cart = Object.assign({}, this.props.carts.selectedCart)
    cart.extras.deviceCartName = value
    this.props.editCart(cart)
    localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
    this.props.selectCart(cart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))

    AppInstances.getCountrSdk().then(socket => {
      socket.carts.update(cart._id, cart).then(
        updatedCart => {},
        error => {
          RequestQueue.enqueueAction({
            type: 'carts',
            action: 'update',
            payload: cart
          })
        }
      )
    })
  }

  handleOpenCartSelectionPopover = () => {
    this.setState({ openCartSelectionPopover: true })
  }

  handleCloseCartSelectionPopover = id => {
    this.setState({ openCartSelectionPopover: false })

    if (id === 'new_cart') {
      this.props.loadingTrue()

      const cart = this.props.createCart(this.props.carts.carts.length + 1)
      cart.extras.deviceCartName = this.props.settings.cartsTable
        ? `Table ${this.props.carts.carts.length + 1}`
        : `Cart ${this.props.carts.carts.length + 1}`

      AppInstances.getCountrSdk().then(socket => {
        socket.carts.create(cart).then(newCart => {
          this.props.loadingFalse()
          this.props.addCart(newCart)
          this.changeToNewCart(newCart)
        })
      })
    } else {
      if (id !== undefined && typeof id === 'string') {
        this.openDeleteCartConfirmation(id)
      }
    }
  }

  openDeleteCartConfirmation = cartId => {
    this.setState({
      openConfirmationModal: true,
      deleteCartSelection: cartId,
      confirmationModalType: DELETE_CART,
      confirmationModalTitle: 'delete_cart',
      confirmationModalText: 'delete_cart_confirmation'
    })
  }

  openDeleteAllEmptyCartsConfirmation = cartId => {
    this.setState({
      openConfirmationModal: true,
      confirmationModalType: DELETE_ALL_EMPTY_CART,
      confirmationModalTitle: 'delete_all_empty_cart',
      confirmationModalText: 'delete_all_empty_cart_confirmation'
    })
  }

  deleteCart = id => {
    if (id === this.props.carts.selectedCart._id) {
      const newSelectCart = this.props.carts.carts.find(c => c._id !== id)
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(newSelectCart))
      this.props.selectCart(newSelectCart)
    }

    this.props.deleteCart(id)
    localStorage.removeItem('CountrLite:Cart-' + id)

    AppInstances.getCountrSdk().then(socket => {
      socket.carts.delete(id).then(
        cartDeleted => {},
        error => {}
      )
    })

    this.handleCloseConfirmationModal()
  }

  deleteAllEmptyCarts = () => {
    const emptyCarts = this.props.carts.carts.filter(cart => cart.items.length === 0)

    // If all carts are empty, we should keep at least one cart in the list
    if (emptyCarts.length === this.props.carts.carts.length) {
      emptyCarts.pop()
    }

    this.props.setToastMessage('all_empty_carts_deleted', { cartsNumber: emptyCarts.length })
  }

  returnCartsName = cart => {
    const hasTables = StoreUtils.hasFloorplans(this.props.devices.store)
    const exchangeable = !!cart.extras ? cartUtils.isExchangeableCart(cart) : false
    const cartText = `${cartUtils.getCartName(cart)} (${cartUtils.calculateCartItemsNum(cart)})`

    return hasTables || exchangeable ? (
      <font className="btn-layout">{cartText}</font>
    ) : (
      <Button onClick={() => this.handleRenameCart(cart)}>{cartText}</Button>
    )
  }

  render() {
    return (
      <Fragment>
        <div className="cart-header toolbar">
          <IconButton
            onClick={() => this.handleOpenConfirmationModal(CLEAN)}
            testid="clean-cart-btn">
            <span
              className="icon-trash-bin"
              style={{
                color: (this.props.theme.colors || { toolbarText: '#000' }).toolbarText
              }}
            />
          </IconButton>
          <div className="cart-header-name">
            {this.returnCartsName(this.props.carts.selectedCart)}
            {this.props.settings.askBuzzerInfo && this.props.carts.selectedCart?.extras?.buzzer && (
              <div className="cart-buzzer-id">
                {`${translate('buzzer')}: ${this.props.carts.selectedCart.extras.buzzer}`}
              </div>
            )}
          </div>
          <IconButton id="cart-selector" onClick={this.handleOpenCartSelection}>
            <span
              className="icon-cart-sidebar"
              style={{
                color: (this.props.theme.colors || { toolbarText: '#000' }).toolbarText
              }}
            />
          </IconButton>
        </div>
        {this.state.openConfirmationModal && (
          <ConfirmationModal
            openConfirmation={this.state.openConfirmationModal}
            handleCloseConfirmation={this.handleCloseConfirmationModal}
            confirmBtn={this.handleConfirmConfirmationModal}
            confirmationTitle={<Text id={this.state.confirmationModalTitle} />}
            confirmationText={<Text id={this.state.confirmationModalText} />}
            type={this.state.confirmationModalTitle}
          />
        )}
        {this.state.changePropertyModal && (
          <ChangePropertyModal
            openChangeProperty={this.state.changePropertyModal}
            handleClose={this.handleCloseChangeProperty}
            handleConfirm={this.handleConfirmChangeProperty}
            changePropertyTitle={this.state.changePropertyTitle}
            changePropertyText={this.state.changePropertyText}
            changePropertyHelpText={this.state.changePropertyHelpText}
            initialValue={this.state.initialValue}
            errorCounterMin={2}
            errorCounterMax={30}
          />
        )}
        {this.state.openTableLayoutModal && (
          <TableLayout
            open={this.state.openTableLayoutModal}
            handleClose={this.handleCloseTableLayout}
          />
        )}
        {this.state.openCartSelectionPopover && (
          <CartSelectionPopover
            openCartSelectionPopover={this.state.openCartSelectionPopover}
            handleCloseCartSelectionPopover={this.handleCloseCartSelectionPopover}
          />
        )}
      </Fragment>
    )
  }
}

const CartHeaderConnected = connect(mapStateToProps, mapDispatchToProps)(CartHeader)
export default CartHeaderConnected
