import { AppInstances } from './countrSdkInstance'
import WorkerResourceLoader from './../WorkerResourceLoader'

export const CountrListeners = (function () {
  const CONST = {
    NOTIFICATION: 'notification',
    PRODUCT_CREATED: 'product.created',
    PRODUCT_UPDATED: 'product.updated',
    PRODUCT_DELETED: 'product.deleted',
    TAX_CREATED: 'tax.created',
    TAX_UPDATED: 'tax.updated',
    TAX_DELETED: 'tax.deleted',
    CATEGORY_CREATED: 'category.created',
    CATEGORY_UPDATED: 'category.updated',
    CATEGORY_DELETED: 'category.deleted',
    STORE_UPDATED: 'store.updated',
    DEVICE_UPDATED: 'device.updated',
    TRANSACTION_CREATED: 'transaction.created',
    TRANSACTION_UPDATED: 'transaction.updated',
    REFUND_CREATED: 'refund.created',
    CART_CREATED: 'cart.created',
    CART_UPDATED: 'cart.updated',
    EMPLOYEE_UPDATED: 'employee.updated',
    SHIFT_OPENED: 'shift.open',
    SHIFT_CLOSED: 'shift.close'
  }

  /**
   * Merchant listeners
   */
  async function merchantLister(countr, merchantId, callback) {
    countr.ws.on(`m${merchantId}:${CONST.NOTIFICATION}`, data => {
      if (data && data.message) {
        callback(data.message)
      }
    })
  }

  /**
   * Employee listeners
   */
  async function employeeListener(countr, merchantId, callback) {
    countr.ws.on(`m${merchantId}:${CONST.EMPLOYEE_UPDATED}`, data => {
      if (data) {
        callback(data)
      }
    })
  }

  /**
   * Device listeners
   */
  async function deviceListeners(countr, deviceId, callback) {
    countr.ws.on(`d${deviceId}:${CONST.NOTIFICATION}`, data => {
      console.log('Message on socket', data)
      if (data && data.message) {
        callback(data.message, CONST.NOTIFICATION)
      }
    })

    countr.ws.on(`d${deviceId}:${CONST.DEVICE_UPDATED}`, async s => {
      const device = await countr.devices.readOne.detailed(deviceId)
      callback(device, CONST.DEVICE_UPDATED)
    })
  }

  /**
   * Products listeners
   */
  async function initProductsListeners(countr, storeId, callback) {
    countr.ws.on(`s${storeId}:${CONST.PRODUCT_CREATED}`, async id => {
      const product = await countr.products.readOne(id)
      WorkerResourceLoader.searchByIdAndUpdateOrAdd(product, 'products', 'create')
        .then(result => {
          callback(product, CONST.PRODUCT_CREATED)
        })
        .catch(async e => {
          // Error handler
          errorHandler(countr, {
            msg: 'Error when trying to Create Product in IndexedDB',
            stack: e.stack
          })
        })
    })
    countr.ws.on(`s${storeId}:${CONST.PRODUCT_UPDATED}`, async id => {
      if (id) {
        const product = await countr.products.readOne(id)
        WorkerResourceLoader.searchByIdAndUpdateOrAdd(product, 'products', 'update')
          .then(result => {
            callback(product, CONST.PRODUCT_UPDATED)
          })
          .catch(async e => {
            // Error handler
            errorHandler(countr, {
              msg: 'Error when trying to Update Product in IndexedDB',
              stack: e.stack
            })
          })
      }
    })
    countr.ws.on(`s${storeId}:${CONST.PRODUCT_DELETED}`, id => {
      WorkerResourceLoader.searchByIdAndDelete([id], 'products')
        .then(result => {
          callback(id, CONST.PRODUCT_DELETED)
        })
        .catch(async e => {
          // Error handler
          errorHandler(countr, {
            msg: 'Error when trying to Delete Product in IndexedDB',
            stack: e.stack
          })
        })
    })
  }

  /**
   * Taxes listeners
   */
  async function initTaxesListeners(countr, storeId, callback) {
    countr.ws.on(`s${storeId}:${CONST.TAX_CREATED}`, async id => {
      const tax = await countr.taxes.readOne(id)
      callback(tax, CONST.TAX_CREATED)
    })
    countr.ws.on(`s${storeId}:${CONST.TAX_UPDATED}`, async id => {
      const tax = await countr.taxes.readOne(id)
      callback(tax, CONST.TAX_UPDATED)
    })
    countr.ws.on(`s${storeId}:${CONST.TAX_DELETED}`, id => {
      callback(id, CONST.TAX_DELETED)
    })
  }

  /**
   * Categories listeners
   */
  async function initCategoryListeners(countr, storeId, callback) {
    countr.ws.on(`s${storeId}:${CONST.CATEGORY_CREATED}`, async id => {
      const category = await countr.categories.readOne(id)
      WorkerResourceLoader.searchByIdAndUpdateOrAdd(category, 'categories', 'create')
        .then(result => {
          callback(category, CONST.CATEGORY_CREATED)
        })
        .catch(async e => {
          // Error handler
          errorHandler(countr, {
            msg: 'Error when trying to Create Category in IndexedDB',
            stack: e.stack
          })
        })
    })

    countr.ws.on(`s${storeId}:${CONST.CATEGORY_UPDATED}`, async id => {
      const category = await countr.categories.readOne(id)
      WorkerResourceLoader.searchByIdAndUpdateOrAdd(category, 'categories', 'update')
        .then(result => {
          callback(category, CONST.CATEGORY_UPDATED)
        })
        .catch(async e => {
          // Error handler
          errorHandler(countr, {
            msg: 'Error when trying to Update Category in IndexedDB',
            stack: e.stack
          })
        })
    })

    countr.ws.on(`s${storeId}:${CONST.CATEGORY_DELETED}`, id => {
      WorkerResourceLoader.searchByIdAndDelete([id], 'categories')
        .then(result => {
          callback(id, CONST.CATEGORY_DELETED)
        })
        .catch(async e => {
          // Error handler
          errorHandler(countr, {
            msg: 'Error when trying to Delete Category in IndexedDB',
            stack: e.stack
          })
        })
    })
  }

  /**
   * Store listeners
   */
  function storeListeners(countr, storeId, callback) {
    countr.ws.on(`s${storeId}:${CONST.STORE_UPDATED}`, async () => {
      const store = await countr.stores.readOne(storeId)
      callback(store)
    })

    countr.ws.on(`s${storeId}:${CONST.SHIFT_OPENED}`, async data => {
      console.log('+++++++SHIFT_OPENED++++')
      console.log(data)
      console.log('+++++++++++')
    })

    countr.ws.on(`s${storeId}:${CONST.SHIFT_CLOSED}`, async data => {
      console.log('+++++++SHIFT_CLOSED++++')
      console.log(data)
      console.log('+++++++++++')
    })
  }

  /**
   * Transactions/Refunds listeners
   */
  function transactionsListeners(countr, storeId, callback) {
    countr.ws.on(`s${storeId}:${CONST.TRANSACTION_CREATED}`, async t => {
      const id = t.transaction ? t.transaction : t
      const transaction = await countr.transactions.readOne(id)
      callback(transaction, CONST.TRANSACTION_CREATED)
    })

    countr.ws.on(`s${storeId}:${CONST.TRANSACTION_UPDATED}`, async t => {
      const id = t.transaction ? t.transaction : t
      const transaction = await countr.transactions.readOne(id)
      callback(transaction, CONST.TRANSACTION_UPDATED)
    })

    countr.ws.on(`s${storeId}:${CONST.REFUND_CREATED}`, async r => {
      const id = r.transaction ? r.transaction : r
      const refund = await countr.refunds.readOne(id)
      callback(refund, CONST.REFUND_CREATED)
    })
  }

  /**
   * Remove Transactions/Refunds listeners
   */
  function removeTransactionsListeners(countr, storeId, callback) {
    countr.ws.off(`s${storeId}:${CONST.TRANSACTION_CREATED}`)
    countr.ws.off(`s${storeId}:${CONST.TRANSACTION_UPDATED}`)
    countr.ws.off(`s${storeId}:${CONST.REFUND_CREATED}`)
  }

  /**
   * Carts listeners
   */
  function cartListeners(countr, storeId, callback) {
    countr.ws.on(`s${storeId}:${CONST.CART_CREATED}`, async message => {
      const cart = await countr.carts.readOne(message.cart)
      callback(cart, CONST.CART_CREATED)
    })

    countr.ws.on(`s${storeId}:${CONST.CART_UPDATED}`, async message => {
      const cart = await countr.carts.readOne(message.cart)

      if (!!message.originator) {
        cart.originator = message.originator
      }

      callback(cart, CONST.CART_UPDATED)
    })
  }

  /**
   * General listener error handler
   */
  function errorHandler(countr, obj) {
    const errorObj = {
      source: process.env.REACT_APP_ERROR_SOURCE,
      message: obj.msg,
      // user: this.props.user.user._id,
      // store: this.props.devices.store._id,
      // device: this.props.devices.device._id,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    AppInstances.logError(errorObj)
  }

  return {
    getConstants() {
      return CONST
    },

    async initEmployeeListeners(merchantId, callback) {
      const countr = await AppInstances.getCountrSdk()
      employeeListener(countr, merchantId, callback)
    },

    async initMerchantListeners(merchantId, callback) {
      const countr = await AppInstances.getCountrSdk()
      merchantLister(countr, merchantId, callback)
    },

    async initProdListeners(storeId, callbackProduct, callbackTax) {
      const countr = await AppInstances.getCountrSdk()
      initProductsListeners(countr, storeId, callbackProduct)
      initTaxesListeners(countr, storeId, callbackTax)
    },

    async initCategoriesListener(storeId, callback) {
      const countr = await AppInstances.getCountrSdk()
      initCategoryListeners(countr, storeId, callback)
    },

    async initStoreListeners(storeId, callback) {
      const countr = await AppInstances.getCountrSdk()
      storeListeners(countr, storeId, callback)
    },

    async initDeviceListeners(deviceId, callback) {
      const countr = await AppInstances.getCountrSdk()
      deviceListeners(countr, deviceId, callback)
    },

    async initTransactionsListeners(storeId, callback) {
      const countr = await AppInstances.getCountrSdk()
      transactionsListeners(countr, storeId, callback)
    },

    async removeStoreTransactionListeners(storeId, callback) {
      const countr = await AppInstances.getCountrSdk()
      removeTransactionsListeners(countr, storeId, callback)
    },

    async initCartListeners(storeId, callback) {
      const countr = await AppInstances.getCountrSdk()
      cartListeners(countr, storeId, callback)
    }
  }
})()
