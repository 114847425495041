import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from '../../utils/countrSdkInstance'
import { PrinterUtils } from '../../utils/PrinterUtils'
import Util from '../../utils/Util'
import StoreUtils from '../../utils/StoreUtils'
import DesktopUtils from '../../utils/DesktopUtils'

import { addTransactionHead, addRefundHead } from '../../store/actions/transactions'
import { setToastMessage } from '../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Button } from '@countr/ui'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { Text, translate } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'
import { cartUtils } from '../../utils/cartUtils'

import Refund from './Refund'
import Receipt from './Receipt'
import Exchange from './Exchange'
import TipModal from './TipModal'
import TransactionListItem from './TransactionListItem'
import TransactionListHeader from './TransactionListHeader'
import TransactionListSendEmail from './TransactionListSendEmail'
import ManualSyncTransactionsModal from './ManualSyncTransactionsModal'
import PayLater from './PayLater'
import PrintButton from '../generic/PrintButton'
import CardInfoRequestModal from './CardInfoRequestModal'

import './Transaction.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user,
    transactions: state.transactions,
    devices: state.devices,
    settings: state.settings,
    queue: state.queue,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction)),
    addRefundHead: refund => dispatch(addRefundHead(refund)),
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt))
  }
}

class Transaction extends PureComponent {
  state = {
    maxTransactionsNum: 5,
    searchedTransaction: '',
    transactionsList: [],
    filteredList: [],
    isTransactionSelected: false,
    selectedTransaction: {},
    anchorEl: null,
    openRefundDialog: false,
    openManualSyncTransactionsModal: false,
    openExchangeDialog: false,
    openPayLaterOptions: false,
    openTipModal: false,
    openCardInfoRequestModal: false
  }
  style = {
    color: this.props.app.theme.colors.text,
    backgroundColor: this.props.app.theme.colors.body
  }

  selectTransaction = transaction => {
    this.setState({ isTransactionSelected: true, selectedTransaction: transaction })
  }

  backToTransactionsList = () => {
    this.setState({
      searchedTransaction: '',
      filteredList: [],
      isTransactionSelected: false,
      selectedTransaction: {}
    })
  }

  loadMoreTransactions = () => {
    this.setState({ maxTransactionsNum: this.state.maxTransactionsNum + 5 })
  }

  handleOpenSendEmail = event => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleCloseSendEmail = () => {
    this.setState({
      anchorEl: null
    })
  }

  sendReceiptEmail = async email => {
    const transaction = this.state.selectedTransaction
    const countr = await AppInstances.getCountrSdk()
    const callback = transaction.__t === 'Transaction' ? countr.transactions : countr.refunds
    const body = JSON.stringify({
      type: 'email',
      mailing_list_permission: true,
      value: email
    })

    callback.readOne.send(transaction._id, body).then(() => {
      this.props.setToastMessage('email_successfully_sent')
      this.handleUpdateTransactionIssued(transaction, email)
    })
    this.handleCloseSendEmail()
  }

  handleUpdateTransactionIssued = (transaction, email = null) => {
    const e = new CustomEvent('print-receipt', {
      detail: {
        id: transaction.receipt_id,
        email
      }
    })
    window.dispatchEvent(e)
  }

  checkFiscalPrint = () => {
    const { store, device } = this.props.devices
    const { selectedTransaction } = this.state

    if (StoreUtils.isFiscalStore(store, device)) {
      return (
        !!selectedTransaction.fiscal_info && Object.keys(selectedTransaction.fiscal_info).length > 0
      )
    }

    return true
  }

  checkPrintReceipt = () => {
    if (!this.props.settings.printCardInfo) {
      this.setState({ openCardInfoRequestModal: true })
    } else {
      this.printReceipt(this.props.settings.printCardInfo)
    }
  }

  printReceipt = printCardInfo => {
    const transaction = JSON.parse(JSON.stringify(this.state.selectedTransaction))
    const userDefaultReceipt = this.props.user.user.receipt
    const store = this.props.devices.store
    const device = this.props.devices.device
    const currency = this.props.devices.store.currency.code
    const callbacks = {
      setToastMessage: this.props.setToastMessage
    }
    const printEAN = this.props.settings.showEANSKUReceipt
    const printBrand = this.props.settings.showBrandReceipt
    const printQR = this.props.settings.printQR
    const printDescription = this.props.settings.printDescription
    const printTickets = this.props.settings.printTickets
    const qrCodeForLineItems = this.props.settings.qrCodeForLineItems

    if (StoreUtils.isFiscalStore(store, device) && !transaction.fiscal_info) {
      this.props.setToastMessage('fiscal_print_error')
      return
    }

    PrinterUtils.receipt(
      transaction,
      userDefaultReceipt,
      store,
      currency,
      callbacks,
      printEAN,
      printBrand,
      printQR,
      printTickets,
      printDescription,
      false,
      qrCodeForLineItems,
      printCardInfo
    )
  }

  handleWithoutCardInfo = () => {
    this.setState({ openCardInfoRequestModal: false })
    this.printReceipt(false)
  }

  handleWithCardInfo = () => {
    this.setState({ openCardInfoRequestModal: false })
    this.printReceipt(true)
  }

  handleCloseCardInfoRequestModal = () => {
    this.setState({ openCardInfoRequestModal: false })
  }

  transactionAlreadySynced = receipt_id => {
    return this.state.transactionsList.findIndex(t => t.receipt_id === receipt_id) >= 0
      ? true
      : false
  }

  checkBackgroundTransactions = () => {
    const numBackgroundTransactions = []

    for (const key in localStorage) {
      if (
        key.indexOf('CountrWeb:LastTransactionInProgress-') >= 0 &&
        !this.transactionAlreadySynced(key.split('CountrWeb:LastTransactionInProgress-')[1])
      ) {
        numBackgroundTransactions.push(key.split('CountrWeb:LastTransactionInProgress-')[1])
      }
    }

    if (numBackgroundTransactions.length) {
      const transactions = numBackgroundTransactions.join(', ')
      return (
        <div>
          <Tooltip title="Click here to manual sync those transactions" placement="top">
            <ListItem
              button
              className="sync-transactions"
              onClick={this.handleOpenManualSyncTransactions}>
              <span className="icon-cash" />
              <ListItemText
                primary={translate('syncing_transactions')}
                secondary={`# ${translate('transactions')}: ${
                  numBackgroundTransactions.length
                } (${transactions})`}
              />
            </ListItem>
          </Tooltip>
          <LinearProgress color="primary" />
        </div>
      )
    }
  }

  checkBackgroundRefunds = () => {
    if (this.props.queue.queueList && this.props.queue.queueList.length) {
      const refList = this.props.queue.queueList.filter(action => action.type === 'refunds')

      if (refList && refList.length) {
        const ids = refList.map(ref => ref.id).join(', ')
        return (
          <div>
            <ListItem className="refund-item">
              <span className="icon-cash-refund" />
              <ListItemText
                primary={translate('syncing_refunds')}
                secondary={`# ${translate('refunds')}: ${refList.length} (${ids})`}
              />
            </ListItem>
            <LinearProgress color="secondary" />
          </div>
        )
      }
    }
  }

  handleOpenManualSyncTransactions = () => {
    this.setState({ openManualSyncTransactionsModal: true })
  }

  handleCloseManualSyncTransactions = forceRefresh => {
    if (forceRefresh) {
      this.mergeTransactionsWithRefunds()
    }

    this.setState({ openManualSyncTransactionsModal: false })
  }

  handleOpenRefundDialog = () => {
    this.setState({ openRefundDialog: true })
  }

  handleCloseRefundDialog = refund => {
    this.setState({ openRefundDialog: false })

    // If refunds cancelled, it not returns the refund obj, but it returns a event obj
    if (!!refund._id) {
      this.setState({
        transactionsList: [refund].concat(this.state.transactionsList)
      })
    }

    this.mergeTransactionsWithRefunds()

    this.backToTransactionsList()
  }

  findRefundValueOfTransaction = transaction => {
    let totalRefunded = 0

    if (transaction.refunds !== undefined && transaction.refunds.length > 0) {
      transaction.refunds.forEach(refund => {
        const ref = this.props.transactions.refunds.find(r => r._id === refund)
        if (ref) {
          totalRefunded += parseFloat(ref.paid)
        }
      })
    }

    return totalRefunded.toFixed(2)
  }

  searchTransaction = list => {
    this.setState({ transactionsList: list })
  }

  possibleToRefund = () => {
    let possible = true

    if (
      this.props.settings.adminOnlyRefunds &&
      this.props.employees.selectedEmployee &&
      this.props.employees.selectedEmployee.rights &&
      this.props.employees.selectedEmployee.rights !== 'admin'
    ) {
      return false
    }

    if (
      this.props.settings.adminOnlyRefunds &&
      (!this.props.employees.selectedEmployee || !this.props.employees.selectedEmployee.rights)
    ) {
      return false
    }

    if (this.props.queue.queueList && this.props.queue.queueList.length) {
      const refList = this.props.queue.queueList.filter(action => action.type === 'refunds')

      if (refList && refList.length) {
        possible =
          refList.findIndex(
            r => r.payload.transaction_source === this.state.selectedTransaction._id
          ) >= 0
            ? false
            : true
      }
    }

    return (
      this.state.isTransactionSelected &&
      this.state.selectedTransaction.__t === 'Transaction' &&
      Math.abs(this.findRefundValueOfTransaction(this.state.selectedTransaction)) <
        this.state.selectedTransaction.total &&
      possible
    )
  }

  possibleToExchange = () => {
    return (
      navigator.onLine &&
      this.state.isTransactionSelected &&
      this.state.selectedTransaction.__t === 'Transaction' &&
      this.props.settings.allowProductExchanges &&
      !(
        this.state.selectedTransaction.exchanges.length > 0 ||
        this.state.selectedTransaction.refunds.length
      )
    )
  }

  pendingPayment = () => {
    const { isTransactionSelected, selectedTransaction } = this.state
    return (
      this.state.selectedTransaction.total >
        Math.abs(this.findRefundValueOfTransaction(this.state.selectedTransaction)) &&
      isTransactionSelected &&
      selectedTransaction.payments.findIndex(payment => payment.postponed) >= 0
    )
  }

  handleOpenPayLaterOptions = () => {
    // Blocking pin methods if has no internet connection
    if (!Util.isInternetOnline()) {
      this.props.setToastMessage('no_connection_payment')
      return
    }

    this.setState({ openPayLaterOptions: true })
  }

  handleCloseopenPayLaterOptions = () => {
    this.setState({ openPayLaterOptions: false })
  }

  handleOpenExchangeDialog = () => {
    this.setState({ openExchangeDialog: true })
  }

  handleCloseExchangeDialog = exchangeResult => {
    this.setState({ openExchangeDialog: false })
    if (!!exchangeResult && exchangeResult === 'CLOSE_TRANSACTION_MODAL') {
      this.props.handleCloseTransaction(exchangeResult)
    }
  }

  mergeTransactionsWithRefunds = () => {
    // Mergesort with transactions and refunds
    const list = []
    let i = 0
    let j = 0
    const t = this.props.transactions.transactions
    const f = this.props.transactions.refunds

    // if there is no refunds, just return the transactions
    if (f.length === 0) {
      this.setState({ transactionsList: t })
    } else {
      while (i < t.length && j < f.length) {
        if (t[i].created_at > f[j].created_at) {
          list.push(t[i])
          i++
        } else {
          list.push(f[j])
          j++
        }
      }

      this.setState({ transactionsList: list })
    }
  }

  handleAddTip = () => {
    this.setState({ openTipModal: true })
  }

  handlePrintTicket = () => {
    const transaction = this.state.selectedTransaction
    if (this.props.settings.printTicketsOnReceiptPrinter) {
      const receipt = {
        header: [],
        footer: [],
        tickets: transaction.extras.tickets
      }

      let userReceipt = this.props.devices.store.receipt
      if (!userReceipt) {
        userReceipt = this.props.user.user?.receipt ?? cartUtils.getDefaultReceipt()
      }

      receipt.header = userReceipt?.header ?? []

      userReceipt.footer.forEach(foot => {
        if (foot.type !== 'image') {
          receipt.footer.push(foot)
        }
      })
      PrinterUtils.sendToPrinter('print-ticket-receipt', receipt)
    } else {
      PrinterUtils.sendToPrinter('print-ticket', transaction.extras.tickets)
    }
  }

  handleCloseTipModal = () => {
    this.setState({ openTipModal: false })
  }

  componentDidMount = () => {
    this.mergeTransactionsWithRefunds()
  }

  render() {
    return (
      <Dialog
        open={this.props.openTransaction}
        onClose={this.props.handleCloseTransaction}
        aria-labelledby="form-dialog-title"
        fullScreen={isMobileOnly}>
        <DialogTitle id="form-dialog-title" style={this.style}>
          <div className="transaction-title">
            <TransactionListHeader
              isTransactionSelected={this.state.isTransactionSelected}
              selectedTransaction={this.state.selectedTransaction}
              searchTransaction={this.searchTransaction}
              backToTransactionsList={this.backToTransactionsList}
            />

            {this.state.isTransactionSelected && this.checkFiscalPrint() && (
              <div className="transaction-btn-action">
                <IconButton className="transaction-email" onClick={this.handleOpenSendEmail}>
                  <span className="icon-email-receipt" style={this.style} />
                </IconButton>
                <TransactionListSendEmail
                  anchorEl={this.state.anchorEl}
                  handleCloseSendEmail={this.handleCloseSendEmail}
                  sendReceiptEmail={this.sendReceiptEmail}
                  initCustomer={
                    this.state.selectedTransaction.customer &&
                    this.state.selectedTransaction.customer.email
                      ? this.state.selectedTransaction.customer.email
                      : ''
                  }
                />
                {this.props.app.isDesktop && this.state.selectedTransaction.extras.tickets && (
                  <IconButton className="ticket-print" onClick={this.handlePrintTicket}>
                    <span className="icon-ticket" style={this.style} />
                  </IconButton>
                )}
                <PrintButton
                  transaction={this.state.selectedTransaction}
                  externalPrinterAvailable={DesktopUtils.isExternalPrintersAvailable()}
                  isDesktop={this.props.app.isDesktop}
                  a4Print={this.props.settings.a4Print}
                  a4ContentRef={this.printComponent}
                  printReceipt={this.checkPrintReceipt}
                  handleUpdateTransaction={() => {}}
                />
              </div>
            )}
          </div>
        </DialogTitle>
        <DialogContent style={this.style}>
          <Grid
            container
            alignItems="center"
            justifyContent={!isMobileOnly ? 'center' : 'space-evenly'}
            className="center"
            ref={el => (this.printComponent = el)}>
            {/* Title change between select transaction and last transactions */}
            <Grid item xs={12} className="last-transaction-result">
              <InputLabel style={this.style}>
                {!this.state.isTransactionSelected && this.state.transactionsList.length === 0 && (
                  <Text id="transaction_not_found" />
                )}
                {!this.state.isTransactionSelected && this.state.transactionsList.length > 0 && (
                  <Text id="last_transaction_result" />
                )}
              </InputLabel>
            </Grid>
            {/* List of last 5 transactions */}
            {!this.state.isTransactionSelected && (
              <div>
                <Grid item xs={12}>
                  {this.checkBackgroundTransactions()}
                  {this.checkBackgroundRefunds()}
                  <List component="nav" style={{ minWidth: isMobileOnly ? 300 : 400 }}>
                    {this.state.transactionsList.map(
                      (transaction, index) =>
                        index < this.state.maxTransactionsNum && (
                          <TransactionListItem
                            key={transaction._id}
                            transaction={transaction}
                            selectTransaction={this.selectTransaction}
                            symbol={this.props.devices.store.currency.symbol}
                            refunds={this.props.transactions.refunds}
                          />
                        )
                    )}
                  </List>
                </Grid>
                {this.state.maxTransactionsNum <= this.state.transactionsList.length && (
                  <Grid item xs={12}>
                    <Button
                      label={<Text id="load_more_transactions" />}
                      className="neutro"
                      onClick={this.loadMoreTransactions}
                    />
                  </Grid>
                )}
              </div>
            )}
            {this.state.isTransactionSelected && (
              <Receipt transaction={this.state.selectedTransaction} transactionComplete={false} />
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={this.style}>
          {this.props.settings.askTips && this.state.isTransactionSelected && (
            <div>
              <Button
                label={<Text id="add_tip" />}
                onClick={this.handleAddTip}
                className="primary"
              />
            </div>
          )}
          <div style={{ flex: '1 0 0' }} />
          <Button
            label={<Text id="close" />}
            className="secondary"
            onClick={this.props.handleCloseTransaction}
          />
          {this.possibleToRefund() && this.props.settings.allowProductRefunds && (
            <Button
              label={<Text id="refund" />}
              className="primary"
              onClick={this.handleOpenRefundDialog}
            />
          )}
          {this.possibleToExchange() && (
            <Button
              label={<Text id="exchange" />}
              className="primary"
              onClick={this.handleOpenExchangeDialog}
            />
          )}
          {this.pendingPayment() && (
            <Button
              label={<Text id="pay" />}
              className="primary"
              onClick={this.handleOpenPayLaterOptions}
            />
          )}
        </DialogActions>
        {this.state.openRefundDialog && (
          <Refund
            openRefundDialog={this.state.openRefundDialog}
            handleClose={this.handleCloseRefundDialog}
            transaction={this.state.selectedTransaction}
          />
        )}
        {this.state.openExchangeDialog && (
          <Exchange
            openExchangeDialog={this.state.openExchangeDialog}
            handleClose={this.handleCloseExchangeDialog}
            transaction={this.state.selectedTransaction}
          />
        )}
        {this.state.openManualSyncTransactionsModal && (
          <ManualSyncTransactionsModal
            open={this.state.openManualSyncTransactionsModal}
            handleClose={this.handleCloseManualSyncTransactions}
            transactions={this.props.transactions.transactions}
            addTransactionHead={this.props.addTransactionHead}
          />
        )}
        {this.state.openPayLaterOptions && (
          <PayLater
            open={this.state.openPayLaterOptions}
            handleClose={this.handleCloseopenPayLaterOptions}
            transaction={this.state.selectedTransaction}
          />
        )}
        {this.state.openTipModal && (
          <TipModal
            open={this.state.openTipModal}
            handleClose={this.handleCloseTipModal}
            handleUpdateTransaction={this.selectTransaction}
            currency={this.props.devices.store.currency.symbol}
            transaction={this.state.selectedTransaction}
          />
        )}
        {this.state.openCardInfoRequestModal && (
          <CardInfoRequestModal
            openCardInfoRequestModal={this.state.openCardInfoRequestModal}
            handleWithoutCardInfo={this.handleWithoutCardInfo}
            handleWithCardInfo={this.handleWithCardInfo}
            handleClose={this.handleCloseCardInfoRequestModal}
          />
        )}
      </Dialog>
    )
  }
}

const TransactionConnected = connect(mapStateToProps, mapDispatchToProps)(Transaction)
export default TransactionConnected
