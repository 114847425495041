import {
  ADD_QUEUE,
  REMOVE_QUEUE,
  REMOVE_ACTION_QUEUE,
  REMOVE_DUPLICATE
} from './../actions/actionTypes'

const QUEUE_STORAGE_TAG = 'CountrWeb:QueueList'

const initialState = {
  queueList: []
}

const QueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUEUE:
      const list = state.queueList.concat(action.payload)
      localStorage.setItem(QUEUE_STORAGE_TAG, JSON.stringify(list))
      return {
        ...state,
        queueList: list
      }
    case REMOVE_QUEUE:
      const newQueue = Object.assign([], state.queueList).shift()
      return {
        ...state,
        queueList: newQueue ? newQueue : []
      }
    case REMOVE_ACTION_QUEUE:
      const removedList = state.queueList.filter(item => item.id !== action.payload)
      localStorage.setItem(QUEUE_STORAGE_TAG, JSON.stringify(removedList))
      return {
        ...state,
        queueList: removedList
      }
    case REMOVE_DUPLICATE:
      const uniqueQueue = state.queueList.filter(
        item => !item.payload._id || item.payload._id !== action.payload
      )
      return {
        ...state,
        queueList: uniqueQueue
      }
    default:
      return state
  }
}

export default QueueReducer
