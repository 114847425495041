import { ADD_TAX, ADD_ALL_TAXES, UPDATE_TAX, DELETE_TAX } from './actionTypes'

export const addTax = tax => ({
  type: ADD_TAX,
  payload: tax
})

export const addAllTax = taxes => ({
  type: ADD_ALL_TAXES,
  payload: taxes
})

export const updateTax = tax => ({
  type: UPDATE_TAX,
  payload: tax
})

export const deleteTax = id => ({
  type: DELETE_TAX,
  payload: id
})
