export const handleItemWithouCategoryName = transaction => {
  transaction.items.forEach(item => {
    item.product.categories = item.product.categories.map(category => ({
      ...category,
      name: !!category.name ? category.name : 'Uncategorized'
    }))
  })

  return transaction
}
