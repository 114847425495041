import {
  ADD_TRANSACTION,
  ADD_ALL_TRANSACTIONS,
  UPDATE_TRANSACTION,
  ADD_REFUND,
  ADD_ALL_REFUNDS,
  ADD_TRANSACTION_HEAD,
  ADD_REFUND_HEAD,
  EDIT_TRANSACTION,
  SET_REFUND_EXTRAS
} from './actionTypes'

export const addTransaction = transaction => ({
  type: ADD_TRANSACTION,
  payload: transaction
})

export const addAllTransactions = transactions => ({
  type: ADD_ALL_TRANSACTIONS,
  payload: transactions
})

export const updateTransaction = transaction => ({
  type: UPDATE_TRANSACTION,
  payload: transaction
})

export const addRefund = refunds => ({
  type: ADD_REFUND,
  payload: refunds
})

export const addAllRefunds = refunds => ({
  type: ADD_ALL_REFUNDS,
  payload: refunds
})

export const addTransactionHead = transaction => ({
  type: ADD_TRANSACTION_HEAD,
  payload: transaction
})

export const addRefundHead = refund => ({
  type: ADD_REFUND_HEAD,
  payload: refund
})

export const editTransaction = transaction => ({
  type: EDIT_TRANSACTION,
  payload: transaction
})

export const setRefundExtras = extras => ({
  type: SET_REFUND_EXTRAS,
  payload: extras
})
