import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useTheme, GlobalStyles } from '@countr/ui'
import WebFont from 'webfontloader'
import { setTheme } from './store/actions/app'
import themes from './themes/default.json'
import App from './App'

function AppWrapper() {
  const dispatch = useDispatch()
  const { theme, getFonts, setMode } = useTheme(themes.data)
  const currentTheme = useSelector(state => state.app.theme)
  const setDefaultTheme = useCallback(t => dispatch(setTheme(t)), [dispatch])

  useEffect(() => {
    WebFont.load({
      google: {
        families: getFonts()
      }
    })
  })

  useEffect(() => {
    if (!!currentTheme && !!currentTheme._id) {
      setMode(currentTheme)
    } else {
      setDefaultTheme(theme)
    }
  }, [setMode, currentTheme])

  return (
    <ThemeProvider theme={theme} style={{ fontFamily: theme.font }}>
      <GlobalStyles theme={theme} />
      <App />
    </ThemeProvider>
  )
}

export default AppWrapper
