import { memo, useState } from 'react';
import { useSelector } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import KeyboardComponent from '../../generic/KeyboardComponent'
import { Button } from '@countr/ui'

import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'

import './CustomProductModal.scss'

const CustomPriceModal = memo(props => {
  const [price, setPrice] = useState('0.00')
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }

  const handleClose = () => {
    props.handleCloseCustomPrice()
  }

  const handleConfirm = () => {
    if (checkErrorValue()) {
      return
    }

    props.handleConfirmCustomPrice(parseFloat(price))
    handleClose()
  }

  const handleChangePrice = value => {
    if (value === 'C') {
      setPrice('0.00')
    } else {
      const newValue = price + value
      const before = newValue.substr(0, newValue.indexOf('.'))
      const after = newValue.substr(newValue.indexOf('.') + 1)
      const num = before + after[0] + '.' + after.slice(1)
      setPrice(parseFloat(num).toFixed(2).toString())
    }
  }

  const checkErrorValue = () => {
    return parseFloat(price) < 0
  }

  return (
    <Dialog open={props.openCustomPrice} onClose={handleClose} fullScreen={isMobileOnly}>
      <DialogTitle style={style}>
        <Text id="custom_price" />
      </DialogTitle>
      <DialogContent style={style}>
        <div className="custom-product">
          <span>
            {props.store.currency.symbol} {price}
          </span>
        </div>
        <KeyboardComponent handleChange={handleChangePrice} />
      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        <Button
          className="primary"
          label={<Text id="add_to_cart" />}
          onClick={handleConfirm}
          disabled={checkErrorValue()}
        />
      </DialogActions>
    </Dialog>
  )
})

export default CustomPriceModal
