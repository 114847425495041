import { memo } from 'react';
import { IconButton } from '@material-ui/core'
import './CountButtonComponent.scss'

const CountButtonComponent = memo(({ value, handleChange }) => {
  return (
    <div className="count-btn-component">
      <IconButton
        className="count-btn-component-btn"
        onClick={() => {
          if (value > 0) {
            handleChange(value - 1)
          }
        }}>
        <span className="icon-minus" />
      </IconButton>
      <span className="count-btn-component-label">
        <strong>{value}</strong>
      </span>
      <IconButton className="count-btn-component-btn" onClick={() => handleChange(value + 1)}>
        <span className="icon-plus" />
      </IconButton>
    </div>
  )
})

export default CountButtonComponent
