/* eslint-disable no-unreachable */
import { memo, Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTracking } from 'react-tracking'
import CustomerModal from './CustomerModal'
import RepsWasteModal from './RepsWasteModal'
import CartToolbarButton from './CartToolbarButton'
import CustomerInfo from './CustomerInfo'
import CoversTakeawayModal from '../Optional/CoversTakeawayModal'
import AskBuzzerInfoModal from '../Optional/AskBuzzerInfoModal'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { cartUtils } from '../../../utils/cartUtils'
import { PrinterUtils } from '../../../utils/PrinterUtils'
import Util from '../../../utils/Util'

import { selectCart, editCart, setTableSeat } from '../../../store/actions/carts'
import { setToastMessage } from '../../../store/actions/app'
import { enableDepositProductsView } from '../../../store/actions/products'
import { TRACKER_EVENTS } from '../../../utils/trackerEvents'

import './CartToolbar.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user.user,
    devices: state.devices,
    settings: state.settings,
    selectedCart: state.carts.selectedCart,
    showDepositProducts: state.products.showDepositProducts,
    carts: state.carts.carts,
    seatNo: state.carts.seatNo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt)),
    enableDepositProductsView: () => dispatch(enableDepositProductsView()),
    setTableSeat: seat => dispatch(setTableSeat(seat))
  }
}

const CartToolbar = memo(props => {
  const { trackEvent } = useTracking()
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false)
  const [hasCustomer, setHasCustomer] = useState(false)
  const [openRepsWasteModal, setOpenRepsWasteModal] = useState(false)
  const [openCoversTakeaway, setOpenCoversTakeaway] = useState(false)
  const [openBuzzerInfoRequest, setOpenBuzzerInfoRequest] = useState(false)

  const handleOpenCustomerDialog = () => {
    setOpenCustomerDialog(true)
  }

  const handleCloseCustomerDialog = () => {
    const customer = props.selectedCart.customer
    setOpenCustomerDialog(false)

    if (customer?._id) {
      setHasCustomer(!!customer)

      const event = {
        event: TRACKER_EVENTS.ADD_CUSTOMER,
        data: { _id: customer._id, name: `${customer.first_name} ${customer.last_name}` },
        merchant: customer.merchant || ''
      }

      trackEvent(event)
    }
  }

  const handleOpenRepsWasteModal = () => {
    if (props.selectedCart.items.length === 0) {
      props.setToastMessage('cart_is_empty')
    } else {
      setOpenRepsWasteModal(true)
    }
  }

  const handleCloseRepsWasteModal = () => {
    setOpenRepsWasteModal(false)
  }

  const handleShowDepositProducts = () => {
    props.enableDepositProductsView()
  }

  const handleShowCoversTakeaway = () => {
    setOpenCoversTakeaway(true)
  }
  const handleShowBuzzerInfoRequest = () => {
    setOpenBuzzerInfoRequest(true)
  }

  const handleChangeCoverTakeaway = covers => {
    if (!!props?.devices?.device?.store?.options?.useSeats) {
      updateCart(props.selectedCart)
      props.setTableSeat(1)
    }
    // setOpenCoversTakeaway(false)
  }

  const handleEnableDropShip = () => {
    const cart = JSON.parse(JSON.stringify(props.selectedCart))

    if (!!cart.extras.drop_ship) {
      delete cart.extras.drop_ship
    } else {
      cart.extras.drop_ship = true
    }

    updateCart(cart)
  }

  const handleBuzzerInfoClose = value => {
    const cart = JSON.parse(JSON.stringify(props.selectedCart))

    if (!!value) {
      cart.extras.buzzer = value
    } else {
      cart.extras.buzzer = null
    }
    updateCart(cart)
    setOpenBuzzerInfoRequest(false)
  }

  const updateCart = async cart => {
    props.selectCart(cart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
    props.editCart(cart)
    localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
    const countr = await AppInstances.getCountrSdk()

    countr.carts.update(cart._id, cart)
    setHasCustomer(checkCartHasCustomer())
  }

  const printOrder = () => {
    const { splitOrderItems, splitOrderAmount } = props.settings
    const { kdsAttached } = props.devices.store.options

    const cart = JSON.parse(JSON.stringify(props.selectedCart))

    if (!cart.items.length) {
      props.setToastMessage('cart_is_empty')
    } else {
      if (props.settings.askBuzzerInfo && !cart.extras.buzzer) {
        // if buzzer setting enabled and no buzzer set, prompt to set one
        setOpenBuzzerInfoRequest(true)
      } else {
        const callbacks = {
          editCart: props.editCart,
          selectCart: props.selectCart,
          setToastMessage: props.setToastMessage
        }

        PrinterUtils.order(cart, callbacks, false, splitOrderItems, splitOrderAmount, kdsAttached)
      }
    }
  }

  const printBill = virtualCart => {
    let cart = JSON.parse(JSON.stringify(props.selectedCart))

    if (virtualCart && virtualCart._id) {
      cart = virtualCart
    }

    if (!cart.items.length) {
      props.setToastMessage('cart_is_empty')
    } else {
      const callbacks = {
        setToastMessage: props.setToastMessage
      }
      const userDefaultReceipt = props.user.receipt
      const currency = props.devices.store.currency.code
      const store = props.devices.store
      const printEAN = props.settings.showEANSKUReceipt
      const printBrand = props.settings.showBrandReceipt
      const printQR = props.settings.printQR
      const printDescription = props.settings.printDescription
      const printTickets = props.settings.printTickets
      const qrCodeForLineItems = props.settings.qrCodeForLineItems

      if (cart.__t === 'Cart') {
        PrinterUtils.bill(
          cart,
          userDefaultReceipt,
          store,
          currency,
          callbacks,
          printEAN,
          printBrand
        )
      } else {
        PrinterUtils.receipt(
          cart,
          userDefaultReceipt,
          store,
          currency,
          callbacks,
          printEAN,
          printBrand,
          printQR,
          printTickets,
          printDescription,
          false,
          qrCodeForLineItems,
          props.settings.printCardInfo
        )
      }
    }
  }

  const checkCartHasCustomer = () => {
    const customer = props.selectedCart.customer
    return !!(customer && Object.keys(customer).length > 0)
  }

  const printBillByEvent = (data, type = 'carts') => {
    AppInstances.getCountrSdk().then(socket => {
      socket[type]
        .readOne(data.cart)
        .then(checkout => {
          const parsedCheckout = checkout
          if (data.status) {
            const itemsFormattedPerStatus = checkout.items.filter(item => {
              const parsedProductId = data.body.item.split(':')[0]

              const statusExist = item.status.filter(st => st.state === data.status)
              if (
                statusExist.length &&
                (item.product._id === parsedProductId || data.body.item === 'all')
              ) {
                return item
              }
            })

            checkout.items = itemsFormattedPerStatus

            printBill(parsedCheckout)
          } else {
            printBill(checkout)
          }
        })
        .catch(e => {
          const errorObj = {
            source: Util.returnPOSType(),
            message: `Failed to print via KDS. Checkout ID: ${data.cart} not found.`,
            user: props.user._id,
            store: props.devices.store._id,
            device: props.devices.device._id,
            date: new Date()
          }
          AppInstances.logError(errorObj)
        })
    })
  }

  const initDeviceListener = () => {
    AppInstances.getCountrSdk().then(socket => {
      socket.ws.on(`d${props.devices.device._id}:transaction.printed`, data => {
        printBillByEvent(data, 'transactions')
      })
      socket.ws.on(`d${props.devices.device._id}:cart.printed`, data => {
        printBillByEvent(data, 'carts')
      })
    })
  }

  const handleSeatChange = () => {
    // Check if covers are 0 and show covers modal
    const hasCover = props.selectedCart?.extras?.covers
    if (!hasCover || hasCover === 0) {
      handleShowCoversTakeaway()
    } else if (props.seatNo + 1 <= props.selectedCart.extras.covers) {
      // else change seat
      props.setTableSeat(props.seatNo + 1)
    } else {
      props.setTableSeat(0)
    }
  }

  useEffect(() => {
    initDeviceListener()
  }, [])

  useEffect(() => {
    setHasCustomer(checkCartHasCustomer())
  }, [props.selectedCart.customer])

  return (
    <Fragment>
      <div className="cart-actions-header toolbar">
        <CartToolbarButton text="customerid" icon="icon-reward" action={handleOpenCustomerDialog} />
        {props.app.isDesktop && props.settings.showOrderBill && (
          <CartToolbarButton text="print" icon="icon-printer" action={printBill} />
        )}
        {props.settings.showOrderBill && (
          <CartToolbarButton text="order" icon="icon-features-kitchenreceipt" action={printOrder} />
        )}
        {props.settings.showRepsWaste && (
          <CartToolbarButton
            text="reps"
            icon="icon-sidebar-order"
            action={handleOpenRepsWasteModal}
          />
        )}
        {props.settings.dropShip && (
          <CartToolbarButton
            text="drop-ship"
            icon="icon-dropship"
            action={handleEnableDropShip}
            iconSelected={cartUtils.isDropShipCart(props.selectedCart)}
          />
        )}
        {props.settings.enableDepositButton && (
          <CartToolbarButton
            text="deposit"
            icon="icon-recycle-512"
            action={handleShowDepositProducts}
            iconSelected={props.showDepositProducts}
          />
        )}
        {props.settings.askCoversTakeaway && (
          <CartToolbarButton text="guests" icon="icon-takeaway" action={handleShowCoversTakeaway} />
        )}
        {!!props?.devices?.device?.store?.options?.useSeats && (
          <CartToolbarButton text="guests" icon="icon-profile" action={handleSeatChange} />
        )}
        {props.settings.askBuzzerInfo && (
          <CartToolbarButton
            text="buzzer"
            icon="icon-calculator"
            action={handleShowBuzzerInfoRequest}
          />
        )}
        {openCustomerDialog && (
          <CustomerModal
            openCustomerDialog={openCustomerDialog}
            handleCloseCustomerDialog={handleCloseCustomerDialog}
            isInvoicePayment={false}
          />
        )}
        {openRepsWasteModal && (
          <RepsWasteModal
            openRepsWasteModal={openRepsWasteModal}
            handleCloseRepsWasteModal={handleCloseRepsWasteModal}
          />
        )}
        {openCoversTakeaway && (
          <CoversTakeawayModal
            open={openCoversTakeaway}
            handleClose={() => setOpenCoversTakeaway(false)}
            cart={props.selectedCart}
            setGuests={handleChangeCoverTakeaway}
            store={props.devices.store}
          />
        )}
        {openBuzzerInfoRequest && (
          <AskBuzzerInfoModal
            openBuzzerInfoRequest={openBuzzerInfoRequest}
            handleCloseBuzzerInfoRequest={handleBuzzerInfoClose}
            handleConfirmBuzzerInfo={handleBuzzerInfoClose}
            store={props.devices.store}
          />
        )}
      </div>
      {hasCustomer && <CustomerInfo cart={props.selectedCart} update={updateCart} />}
    </Fragment>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(CartToolbar)
