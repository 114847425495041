import { AppInstances } from './countrSdkInstance'

export default class StoreUtils {
  static hasFloorplans = store => {
    return !!store.floorplans && !!store.floorplans.length
  }

  static floorplanHasTables = floorplan => {
    return !!floorplan.tables && !!floorplan.tables.length
  }

  static updateStoreServer = async storeUpdated => {
    const countr = await AppInstances.getCountrSdk()
    return countr.stores.update(storeUpdated._id, storeUpdated).then(
      updated => {
        return Promise.resolve(updated)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  static isFiscalStore = (store,device) => {
    return !!store.options && !!store.options.fiscalTransactions && !!device.options?.fiscalConfiguration
  }
}
