import {
  ADD_CART,
  EDIT_CART,
  SELECT_CART,
  CLEAN_CART,
  SELECT_CART_ITEM,
  SET_TRANSACTION,
  ADD_CUSTOMER_CART,
  DELETE_CART,
  TABLE_SEAT
} from './actionTypes'

export const addCart = cart => ({
  type: ADD_CART,
  payload: cart
})

export const editCart = cart => ({
  type: EDIT_CART,
  id: cart._id,
  payload: cart
})

export const selectCart = cart => ({
  type: SELECT_CART,
  payload: cart
})

export const cleanCart = () => ({
  type: CLEAN_CART
})

export const selectCartItem = item => ({
  type: SELECT_CART_ITEM,
  payload: item
})

export const setTransaction = transaction => ({
  type: SET_TRANSACTION,
  payload: transaction
})

export const addCustomerCart = customers => ({
  type: ADD_CUSTOMER_CART,
  payload: customers
})

export const deleteCart = id => ({
  type: DELETE_CART,
  payload: id
})

export const setTableSeat = seat => ({
  type: TABLE_SEAT,
  payload: seat
})
