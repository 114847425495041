import {
  SET_PAYMENTS,
  LOAD_PAYMENT_METHODS,
  SET_METHOD,
  SET_METHOD_CASHDRAWER,
  SET_METHOD_PRINT,
  SET_METHOD_EXTRA,
  LOAD_PAYMENT_METHODS_EXTRA,
  GIFTCARD_CODE
} from './actionTypes'

export const setPayments = payments => ({
  type: SET_PAYMENTS,
  payload: payments
})

export const loadPaymentsMethods = payments => ({
  type: LOAD_PAYMENT_METHODS,
  payload: payments
})

export const setMethod = method => ({
  type: SET_METHOD,
  payload: method
})

export const setMethodCashDrawer = method => ({
  type: SET_METHOD_CASHDRAWER,
  payload: method
})

export const setMethodPrintReceipt = method => ({
  type: SET_METHOD_PRINT,
  payload: method
})

export const setMethodExtra = method => {
  return {
    type: SET_METHOD_EXTRA,
    payload: { method: method.method, extra: method.extra }
  }
}

export const loadPaymentsMethodsExtra = extraPaymentMethods => ({
  type: LOAD_PAYMENT_METHODS_EXTRA,
  payload: extraPaymentMethods
})

export const setGiftcardCode = code => ({
  type: GIFTCARD_CODE,
  payload: code
})
