import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import onScan from 'onscan.js'
import Dialog from '@material-ui/core/Dialog'
import VoucherUtils from './../../../utils/VoucherUtils'
import GiftcardDetails from './GiftcardDetails'
import CountrButton from '../../Shared/CountrButton'
import { SET_TOAST_MESSAGE } from '../../../store/actions/actionTypes'
import { BarcodeUtils } from '../../../utils/BarcodeUtils'
import Util from '../../../utils/Util'
import ScanOptions from '../../Shared/ScanOptions'

const Giftcard = ({ open, handleResult, handleClose, total, hasItems }) => {
  const selectedCart = useSelector(state => state.carts.selectedCart)
  const store = useSelector(state => state.devices.store)
  const dispatch = useDispatch()
  const showMsg = useCallback(
    msg =>
      dispatch({
        type: SET_TOAST_MESSAGE,
        payload: msg
      }),
    [dispatch]
  )
  const [readingQrCode, setReadingQrCode] = useState(true)
  const [giftcard, setGiftcard] = useState(null)
  const [code, setCode] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      const modal = document.getElementById('giftcard-modal')

      if (!!modal && !onScan.isAttachedTo(modal)) {
        onScan.attachTo(modal, {
          suffixKeyCodes: [13], // enter-key expected at the end of a scan
          ignoreIfFocusOn: 'input',
          onScan: code => {
            handleScanResult(code)
          }
        })
      }
    }, 100)

    return () => {
      const modal = document.getElementById('giftcard-modal')
      if (!!modal && !!modal.scannerDetectionData) {
        onScan.detachFrom(modal)
      }
    }
  })

  const handleScanError = error => {
    const msg = !!error.message ? error.message : JSON.stringify(error)
    showMsg({ message: msg })
    setReadingQrCode(false)
    handleClose()
  }

  const handleScanResult = async result => {
    if (!!result) {
      const giftcard = await VoucherUtils.searchGiftcard(result)
      setCode(result)
      setGiftcard(giftcard)
      setReadingQrCode(false)

      if (!giftcard) {
        BarcodeUtils.registerBarcodeNotFound(result, selectedCart, true)
      }
    }
  }

  const redeemGiftcard = async () => {
    try {
      const result = await VoucherUtils.redeemGiftcard(giftcard, store, selectedCart, total)

      if (result) {
        handleResult(result)
      }
    } catch (error) {
      handleScanError(error)
    }
  }

  return (
    <Dialog
      id="giftcard-modal"
      onClose={(event, reason) => {
        Util.handleModalDisableBackdrop(reason, handleClose)
      }}
      aria-labelledby="qrcode-reader"
      open={open}
      disableEscapeKeyDown>
      {readingQrCode && (
        <ScanOptions handleScanError={handleScanError} handleScanResult={handleScanResult} />
      )}
      {giftcard && (
        <GiftcardDetails
          giftcard={giftcard}
          redeem={redeemGiftcard}
          hasItems={hasItems}
          total={total}
        />
      )}
      {!giftcard && !readingQrCode && (
        <div className="giftcard-details">
          <span className="title">
            <Text id="scan_fail" />
          </span>
          <div className="current-balance">
            <span className="label">
              <Text id="scan_code_not_exist" values={{ barcode: code }} />
            </span>
          </div>
        </div>
      )}
      <div className="action">
        <CountrButton handleClick={handleClose} text="cancel" color="#c51162" />
      </div>
    </Dialog>
  )
}

export default Giftcard
