import { memo } from 'react';
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Util from './../../utils/Util'
import { Text } from 'react-internationalization'

const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'

const ReportPeriodSelection = memo(({ reportSelected, getReportBy, outstandingInvoices }) => {
  const isPeriodSelectedStyle = period => {
    return reportSelected === period ? { backgroundColor: 'rgba(0, 0, 0, 0.08)' } : {}
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className="report-selection">
      <Grid item xs={12}>
        <InputLabel>
          <Text id="select_report" />
        </InputLabel>
      </Grid>
      <Grid item xs={outstandingInvoices ? 12 : 4}>
        <Button
          onClick={() => getReportBy(DAY)}
          className="report-selection-button"
          style={isPeriodSelectedStyle(DAY)}>
          <Text id="day" />
          <br />
          <font className="date">{Util.formattedDate(new Date())}</font>
        </Button>
      </Grid>
      {outstandingInvoices && (
        <Grid item xs={12} style={{ margin: '8px 16px 0px 16px' }}>
          <InputLabel>
            <Text id="outstanding_inovices" />
          </InputLabel>
        </Grid>
      )}
      {!outstandingInvoices && (
        <Grid item xs={4}>
          <Button
            onClick={() => getReportBy(WEEK)}
            className="report-selection-button"
            style={isPeriodSelectedStyle(WEEK)}>
            <Text id="week" />
            <br />
            <font className="date">{Util.getInitWeek(new Date())}</font>
          </Button>
        </Grid>
      )}
      {!outstandingInvoices && (
        <Grid item xs={4}>
          <Button
            onClick={() => getReportBy(MONTH)}
            className="report-selection-button"
            style={isPeriodSelectedStyle(MONTH)}>
            <Text id="month" />
            <br />
            <font className="date">{Util.getInitMonth(new Date())}</font>
          </Button>
        </Grid>
      )}
    </Grid>
  )
})

export default ReportPeriodSelection
