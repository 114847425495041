import { memo } from 'react';

import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'

import { Text } from 'react-internationalization'

import './Reports.css'

const ReportSection = memo(props => {
  const _isNested = detailsType => {
    return !!detailsType.details
  }

  const _renderLine = (details, type) => {
    const { currency } = props

    if (_isNested(details[type])) {
      return Object.keys(details[type].details).map(nestedKey => (
        <Grid
          key={nestedKey}
          container
          justifyContent="space-between"
          className="reports-type-table-content-row">
          <Grid item xs={6} className="reports-type-table-content">
            {type} - <Text id={nestedKey.toLowerCase()} />
          </Grid>
          <Grid item xs={3} className="reports-type-table-content">
            {currency} {details[type].details[nestedKey].amount.toFixed(2)}
          </Grid>
          <Grid item xs={3} className="reports-type-table-content">
            {details[type].details[nestedKey].number}
          </Grid>
        </Grid>
      ))
    }

    return (
      <Grid
        key={type}
        container
        justifyContent="space-between"
        className="reports-type-table-content-row">
        <Grid item xs={6} className="reports-type-table-content">
          {type}
        </Grid>
        <Grid item xs={3} className="reports-type-table-content">
          {currency} {details[type].amount.toFixed(2)}
        </Grid>
        <Grid item xs={3} className="reports-type-table-content">
          {details[type].number}
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Grid container className="reports-type-container">
        <Grid item xs={12} className="reports-type-title">
          <InputLabel>
            <Text id={props.type} />
          </InputLabel>
        </Grid>
        <Grid item xs={12} className="reports-type-table">
          <Grid container justifyContent="space-between" className="reports-type-table-content-row">
            <Grid item xs={6} className="reports-type-table-header">
              <InputLabel>
                <Text id={props.type} />
              </InputLabel>
            </Grid>
            <Grid item xs={3} className="reports-type-table-header">
              <InputLabel>
                <Text id="amount" />
              </InputLabel>
            </Grid>
            <Grid item xs={3} className="reports-type-table-header">
              <InputLabel>
                <Text id="number" />
              </InputLabel>
            </Grid>
          </Grid>
          {props.reportSection.details &&
            Object.keys(props.reportSection.details).map(key =>
              _renderLine(props.reportSection.details, key)
            )}
          <Grid container justifyContent="space-between" className="reports-type-table-content-row">
            <Grid item xs={6} className="reports-type-table-header">
              <b>
                <Text id="total" />
              </b>
            </Grid>
            <Grid item xs={3} className="reports-type-table-header">
              <b>
                {props.currency} {props.reportSection.summary.amount.toFixed(2)}
              </b>
            </Grid>
            <Grid item xs={3} className="reports-type-table-header">
              <b>{props.reportSection.summary.number}</b>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
})

export default ReportSection
