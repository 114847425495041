import { memo, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Text } from 'react-internationalization'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Floorplan from './Floorplan'
import { cartUtils } from '../../../utils/cartUtils'
import { AppInstances } from '../../../utils/countrSdkInstance'
import StoreUtils from '../../../utils/StoreUtils'
import { LOADING_TRUE, LOADING_FALSE, SET_TOAST_MESSAGE } from '../../../store/actions/actionTypes'

const NEXT = 'NEXT'
const PREV = 'PREV'

const TableLayout = memo(function TableLayout({ open, handleClose }) {
  const store = useSelector(state => state.devices.store)
  const device = useSelector(state => state.devices.device)
  const merchant = useSelector(state => state.user.user)
  const carts = useSelector(state => state.carts.carts)
  const currentCart = useSelector(state => state.carts.selectedCart._id)
  const coverSettings = useSelector(state => state.settings.askCoversTakeaway)
  const seatSettings = useSelector(state => state?.devices?.device?.store?.options?.useSeats || false)

  const dispatch = useDispatch()
  const loadingTrue = useCallback(c => dispatch({ type: LOADING_TRUE }), [dispatch])
  const loadingFalse = useCallback(c => dispatch({ type: LOADING_FALSE }), [dispatch])
  const showMsg = useCallback(
    msg =>
      dispatch({
        type: SET_TOAST_MESSAGE,
        payload: msg
      }),
    [dispatch]
  )

  const [currentFloorplan, setCurrentFloorplan] = useState({})
  const [mergeTables, setMergeTables] = useState(false)

  useEffect(() => {
    const index = cartBelongsFloorplan()
    setCurrentFloorplan(index >= 0 ? store.floorplans[index] : store.floorplans[0])
  }, [])

  const cartBelongsFloorplan = () => {
    return store.floorplans.findIndex(floorplan => {
      return floorplan.tables.findIndex(table => table.linked_cart === currentCart) >= 0
    })
  }

  const showChangeFloorBtns = () => {
    return store.floorplans.length > 1
  }

  const changeFloorplan = prevNext => {
    const index = store.floorplans.findIndex(f => f._id === currentFloorplan._id)

    if (prevNext === NEXT) {
      setCurrentFloorplan(
        !!store.floorplans[index + 1] ? store.floorplans[index + 1] : store.floorplans[0]
      )
    } else {
      const lastIndex = store.floorplans.length - 1
      setCurrentFloorplan(
        !!store.floorplans[index - 1] ? store.floorplans[index - 1] : store.floorplans[lastIndex]
      )
    }
  }

  const handleUnlinkedCart = table => {
    loadingTrue()
    const newCart = cartUtils.createCart(merchant._id, device, carts.length || 1, true)
    newCart.deviceCartName = table.name

    cartUtils
      .createCartServer(newCart)
      .then(async createdCart => {
        const id = createdCart._id
        const tableToUpdate = { ...table }
        const toUpdateStore = { ...store }
        const floorplanIndex = store.floorplans.findIndex(f => f._id === currentFloorplan._id)
        const tableIndex = currentFloorplan.tables.findIndex(t => t._id === tableToUpdate._id)
        tableToUpdate.linked_cart = id
        toUpdateStore.floorplans[floorplanIndex].tables[tableIndex] = tableToUpdate

        await StoreUtils.updateStoreServer(toUpdateStore)
        loadingFalse()
      })
      .catch(handleError)
  }

  const handleError = async error => {
    const msg = !!error.message ? error.message : JSON.stringify(error)
    showMsg({ message: msg })
    loadingFalse()
    AppInstances.logError({
      message: `Error while getting linked cart from table`,
      user: merchant._id,
      store: store._id,
      device: device._id,
      stack: JSON.stringify(error),
      info: msg,
      date: new Date().toISOString()
    })
  }

  const handleSetMergeTablesState = () => {
    setMergeTables(prevState => !prevState)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>
        <div className="table-layout-header">
        <div className="table-merge-container">
            <Text id="table_layout" />
            <Button
              startIcon={<span className="icon-features-tablelayout" />}
              variant="contained"
              onClick={handleSetMergeTablesState}
              className={mergeTables ? 'cancel-merge-btn' : 'start-merge-btn'}>
              {mergeTables ? (
                <span>
                  <Text id="cancel" /> <Text id="merge_tables" />
                </span>
              ) : (
                <Text id="merge_tables" />
              )}
            </Button>
          </div>       
          {!!currentFloorplan && !!currentFloorplan._id && (
            <div className="change-floorplan">
              {showChangeFloorBtns() && (
                <IconButton onClick={() => changeFloorplan(PREV)}>
                  <span className="icon-left-arrow" />
                </IconButton>
              )}
              <div>
                <small>
                  <Text id="current_floor" />:
                </small>
                <span>{currentFloorplan.name}</span>
              </div>
              {showChangeFloorBtns() && (
                <IconButton onClick={() => changeFloorplan(NEXT)}>
                  <span className="icon-right-arrow" />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        {store.floorplans.map(
          floorplan =>
            currentFloorplan._id === floorplan._id && (
              <Floorplan
                key={floorplan._id}
                floorplan={floorplan}
                handleClose={handleClose}
                handleUnlinkedCart={handleUnlinkedCart}
                handleError={handleError}
                coverSettings={coverSettings}
                seatSettings={seatSettings}
                mergeTables={mergeTables}
                handleSetMergeTablesState={handleSetMergeTablesState}
              />
            )
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          <Text id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default TableLayout
