import { memo } from 'react';
import { Text } from 'react-internationalization'
import { AddRemoveButtons, Column } from '@countr/ui'
import { ProductUtils } from '@countr/utils'

const CartItemQuantityComponent = memo(
  ({ product, amount, updateCartAmount, handleChangeAmount }) => {
    return (
      <Column>
        <span>
          <Text id="quantity" />
          {ProductUtils.isSoldByWeight(product) && <span> (grams)</span>}
        </span>
        <AddRemoveButtons
          value={amount}
          handleAdd={() => updateCartAmount('add')}
          handleRemove={() => updateCartAmount('remove')}
          handleChange={handleChangeAmount}
        />
      </Column>
    )
  }
)

export default CartItemQuantityComponent
