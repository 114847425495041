import {
  ADD_CATEGORY,
  ADD_ALL_CATEGORIES,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  SET_ACTIVE_CATEGORY
} from './actionTypes'

export const addCategory = category => ({
  type: ADD_CATEGORY,
  payload: category
})

export const addAllCategory = categories => ({
  type: ADD_ALL_CATEGORIES,
  payload: categories
})

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: category
})

export const deleteCategory = id => ({
  type: DELETE_CATEGORY,
  payload: id
})

export const setActiveCategory = id => ({
  type: SET_ACTIVE_CATEGORY,
  payload: id
})
