import { CountrRequest } from './CountrRequest'
import WorkerResourceLoader from './../../src/WorkerResourceLoader'

export default class CategoryUtils {
  static getInitialCategories = (reduxCategories, addCategory) => {
    return WorkerResourceLoader.getCategories().then(categories => {
      categories.forEach(category => {
        const index = reduxCategories.findIndex(c => c._id === category._id)
        if (index < 0) {
          addCategory(category)
        }
      })
    })
  }

  static checkDeletedAndRemoveFromDB = async (store, delta, deleteCategory) => {
    return CountrRequest.request(
      'GET',
      `stores/${store}/categories/delta/count?start_date=${delta}&include_deleted=true`
    ).then(async count => {
      if (count && count > 0) {
        const categories = await CountrRequest.request(
          'GET',
          `stores/${store}/categories/delta?start_date=${delta}&include_deleted=true&limit=200`
        )
        categories.forEach(category => {
          if (category.deletedAt) {
            WorkerResourceLoader.searchByIdAndDelete([category._id], 'categories').catch(
              async e => {
                this.logError({
                  msg: 'Error when trying to Delete category in IndexedDB',
                  stack: e.stack
                })
              }
            )

            deleteCategory(category._id)
          }
        })
      }
    })
  }
}
