/* eslint-disable max-len */
import arLang from './ar'
import deLang from './de'
import elLang from './el'
import enLang from './en'
import esLang from './es'
import frLang from './fr'
import isLang from './is'
import itLang from './it'
import nlLang from './nl'
import noLang from './no'
import ptLang from './pt'

export function ar(){
   return arLang
}
export function de(){
   return deLang
}
export function el(){
   return elLang
}
export function en(){
   return enLang
}
export function es(){
   return esLang
}
export function fr(){
   return frLang
}
export function is(){
   return isLang
}
export function it(){
   return itLang
}
export function nl(){
   return nlLang
}
export function no(){
   return noLang
}
export function pt(){
   return ptLang
}

const langList = [{ key: 'ar', label: 'العربية'},{ key: 'de', label: 'Deutsch'},{ key: 'el', label: 'Ελληνικά'},{ key: 'en', label: 'English'},{ key: 'es', label: 'Español'},{ key: 'fr', label: 'Français'},{ key: 'is', label: 'Íslenska'},{ key: 'it', label: 'Italiano'},{ key: 'nl', label: 'Nederlands'},{ key: 'no', label: 'Norsk'},{ key: 'pt', label: 'Português'}]

export default langList