import { Text } from 'react-internationalization'
import Button from '@material-ui/core/Button'

const CountrButton = ({
  handleClick,
  text = '',
  disabled = false,
  color = '#318ed5',
  type = 'contained',
  fullWidth = false,
  style = {}
}) => {
  const getStyle = () => {
    return {
      color: '#fff',
      backgroundColor: disabled ? '#b0c6d7' : color,
      ...style
    }
  }

  return (
    <Button
      variant={type}
      style={getStyle()}
      onClick={handleClick}
      disabled={disabled}
      fullWidth={fullWidth}>
      <Text id={text} />
    </Button>
  )
}

export default CountrButton
