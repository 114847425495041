import { cartUtils } from './cartUtils'
import { AppInstances } from './../utils/countrSdkInstance'
import { translate } from 'react-internationalization'
import DesktopUtils from './DesktopUtils'
import RegisterOperationsUtils from './RegisterOperationsUtils'
import Util from './Util'
import store from './../index'

const NEW = 'new'
const PENDING = 'pending'
const PRINTED = 'printed'

const snooze = ms => new Promise(resolve => setTimeout(resolve, ms))

export const PrinterUtils = (function () {
  const logError = (user, msg) => {
    const errorObj = {
      message: msg,
      user: user._id,
      store: user.store,
      device: user.device,
      date: new Date().toISOString()
    }

    AppInstances.getCountrSdk().then(countr => countr.logError(errorObj))
  }

  const removeSpecialChar = str => {
    return str.replace(/[^\w\s]/gi, '')
  }

  return {
    getTranslations: function () {
      return {
        discount: translate('discount'),
        surcharge: translate('surcharge'),
        total: translate('total'),
        subtotal: translate('subtotal'),
        tax: translate('tax'),
        taxes: translate('taxes'),
        tax_rate: translate('tax_rate'),
        net: translate('net'),
        change: translate('change'),
        paid: translate('paid'),
        payment: translate('payment'),
        receipt: translate('receipt'),
        employee: translate('employeeid'),
        customer: translate('customerid'),
        partial_payment: translate('partial_payment'),
        remaining_payment: translate('remaining_payment'),
        deposit: translate('deposit'),
        transactionFee: translate('transactionFee'),
        deliveryCost: translate('deliveryCost'),
        deli: translate('delivery')
      }
    },

    externalOSPrint: async function (entity) {
      if (!entity?.items?.length) {
        return
      }

      // entity.items.forEach(item => {
      for (let i = 0; i < entity.items.length; i++) {
        if (i > 0 && i % 10 === 0) {
          await snooze(500) // Wait an extra 500ms every 10 tickets to slow things down more
        }

        const item = entity.items[i]
        const printType = item.product?.options?.voucher?.print_type ?? null

        if (item.product?.extras?.ticket_download_url && printType) {
          const localDesktop = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))

          if (localDesktop && localDesktop.local_ip && localDesktop.osprinters_saved?.length) {
            const osPrinterNames = localDesktop.osprinters_saved.filter(
              osprinter => !!osprinter.types[printType]
            )

            const web = AppInstances.getDesktopConnection(
              `${process.env.REACT_APP_WS_TYPE}://${
                localDesktop.local_ip
              }:${DesktopUtils.getDesktopListenerPort()}`
            )

            if (web?.readyState === 1) {
              const data = {
                type: 'silentPrint',
                printers: osPrinterNames,
                url: item.product.extras.ticket_download_url
              }

              web.send(JSON.stringify(data))

              await snooze(400)

              web.onmessage = message => {}
            }
          }
        }
      }
    },

    /**
     *  Send Order to printer
     */
    order: function (
      orderCart,
      callbacks,
      kitchenOrder = false,
      splitItems = false,
      splitAmount = false,
      kdsAttached = false
    ) {
      const items = JSON.parse(JSON.stringify(orderCart.items))
      const cart = JSON.parse(JSON.stringify(orderCart))
      cart.status = PRINTED

      // Using ordersToPrint array to control which new item should go to printer
      const ordersToPrint = []

      items.forEach((item, i) => {
        if (!item.status.length) {
          item.status.push({
            last_update: new Date().toISOString(),
            state: NEW,
            amount: item.amount
          })
          ordersToPrint.push({ index: i, amount: item.amount })
        } else {
          // updating all new to printed
          const newIndex = item.status.findIndex(st => st.state === NEW)
          const pendingIndex = item.status.findIndex(st => st.state === PENDING)
          const printedIndex = item.status.findIndex(st => st.state === PRINTED)

          // Just double checking if has new state in the status array
          if (newIndex < 0) {
            item.status = [
              {
                last_update: new Date().toISOString(),
                state: NEW,
                amount: 0
              },
              {
                last_update: new Date().toISOString(),
                state: PENDING,
                amount: 0
              }
            ].concat(item.status)
          } else {
            ordersToPrint.push({
              index: i,
              amount:
                item.status[newIndex].amount +
                (pendingIndex >= 0 ? item.status[pendingIndex].amount : 0)
            })
          }

          const amount =
            (newIndex >= 0 ? item.status[newIndex].amount : 0) +
            (pendingIndex >= 0 ? item.status[pendingIndex].amount : 0)
          if (printedIndex < 0) {
            // creating a new status obj "printed" with all new status amount
            item.status.push({
              last_update: new Date().toISOString(),
              state: PRINTED,
              amount
            })
          } else {
            // already has printed status, check if has new items with new status to go to printed
            item.status[printedIndex].amount += amount
            item.status[printedIndex].last_update = new Date().toISOString()
          }

          if (newIndex >= 0) {
            // all new status amount goes to 0
            item.status[newIndex].amount = 0
            // item.status[newIndex].last_update = new Date().toISOString()
          }

          if (pendingIndex >= 0 && newIndex && item?.status[newIndex]) {
            item.status[newIndex].amount = 0
          }
        }
      })

      cart.items = items

      if (!kitchenOrder && (cart.order_source === 'pos' || cart.order_source === 'web_pos')) {
        localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
        localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
      }

      if (callbacks && !kitchenOrder) {
        callbacks.selectCart(cart)
        callbacks.editCart(cart)
      }

      const order = {
        table: '',
        order_source: cart.order_source,
        items: [],
        updated_at: cart.updated_at,
        reprint: false,
        receipt_id: orderCart.receipt_id,
        splitItems: splitItems,
        splitAmount: splitAmount
      }

      order.table =
        cart.extras.tableNumber ||
        (cart.extras.deviceCartName && cart.extras.deviceCartName.split(':').length > 1
          ? cart.extras.deviceCartName.split(':')[1]
          : cart.extras.deviceCartName)

      order.note = cart.extras.note || ''

      // TODO
      // if (cartUtils.isThirdPartySource(cart)) {
      //   order.table = `${cart.extras.by}`
      // }

      items.forEach((item, i) => {
        const index = ordersToPrint.findIndex(order => order.index === i)

        if (index >= 0) {
          const p = {}
          p.addon = false
          p.name = removeSpecialChar(item.product.name)
          p.current_variant = item.product.current_variant
          p.categories = item.product.categories.map(category => category._id)
          p.amount = ordersToPrint[index].amount
          p.note = item.note || ''
          p.addons = []

          if (p.amount > 0) {
            if (item.product.current_addons.length) {
              item.product.current_addons.forEach(addon => {
                p.addons.push({
                  addon: true,
                  name: addon.name,
                  amount: addon.amount
                })
              })
            }

            order.items.push(p)
          }
        }
      })

      // If every items was already printed, print the whole cart marked as re-print
      if (order.items.length === 0) {
        order.reprint = true

        items.forEach(item => {
          const p = {}
          p.addon = false
          p.name = removeSpecialChar(item.product.name)
          p.current_variant = item.product.current_variant
          p.categories = item.product.categories.map(category => category._id)
          p.amount = item.amount
          p.note = item.note || ''
          p.addons = []

          if (item.product.current_addons.length) {
            item.product.current_addons.forEach(addon => {
              p.addons.push({
                addon: true,
                name: addon.name,
                amount: addon.amount
              })
            })
          }

          order.items.push(p)
        })
      }

      if (kitchenOrder || kdsAttached) {
        if (cartUtils.isThirdPartySource(cart)) {
          order.reprint = false

          // Delivery info check
          if (!!cart.extras.delivery && !!cart.extras.delivery.print) {
            order.table = cart.extras.delivery.orderType || order.table

            order.delivery = {
              note: cart.extras.delivery.note || '',
              print: cart.extras.delivery.print,
              currency: cart.currency.code,
              langs: {
                delivery_details: translate('delivery_details'),
                delivery: Object.keys(cart.extras.delivery.print).map(item => translate(item))
              },
              toPay: cart.__t === 'Cart' && !!cart.status && cart.status !== 'new'
            }
          }
        }

        //If buzzer set in extras, add as delivery note so will print and show without changes to desktop
        if (cart.extras.buzzer) {
          order.delivery = order.delivery || {}
          order.delivery.note = translate('buzzer') + ' ' + cart.extras.buzzer
        }

        if (cart._id && cart.__t === 'Cart') {
          // Set originator of the current device
          const device = store.getState().devices.device
          cart.originator = device._id

          // Update it on server
          AppInstances.getCountrSdk()
            .then(countr => {
              countr.carts.update(cart._id, cart)
            })
            .catch(e => {
              logError(
                {
                  _id: cart.merchant,
                  store: cart.store,
                  device: cart.device
                },
                `Failed to change Cart id ${cart._id} status to printed. 
                Countr web PrinterUtils file`
              )
            })
        }
      }

      this.sendToPrinter('print-order', order, callbacks, kdsAttached)
    },

    /**
     *
     * @param {Object} cart
     * @param {Object} userDefaultReceipt
     * @param {Object} store
     * @param {Object} currency
     * @param {Function} callbacks
     * @param {Object} printEAN
     * @param {Object} printBrand
     */
    bill: async function (
      cart,
      userDefaultReceipt,
      store,
      currency,
      callbacks,
      printEAN,
      printBrand
    ) {
      const bill = {
        header: [],
        footer: [],
        items: [],
        total: {},
        currency: currency,
        printEAN: printEAN,
        printBrand,
        langs: this.getTranslations()
      }

      let userReceipt = store.receipt
      if (userReceipt === undefined) {
        userReceipt = userDefaultReceipt
      }

      if (userReceipt === undefined) {
        userReceipt = cartUtils.getDefaultReceipt()
      }

      // Headers
      userReceipt.header.forEach(head => {
        bill.header.push(head)
      })

      // Footers
      userReceipt.footer.forEach(foot => {
        if (foot.type !== 'image') {
          bill.footer.push(foot)
        }
      })

      // Items
      cart.items.forEach(item => {
        const p = {}
        p.addon = false
        p.name = removeSpecialChar(item.product.name)
        p.amount = item.amount
        p.current_variant = item.product.current_variant
        p.description = item.product.description

        const { reduction = {} } = item.product

        p.reduction = reduction

        if (item.product.discount < 0 && !reduction.reason) {
          // Negative discount -> new price bigger than old price
          p.price = item.product.current_variant.price * (1 + Math.abs(item.product.discount))
          p.discount = 0
        } else {
          p.price = item.product.current_variant.price
          p.discount = item.product.discount
        }

        p.note = item.note || ''
        p.addons = []
        p.ean =
          item.product.current_variant.ean && item.product.current_variant.ean.length
            ? item.product.current_variant.ean
            : ''
        p.brand = item.product.brand && item.product.brand.length ? item.product.brand : ''
        p.soldsold_by_weight = item.product.options && item.product.options.sold_by_weight
        p.deposit = cartUtils.returnProductDepositValue(item.product)

        if (item.product.current_addons.length) {
          item.product.current_addons.forEach(addon => {
            p.addons.push({
              name: addon.name,
              amount: addon.amount,
              price: addon.price,
              discount: 0
            })
          })
        }

        bill.items.push(p)
      })

      let discount = cart.discount

      if (cart.reduction && cart.reduction.numeric) {
        discount = cart.reduction.numeric / (parseFloat(cart.total) + cart.reduction.numeric)
      }

      bill.total = {
        total: cart.total,
        sub_total: cart.sub_total,
        discount: discount,
        taxes: []
      }
      const taxes = await cartUtils.getTransactionTaxes(cart)
      bill.total.taxes = taxes

      // Delivery info check
      if (cart.extras.delivery && cart.extras.delivery.print) {
        bill.delivery = cart.extras.delivery.print
        bill.langs.delivery_details = translate('delivery_details')
        bill.langs.delivery = Object.keys(cart.extras.delivery.print).map(item => translate(item))

        if (!!cart.extras.transactionFee && parseFloat(cart.extras.transactionFee !== 0)) {
          bill.transactionFee = cart.extras.transactionFee
          bill.total.total += bill.transactionFee
        }

        if (!!cart.extras.delivery.deliveryCost) {
          bill.deliveryCost = cartUtils.getDeliveryCost(cart)
        }
      }

      this.sendToPrinter('print-bill', bill, callbacks)
    },

    /**
     *
     * @param {Object} transaction
     * @param {Object} userDefaultReceipt
     * @param {Object} store
     * @param {Object} currency
     * @param {Function} callbacks
     * @param {Boolean} printEAN
     * @param {Boolean} printBrand
     */
    receipt: async function (
      transaction,
      userDefaultReceipt,
      store,
      currency,
      callbacks,
      printEAN,
      printBrand,
      printQR,
      printTickets,
      printDescription,
      openCashDrawer,
      qrCodeForLineItems,
      printCardInfo
    ) {
      const receipt = {
        header: [],
        items: [],
        total: {},
        footer: [],
        number: '',
        date: '',
        customer: {},
        employee: '',
        currency: currency,
        openCashDrawer,
        info: [],
        reprint: transaction.issued && transaction.issued > 0,
        paymentNumber: 0,
        printEAN: printEAN,
        printBrand,
        langs: this.getTranslations(),
        giveawayReason: '',
        refund: ''
      }

      let userReceipt = store.receipt
      if (userReceipt === undefined) {
        userReceipt = userDefaultReceipt
      }

      if (userReceipt === undefined) {
        userReceipt = cartUtils.getDefaultReceipt()
      }

      // if (
      //   transaction.payments.findIndex(
      //     payment => payment.method === 'cash' || payment.method === 'giftcard'
      //   ) >= 0
      // ) {
      //   receipt.openCashDrawer = true
      // }

      if (printCardInfo) {
        transaction.payments.forEach(payment => {
          if (!!payment.card_print_info && !!payment.card_print_info.clientTicket) {
            receipt.info.push(payment.card_print_info.clientTicket)
          }
        })
      }

      receipt.paymentNumber = transaction.payments.length

      // Headers
      userReceipt.header.forEach(head => {
        receipt.header.push(head)
      })

      // Footers
      userReceipt.footer.forEach(foot => {
        if (foot.type !== 'image') {
          receipt.footer.push(foot)
        }
      })

      // Items
      transaction.items.forEach(item => {
        const p = {}
        p.addon = false
        p.name = removeSpecialChar(item.product.name)
        p.current_variant = item.product.current_variant
        p.amount = item.amount
        p.description = printDescription ? item.product.description : ''

        const { reduction = {} } = item.product

        p.reduction = reduction
        if (item.product.discount < 0 && !reduction.reason) {
          // Negative discount -> new price bigger than old price
          p.price = item.product.current_variant.price * (1 + Math.abs(item.product.discount))
          p.discount = 0
        } else {
          p.price = item.product.current_variant.price
          p.discount = item.product.discount
        }

        p.note = item.note || ''
        p.addons = []
        p.ean =
          item.product.current_variant.ean && item.product.current_variant.ean.length
            ? item.product.current_variant.ean
            : ''
        p.brand = item.product.brand && item.product.brand.length ? item.product.brand : ''
        p.soldsold_by_weight = item.product.options && item.product.options.sold_by_weight
        p.deposit = cartUtils.returnProductDepositValue(item.product)

        if (item.product.current_addons !== undefined && item.product.current_addons.length) {
          item.product.current_addons.forEach(addon => {
            p.addons.push({
              name: addon.name,
              amount: addon.amount,
              price: addon.price,
              discount: 0
            })
          })
        }

        if (printTickets && item.product?.extras?.qr_tag) {
          if (qrCodeForLineItems) p.ticketQRcode = item.product.extras.qr_tag
          else p.ticketBarcode = item.product.extras.qr_tag
        }

        receipt.items.push(p)
      })

      let discount = transaction.discount

      if (transaction.reduction && transaction.reduction.numeric) {
        discount =
          transaction.reduction.numeric /
          (parseFloat(transaction.total) + transaction.reduction.numeric)
      }

      receipt.total = {
        total: transaction.total,
        sub_total: transaction.sub_total,
        discount: discount,
        taxes: [],
        paid: [],
        change: transaction.change || 0
      }

      transaction.payments.forEach(payment => {
        const method =
          translate(payment.method) && translate(payment.method).length
            ? translate(payment.method)
            : payment.method
        receipt.total.paid.push({
          type: `${method}${!!payment.provider ? `/${translate(payment.provider)}` : ''}`,
          value: payment.paid
        })
      })

      const taxes = await cartUtils.getTransactionTaxes(transaction)

      receipt.total.taxes = taxes

      receipt.number = transaction.receipt_id
      receipt.date = transaction.created_at

      if (
        transaction.employee &&
        transaction.employee.name &&
        transaction.employee.name !== 'None'
      ) {
        receipt.employee = transaction.employee.name
      }

      if (transaction.customer && transaction.customer !== null) {
        receipt.customer.name = `${transaction.customer.first_name || ''} 
                                ${transaction.customer.last_name || ''}`
        receipt.customer.email = transaction.customer.email || ''
      }

      // Delivery info check
      if (transaction.extras.delivery && transaction.extras.delivery.print) {
        receipt.delivery = transaction.extras.delivery.print
        receipt.langs.delivery_details = translate('delivery_details')
        receipt.langs.delivery = Object.keys(transaction.extras.delivery.print).map(item =>
          translate(item)
        )

        if (
          !!transaction.extras.transactionFee &&
          parseFloat(transaction.extras.transactionFee !== 0)
        ) {
          receipt.transactionFee = transaction.extras.transactionFee
          receipt.total.total += receipt.transactionFee
          receipt.total.paid[0].value += receipt.transactionFee
        }

        if (!!transaction.extras.delivery.deliveryCost) {
          receipt.deliveryCost = cartUtils.getDeliveryCost(transaction)
        }
      }

      // fiscal info
      if (!!transaction.fiscal_info && !!transaction.fiscal_info.formattedPrint) {
        receipt.fiscal_info = transaction.fiscal_info.formattedPrint
      }

      // fiscal QR
      if (!!transaction.fiscal_info && !!transaction.fiscal_info.qr_code_data) {
        receipt.fiscal_qr = transaction.fiscal_info.qr_code_data
      }

      // reps & warranties
      if (transaction.extras?.giveaway_reason) {
        receipt.giveawayReason = transaction.extras.giveaway_reason
      }

      // refund
      if (transaction.__t === 'refund' || parseFloat(transaction.total) < 0) {
        receipt.refund = translate('refund')
      }

      if (printQR) {
        // '_' + type + '|' + hash(merchant._id) + '|' + receipt_id
        // _transaction|hashthing|1-1-000001
        const transactionType =
          !transaction.__t || transaction.__t === 'Transaction' ? 'transaction' : 'refund'
        const merchantHash = cartUtils.hashCode(transaction.merchant)
        receipt.qr = `_${transactionType}|${merchantHash}|${transaction.receipt_id}`
      }

      this.sendToPrinter('print', receipt, callbacks)
    },

    /**
     *
     * @param {Object} cart
     * @param {Object} userDefaultReceipt
     * @param {Object} store
     * @param {Object} currency
     */
    partialReceipt: async function (cart, userDefaultReceipt, store, currency, printCardInfo) {
      const receipt = {
        header: [],
        items: [],
        total: {},
        footer: [],
        number: '',
        date: '',
        currency: currency,
        info: [],
        paymentNumber: 0,
        langs: this.getTranslations(),
        is_partial: true
      }

      let userReceipt = store.receipt
      if (userReceipt === undefined) {
        userReceipt = userDefaultReceipt
      }

      if (userReceipt === undefined) {
        userReceipt = cartUtils.getDefaultReceipt()
      }

      const payment = cart.payments[cart.payments.length - 1]
      if (
        payment.method === 'card' &&
        (payment.provider === 'payplaza' || payment.provider === 'ccv') &&
        printCardInfo
      ) {
        receipt.info.push(payment.card_print_info.clientTicket)
      }

      const paidSoFar = cart.payments.reduce((acc, current) => {
        return acc + current.paid
      }, 0)

      receipt.paymentNumber = cart.payments.length

      // Headers
      userReceipt.header.forEach(head => {
        receipt.header.push(head)
      })

      // Footers
      userReceipt.footer.forEach(foot => {
        if (foot.type !== 'image') {
          receipt.footer.push(foot)
        }
      })

      // Items
      cart.items.forEach(item => {
        const p = {}
        p.addon = false
        p.name = item.product.current_variant.default
          ? item.product.name
          : `${item.product.name} (${item.product.current_variant.name})`
        p.amount = item.amount
        p.price = item.product.current_variant.price
        p.discount = item.product.discount
        p.note = item.note || ''
        p.addons = []

        if (item.product.current_addons !== undefined && item.product.current_addons.length) {
          item.product.current_addons.forEach(addon => {
            p.addons.push({
              name: addon.name,
              amount: addon.amount,
              price: addon.price,
              discount: 0
            })
          })
        }

        receipt.items.push(p)
      })

      receipt.total = {
        total: cart.total,
        sub_total: cart.sub_total,
        discount: cart.discount,
        taxes: [],
        paid: [],
        change: cart.change ? cart.change : 0,
        remaining_payment: parseFloat(cart.total - paidSoFar)
      }

      const method =
        translate(payment.method) && translate(payment.method).length
          ? translate(payment.method)
          : payment.method
      receipt.total.paid.push({
        type: `${method}${
          payment.provider !== null && payment.provider !== ''
            ? '/' + translate(payment.provider)
            : ''
        }`,
        value: payment.paid
      })

      const taxes = await cartUtils.getTransactionTaxes(cart)

      receipt.total.taxes = taxes

      receipt.number = translate('partial_payment')
      receipt.date = cart.date

      this.sendToPrinter('print', receipt)
    },

    /**
     * Print Payplaza receipt
     * @param {Object} content
     */
    payplazaReceipt: function (content) {
      this.sendToPrinter('payplaza', content)
    },

    /**
     * Open CashDrawer connected to Printer
     */
    openCashDrawer: function () {
      this.sendToPrinter('cashdrawer', {})
      const operationArgs = {
        action: 'drawer_open'
      }

      RegisterOperationsUtils.applyOperation(operationArgs)
    },

    /**
     * Print Transactions Report
     * @param {Object} report
     * @param {Object} currency
     */
    printReport: function (report, currency = 'EUR', callbacks, store) {
      report.currency = currency
      report.store = store
      this.sendToPrinter('print-report', report, callbacks)
    },

    /**
     * Print receipt image
     *
     * @param {Object} image
     */
    printReceiptImage: function (image) {
      this.sendToPrinter('receipt_image', image)
    },

    /**
     * Main method that control the printers connection
     *
     * @param {String} type
     * @param {Object} payload
     * @param {Function} callbacks
     */
    sendToPrinter: function (type, payload, callbacks, kdsAttached = false) {
      const isDesktop = DesktopUtils.isDesktop()
      const ip = DesktopUtils.getDesktopIP()

      if (isDesktop && !!ip) {
        let intervalDesktopConnection
        const ips = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
        const web = AppInstances.getDesktopConnection(
          `${process.env.REACT_APP_WS_TYPE}://${ip}:${DesktopUtils.getDesktopListenerPort()}`
        )

        const sendEvent = {
          ip: ips,
          type: type,
          payload: payload
        }

        web.onerror = function error() {
          !!callbacks && callbacks.setToastMessage('countr_desktop_not_found')
        }

        web.onmessage = function (event) {
          if (event.data) {
            const msg = JSON.parse(event.data)
            if (msg.event && msg.event === 'print-receipt' && msg.success) {
              const e = new CustomEvent('print-receipt', {
                detail: {
                  id: msg.id
                }
              })
              window.dispatchEvent(e)
            } else if (!!msg.error && !!callbacks) {
              callbacks.setToastMessage('printer_error', {
                error: msg.error
              })
            } else if (msg.success && msg.event !== 'print-reciept' && !!callbacks) {
              callbacks.setToastMessage('print_successful')
            }
          }
        }

        if (web.readyState === 1) {
          web.send(JSON.stringify(sendEvent))

          // if (!!callbacks) {
          //   callbacks.setToastMessage('printing_receipt')
          // }
        } else {
          intervalDesktopConnection = setInterval(() => {
            if (web && web.readyState === 1) {
              web.send(JSON.stringify(sendEvent))
              clearInterval(intervalDesktopConnection)
            }
          }, 200)
        }
      } else if (type === 'print-order' && kdsAttached) {
        !!callbacks && callbacks.setToastMessage('send_order_kds')
      }
    },

    isUSBPrinter: function () {
      const desktop = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))

      if (!!desktop && !!desktop.printers_saved && !!desktop.printers_saved.length) {
        const printerUSB = desktop.printers_saved.findIndex(printer => !!printer.isUSB)
        return printerUSB >= 0
      }

      return false
    },

    handleKitchenPrint: function (checkout, user, devices, setToastMessage) {
      const userDefaultReceipt = user.user.receipt
      const store = devices.store
      const currency = devices.store.currency.code
      const callbacks = {
        setToastMessage: setToastMessage
      }

      try {
        this.order(checkout, null, true)
      } catch (ex) {
        console.log(ex)
      }
      // usb printer need a bigger delay to print the receipt
      const timeout = this.isUSBPrinter() ? 2000 : 500
      try {
        setTimeout(() => {
          this.receipt(checkout, userDefaultReceipt, store, currency, callbacks, true)
        }, timeout)
      } catch (ex) {
        console.log(ex)
      }

      const toastMsg = {
        by: cartUtils.getDeliveryBy(checkout),
        receipt_id: checkout.extras.deviceCartName || ''
      }

      setToastMessage('delivery_toast', toastMsg)
      Util.showNotification(`${translate('transaction')} -> ${toastMsg.by}: ${toastMsg.receipt_id}`)
    },

    handlePrintRemovedItem: function (_item, selectedCart, amount) {
      const item = JSON.parse(JSON.stringify(_item))
      const state = store.getState()

      if (
        state.settings?.disablePrintRemovedItems ||
        (!state.settings.kitchenPrint && !state.settings.kitchenDeliveryPrint)
      ) {
        return
      }

      if (item?.product && item?.status) {
        if (amount) {
          item.amount = amount
        }
        // Register removed items
        const itemPrice = item?.product?.current_variant?.price || 0
        const operationArgs = {
          action: 'item_removed',
          note: `Removed ${item.amount} item (${item.product.name})  with a value of ${
            state.devices.store.currency.symbol
          } ${(itemPrice * item.amount).toFixed(2) || 'Unknow'}`
        }

        RegisterOperationsUtils.applyOperation(operationArgs)

        const printRemoved = item.status.find(
          s => s.amount > 0 && s.state !== 'new' && s.state !== 'completed'
        )

        if (printRemoved) {
          const cart = {
            items: [item],
            order_source: 'web_pos',
            updated_at: new Date(),
            extras: { deviceCartName: selectedCart.extras.deviceCartName }
          }

          this.order(cart, null)
        }
      }
    }
  }
})()
