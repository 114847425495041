import { ADD_CUSTOMER, ADD_ALL_CUSTOMERS } from './../actions/actionTypes'

const initialState = {
  customers: []
}

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: state.customers.concat(action.payload)
      }
    case ADD_ALL_CUSTOMERS:
      return {
        ...state,
        customers: action.payload
      }
    default:
      return state
  }
}

export default customersReducer
