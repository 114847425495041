import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Text, translate } from 'react-internationalization'
import { Row, Button, Divider, Input } from '@countr/ui'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { RequestQueue } from '../../../utils/RequestQueue'
import { cartUtils } from '../../../utils/cartUtils'
import { selectCart, editCart } from '../../../store/actions/carts'
import { setToastMessage } from '../../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Switch } from '@countr/ui'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    theme: state.app.theme,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const defaultDiscounts = [0.05, 0.1, 0.15, 0.2]
const discountSet = ['discount_new_price', 'discount_percent', 'discount_value']

const CartDiscount = memo(props => {
  const [value, setValue] = useState(0)
  const [percent, setPercent] = useState(0)
  const [discountType, setDiscountType] = useState('discount_percent')
  const [isFixedDiscount, setIsFixedDiscount] = useState(0)
  const [note, setNote] = useState('')
  const [allCartsDiscount, setAllcartsDiscount] = useState(false)
  const hasItemsInPartial = cartUtils.checkCartPayments(props.cart, true)
  const style = props.theme &&
    props.theme.colors && {
      color: props.theme.colors.text,
      backgroundColor: props.theme.colors.body
    }

  useEffect(() => {
    if (props.cart.discount && props.cart.discount > 0) {
      setPercent(props.cart.discount * 100)
      setValue(props.cart.discount * 100)
    } else if (
      props.cart.reduction &&
      props.cart.reduction.numeric &&
      props.cart.reduction.numeric > 0
    ) {
      setIsFixedDiscount(props.cart.reduction.numeric)
      setValue(props.cart.reduction.numeric)
      setDiscountType('discount_value')
    }

    if (props.cart.extras.note) {
      setNote(props.cart.extras.note)
    }
    setAllcartsDiscount(false)
  }, [])

  const handleChangeValue = discount => {
    setValue(parseFloat(discount))
  }

  const handleChangeNote = value => {
    setNote(value)
  }

  const returnDiscountButtons = () => {
    const { options } = props.devices.store
    const discounts =
      !!options && !!options.presetDiscounts?.length ? options.presetDiscounts : defaultDiscounts

    return (
      <Row>
        {discounts.map(discount => (
          <Button
            key={discount}
            className="neutro"
            onClick={() => applySelectPercentDiscount(discount)}
            label={returnDiscountValueString(discount)}
            styles={{ width: '100%', margin: 2 }}
          />
        ))}
      </Row>
    )
  }

  const returnDiscountValueString = discount => {
    return `${parseFloat(discount * 100)}%`
  }

  const applySelectPercentDiscount = discount => {
    setDiscountType('discount_percent')
    setValue(discount * 100)
    setPercent(discount * 100)
    setIsFixedDiscount(0)
  }

  const returnTypeTranslated = type => {
    const currency = props.devices.store.currency
    if (type === 'discount_new_price') {
      return <Text id="new_price" values={{ currency: currency.symbol }} />
    } else if (type === 'discount_percent') {
      return `${translate('discount')} (%)`
    } else {
      return <Text id="discount_currency" values={{ currency: currency.symbol }} />
    }
  }

  const saveDiscount = async () => {
    const cart = JSON.parse(JSON.stringify(props.cart))

    // Cleaning discount first, than apply the new value
    cart.discount = 0
    cart.reduction = {
      numeric: 0,
      percentage: 0
    }

    if (discountType === 'discount_percent') {
      if (value > 100 || value < 0) {
        props.setToastMessage('invalid_discount')
        return
      } else {
        const paidSoFar = cart.payments.reduce((acc, current) => {
          return acc + current.paid
        }, 0)

        const numericDicount = (cart.total - paidSoFar) * (value / 100)

        const percentageValue = paidSoFar ? numericDicount / cart.total : value / 100

        cart.discount = percentageValue
        cart.reduction = {
          numeric: !!paidSoFar ? numericDicount : 0,
          percentage: percentageValue
        }
      }
    } else {
      // Using reduction

      if (discountType === 'discount_value') {
        if (value > cart.total || value < 0) {
          props.setToastMessage('invalid_discount')
          return
        }

        // Give x euros of discount in the total
        cart.reduction = {
          numeric: value,
          percentage: value / cart.total
        }
      } else if (discountType === 'discount_new_price') {
        cart.reduction={}
        cart.discount=0
        const countr = await AppInstances.getCountrSdk()
        const {
          totalAmount
        } = countr.calculateTotal(cart)
        if (value > totalAmount || value < 0) {
          props.setToastMessage('invalid_discount')
          return
        }

        const newPrice = totalAmount - value
        cart.reduction = {
          numeric: newPrice,
          percentage: newPrice / cart.total
        }
      }
    }

    updateCartAndCloseDiscount(cart)
  }

  const updateCartAndCloseDiscount = async cart => {
    const countr = await AppInstances.getCountrSdk()
    const {
      totalAmount,
      totalAmountPreTax,
      discountCapped = false,
      discountCapAmount = 0
    } = countr.calculateTotal(cart)

    cart.total = totalAmount
    cart.sub_total = totalAmountPreTax
    cart.reduction.capped = discountCapped
    cart.reduction.capAmount = discountCapAmount

    if (cart.extras) {
      cart.extras.note = note
    } else {
      cart.extras = {
        note: note
      }
    }

    props.editCart(cart)
    localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))

    countr.carts.update(cart._id, cart).then(
      updatedCart => {},
      error => {
        RequestQueue.enqueueAction({
          type: 'carts',
          action: 'update',
          payload: cart
        })
      }
    )

    props.selectCart(cart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
    props.handleCloseCloseDiscount()
  }

  return (
    <Dialog open={props.openCartDiscount} onClose={props.handleCloseCloseDiscount}>
      <DialogTitle style={style}>
        <Text id="discount" />
      </DialogTitle>
      <DialogContent className="discount-modal" style={style}>
        {props.settings.disableCustomDiscounts && (
          <Row>
            <span style={style}>
              <Text id="apply_discount" />
            </span>
          </Row>
        )}
        {returnDiscountButtons()}
        <br />
        {!props.settings.disableCustomDiscounts && (
          <>
            <Row>
              <span style={style}>
                <Text id="or_customize_discount" />
              </span>
            </Row>
            <Divider />
            <Row>
              {discountSet.map(type => (
                <Button
                  key={type}
                  className="primary"
                  onClick={() => setDiscountType(type)}
                  label={returnTypeTranslated(type)}
                  styles={{
                    margin: 2,
                    border:
                      type === discountType ? `2px solid ${props.theme.colors.secondaryText}` : ''
                  }}
                />
              ))}
            </Row>
            <Row>
              <Input
                type="number"
                label={<Text id="discount" />}
                value={value}
                onChange={handleChangeValue}
              />
            </Row>
          </>
        )}
        <Row>
          <Input
            type="text"
            label={<Text id="note" />}
            value={note}
            onChange={handleChangeNote}
            styles={{ width: '100%' }}
          />
        </Row>
        {!!hasItemsInPartial && (
          <Row className="warning-text">
            * <Text id="remove_partial_payment_by_item" />
          </Row>
        )}
      </DialogContent>
      <DialogActions style={style}>
        <Button
          label={<Text id="cancel" />}
          className="secondary"
          onClick={props.handleCloseCloseDiscount}
        />
        <Button
          label={<Text id="save" />}
          className="primary"
          onClick={saveDiscount}
          disabled={hasItemsInPartial}
        />
      </DialogActions>
    </Dialog>
  )
})

const CartDiscountConnected = connect(mapStateToProps, mapDispatchToProps)(CartDiscount)
export default CartDiscountConnected
