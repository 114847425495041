import { PureComponent } from 'react'
import { connect } from 'react-redux'

import CartPayBtn from './CartPayBtn'
import PaymentModal from '../Payments/PaymentModal'
import ConfirmationModal from './../generic/ConfirmationModal'
import FinishedTransactionDialog from '../Transaction/FinishedTransactionDialog'
import ContinuePartialPayment from '../Payments/ContinuePartialPayment'
import AskBuzzerInfoModal from './Optional/AskBuzzerInfoModal'

import { AppInstances } from '../../utils/countrSdkInstance'
import { cartUtils } from '../../utils/cartUtils'
import IntercomUtils from './../../utils/IntercomUtils'
import ScreenUtils from './../../utils/ScreenUtils'
import MerchantUtils from '../../utils/MerchantUtils'

import { getStartDay, evaluateCurrentShiftState } from './../../utils/EmployeeUtils'

import { showKds, showCart, setToastMessage } from '../../store/actions/app'
import { selectCart, editCart } from './../../store/actions/carts'

import { Text, translate } from 'react-internationalization'

import './CartTotal.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user.user,
    devices: state.devices,
    carts: state.carts,
    settings: state.settings,
    transactions: state.transactions,
    employees: state.employees
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showKds: show => dispatch(showKds(show)),
    showCart: () => dispatch(showCart()),
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart))
  }
}

class CartTotal extends PureComponent {
  state = {
    openTransactionDialog: false,
    transaction: {},
    openNewPaymentModal: false,
    openAgeConfirmation: false,
    openIdCheck: false,
    openBuzzerInfoRequest: false,
    openCoversTakeaway: false,
    customer: 'Anonymous',
    age: 0,
    isPartial: false,
    idsToCheck: [],
    idsChecked: false,
    minAgeChecked: false,
    cartItems: 0
  }

  componentDidUpdate() {
    if (this.props.carts.selectedCart.items.length !== this.state.cartItems) {
      this.resetIdChecks()
      this.setState({ cartItems: this.props.carts.selectedCart.items.length })
    }
  }

  componentDidMount() {
    AppInstances.getCountrSdk().then(socket => {
      socket.ws.on(`d${this.props.devices.device._id}:cart.paytriggered`, cartId => {
        socket.carts.readOne(cartId).then(cart => {
          this.props.selectCart(cart)
          this.handleOpenNewPaymentModal()
          this.props.showKds(false)
        })
      })
    })
  }

  resetIdChecks() {
    this.setState({ idsChecked: false, minAgeChecked: false })
  }

  handleClosePartialConfirmation = () => {
    this.setState({ openTransactionDialog: false, isPartial: false })

    if (this.props.app.cart) {
      this.props.showCart()
    }
  }

  handleCloseTransaction = feedback => {
    this.setState({ openTransactionDialog: false })

    if (feedback) {
      this.props.setToastMessage('email_successfully_sent')
    }

    if (this.props.app.cart) {
      this.props.showCart()
    }
  }

  handleOpenTransactionComplete = transactionCreated => {
    this.setState({
      transaction: { ...transactionCreated },
      openTransactionDialog: !!Object.keys(transactionCreated).length,
      isPartial: transactionCreated.paid < transactionCreated.total
    })
  }

  handleBuzzerInfoClose = value => {
    const cart = this.props.carts.selectedCart
    if (!!value) {
      // handleSpecialSelector(actionOrder.order[0], value)
      cart.extras.buzzer = value
    } else {
      cart.extras.buzzer = null
    }
    this.props.selectCart(cart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
    this.props.editCart(cart)
    localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))

    this.setState({ openBuzzerInfoRequest: false })
  }

  fixedDecimalNumber = value => {
    return parseFloat(value).toFixed(2)
  }

  checkShiftState() {
    if (!this.props.settings.openCloseShift) {
      return false
    }
    const { haveStartDay } = evaluateCurrentShiftState(
      getStartDay(this.props.employees.selectedEmployee._id || this.props.devices.device._id)
    )

    return !haveStartDay
  }

  handleCancelConfirmationModal = () => {
    this.removeAllPopups()
  }

  handleConfirmConfirmationModal = () => {
    this.setState({
      openAgeConfirmation: false,
      customer: 'Anonymous',
      age: 0,
      minAgeChecked: true
    })
  }

  handleCancelIdCheckConfirmationModal = () => {
    this.removeAllPopups()
    this.setState({ openIdCheck: false })
  }
  handleIdCheckConfirmationModal = () => {
    this.setState({ idsChecked: true, openIdCheck: false })
  }

  removeAllPopups = () => {
    this.setState({
      openIdCheck: false,
      openAgeConfirmation: false,
      customer: 'Anonymous',
      age: 0,
      openIdsChecked: false,
      openTransactionDialog: false,
      openNewPaymentModal: false,
      openBuzzerInfoRequest: false,
      openCoversTakeaway: false
    })
  }

  arrayToString = array => {
    let finalString = ''
    array.forEach(string => {
      if (finalString === '') {
        finalString += String(translate(string)).toUpperCase()
      } else {
        finalString += ', ' + String(translate(string)).toUpperCase()
      }
    })

    return finalString
  }

  checkId = cart => {
    if (!this.state.idsChecked) {
      const shouldCheck = cart.items.some(
        item =>
          item.product.options.id_check !== 'none' && item.product.options.id_check !== undefined
      )
      if (shouldCheck) {
        let uniqueChecks = cart.items
          .map(item => {
            return item.product.options.id_check
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index && value !== 'none'
          })
        this.setState({ openIdCheck: true, idsToCheck: uniqueChecks, idsChecked: true })
      }
    }
  }

  checkMinimumAge = cart => {
    if (!this.state.minAgeChecked) {
      const shouldCheck = cart.items.some(item => item.product.options.minimum_age > 0)
      if (shouldCheck) {
        const age =
          cart.customer && cart.customer.birth_date
            ? new Date().getFullYear() - new Date(cart.customer.birth_date).getFullYear()
            : 0
        const max = cart.items.reduce(
          (min, item) =>
            item.product.options.minimum_age > min && item.product.options.minimum_age > 0
              ? item.product.options.minimum_age
              : min,
          cart.items[0].product.options.minimum_age
        )

        if (max > age) {
          const name =
            cart.customer && cart.customer.first_name
              ? cart.customer.first_name
              : this.state.customer
          this.setState({ openAgeConfirmation: true, customer: name, age: max })
        }
      }
    }
  }

  handleCoversTakeaway = () => {
    this.setState(prevState => {
      return {
        openCoversTakeaway: !prevState.openCoversTakeaway
      }
    })
  }

  handleOpenNewPaymentModal = () => {
    const cart = { ...this.props.carts.selectedCart }
    const { enableCustomerScreen, customerScreenPort, askBuzzerInfo } = this.props.settings

    if (enableCustomerScreen && customerScreenPort && customerScreenPort.length) {
      ScreenUtils.showTotal(cart, customerScreenPort)
    }

    this.checkId(cart)

    this.checkMinimumAge(cart)

    if (askBuzzerInfo && !this.props.carts.selectedCart.extras.buzzer) {
      // Check if there is a cart item with one of the relevant categories
      // from this.props.settings.buzzerCategories

      if (
        cart.items.find(cartEntry => {
          let catFound = false
          ;(cartEntry.product?.categories || []).forEach(category => {
            if (this.props.settings.buzzerCategories.indexOf(category._id) >= 0) catFound = true
          })
          return catFound
        })
      )
        this.setState({ openBuzzerInfoRequest: true })
    }

    if (cartUtils.isDropShipCart(cart) && !cartUtils.hasCustomerCart(cart)) {
      this.props.setToastMessage('drop_ship_warning')
      return
    }

    this.performeOpenPaymentModal()
  }

  performeOpenPaymentModal = () => {
    if (this.props.carts.selectedCart.items && this.props.carts.selectedCart.items.length) {
      IntercomUtils.trackEvent('cart:pay_button')
      if (parseFloat(this.props.carts.selectedCart.total) >= 0) {
        this.setState({ openNewPaymentModal: true })
      } else {
        if (this.props.settings.allowNegativePayments) {
          this.setState({ openNewPaymentModal: true })
        } else {
          this.props.setToastMessage('negative_payments_warning')
        }
      }
    }
  }

  handleClosePaymentModal = transaction => {
    const completedTransaction = !!transaction?.receipt_id
    this.setState(
      {
        openNewPaymentModal: transaction?.change < -1 || false,
        idsChecked: !completedTransaction,
        minAgeChecked: !completedTransaction
      },
      () => {
        if (completedTransaction) {
          if (!this.props.settings.skipTransactionModal) {
            this.handleOpenTransactionComplete(transaction)
          }
        }
      }
    )
  }

  returnTotalToPay = () => {
    const cart = this.props.carts.selectedCart
    let value = `${this.props.symbol}`
    const paid = cart.paid || 0
    const total = cart.total || 0
    value += this.fixedDecimalNumber(total - paid)

    return `${translate('pay')} ${value}`
  }

  returnPayButtonText = () => {
    if (MerchantUtils.trialHasExpired(this.props.user)) {
      return (
        <small>
          <Text id="trial_expired" />
        </small>
      )
    } else if (this.checkShiftState()) {
      return (
        <small>
          <Text id="pay_before_open_error" />
        </small>
      )
    } else {
      return <font>{this.returnTotalToPay()}</font>
    }
  }

  isPayDisabled = () => {
    return this.checkShiftState() || MerchantUtils.trialHasExpired(this.props.user)
  }

  handleUpdateTransaction = t => {
    this.setState({ transaction: t })
  }

  render() {
    return (
      <div className="Cart-Total">
        <CartPayBtn
          testid="pay-btn"
          cart={this.props.carts.selectedCart}
          label={this.returnPayButtonText()}
          handleClick={this.handleOpenNewPaymentModal}
          isDisabled={this.isPayDisabled()}
        />
        {this.state.openNewPaymentModal && (
          <PaymentModal
            open={this.state.openNewPaymentModal}
            handleClose={this.handleClosePaymentModal}
            settings={this.props.settings}
          />
        )}
        {this.state.openTransactionDialog && (
          <>
            {this.state.isPartial ? (
              <ContinuePartialPayment
                openContinue={this.state.openTransactionDialog}
                handleClose={this.handleClosePartialConfirmation}
                cart={this.props.carts.selectedCart}
              />
            ) : (
              <FinishedTransactionDialog
                open={this.state.openTransactionDialog}
                handleClose={this.handleCloseTransaction}
                transaction={this.state.transaction}
                handleUpdateTransaction={this.handleUpdateTransaction}
              />
            )}
          </>
        )}
        {this.state.openAgeConfirmation && (
          <ConfirmationModal
            openConfirmation={this.state.openAgeConfirmation}
            confirmBtn={this.handleConfirmConfirmationModal}
            handleCancelBtn={this.handleCancelConfirmationModal}
            confirmationTitle={<Text id="minimum_age" />}
            confirmationText={
              <Text
                id="minimum_age_alert"
                values={{ customer: this.state.customer, age: this.state.age }}
              />
            }
            type="minimum_age_alert"
          />
        )}
        {this.state.openIdCheck && (
          <ConfirmationModal
            openConfirmation={this.state.openIdCheck}
            confirmBtn={this.handleIdCheckConfirmationModal}
            handleCancelBtn={this.handleCancelIdCheckConfirmationModal}
            confirmationTitle={<Text id="id_check" />}
            confirmationText={
              <Text
                id="id_check_alert"
                values={{
                  customer: this.state.customer,
                  id: this.arrayToString(this.state.idsToCheck)
                }}
              />
            }
            type="id_check_alert"
          />
        )}
        {this.state.openBuzzerInfoRequest && (
          <AskBuzzerInfoModal
            openBuzzerInfoRequest={this.state.openBuzzerInfoRequest}
            handleCloseBuzzerInfoRequest={this.handleBuzzerInfoClose}
            handleConfirmBuzzerInfo={this.handleBuzzerInfoClose}
            store={this.props.devices.store}
          />
        )}
      </div>
    )
  }
}

const CartTotalConnected = connect(mapStateToProps, mapDispatchToProps)(CartTotal)
export default CartTotalConnected
