import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'
import ProductUtils from '../../../../utils/ProductUtils'
import { ProductUtils as CountrProductUtils } from '@countr/utils'
import { setToastMessage } from '../../../../store/actions/app'

import Grid from '@material-ui/core/Grid'
import { Button, Input } from '@countr/ui'

import './ProductDetails.scss'

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const ProductDescription = memo(({ product, setToastMessage, language }) => {
  const [productsDescription, setProductsDescription] = useState('')
  const [editdescription, setEditDescription] = useState(false)

  const handleEditDescription = () => {
    setEditDescription(!editdescription)
  }

  const handleChange = value => {
    setProductsDescription(value)
  }

  const handleUpdateDescription = async () => {
    product.description = productsDescription
    const updated = await ProductUtils.updateProduct(product)

    if (updated && !updated.error) {
      handleEditDescription()
    } else if (updated.error && updated.message) {
      setToastMessage(updated.message)
    }
  }

  useEffect(() => {
    if (product) {
      const description = CountrProductUtils.getProductDescription(product, language)
      setProductsDescription(description)
    }
  }, [])
  return (
    <>
      <div className="title-div">
        <Text id="description" />
        {!editdescription && (
          <Button
            className="primary change-button"
            label={<Text id="change" />}
            onClick={handleEditDescription}
          />
        )}
      </div>
      <div className="sub-text">
        {editdescription ? (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <div className="modal-margin-bottom">
              <Text id="change_description" />
            </div>
            <div className="description-input">
              <Input value={productsDescription} onChange={handleChange} />
            </div>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              className="modal-margin-top">
              <Button
                className="primary description-buttons"
                label={<Text id="update" />}
                onClick={handleUpdateDescription}
              />

              <Button
                className="secondary description-buttons"
                label={<Text id="cancel" />}
                onClick={handleEditDescription}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            {product.description ? (
              <Text>{product.description}</Text>
            ) : (
              <Text id="no_description" />
            )}
          </>
        )}
      </div>
    </>
  )
})

export default connect(null, mapDispatchToProps)(ProductDescription)
