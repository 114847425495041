import {
  ADD_EMPLOYEE,
  ADD_ALL_EMPLOYEES,
  SELECT_EMPLOYEE,
  SHOW_EMPLOYEES_MODAL
} from './../actions/actionTypes'

const initialState = {
  employeesModal: false,
  employees: [],
  selectedEmployee: {}
}

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.concat(action.payload)
      }
    case ADD_ALL_EMPLOYEES:
      return {
        ...state,
        employees: action.payload
      }
    case SELECT_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: action.payload
      }
    case SHOW_EMPLOYEES_MODAL:
      return {
        ...state,
        employeesModal: !state.employeesModal
      }
    default:
      return state
  }
}

export default employeesReducer
