import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DesktopUtils from '../../utils/DesktopUtils'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Checkbox from '@material-ui/core/Checkbox'

import { Text } from 'react-internationalization'

import './PrinterSettings.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    categories: state.categories
  }
}

const ROLES = ['order', 'receipt', 'report', 'ticket']
const MAX_SIZE = 48
const MIN_SIZE = 30
const MIN_DESKTOP_VERSION = 3 // Comparing only the major version

export const PrinterDetails = memo(
  ({ printer, index, handleChangePrinter, removePrinter, categories, app }) => {
    const [paperSize, setPaperSize] = useState(34)
    const [isUSB, setIsUSB] = useState(false)

    useEffect(() => {
      if (printer.paper_size) {
        setPaperSize(printer.paper_size)
      }
      if (printer.isUSB) {
        setIsUSB(true)
      }
    }, [])

    const handleChange = name => event => {
      handleChangePrinter(name, event.target.value, index)
      if (name === 'name' || name === 'device') {
        setTimeout(() => {
          document.getElementById(`printer-${index}-${name}`).focus()
        }, 0)
      }
    }

    const handleChangePaperSize = (event, value) => {
      setPaperSize(value)
    }

    const handleChangeUSB = () => {
      setIsUSB(!isUSB)
      handleChangePrinter('isUSB', !isUSB, index)
    }

    const updatePaperSize = () => {
      handleChangePrinter('paper_size', paperSize, index)
    }

    return (
      <Grid container justifyContent="center" alignItems="center" spacing={8}>
        <Grid item xs={12} className="small-row line">
          <IconButton className="overlap-btn" onClick={() => removePrinter(index)}>
            <span className="icon-close" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={DesktopUtils.isVersionValid(app.desktopVersion, MIN_DESKTOP_VERSION) ? 5 : 6}
          className="line">
          <TextField
            label={<Text id="printer_name" />}
            fullWidth={true}
            value={printer.name}
            onChange={handleChange('name')}
            type="text"
            id={`printer-${index}-name`}
          />
        </Grid>
        <Grid
          item
          xs={DesktopUtils.isVersionValid(app.desktopVersion, MIN_DESKTOP_VERSION) ? 4 : 6}
          className="line">
          <TextField
            label={<Text id="printer_ip" />}
            fullWidth={true}
            value={printer.device}
            onChange={handleChange('device')}
            type="text"
            id={`printer-${index}-device`}
            disabled={isUSB}
          />
        </Grid>
        {DesktopUtils.isVersionValid(app.desktopVersion, MIN_DESKTOP_VERSION) && (
          <Grid item xs={3} className="usb-grid line">
            <Checkbox checked={isUSB} onChange={handleChangeUSB} style={{ color: '#318ed5' }} />
            <InputLabel className="usb-checkbox" onClick={handleChangeUSB}>
              <Text id="usb" />
            </InputLabel>
          </Grid>
        )}
        <Grid item xs={12} className="line">
          <InputLabel className="select-label">
            <Text id="printer_roles" />
          </InputLabel>
          <Select
            multiple
            value={printer.roles}
            onChange={handleChange('roles')}
            className="select-input">
            {ROLES.map(role => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} className="line">
          <InputLabel className="select-label">
            <Text id="printer_categories" />
          </InputLabel>
          <Select
            multiple
            value={printer.categories}
            onChange={handleChange('categories')}
            className="select-input">
            {categories.categories.map(category => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} className="line">
          <Typography id="label">Paper Size: {paperSize}</Typography>
          <Slider
            value={paperSize}
            min={MIN_SIZE}
            max={MAX_SIZE}
            step={1}
            onChange={handleChangePaperSize}
            onChangeCommitted={updatePaperSize}
            style={{ marginTop: 15 }}
          />
        </Grid>
      </Grid>
    )
  }
)

PrinterDetails.propTypes = {
  printer: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(PrinterDetails)
